<template>
  <v-card flat class="tab-card">
    <div class="new-client-button-clear"></div>
    <v-layout>
      <v-expansion-panels>
        <v-expansion-panel class="search">
          <v-expansion-panel-header>
            <div id="excursion-list--filter-by-header">
              <v-icon class="fa fa-filter"></v-icon>
              FILTER BY
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list>
              <v-subheader>General</v-subheader>
              <v-layout ml-3>
                <v-flex xs5>
                  <v-text-field
                    id="excursion-list--excursion-filter-id-search--text-input"
                    v-model="searchExcursionId"
                    label="Excursion ID"
                    class="excursion-filter-id excursion-filter-search excursion-input"
                    single-line
                    type="text"
                    @keyup.enter="getExcursionFilterData(true)"
                    :error-messages="excursionIdErrors"
                    @input="$v.searchExcursionId.$touch()"
                    @blur="$v.searchExcursionId.$touch()"
                  ></v-text-field>
                </v-flex>
                <v-flex xs5>
                  <v-text-field
                    v-model="searchExcursionKeyword"
                    id="searchExcursionText"
                    label="Search"
                    single-line
                    @click:append="getExcursionFilterData(true)"
                    @keyup.enter="getExcursionFilterData(true)"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout ml-3>
                <v-flex xs5>
                  <v-select
                    id="excursion-list--excursion-filter-excursion-type--selection"
                    placeholder="Select Excursion Type"
                    :items="excursionTypeChoiceList"
                    v-model="searchExcursionType"
                    @change="getExcursionFilterData(true)"
                  >
                    <template slot="item" slot-scope="data">
                      <div
                        :id="`excursion-list--excursion-type--option-${String(data.item).replaceAll(
                          ' ',
                          ''
                        )}`"
                      >
                        {{ data.item }}
                      </div>
                    </template>
                  </v-select>
                </v-flex>
                <v-flex xs5>
                  <v-select
                    id="excursion-list--excursion-filter-excursion-status--selection"
                    placeholder="Select Excursion Status"
                    :items="excursionStatus"
                    v-model="searchExcursionStatus"
                    @change="getExcursionFilterData(true)"
                  >
                    <template slot="item" slot-scope="data">
                      <div
                        :id="`excursion-list--excursion-status--option-${String(
                          data.item
                        ).replaceAll(' ', '')}`"
                      >
                        {{ data.item }}
                      </div>
                    </template>
                  </v-select>
                </v-flex>
              </v-layout>
              <v-subheader v-if="excursionListFilterSectionDisplayed">Group</v-subheader>
              <v-layout ml-3 v-if="excursionListFilterSectionDisplayed">
                <v-flex xs12>
                  <v-autocomplete
                    label="Select one or more Clients"
                    :items="clientItems"
                    v-model="searchClientSids"
                    multiple
                    :search-input.sync="searchClients"
                    cache-items
                    clearable
                    chips
                    hide-details
                    hide-selected
                    :deletable-chips="true"
                    id="excursion-list--search-clients"
                    item-text="name"
                    item-value="sid"
                    @change.native="handleClientChange"
                    ref="selectClient"
                    @input="searchClients = null"
                  >
                    <template slot="item" slot-scope="data">
                      <div
                        :id="`excursion-list--excursion-status--option-${String(
                          data.item.name
                        ).replaceAll(' ', '')}`"
                      >
                        {{ data.item.name }}
                      </div>
                    </template>
                  </v-autocomplete>
                </v-flex>
              </v-layout>
              <v-subheader>Integration</v-subheader>
              <v-layout ml-3>
                <v-flex xs3>
                  <v-text-field
                    v-model="searchVendorExcursionSid"
                    label="Vendor Excursion SID"
                    single-line
                    type="text"
                    @keyup.enter="getExcursionFilterData"
                    id="excursion-list--search-vendor-excursion-sid"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout ml-3 mt-3>
                <v-flex>
                  <v-btn
                    class="normal-btn"
                    color="primary"
                    id="excursion-list--search-button"
                    @click.native="getExcursionFilterData(true)"
                  >
                    <v-icon>search</v-icon>Search
                  </v-btn>
                  <v-btn
                    class="ml-3 normal-btn"
                    outlined
                    color="primary"
                    id="excursion-list--search-clear-button"
                    @click.native="handleClearExcursionFilterButtonClick"
                  >
                    Clear
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-layout>
    <v-data-table
      :headers="getExcursionTableHeaders()"
      :items="excursionList"
      :options.sync="pagination"
      class="elevation-2 excursion-table table-responsive"
      :footer-props="footerProps"
      :server-items-length="excursionListTotal"
      :loading="loading"
      :no-data-text="noDataText"
    >
      <template slot="item" slot-scope="props">
        <tr>
          <td class="text-xs-center">
            <a
              :href="`/excursions/${props.item.id}`"
              :id="`excursion-list--${String(props.item.name).replaceAll(' ', '')}--link-${
                props.index
              }`"
              >{{ props.item.id }}</a
            >
          </td>
          <td class="excursion-name-cell white-wrap-normal">
            <div class="excursion-list--padding-top">{{ props.item.name }}</div>
            <div class="excursion-list-created">
              <div class="excursion-list--padding-bottom">
                <span>
                  <v-tooltip right>
                    <template v-slot:activator="{ on: tooltip }">
                      <span v-on="{ ...tooltip }">
                        {{
                          userSegmentFromAuthor(
                            props.item.user_email,
                            props.item.author_display_name
                          )
                        }}
                      </span>
                    </template>
                    <span>
                      {{
                        emailAddressFromAuthor(
                          props.item.user_email,
                          props.item.author_display_name
                        )
                      }}
                    </span>
                  </v-tooltip>
                  ,&nbsp;{{ props.item.created_at }}
                </span>
              </div>
            </div>
          </td>
          <td class="text-xs-center">
            <v-chip color="primary" outlined class="excursion-list--chip-size excursion-list--type">
              {{ props.item.type }}
            </v-chip>
          </td>
          <td class="text-xs-center">
            <v-chip
              color="#555757"
              outlined
              label
              class="excursion-list--chip-size excursion-list--status"
            >
              {{
                props.item.status
                  ? props.item.status.substr(0, 1).toUpperCase() + props.item.status.substr(1)
                  : ''
              }}
            </v-chip>
          </td>
          <td class="text-xs-left" v-if="excursionListClientColumnDisplayed">
            {{ props.item.client_name }}
          </td>
          <td class="text-xs-left" v-if="excursionListOrganisationNameColumnDisplayed">
            {{ props.item.parent_client_name }}
          </td>
          <td class="text-xs-left">
            <v-tooltip left color="#ffffff">
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" class="d-inline">
                  <v-btn
                    icon
                    v-bind="attrs"
                    class="excursion-list--btn-download"
                    :class="excursionCopyDisplayed ? '' : 'ml-3'"
                    :disabled="props.item.status === 'draft'"
                    @click.native="
                      exportRiskAssessment(
                        props.item.id,
                        props.item.type,
                        props.item.name,
                        props.item.status,
                        props.item.jurisdiction
                      )
                    "
                    :id="`excursion-list--btn-download-${props.index}`"
                  >
                    <i class="fas fa-download copy-icon"></i>
                  </v-btn>
                </div>
              </template>
              <span
                v-if="props.item.status === 'draft'"
                style="color: gray"
                class="action-tooltip-content"
                >Exporting risk assessment is unavailable for 'Draft' excursions</span
              >
              <span
                v-if="props.item.status !== 'draft'"
                style="color: gray"
                class="action-tooltip-content"
                >Export Risk Assessment</span
              >
            </v-tooltip>
            <v-tooltip left color="#ffffff" v-if="excursionCopyDisplayed">
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  v-on="{ ...tooltip }"
                  icon
                  class="excursion-list--btn-download"
                  @click.native="
                    openExcursionBeforeCopyDialog(props.item.id, props.item.type, props.item.name)
                  "
                  :id="`excursion-list--btn-copy-${props.index}`"
                >
                  <i class="fas fa-copy copy-icon"></i>
                </v-btn>
              </template>
              <span style="color: gray" class="action-tooltip-content">Copy Excursion</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </v-data-table>
    <div>
      <cs-form-dialog
        v-model="excursionBeforeCopyConfirmationDialogDisplayed"
        id="dlg-excursions-copy-excursion"
        heading="Excursions - Copy Excursion"
        persistent
        :primaryAction="{
          label: 'OK',
          eventName: 'primary-click',
          disabled: !continueBtnEnabled
        }"
        :secondaryAction1="{ label: 'Cancel', eventName: 'secondary1-click' }"
        @primary-click="openExcursionCopy"
        @secondary1-click="closeExcursionCopy"
      >
        <template v-slot:cs-form-dialog-content>
          <v-card-text>
            <v-layout>
              {{ excursionBeforeCopyConfirmationDialogMessage }}
            </v-layout>
            <v-radio-group v-model="excursionCopySelection">
              <v-layout class="excursion-convert-to-template">
                <v-flex>
                  <v-radio
                    id="convertToTemplateEnabled"
                    label="Copy excursion and convert to template"
                    value="1"
                  ></v-radio>
                </v-flex>
              </v-layout>
              <v-layout class="excursion-convert-to-template">
                <v-flex>
                  <v-radio
                    id="convertToTemplateNotEnabled"
                    v-if="hasClientRelativeList"
                    label="Copy excursion within this school"
                    value="2"
                  ></v-radio>
                  <v-radio
                    id="convertToTemplateNotEnabled"
                    v-if="!hasClientRelativeList"
                    label="Copy excursion"
                    value="2"
                  ></v-radio>
                </v-flex>
              </v-layout>
              <v-layout
                v-if="hasClientRelativeList && clientRelativeListItems.length > 0"
                class="excursion-convert-to-template excursion-copy-to-sibling"
              >
                <v-flex xs8>
                  <v-radio
                    id="copyExcursionToSiblingEnabled"
                    label="Copy excursion to another school"
                    value="3"
                  ></v-radio>
                </v-flex>
                <v-select
                  v-if="excursionCopySelection == 3"
                  v-model="siblingSchool"
                  class="sibling-school-select"
                  :items="clientRelativeListItems"
                  item-text="siblingSchoolname"
                  item-value="siblingSchoolname"
                  id="selectedSiblingSchool"
                  testing-id="selected-sibling-school"
                >
                  <template slot="item" slot-scope="data">
                    <div
                      :id="`excursion-list--excursion-before-copy-confirmation-dialog--sibling-school--option-${data.item}`"
                    >
                      {{ data.item }}
                    </div>
                  </template>
                </v-select>
              </v-layout>
            </v-radio-group>
          </v-card-text>
        </template>
      </cs-form-dialog>
      <cs-form-dialog
        v-model="copyExcursionGenericDialogDisplayed"
        id="dlg-excursions-copy-excursion-status"
        heading="Excursions - Copy Excursion Status"
        bodyId="excursion-list--copy-excursion-modal--generic-dialog"
        :primaryAction="{ label: 'OK', eventName: 'primary-click' }"
        @primary-click="
          handlecopyExcursionGenericDialogOkButtonClick(copyExcursionGenericDialogMessage)
        "
      >
        <template v-slot:cs-form-dialog-content>
          <v-spacer></v-spacer>
          <v-card-text>
            <div v-safe-html="copyExcursionGenericDialogMessage"></div>
          </v-card-text>
        </template>
      </cs-form-dialog>
      <cs-form-dialog
        v-model="riskAssessmentExportModalDisplayed"
        id="dlg-excursions-export-risk-assessment"
        heading="Excursions - Export Risk Assessment"
        :primaryAction="{
          label: 'Export PDF',
          eventName: 'primary-click',
          disabled: riskAssessmentDownloadActionInProgress,
          loading: riskAssessmentDownloadActionInProgress
        }"
        :secondaryAction1="{
          label: 'Export DOC',
          eventName: 'secondary1-click',
          disabled: riskAssessmentDownloadActionInProgress,
          loading: riskAssessmentDownloadActionInProgress
        }"
        :secondaryAction2="{ label: 'Cancel', eventName: 'secondary2-click' }"
        @primary-click="downloadAssessment(`pdf`)"
        @secondary1-click="downloadAssessment(`docx`)"
        @secondary2-click="close"
        @input="onDlgExportClose"
      >
        <template v-slot:cs-form-dialog-content>
          <v-card-text>
            <v-container class="export-excursion-policy">
              <div>
                <v-layout class="selected-risk-container">
                  <v-flex xs1>
                    <v-checkbox
                      id="export-excursion-risk-assessment--export-all-checklists"
                      v-model="allChecklistsExported"
                      class="excursion-policy-row-title-checkbox"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex
                    id="export-excursion-risk-assessment--export-all-checklists--label"
                    align-end
                    justify-end
                    class="excursion-policy-row-title"
                    @click="allChecklistsExported = !allChecklistsExported"
                    >Export All Checklists</v-flex
                  >
                </v-layout>
                <v-layout class="selected-risk-container">
                  <v-flex xs1>
                    <v-checkbox
                      id="export-excursion-risk-assessment--pre-departure-checklist"
                      v-model="predepartureChecklistExported"
                      class="excursion-policy-row-title-checkbox"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex
                    id="export-excursion-risk-assessment--pre-departure-checklist--label"
                    align-end
                    justify-end
                    class="excursion-policy-row-title"
                    @click="predepartureChecklistExported = !predepartureChecklistExported"
                    >Pre-Departure Checklist</v-flex
                  >
                </v-layout>
                <v-layout class="selected-risk-container">
                  <v-flex xs1>
                    <v-checkbox
                      id="export-excursion-risk-assessment--all-attachments-checkbox"
                      v-model="allAttachmentsExported"
                      class="excursion-policy-row-title-checkbox"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex
                    id="export-excursion-risk-assessment--all-attachments--label"
                    align-end
                    justify-end
                    class="excursion-policy-row-title"
                    @click="allAttachmentsExported = !allAttachmentsExported"
                    >Include All Attachments</v-flex
                  >
                </v-layout>
              </div>
            </v-container>
          </v-card-text>
        </template>
      </cs-form-dialog>
    </div>
    <page-loading-animation :displayed="loading"></page-loading-animation>
  </v-card>
</template>

<script>
import * as types from '@/store/mutationTypes';
import { validationMixin } from 'vuelidate';
import { numeric } from 'vuelidate/lib/validators';
import excursionTypeInfo from '../lib/const/excursionTypeInfo';
import excursionStatus from '../lib/const/excursionStatus';
import trackableEvent from '../lib/const/trackableEvent';
import excursionTableColumn from '../lib/const/excursionTableColumn';
import { populateErrorMessage } from '../lib/errorMessages';

export default {
  mixins: [validationMixin],
  validations: {
    searchExcursionId: {
      numeric
    }
  },
  props: ['excursionTemplateFlag'],
  data() {
    return {
      cacheResetFlag: false,
      debugFlag: false,
      propExcursionCopyModalRefreshed: true,
      excursionTypeName: '',
      excursionId: 0,
      excursionCopyModalDisplayed: false,
      search: '',
      querystringRefToTableColumnRefMap: {},
      tableColumnRefToQuerystringRefMap: {},
      footerProps: {
        'items-per-page-options': [10, 30, 50]
      },
      pagination: {
        itemsPerPage: 30,
        page: 1,
        sortDesc: [true],
        sortBy: ['']
      },
      excursionHeaders: [
        {
          text: excursionTableColumn.ID.label,
          value: excursionTableColumn.ID.tableColumnRef,
          class: 'header text-xs-center',
          sortable: false
        },
        {
          text: excursionTableColumn.DETAILS.label,
          value: excursionTableColumn.DETAILS.tableColumnRef,
          class: 'header'
        },
        {
          text: excursionTableColumn.TYPE.label,
          value: excursionTableColumn.TYPE.tableColumnRef,
          class: 'header',
          align: 'center',
          sortable: false
        },
        {
          text: excursionTableColumn.STATUS.label,
          value: excursionTableColumn.STATUS.tableColumnRef,
          class: 'header',
          align: 'center',
          sortable: false
        }
      ],
      searchClientName: '',
      searchExcursionId: '',
      searchExcursionKeyword: '',
      searchVendorExcursionSid: '',
      searchExcursionType: '',
      searchExcursionStatus: '',
      searchClientSids: '',
      noDataText: 'No excursion was found',
      excursionBeforeCopyConfirmationDialogDisplayed: false,
      excursionBeforeCopyConfirmationDialogMessage:
        'Only the risk profile will be copied. All content and checked items will be created from scratch.',
      convertToTemplateEnabled: false,
      searchClients: '',
      clientSubdomain: '',
      excursionCopySelection: '2',
      siblingSchool: '',
      excursionName: '',
      copyExcursionGenericDialogDisplayed: false,
      copyExcursionGenericDialogMessage: '',
      copyExcursionGenericDialogSuccessMessage: 'The excursion has been copied.',
      riskAssessmentExportModalDisplayed: false,
      riskAssessmentDownloadActionInProgress: false,
      allChecklistsExported: true,
      predepartureChecklistExported: false,
      allAttachmentsExported: false,
      exportedExcursionDc: {}
    };
  },
  methods: {
    getExcursionTableHeaders() {
      const header = JSON.parse(JSON.stringify(this.excursionHeaders));
      if (this.excursionListClientColumnDisplayed) {
        header.splice(6, 0, {
          text: excursionTableColumn.CLIENT.label,
          value: excursionTableColumn.CLIENT.tableColumnRef,
          class: 'header'
        });
      }

      if (this.excursionListOrganisationNameColumnDisplayed) {
        header.push({
          text: excursionTableColumn.ORGANISATION.label,
          value: excursionTableColumn.ORGANISATION.label,
          class: 'header',
          sortable: false
        });
      }
      header.push({
        text: excursionTableColumn.ACTION.label,
        value: excursionTableColumn.ACTION.tableColumnRef,
        class: 'header text-xs-left pl-4',
        sortable: false,
        width: '82px'
      });

      return header;
    },
    async getExcursionFilterData(pageResetTriggered = false) {
      if (pageResetTriggered) {
        this.resetPage();
      }
      if (!this.$v.$invalid) {
        this.$store.commit(types.COMMON_SET_EXCURSIONS_LIST_LOADING, true);
        const filterClientSids =
          this.searchClientSids && this.searchClientSids.length > 0 ? this.searchClientSids : '';
        const filterParam = {
          'excursion-template-flag': this.excursionTemplateFlag,
          s: this.searchExcursionKeyword,
          's-excursion-id': this.searchExcursionId,
          's-excursion-type': this.searchExcursionType,
          's-vendor-client-sid': this.searchVendorExcursionSid,
          's-excursion-status': this.searchExcursionStatus,
          's-client-sids': filterClientSids,
          page: this.pagination.page,
          length: this.pagination.itemsPerPage
        };

        if (this.cacheResetFlag) {
          filterParam['cache-reset-flag'] = true;
        }

        if (this.debugFlag) {
          filterParam['debug-flag'] = true;
        }
        const searchQuery = JSON.parse(JSON.stringify(filterParam));
        if (this.tableColumnRefToQuerystringRefMap[this.pagination.sortBy[0]]) {
          const [paginationFirstSortBy] = this.pagination.sortBy;
          filterParam.order = paginationFirstSortBy;
          filterParam.direction = this.pagination.sortDesc[0] === false ? 'ASC' : 'DESC';
          searchQuery.order = this.tableColumnRefToQuerystringRefMap[this.pagination.sortBy[0]];
          searchQuery.direction = filterParam.direction;
        }

        Object.keys(filterParam).forEach((key) => {
          if (filterParam[key] === '' || undefined === filterParam[key]) {
            delete filterParam[key];
          }
        });
        Object.keys(searchQuery).forEach((key) => {
          if (searchQuery[key] === '' || undefined === searchQuery[key]) {
            delete searchQuery[key];
          }
        });

        const argHash = {
          stbApiAdapter: this.$app.stbApiAdapter,
          filterParam,
          store: this.$store
        };
        await this.$store.dispatch('common/getExcursionList', argHash);
        this.$store.commit(types.COMMON_SET_EXCURSIONS_LIST_LOADING, false);
        this.$router.replace({ query: searchQuery }, () => {});
      }
    },
    openExcursionBeforeCopyDialog(id, type, name) {
      this.excursionTypeName = type;
      this.excursionId = id;
      this.excursionName = name;
      this.excursionBeforeCopyConfirmationDialogDisplayed = true;
      this.convertToTemplateEnabled = false;
    },
    async openExcursionCopy() {
      this.excursionBeforeCopyConfirmationDialogDisplayed = false;

      const apiPayload = {
        'new-excursion-name': `Copy of ${this.excursionName}`
      };

      if (
        this.excursionCopySelection === '3' &&
        this.selectedSiblingSchool &&
        this.selectedSiblingSchool.clientSid
      ) {
        apiPayload['new-client-sid'] = this.selectedSiblingSchool.clientSid;
      }
      if (this.excursionCopySelection === '1') {
        apiPayload['new-excursion-template-flag'] = true;
      }
      const postExcursionCopiesApiArgHash = {
        id: this.excursionId,
        payload: apiPayload,
        store: this.$store
      };
      const postExcursionCopiesCbResponse = await this.$app.stbApiAdapter.postExcursionCopies(
        postExcursionCopiesApiArgHash
      );
      if (!postExcursionCopiesCbResponse.error) {
        this.copyExcursionGenericDialogMessage = this.copyExcursionGenericDialogSuccessMessage;
        this.copyExcursionGenericDialogDisplayed = true;
      } else {
        this.copyExcursionGenericDialogMessage = `Excursion cannot be copied. ${postExcursionCopiesCbResponse.message}.`;

        this.copyExcursionGenericDialogMessage = populateErrorMessage({
          message: this.copyExcursionGenericDialogMessage,
          store: this.$store
        });

        this.copyExcursionGenericDialogDisplayed = true;
      }
    },
    handlecopyExcursionGenericDialogOkButtonClick() {
      if (
        this.copyExcursionGenericDialogMessage === this.copyExcursionGenericDialogSuccessMessage
      ) {
        this.$router.go();
      }
      this.copyExcursionGenericDialogDisplayed = false;
    },
    closeExcursionCopy() {
      this.excursionBeforeCopyConfirmationDialogDisplayed = false;
      this.convertToTemplateEnabled = false;
    },
    capitalize(str) {
      return str
        .toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
    },
    async handleClientChange() {
      await this.getExcursionFilterData(true);
    },
    userSegmentFromAuthor(email, authorDisplayName) {
      let localEmail = email || authorDisplayName;
      if (localEmail) {
        localEmail = localEmail.substr(0, localEmail.indexOf('@'));
      } else {
        localEmail = '';
      }
      return localEmail;
    },
    emailAddressFromAuthor(email, authorDisplayName) {
      let localEmail = email || authorDisplayName;
      if (!localEmail) {
        localEmail = '';
      }
      return localEmail;
    },
    async filterClients(val) {
      const { excursionListAllClientSuggestionDisplayed } = this.$store.state.common;

      // eslint-disable-next-line no-param-reassign
      val = val || '';

      this.$store.commit(types.COMMON_SET_CLIENT_CLIENT_LIST_LOADING, true);
      const filterParam = {
        s: val,
        page: this.pagination.page,
        length: 5,
        direction: this.pagination.sortDesc[0] === false ? 'ASC' : 'DESC'
      };
      Object.keys(filterParam).forEach((key) => {
        if (filterParam[key] === '') {
          delete filterParam[key];
        }
      });
      const store = this.$store;
      const argHash = {
        stbApiAdapter: this.$app.stbApiAdapter,
        filterParam,
        store
      };

      if (excursionListAllClientSuggestionDisplayed && !this.parentClientSiteFlag) {
        await this.$store.dispatch('common/getClientList', argHash);
      } else {
        await this.$store.dispatch('common/getClientClientList', argHash);
      }

      this.$store.commit(types.COMMON_SET_CLIENT_CLIENT_LIST_LOADING, false);
    },
    resetPage() {
      this.pagination.itemsPerPage = 30;
      this.pagination.page = 1;
      this.pagination.sortDesc[0] = true;
      this.pagination.sortBy[0] = '';
    },
    resetExcursionFilter() {
      this.cacheResetFlag = false;
      this.debugFlag = false;
      this.searchExcursionKeyword = '';
      this.searchExcursionId = '';
      this.searchExcursionType = '';
      this.searchExcursionStatus = '';
      this.resetPage();
      this.searchClientSids = '';
      this.searchVendorExcursionSid = '';
    },
    handleClearExcursionFilterButtonClick() {
      this.resetExcursionFilter();
      this.$router.replace({ query: null });
      this.getExcursionFilterData();
    },
    handleHomeButtonClick() {
      this.resetExcursionFilter();
      if (window.location.search !== `?page=1&length=30`) {
        window.location.href = `/excursions?page=1&length=30`;
      }
    },
    close() {
      this.riskAssessmentDownloadActionInProgress = false;
      this.riskAssessmentExportModalDisplayed = false;
      this.allChecklistsExported = true;
      this.predepartureChecklistExported = false;
      this.allAttachmentsExported = false;
      this.exportedExcursionDc = {};
    },
    async downloadAssessment(format) {
      this.riskAssessmentDownloadActionInProgress = true;

      const trackableEventBaseArgHash =
        this.$app.excursionCommon.getRiskAssessmentTrackableEventBaseArgHash(
          this.$store.state.common.apiDataDefaultJurisdiction,
          this.exportedExcursionDc
        );
      const trackableEventOptionArgHash =
        this.$app.excursionCommon.getRiskAssessmentTrackableEventOptionArgHash();
      this.$app.eventPluginCommonAdapter.registerTrackableEvent(
        trackableEvent.RISK_ASSESSMENT_EXCURSION_RISK_ASSESSMENT_EXPORT,
        trackableEventBaseArgHash,
        trackableEventOptionArgHash
      );

      const { query } = this.$route;
      const clientLogoEnabled = !(query && query.referrer && query.referrer === 'pcg');

      const excursionCommonAdapter = new this.$app.excursionCommon.Adapter({
        store: this.$store,
        app: this.$app
      });

      await excursionCommonAdapter.downloadAssessment(
        this.exportedExcursionDc.id,
        query,
        this.allChecklistsExported,
        clientLogoEnabled,
        this.predepartureChecklistExported,
        this.allAttachmentsExported,
        format,
        this.$app.deviceDetector
      );

      this.close();
    },
    async exportRiskAssessment(id, type, name, status, jurisdiction) {
      this.exportedExcursionDc.id = id;
      this.exportedExcursionDc.name = name;
      this.exportedExcursionDc.typeId = type;
      this.exportedExcursionDc.status = status;
      const argHash = {
        stbApiAdapter: this.$app.stbApiAdapter,
        store: this.$store,
        queryParam: this.$route.query
      };
      await this.$store.dispatch('common/getDefaultJurisdiction', argHash);
      this.exportedExcursionDc.jurisdiction =
        jurisdiction === '' || jurisdiction === undefined
          ? this.$store.state.common.apiDataDefaultJurisdiction
          : jurisdiction;
      this.riskAssessmentExportModalDisplayed = true;
    },
    onDlgExportClose(boolShow) {
      if (!boolShow) {
        this.close();
      }
    }
  },
  computed: {
    excursionIdErrors() {
      const errors = [];
      if (!this.$v.searchExcursionId.$dirty) return errors;
      if (!this.$v.searchExcursionId.numeric) {
        errors.push('Excursion ID must be a number');
      }
      return errors;
    },
    excursionListClientColumnDisplayed() {
      return (
        (this.$store.state.common.excursionListClientColumnDisplayed && !this.clientSubdomain) ||
        (this.clientSubdomain && this.parentClientSiteFlag)
      );
    },
    excursionListFilterSectionDisplayed() {
      return this.$store.state.common.excursionListFilterSectionDisplayed;
    },
    excursionList() {
      return this.$store.state.common.excursionsList.data;
    },
    excursionCopyDisplayed() {
      return (
        this.$store.state.common.excursionCopyActionEnabled &&
        this.$store.state.common.copyExcursionButtonDisplayed
      );
    },
    loading() {
      return this.$store.state.common.excursionsList.loading;
    },
    clientSetupLoading() {
      return this.$store.state.authorisation.isInitClientConfigInProgress;
    },
    excursionListTotal() {
      return this.$store.state.common.excursionsList.total;
    },
    clientItems() {
      let items;
      if (
        this.$store.state.common.excursionListAllClientSuggestionDisplayed &&
        !this.parentClientSiteFlag
      ) {
        items = this.$store.state.common.clientList.data.map((clientItem) => {
          return {
            sid: clientItem.sid,
            name: `${clientItem.sid} - ${clientItem.metadata.name}`
          };
        });
      } else {
        items = this.$store.state.common.clientClientList.items;
      }
      return items;
    },
    excursionTypeChoiceList() {
      return [
        excursionTypeInfo.DAY_EXCURSION.label,
        excursionTypeInfo.OVERNIGHT_EXCURSION.label,
        excursionTypeInfo.INTERNATIONAL_EXCURSION.label
      ];
    },
    excursionStatus() {
      return [
        this.capitalize(excursionStatus.DRAFT),
        this.capitalize(excursionStatus.SUBMITTED),
        this.capitalize(excursionStatus.APPROVED),
        this.capitalize(excursionStatus.ARCHIVED)
      ];
    },
    excursionListOrganisationNameColumnDisplayed() {
      const { clientParentId } = this.$store.state.common;
      return !this.clientSubdomain || (this.clientSubdomain && clientParentId);
    },
    parentClientSiteFlag() {
      return this.$store.state.common.parentClientSiteFlag;
    },
    hasClientRelativeList() {
      if (!this.clientSetupLoading) {
        const localClientRelativeList = this.$store.state.common.clientRelativeList;
        if (localClientRelativeList && localClientRelativeList.length > 0) {
          return true;
        }
        return false;
      }
      return false;
    },
    clientRelativeList() {
      const selectedExcursion = this.excursionList.filter(
        (item) => item.id === this.excursionId
      )[0];
      if (!this.clientSetupLoading && selectedExcursion) {
        const localClientRelativeList = this.$store.state.common.clientRelativeList;
        const siblingSchools = localClientRelativeList.filter(
          (item) => item.clientName !== selectedExcursion.client_name
        );
        return siblingSchools;
      }
      return [];
    },
    clientRelativeListItems() {
      if (this.hasClientRelativeList) {
        return this.clientRelativeList.map((item) => item.clientName);
      }
      return [];
    },
    continueBtnEnabled() {
      if (this.excursionCopySelection === '3' && this.siblingSchool === '') {
        return false;
      }
      return true;
    },
    selectedSiblingSchool() {
      const localClientRelativeList = this.$store.state.common.clientRelativeList;
      if (localClientRelativeList) {
        const localSelectedSiblingSchool = localClientRelativeList.filter(
          (item) => item.clientName === this.siblingSchool
        )[0];
        return localSelectedSiblingSchool;
      }
      return null;
    }
  },
  async mounted() {
    const { hostname } = window.location;
    const uiBaseDomain = this.$app.env.UI_BASE_DOMAIN;
    this.clientSubdomain = hostname.substring(0, hostname.indexOf(uiBaseDomain) - 1);

    Object.keys(excursionTableColumn).forEach((key) => {
      this.querystringRefToTableColumnRefMap[excursionTableColumn[key].querystringRef] =
        excursionTableColumn[key].tableColumnRef;
      this.tableColumnRefToQuerystringRefMap[excursionTableColumn[key].tableColumnRef] =
        excursionTableColumn[key].querystringRef;
    });

    if (Object.keys(this.$route.query).length !== 0) {
      const {
        'cache-reset-flag': parsedCacheResetFlag,
        'debug-flag': parsedDebugFlag,
        s: parsedSearchExcursionKeyword,
        's-excursion-id': parsedSearchExcursionId,
        's-excursion-type': parsedSearchExcursionType,
        's-vendor-client-sid': parsedSearchVendorExcursionSid,
        's-excursion-status': parsedSearchExcursionStatus,
        's-client-sids': parsedSearchClientSids,
        page: parsedSearchPage,
        length: parsedSearchLength,
        direction: parsedSearchDirection,
        order: parsedSortBy
      } = this.$route.query;

      this.cacheResetFlag = parsedCacheResetFlag === 'true';
      this.debugFlag = parsedDebugFlag === 'true';

      if (
        parsedSearchExcursionKeyword &&
        String(parsedSearchExcursionKeyword).trim().length !== 0
      ) {
        this.searchExcursionKeyword = parsedSearchExcursionKeyword;
      }

      if (!Number.isNaN(parseInt(parsedSearchExcursionId, 10))) {
        this.searchExcursionId = parseInt(parsedSearchExcursionId, 10);
      }

      if (
        excursionTypeInfo.DAY_EXCURSION.label === parsedSearchExcursionType ||
        excursionTypeInfo.OVERNIGHT_EXCURSION.label === parsedSearchExcursionType ||
        excursionTypeInfo.INTERNATIONAL_EXCURSION.label === parsedSearchExcursionType
      ) {
        this.searchExcursionType = parsedSearchExcursionType;
      }

      if (
        excursionStatus.DRAFT === parsedSearchExcursionStatus ||
        excursionStatus.SUBMITTED === parsedSearchExcursionStatus ||
        excursionStatus.APPROVED === parsedSearchExcursionStatus ||
        excursionStatus.ARCHIVED === parsedSearchExcursionStatus
      ) {
        this.searchExcursionStatus = parsedSearchExcursionStatus;
      }

      if (
        parsedSearchLength === '10' ||
        parsedSearchLength === '30' ||
        parsedSearchLength === '50'
      ) {
        this.pagination.itemsPerPage = parseInt(parsedSearchLength, 10);
      } else {
        this.pagination.itemsPerPage = 30;
      }

      if (!Number.isNaN(parseInt(parsedSearchPage, 10)) && parseInt(parsedSearchPage, 10) > 0) {
        this.pagination.page = parseInt(parsedSearchPage, 10);
      }

      if (
        excursionTableColumn.DETAILS.querystringRef === parsedSortBy ||
        excursionTableColumn.CLIENT.querystringRef === parsedSortBy ||
        excursionTableColumn.ORGANISATION.querystringRef === parsedSortBy
      ) {
        this.pagination.sortBy[0] = this.querystringRefToTableColumnRefMap[parsedSortBy];
        if (parsedSearchDirection === 'DESC' || parsedSearchDirection === 'ASC') {
          this.pagination.sortDesc[0] = parsedSearchDirection === 'DESC';
        }
      }

      if (
        Array.isArray(parsedSearchClientSids) &&
        parsedSearchClientSids.every((item) => !Number.isNaN(item))
      ) {
        this.searchClientSids = parsedSearchClientSids;
      } else if (!Number.isNaN(parseInt(parsedSearchClientSids, 10))) {
        this.searchClientSids = [parsedSearchClientSids];
      }

      if (String(parsedSearchVendorExcursionSid).trim().length > 0) {
        this.searchVendorExcursionSid = parsedSearchVendorExcursionSid;
      }
    }
    this.$app.eventPluginCommonAdapter.registerTrackableEvent(trackableEvent.EXCURSION_LIST_LOADED);
    const getExcursionsArgHash = {
      stbApiAdapter: this.$app.stbApiAdapter,
      store: this.$store,
      excursionId: 0
    };
    await this.$store.dispatch('common/getExcursions', getExcursionsArgHash);
    if (document.getElementById('cs-header--home-btn')) {
      document
        .getElementById('cs-header--home-btn')
        .addEventListener('click', () => this.handleHomeButtonClick());
    }
  },
  watch: {
    pagination: {
      handler() {
        this.getExcursionFilterData();
      },
      deep: true
    },
    excursionTemplateFlag() {
      this.resetExcursionFilter();
      this.getExcursionFilterData(true);
    },
    async searchClients(val) {
      await this.filterClients(val);
    }
  }
};
</script>
<style scoped>
.mt-40 {
  margin-top: 40px;
}
.excursion-filter-item {
  margin-right: 30px;
  width: 160px;
}

.search .v-text-field .v-input__control {
  margin-right: 15px;
}
.excursion-detail-text .v-text-field.v-text-field--solo .v-input__control {
  margin-right: 0px;
}

.excursion-name-cell a {
  color: #c80063;
  text-decoration: none;
  padding-left: 8px;
}

.excursion-name-cell a:not([href]):not([tabindex]) {
  color: #c80063;
  text-decoration: none;
  padding-left: 8px;
}

.excursion-table table.v-table > thead > tr > th:first-child {
  padding: 0 16px;
}

/*Override th css*/
.theme--light.v-table thead th {
  font-weight: bolder;
  color: #000000;
}

.tab-card {
  padding: 20px 0px;
  border-radius: unset !important;
}

.theme--light.v-tabs__bar {
  width: unset;
}

.v-tabs__item:not(.v-tabs__item--active) {
  opacity: unset;
}

.theme--light.v-tabs__bar .v-tabs__div {
  color: #9ca7a2;
}

.theme--light.v-tabs__bar .v-tabs__div .v-tabs__item--active {
  color: #000000;
  background: #ffffff;
}

.search .filter-by {
  margin-right: 15px;
  line-height: 38px;
  max-width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.excursion-table .v-input__control .v-input__slot {
  background: unset;
}

table.v-table thead td:not(:nth-child(1)),
table.v-table tbody td:not(:nth-child(1)),
table.v-table thead th:not(:nth-child(1)),
table.v-table tbody th:not(:nth-child(1)),
table.v-table thead td:first-child,
table.v-table tbody td:first-child,
table.v-table thead th:first-child,
table.v-table tbody th:first-child {
  padding: 0 5px;
}

.v-messages__message {
  margin-left: -10px;
  margin-top: 8px;
  position: absolute;
}

.excursion-detail-text .v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: unset;
  height: 23px;
}

.excursions-content-padding {
  padding-top: 10px;
  padding-left: 24px;
  padding-right: 24px;
}

.search .v-expansion-panel__header {
  padding: 12px 16px;
}

.search .v-list {
  padding: 0px 0px 10px 0px;
}

.search .v-text-field__details {
  overflow: visible;
}

.update-provider-stepper-button {
  padding: 4px 0 0px 0;
}
.update-provider-stepper-button button {
  margin: 12px 8px 19px 0;
}
.excursions-navigator {
  height: 55px;
  margin-top: 14px;
}
.excursions-navigator-link {
  color: #9ca7a2 !important;
  text-decoration: unset;
  padding: 13px;
  font-weight: 500;
}
.excursions-navigator-link:hover {
  text-decoration: unset;
  border-bottom: 3px solid #c80063;
  color: #000000 !important;
}

.excursions-navigator-link-active {
  color: #000000 !important;
  border-bottom: 3px solid #c80063;
}

.table-responsive .v-table__overflow {
  background-image: linear-gradient(to right, white, white), linear-gradient(to right, white, white),
    linear-gradient(to right, rgba(0, 0, 20, 0.5), rgba(255, 255, 255, 0)),
    linear-gradient(to left, rgba(0, 0, 20, 0.5), rgba(255, 255, 255, 0));
  /* Shadows */
  /* Shadow covers */
  background-position: left center, right center, left center, right center;
  background-repeat: no-repeat;
  background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;
  background-attachment: local, local, scroll, scroll;
}

.table-responsive .theme--light.v-table {
  background-color: unset;
}

.white-wrap-normal {
  white-space: nowrap;
}

.excursion-table .copy-icon {
  color: #777777;
}

.text-start.header.text-xs-center {
  text-align: center !important;
}
</style>
