<template>
  <cs-view single-view>
    <div style="min-height: 837px" slot="content">
      <page-loading-animation :displayed="pageLoadingAnimationDisplayed()"></page-loading-animation>
      <div class="mt-15">
        <div
          class="mt-15"
          v-show="showError || (!isAuthenticated && !isInitClientConfigInProgress)"
        >
          <v-layout>
            <v-flex class="main-title">Excursions</v-flex>
          </v-layout>
          <v-layout class="mt-2 mb-3">
            <v-flex>
              <span id="labelNotAuthorizedToViewExcursions"
                >You are not authorized to view Excursions, please provide a correct auth
                token.</span
              >
            </v-flex>
          </v-layout>
        </div>
        <form
          id="form"
          class="mt-15"
          v-show="!showError && isAuthenticated && !isInitClientConfigInProgress"
        >
          <v-card>
            <div class="excursions-content-padding">
              <div class="excursions-navigator">
                <v-btn
                  v-if="excursionExportButtonDisplayed && 0 === this.active"
                  class="normal-btn toolbar-btn safe-update-provider"
                  active-class="normal-btn"
                  name="export-excursions"
                  testing-id="export-excursions"
                  id="export-excursions"
                  @click.native="excursionExportDialogDisplayed = true"
                >
                  EXPORT EXCURSIONS
                </v-btn>

                <div v-if="this.active === 0" class="update-providers-margin">
                  <update-providers :buttonUpdateProvidersEnabled="true"></update-providers>
                </div>
              </div>
              <div class="create-new-client-button-clear"></div>
              <excursion-list
                :excursionTemplateFlag="false"
                v-if="0 === this.active"
              ></excursion-list>
              <excursion-list
                :excursionTemplateFlag="true"
                v-if="1 === this.active"
              ></excursion-list>
              <client-list v-if="2 === this.active && clientsTabDisplayed"></client-list>
              <client-user-list v-if="3 === this.active && clientUsersTabDisplayed">
              </client-user-list>
              <system-user-list v-if="4 === this.active && usersTabDisplayed"></system-user-list>
            </div>
          </v-card>
          <cs-form-dialog
            v-model="excursionExportDialogDisplayed"
            id="dlg-export-excursion"
            heading="Export Excursions"
            bodyId="excursionExportDialog"
            :primaryAction="{ label: 'Export', eventName: 'primary-click' }"
            :secondaryAction1="{
              label: 'Cancel',
              eventName: 'secondary1-click'
            }"
            @primary-click="excursionExport"
            @secondary1-click="excursionExportDialogDisplayed = false"
          >
            <template v-slot:cs-form-dialog-content>
              <v-card-text>Select export excursions date range:</v-card-text>
              <v-layout class="export-format-container">
                <v-flex xs3 class="export-format-label"> Start Date </v-flex>
                <v-flex xs9 class="export-format-label">
                  <v-text-field
                    id="excursion-export-start-date-input"
                    solo
                    placeholder="DD/MM/YYYY"
                    class="export-date-input"
                    v-model="excursionExportStartDate"
                    :error-messages="excursionExportStartDateErrors"
                    @input="$v.excursionExportStartDate.$touch()"
                    @blur="$v.excursionExportStartDate.$touch()"
                    @keyup="excursionExportStartDateInputChange"
                    required
                  />
                </v-flex>
              </v-layout>
              <v-layout class="export-format-container">
                <v-flex xs3 class="export-format-label"> End Date </v-flex>
                <v-flex xs9 class="export-format-label">
                  <v-text-field
                    id="excursion-export-end-date-input"
                    solo
                    placeholder="DD/MM/YYYY"
                    class="export-date-input"
                    v-model="excursionExportEndDate"
                    :error-messages="excursionExportEndDateErrors"
                    @input="$v.excursionExportEndDate.$touch()"
                    @blur="$v.excursionExportEndDate.$touch()"
                    @keyup="excursionExportEndDateInputChange"
                    required
                  />
                </v-flex>
                <div
                  v-if="excursionExportErrorMessageDisplay"
                  class="v-messages theme--light error--text excursions-not-found-message"
                >
                  No excursion found in this date range. Please select alternative dates and try
                  again.
                </div>
              </v-layout>
              <v-card-text>Select export file format:</v-card-text>
              <v-layout class="export-format-container">
                <v-flex xs1>
                  <v-checkbox
                    id="jsonFormatCheckbox"
                    v-model="jsonFormatTicked"
                    class="export-format-checkbox"
                  ></v-checkbox>
                </v-flex>
                <v-flex xs11 class="export-format-label">Json Format</v-flex>
              </v-layout>
              <v-layout class="export-format-container">
                <v-flex xs1>
                  <v-checkbox
                    id="csvFormatCheckbox"
                    v-model="csvFormatTicked"
                    class="export-format-checkbox"
                  ></v-checkbox>
                </v-flex>
                <v-flex xs11 class="export-format-label">CSV Format</v-flex>
              </v-layout>
            </template>
          </cs-form-dialog>
        </form>
      </div>
    </div>
  </cs-view>
</template>

<script>
import * as types from '@/store/mutationTypes';
import { CSBase } from '@complispace/cs-design-system';
import { validationMixin } from 'vuelidate';
import { mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import PageLoadingAnimation from './PageLoadingAnimation';
import UpdateProviders from './UpdateProviders';
import ExcursionList from './ExcursionList';
import ClientUserList from './ClientUserList';
import ClientList from './ClientList';

export default {
  name: 'EntityManagement',

  components: {
    PageLoadingAnimation,
    UpdateProviders,
    ExcursionList,
    ClientUserList,
    ClientList
  },

  extends: CSBase,

  props: [],

  data() {
    return {
      active: 0,
      excursionExportDialogDisplayed: false,
      jsonFormatTicked: false,
      csvFormatTicked: true,
      excursionExportStartDate: '',
      excursionExportEndDate: '',
      excursionExportErrorMessageDisplay: false
    };
  },
  mixins: [validationMixin],

  validations: {
    excursionExportStartDate: {
      required,
      minValue: (value) => {
        const pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
        if (!pattern.test(value)) {
          return false;
        }
        const startDate = new Date(value.replace(pattern, '$3-$2-$1'));
        return startDate > new Date('1900-01-01');
      },
      maxValue: (value) => {
        const pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
        if (!pattern.test(value)) {
          return false;
        }
        const startDate = new Date(value.replace(pattern, '$3-$2-$1'));
        return startDate <= new Date();
      }
    },
    excursionExportEndDate: {
      required,
      minValue: (value) => {
        const pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
        if (!pattern.test(value)) {
          return false;
        }
        const endDate = new Date(value.replace(pattern, '$3-$2-$1'));
        return endDate > new Date('1900-01-01');
      },
      maxValue: (value) => {
        const pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
        if (!pattern.test(value)) {
          return false;
        }
        const endDate = new Date(`${value.replace(pattern, '$3-$2-$1')} 00:00:00`);
        let today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const yyyy = today.getFullYear();
        today = `${yyyy}-${mm}-${dd}`;

        return endDate <= new Date(`${today} 23:59:59`);
      }
    }
  },
  computed: {
    ...mapState({
      isAuthenticated: (state) => state.authorisation.isAuthenticated,
      isInitClientConfigInProgress: (state) => state.authorisation.isInitClientConfigInProgress,
      excursionExportButtonDisplayed: (state) => state.common.excursionExportButtonDisplayed,
      clientsTabDisplayed: (state) => state.common.clientsTabDisplayed,
      clientUsersTabDisplayed: (state) => state.common.clientUsersTabDisplayed,
      usersTabDisplayed: (state) => state.common.usersTabDisplayed
    }),
    showError() {
      return this.$store.state.common.showError ? this.$store.state.common.showError : false;
    },
    excursionExportStartDateErrors() {
      const errors = [];

      if (!this.$v.excursionExportStartDate.$dirty) return errors;
      if (!this.$v.excursionExportStartDate.required) {
        errors.push('Excursion Export Start Date is required.');
        return errors;
      }
      if (
        !this.$v.excursionExportStartDate.minValue ||
        !this.$v.excursionExportStartDate.maxValue
      ) {
        errors.push('Excursion Export Start Date is not valid.');
      }

      return errors;
    },
    excursionExportEndDateErrors() {
      const errors = [];

      if (!this.$v.excursionExportEndDate.$dirty) return errors;
      if (!this.$v.excursionExportEndDate.required) {
        errors.push('Excursion Export End Date is required.');
        return errors;
      }
      if (!this.$v.excursionExportEndDate.minValue || !this.$v.excursionExportEndDate.maxValue) {
        errors.push('Excursion Export End Date is not valid.');
      }

      return errors;
    }
  },
  methods: {
    pageLoadingAnimationDisplayed() {
      let loadingAnimationDisplayed = false;

      if (this.isInitClientConfigInProgress) {
        loadingAnimationDisplayed = true;
      }

      return loadingAnimationDisplayed;
    },
    async excursionExport() {
      this.$v.excursionExportStartDate.$touch();
      this.$v.excursionExportEndDate.$touch();

      if (
        this.excursionExportStartDateErrors.length !== 0 ||
        this.excursionExportEndDateErrors.length !== 0
      ) {
        return;
      }

      const excursionCommonAdapter = new this.$app.excursionCommon.Adapter({
        store: this.$store,
        app: this.$app
      });

      const filterParam = {
        'start-date': this.formatDateString(this.excursionExportStartDate),
        'end-date': this.formatDateString(this.excursionExportEndDate)
      };

      if (this.csvFormatTicked) {
        filterParam.format = 'csv';
        const { excursionsNotFound, message } =
          await excursionCommonAdapter.exportExcursionsWithCSVFormat(filterParam, this.$store);
        if (message) {
          this.showErrorAlert(message, true);
        } else if (excursionsNotFound) {
          this.excursionExportErrorMessageDisplay = true;
          setTimeout(() => {
            this.excursionExportErrorMessageDisplay = false;
          }, 5000);
        } else {
          this.excursionExportDialogDisplayed = false;
        }
      } else {
        filterParam.format = 'json';
        const { excursionsNotFound, message } =
          await excursionCommonAdapter.exportExcursionsWithJSONFormat(filterParam, this.$store);
        if (message) {
          this.showErrorAlert(message, true);
        } else if (excursionsNotFound) {
          this.excursionExportErrorMessageDisplay = true;
          setTimeout(() => {
            this.excursionExportErrorMessageDisplay = false;
          }, 5000);
        } else {
          this.excursionExportDialogDisplayed = false;
        }
      }
    },
    excursionExportStartDateInputChange(e) {
      if (e.key === 'Backspace' || e.key === 'Delete') return;

      if (
        Number.isNaN(parseInt(String(e.key), 10)) ||
        String(this.excursionExportStartDate).length >= 11
      ) {
        this.excursionExportStartDate = String(this.excursionExportStartDate).substring(0, 10);
      }
      if (
        String(this.excursionExportStartDate).length === 2 ||
        String(this.excursionExportStartDate).length === 5
      ) {
        this.excursionExportStartDate += '/';
      }
    },
    excursionExportEndDateInputChange(e) {
      if (e.key === 'Backspace' || e.key === 'Delete') return;

      if (
        Number.isNaN(parseInt(String(e.key), 10)) ||
        String(this.excursionExportStartDate).length >= 11
      ) {
        this.excursionExportStartDate = String(this.excursionExportStartDate).substring(0, 10);
      }
      if (
        String(this.excursionExportEndDate).length === 2 ||
        String(this.excursionExportEndDate).length === 5
      ) {
        this.excursionExportEndDate += '/';
      }
    },
    formatDateString(dateString) {
      const year = String(dateString).split('/')[2];
      const month = String(dateString).split('/')[1];
      const day = String(dateString).split('/')[0];
      return `${year}-${month}-${day}`;
    }
  },
  async mounted() {
    const excludedAttributeList = [
      'excursionAdditionalInformationEnabled',
      'vendorExcursionSidInputEnabled',
      'providerUpdateActionEnabled',
      'clientUsersTabDisplayed',
      'usersTabDisplayed',
      'clientsTabDisplayed',
      'clientSiteSettingsButtonDiplayed',
      'clientSelfGroupManagementDisplayed',
      'publicSchoolFeatureSetEnabled',
      'riskCategoryManagementMenuItemDisplayed',
      'operationManagementMenuItemDisplayed',
      'studentManagementSubjectManagementDisplayed',
      'userDisplayName',
      'userEmail'
    ];
    const argHash = {
      excludedAttributeList,
      storeModuleName: ''
    };
    this.$store.commit(types.COMMON_RESET_STORE_MODULE_STATE, argHash);

    const route = this.$route;
    if (route) {
      if (`${route.name}` === 'Excursions') {
        this.active = 0;
      } else if (`${route.name}` === 'Templates') {
        this.active = 1;
      } else if (`${route.name}` === 'Clients') {
        this.active = 2;
      } else if (`${route.name}` === 'ClientUsers') {
        this.active = 3;
      } else {
        this.active = 4;
      }
      const { axiosInstance, eventPluginCommonAdapter, stbApiAdapter } = this.$app;
      await this.$store.dispatch('authorisation/validateAndSetUserTokenOrLogout', {
        query: this.$route.query,
        axiosInstance
      });
      await this.$store.dispatch('common/initClientConfig', {
        eventPluginCommonAdapter,
        stbApiAdapter
      });
    }
  },
  watch: {
    $route(to) {
      if (`${to.name}` === 'Excursions') {
        this.active = 0;
        if (Object.keys(to.query).length === 0) {
          window.location.reload();
        }
      } else if (`${to.name}` === 'Templates') {
        this.active = 1;
        if (Object.keys(to.query).length === 0) {
          window.location.reload();
        }
      }
    },
    jsonFormatTicked(newVal) {
      this.csvFormatTicked = !newVal;
    },
    csvFormatTicked(newVal) {
      this.jsonFormatTicked = !newVal;
    }
  }
};
</script>
<style scoped>
.update-providers-margin {
  display: block;
  margin-left: auto;
  float: right;
}
.update-providers-margin button {
  margin-right: 0;
}

.export-format-label {
  display: flex;
  align-items: center;
}
.export-format-container {
  margin: 0 2rem;
}
.excursions-export-link {
  padding: 0 0.5rem;
  padding-bottom: 13px;
  cursor: pointer;
}
.excursions-not-found-message {
  position: absolute;
  margin-top: 60px;
  text-align: center;
}
.home_main_container {
  position: relative;
}
.left_menu_container {
  background-color: #2f4051;
  height: 100%;
}
.client-admin-setting-section-title {
  font-weight: bold;
}
</style>
