var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.selectedriskClasses.length > 0)?_c('div',{staticClass:"sub-category-section-margin sub-category-section-not-provider"},_vm._l((_vm.selectedriskClasses[0].types),function(riskType,index){return _c('div',{key:index,staticClass:"sub-category-section"},[(riskType.classes.length > 0)?_c('v-layout',{attrs:{"align-center":"","justify-space-between":""}},[(_vm.riskTypeLabelDisplayed)?_c('v-flex',{attrs:{"xs6":""}},[_c('div',{staticClass:"name label-padding"},[_vm._v(_vm._s(riskType.name))])]):_vm._e(),(_vm.selectedriskClasses[0].name === 'Activities')?_c('v-flex',[_c('complete-checklist',{attrs:{"categoryName":riskType.name,"riskClassName":_vm.selectedriskClasses[0].name,"formControlEnabled":_vm.formControlEnabled,"checklistFetchActionInProgress":false}}),_c('view-policy',{attrs:{"categoryName":riskType.name,"riskClassName":_vm.selectedriskClasses[0].name}})],1):_vm._e()],1):_vm._e(),_vm._l((riskType.classes),function(selectedRiskClass,index){return _c('v-layout',{key:index,attrs:{"justify-center":""}},[_c('v-select',{staticClass:"type-dropdown risk-class-select excursion-selection",attrs:{"id":_vm.getRiskClassId(selectedRiskClass),"items":_vm.listRiskClasses(selectedRiskClass.name, riskType.name, _vm.selectedriskClasses[0].name),"item-text":"name","item-value":"name","item-disabled":"disabled","solo":"","value":selectedRiskClass.name,"disabled":!_vm.selectedClassExistInItems(
            selectedRiskClass.name,
            riskType.name,
            _vm.selectedriskClasses[0].name
          ) || !_vm.formControlEnabled},on:{"change":function($event){return _vm.changeSelectedClassId(
            riskType.name,
            _vm.selectedriskClasses[0].name,
            selectedRiskClass,
            $event
          )}},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('div',{attrs:{"id":("risk-class--option-" + (String(data.item.name).replaceAll(' ', '')))}},[_vm._v(" "+_vm._s(data.item.name)+" ")])]}}],null,true)}),_c('div',[_c('v-btn',{staticClass:"remove-icon",attrs:{"id":_vm.getRiskClassDeleteId(selectedRiskClass),"icon":"","disabled":!_vm.formControlEnabled},nativeOn:{"click":function($event){return _vm.openRemoveSelectedClassDialog(
              riskType.name,
              _vm.selectedriskClasses[0].name,
              selectedRiskClass.name
            )}}},[_c('v-icon',[_vm._v("delete")])],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(selectedRiskClass.dialog),callback:function ($$v) {_vm.$set(selectedRiskClass, "dialog", $$v)},expression:"selectedRiskClass.dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Are you Sure?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"normal-btn white--text primary",attrs:{"id":"btnDelete","disabled":!_vm.formControlEnabled},on:{"click":function($event){$event.stopPropagation();return _vm.removeSelectedClassId(
                    riskType.name,
                    _vm.selectedriskClasses[0].name,
                    selectedRiskClass
                  )}}},[_vm._v("Delete")]),_c('v-btn',{staticClass:"normal-btn",attrs:{"id":"risk-class--selected-risk-class-dialog--close--button"},on:{"click":function($event){return _vm.closeRemoveSelectedClassDialog(
                    riskType.name,
                    _vm.selectedriskClasses[0].name,
                    selectedRiskClass.name
                  )}}},[_vm._v("Cancel")])],1)],1)],1)],1)],1)})],2)}),0):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }