<template>
  <v-container grid-list-md text-xs-center class="settings-container">
    <div class="risk-matrix-management-container">
      <v-toolbar class="risk-matrix-management-header" color="white">
        <v-toolbar-title>Risk Matrix Management</v-toolbar-title>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>

            <v-tab v-for="item in items" :key="item" :id="getTabId(item)">
              {{ item }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in items" :key="item">
          <v-card flat>
            <div v-if="item === 'Risk Matrix'">
              <v-card>
                <table class="matrix-preview-table">
                  <tr>
                    <td class="likelihoodAxis">{{ likelihoodAxis[0] }}</td>
                    <td class="risk-matrix-cell moderateCell"></td>
                    <td class="risk-matrix-cell moderateCell"></td>
                    <td class="risk-matrix-cell highCell"></td>
                    <td class="risk-matrix-cell extremeCell"></td>
                    <td class="risk-matrix-cell extremeCell"></td>
                  </tr>
                  <tr>
                    <td class="likelihoodAxis">{{ likelihoodAxis[1] }}</td>
                    <td class="risk-matrix-cell lowCell"></td>
                    <td class="risk-matrix-cell moderateCell"></td>
                    <td class="risk-matrix-cell highCell"></td>
                    <td class="risk-matrix-cell extremeCell"></td>
                    <td class="risk-matrix-cell extremeCell"></td>
                  </tr>
                  <tr>
                    <td class="likelihoodAxis">{{ likelihoodAxis[2] }}</td>
                    <td class="risk-matrix-cell lowCell"></td>
                    <td class="risk-matrix-cell moderateCell"></td>
                    <td class="risk-matrix-cell moderateCell"></td>
                    <td class="risk-matrix-cell highCell"></td>
                    <td class="risk-matrix-cell extremeCell"></td>
                  </tr>
                  <tr>
                    <td class="likelihoodAxis">{{ likelihoodAxis[3] }}</td>
                    <td class="risk-matrix-cell lowCell"></td>
                    <td class="risk-matrix-cell lowCell"></td>
                    <td class="risk-matrix-cell moderateCell"></td>
                    <td class="risk-matrix-cell moderateCell"></td>
                    <td class="risk-matrix-cell highCell"></td>
                  </tr>
                  <tr>
                    <td class="likelihoodAxis">{{ likelihoodAxis[4] }}</td>
                    <td class="risk-matrix-cell lowCell"></td>
                    <td class="risk-matrix-cell lowCell"></td>
                    <td class="risk-matrix-cell lowCell"></td>
                    <td class="risk-matrix-cell moderateCell"></td>
                    <td class="risk-matrix-cell highCell"></td>
                  </tr>
                  <tr>
                    <td class="consequenceAxis"></td>
                    <td class="consequenceAxis">{{ consequenceAxis[0] }}</td>
                    <td class="consequenceAxis">{{ consequenceAxis[1] }}</td>
                    <td class="consequenceAxis">{{ consequenceAxis[2] }}</td>
                    <td class="consequenceAxis">{{ consequenceAxis[3] }}</td>
                    <td class="consequenceAxis">{{ consequenceAxis[4] }}</td>
                  </tr>
                </table>
                <div class="riskRatingLegend">
                  <div
                    v-for="(item, index) in computedRiskRating"
                    :key="item.sid"
                    :class="{
                      lowEditor: index === 0,
                      mediumEditor: index === 1,
                      highEditor: index === 2,
                      extremeEditor: index === 3,
                      riskRatingLegendEditor: index >= 0
                    }"
                  >
                    <v-edit-dialog
                      :return-value.sync="item.label"
                      @save="save"
                      @cancel="cancel"
                      @open="open"
                      @close="close"
                      large
                    >
                      <span
                        :id="getEditFieldLabelId('matrix-risk-rating-definition-title', index)"
                        >{{ item.label }}</span
                      >
                      <template v-slot:input>
                        <v-text-field
                          v-model="item.label"
                          label="Edit"
                          single-line
                          :id="getEditFieldId('matrix-risk-rating-definition-title', index)"
                        ></v-text-field>
                      </template>
                      <div class="risk-rating-edit">
                        <v-icon
                          size="16"
                          :id="getEditIconId('matrix-risk-rating-definition-icon', index)"
                          >edit</v-icon
                        >
                      </div>
                    </v-edit-dialog>
                  </div>
                </div>
              </v-card>
            </div>
            <div v-if="item === 'Likelihood'">
              <v-card>
                <v-data-table
                  :headers="headers"
                  :items="computedLikelihood"
                  item-key="sid"
                  hide-default-footer
                  class="matrix-table"
                >
                  <template v-slot:body="{ items, headers }">
                    <tbody>
                      <tr v-for="(item, idx) in items" :key="idx">
                        <td
                          v-for="(header, key) in headers"
                          :key="key"
                          class="text-xs-left"
                          :class="key % 2 === 1 ? '' : 'matrix-cell-left'"
                        >
                          <div class="matrix-cell">
                            <v-edit-dialog
                              :return-value.sync="item[header.value]"
                              @save="save"
                              @cancel="cancel"
                              @open="open"
                              @close="close"
                              large
                            >
                              <pre
                                :id="
                                  header.text === 'Title'
                                    ? getEditFieldLabelId('matrix-likelihood-definition-title', idx)
                                    : getEditFieldId(
                                        'matrix-likelihood-definition-description',
                                        idx
                                      )
                                "
                                class="matrix-definition-description"
                                >{{ item[header.value] }}</pre
                              >
                              <template v-slot:input>
                                <v-text-field
                                  v-if="header.text === 'Title'"
                                  v-model="item[header.value]"
                                  label="Edit"
                                  single-line
                                  :id="getEditFieldId('matrix-likelihood-definition-title', idx)"
                                ></v-text-field>
                                <v-textarea
                                  v-if="header.text !== 'Title'"
                                  v-model="item[header.value]"
                                  label="Edit"
                                  :id="
                                    getEditFieldId('matrix-likelihood-definition-description', idx)
                                  "
                                ></v-textarea>
                              </template>
                              <div class="matrix-edit">
                                <v-icon
                                  :id="
                                    header.text === 'Title'
                                      ? getEditIconId(
                                          'matrix-likelihood-definition-title-icon',
                                          idx
                                        )
                                      : getEditFieldId(
                                          'matrix-likelihood-definition-description-icon',
                                          idx
                                        )
                                  "
                                  >edit</v-icon
                                >
                              </div>
                            </v-edit-dialog>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </v-card>
            </div>
            <div v-if="item === 'Consequence'">
              <v-card>
                <v-data-table
                  :headers="headers"
                  :items="computedConsequence"
                  hide-default-footer
                  item-key="sid"
                  class="matrix-table"
                >
                  <template v-slot:body="{ items, headers }">
                    <tbody>
                      <tr v-for="(item, idx) in items" :key="idx">
                        <td
                          v-for="(header, key) in headers"
                          :key="key"
                          class="text-xs-left"
                          :class="key % 2 === 1 ? '' : 'matrix-cell-left'"
                        >
                          <div class="matrix-cell">
                            <v-edit-dialog
                              :return-value.sync="item[header.value]"
                              @save="save"
                              @cancel="cancel"
                              @open="open"
                              @close="close"
                              large
                            >
                              <pre
                                :id="
                                  header.text === 'Title'
                                    ? getEditFieldLabelId(
                                        'matrix-consequence-definition-title',
                                        idx
                                      )
                                    : getEditFieldId(
                                        'matrix-consequence-definition-description',
                                        idx
                                      )
                                "
                                class="matrix-definition-description"
                                >{{ item[header.value] }}</pre
                              >
                              <template v-slot:input>
                                <v-text-field
                                  v-if="header.text === 'Title'"
                                  v-model="item[header.value]"
                                  label="Edit"
                                  single-line
                                  :id="getEditFieldId('matrix-consequence-definition-title', idx)"
                                ></v-text-field>
                                <v-textarea
                                  v-if="header.text !== 'Title'"
                                  v-model="item[header.value]"
                                  label="Edit"
                                  :id="
                                    getEditFieldId('matrix-consequence-definition-description', idx)
                                  "
                                ></v-textarea>
                              </template>
                              <div class="matrix-edit">
                                <v-icon
                                  :id="
                                    header.text === 'Title'
                                      ? getEditIconId(
                                          'matrix-consequence-definition-title-icon',
                                          idx
                                        )
                                      : getEditFieldId(
                                          'matrix-consequence-definition-description-icon',
                                          idx
                                        )
                                  "
                                  >edit</v-icon
                                >
                              </div>
                            </v-edit-dialog>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </v-card>
            </div>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <cs-form-dialog
      v-model="groupSaveGenericDialogDisplayed"
      id="dlg-save-generic"
      heading="Risk Matrix Management"
      bodyId="group-import-modal--generic-dialog"
      :primaryAction="{ label: 'OK', eventName: 'primary-click' }"
      @primary-click="groupSaveGenericDialogDisplayed = false"
    >
      <template v-slot:cs-form-dialog-content>
        <v-card-text>
          <div v-safe-html="groupSaveGenericDialogMessage"></div>
        </v-card-text>
      </template>
    </cs-form-dialog>
  </v-container>
</template>

<script>
import * as types from '@/store/mutationTypes';
import { CSBase } from '@complispace/cs-design-system';
import { mapState } from 'vuex';
import defaultRiskMatrix from '../lib/const/riskMatrixTemplate';
import { populateErrorMessage } from '../lib/errorMessages';

export default {
  extends: CSBase,
  data() {
    return {
      tab: null,
      items: ['Risk Matrix', 'Likelihood', 'Consequence'],
      headers: [
        {
          text: 'Title',
          align: 'left',
          sortable: false,
          value: 'label'
        },
        { text: 'Definition', value: 'description', sortable: false }
      ],
      groupSaveGenericDialogDisplayed: false,
      groupSaveGenericDialogMessage: '',
      groupSaveGenericDialogSuccessMessage: 'Risk matrix has been updated.'
    };
  },
  computed: {
    ...mapState({
      clientSiteSettingsButtonDiplayed: (state) => state.common.clientSiteSettingsButtonDiplayed,
      publicSchoolFeatureSetEnabled: (state) => state.common.publicSchoolFeatureSetEnabled
    }),
    computedLikelihood: {
      get() {
        return this.convertConsequenceOrLikelihood(
          this.$store.state.common.clientSettingRiskMatrix.likelihood ??
            defaultRiskMatrix.likelihood
        );
      },
      set(value) {
        this.$store.commit(types.COMMON_SET_RISK_MATRIX_LIKELIHOOD, value);
      }
    },
    computedConsequence: {
      get() {
        return this.convertConsequenceOrLikelihood(
          this.$store.state.common.clientSettingRiskMatrix.consequence ??
            defaultRiskMatrix.consequence
        );
      },
      set(value) {
        this.$store.commit(types.COMMON_SET_RISK_MATRIX_CONSEQUENCE, value);
      }
    },
    computedRiskRating: {
      get() {
        return this.convertRiskRating(
          this.$store.state.common.clientSettingRiskMatrix.riskRating ??
            defaultRiskMatrix.riskRating
        );
      },
      set(value) {
        this.$store.commit(types.COMMON_SET_CLIENT_SETTING_RISK_MATRIX_RISK_RATING, value);
      }
    },
    likelihoodAxis() {
      return this.computedLikelihood.map((item) => item.label).reverse();
    },
    consequenceAxis() {
      return this.computedConsequence.map((item) => item.label);
    }
  },
  methods: {
    getTabId(description) {
      return `client-admin-risk-matrix-management--tab-item--label-${description}`;
    },
    getEditFieldId(description, index) {
      return `client-admin-risk-matrix-management--risk-matrix--text-input-${description}_${index}`;
    },
    getEditFieldLabelId(description, index) {
      return `client-admin-risk-matrix-management--risk-matrix--text-input-label-${description}_${index}`;
    },
    getEditIconId(description, index) {
      return `client-admin-risk-matrix-management--risk-matrix--edit-icon-${description}_${index}`;
    },
    convertConsequenceOrLikelihood(value) {
      return Object.keys(value)
        .map((item) => ({
          sid: item,
          label: value[item].label,
          description: value[item].description,
          orderIndex: value[item].orderIndex
        }))
        .sort((a, b) => a.orderIndex - b.orderIndex);
    },
    convertRiskRating(value) {
      return Object.keys(value)
        .map((item) => ({
          sid: item,
          label: value[item].label,
          orderIndex: value[item].orderIndex
        }))
        .sort((a, b) => a.orderIndex - b.orderIndex);
    },
    async save() {
      const updatedRiskRating = this.computedRiskRating.reduce((prev, current) => {
        return { ...prev, [current.sid]: { label: current.label, orderIndex: current.orderIndex } };
      }, {});
      const updatedConsequence = this.computedConsequence.reduce((prev, current) => {
        return {
          ...prev,
          [current.sid]: {
            label: current.label,
            description: current.description,
            orderIndex: current.orderIndex
          }
        };
      }, {});
      const updatedLikelihood = this.computedLikelihood.reduce((prev, current) => {
        return {
          ...prev,
          [current.sid]: {
            label: current.label,
            description: current.description,
            orderIndex: current.orderIndex
          }
        };
      }, {});

      const argHash = {
        payload: {
          riskRating: updatedRiskRating,
          likelihood: updatedLikelihood,
          consequence: updatedConsequence
        },
        store: this.$store
      };
      const cbResponse = await this.$app.stbApiAdapter.putClientRiskMatrix(argHash);
      if (cbResponse.error) {
        let dialogMessage = 'Risk matrix cannot be updated.';
        if (cbResponse.refinedMessage) {
          this.$forceUpdate();
          dialogMessage = `${dialogMessage} ${cbResponse.refinedMessage}.`;
        }

        this.groupSaveGenericDialogMessage = populateErrorMessage({
          message: dialogMessage,
          store: this.$store
        });
        this.groupSaveGenericDialogDisplayed = true;
      } else {
        this.$store.commit(types.COMMON_SET_CLIENT_SETTING_RISK_MATRIX, {
          likelihood: updatedLikelihood,
          consequence: updatedConsequence,
          riskRating: updatedRiskRating
        });
      }
    },
    cancel() {},
    open() {
      setTimeout(() => {
        [...document.getElementsByTagName('button')].forEach((element) => {
          if (element.innerText === 'SAVE') {
            // eslint-disable-next-line no-param-reassign
            element.id = 'client-admin-risk-matrix-management--save--button';
          }
          if (element.innerText === 'CANCEL') {
            // eslint-disable-next-line no-param-reassign
            element.id = 'client-admin-risk-matrix-management--cancel--button';
          }
        });
      }, 50);
    },
    close() {},
    handleSettingsAction() {
      this.$router.push('/clients/admin');
    },
    async updateRiskMatrix() {
      const requestParam = {
        'vendor-sid': this.$store.state.common.apiParam['vendor-sid'],
        'oauth-signature': this.$store.state.common.apiParam['oauth-signature']
      };
      const getClientsArgHash = {
        sid: this.$store.state.common.userClientSid,
        requestParam,
        store: this.$store
      };
      const cbResponse = await this.$app.stbApiAdapter.getClientRiskMatrix(getClientsArgHash);

      if (!cbResponse.error) {
        const {
          data: { likelihood, riskRating, consequence }
        } = cbResponse;
        this.$store.commit(types.COMMON_SET_CLIENT_SETTING_RISK_MATRIX, {
          likelihood,
          riskRating,
          consequence
        });
      } else {
        this.$store.commit(types.COMMON_SET_CLIENT_SETTING_RISK_MATRIX, {
          likelihood: defaultRiskMatrix.likelihood,
          consequence: defaultRiskMatrix.consequence,
          riskRating: defaultRiskMatrix.riskRating
        });
      }
    }
  },
  async mounted() {
    const { axiosInstance, eventPluginCommonAdapter, stbApiAdapter } = this.$app;
    const isVendorAuthScheme =
      !!this.$route.query['vendor-sid'] && !!this.$route.query['oauth-signature'];
    if (!isVendorAuthScheme) {
      await this.$store.dispatch('authorisation/validateAndSetUserTokenOrLogout', {
        query: this.$route.query,
        axiosInstance
      });
    }
    await this.$store.dispatch('common/initClientConfig', {
      queryParam: isVendorAuthScheme ? this.$route.query : {},
      eventPluginCommonAdapter,
      stbApiAdapter
    });
    if (!this.clientSiteSettingsButtonDiplayed || this.publicSchoolFeatureSetEnabled) {
      this.$router.push('/excursions');
    }
    this.updateRiskMatrix();
  }
};
</script>
<style scoped>
.settings-container {
  width: 100%;
  height: 100%;
  max-width: unset;
  margin: 0;
  padding: 0;
}
.settings-content {
  text-align: left;
  height: 100vh;
}

.risk-matrix-management-container {
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 30px;
  height: 100%;
  background-color: white;
}
.matrix-cell {
  position: relative !important;
  height: 100%;
  width: 100%;
  min-width: 250px;
  display: flex;
  align-items: center;
}
.matrix-cell .v-small-dialog__activator {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}
.matrix-cell-left {
  width: 250px;
}
pre {
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word;
}
.matrix-edit {
  position: absolute;
  display: none;
  top: 25%;
  right: 0px;
}
.matrix-cell:hover .matrix-edit {
  display: block;
}
.matrix-table {
  padding-left: 50px;
  padding-right: 50px;
}
.matrix-preview-table {
  margin: 30px auto;
  padding-right: 12%;
}
.likelihoodAxis {
  width: 200px;
  height: 60px;
}
.consequenceAxis {
  width: 200px;
  height: 90px;
}
.risk-matrix-cell {
  width: 200px;
  height: 60px;
}
.lowCell {
  background-color: green;
}
.moderateCell {
  background-color: #ffff00;
}
.highCell {
  background-color: orange;
}
.extremeCell {
  background-color: red;
}
.riskRatingLegend {
  display: flex;
  width: 100%;
  justify-content: center;
}
.riskRatingLegendEditor {
  width: 250px;
  position: relative;
}
.risk-rating-edit {
  position: absolute;
  display: none;
  top: -10%;
  right: 0px;
}
.riskRatingLegendEditor:hover .risk-rating-edit {
  display: block;
}
.riskRatingLegendEditor span::before {
  content: '';
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
.lowEditor .v-small-dialog__activator__content::before {
  background: green;
  transform: translateY(2px);
}
.mediumEditor .v-small-dialog__activator__content::before {
  background: yellow;
  transform: translateY(2px);
}
.highEditor .v-small-dialog__activator__content::before {
  background: orange;
  transform: translateY(2px);
}
.extremeEditor .v-small-dialog__activator__content::before {
  background: red;
  transform: translateY(2px);
}
.matrix-definition-description {
  font-family: 'Roboto', sans-serif;
}
.settings-content-container {
  background-color: #c80063;
}
.risk-matrix-management-header {
  box-shadow: none !important;
}
</style>
