import cloneDeep from 'lodash/cloneDeep';
import riskCategoryLabel from '../../../lib/const/riskCategoryLabel';

export default {
  selectedClassByCategoryName: (state) => (name) => {
    const { categories } = state.excursionDc;
    let selectedCategories = [];
    if (categories) {
      selectedCategories = cloneDeep(state.excursionDc.categories).filter(
        (risk) => risk.name === name
      );
      if (selectedCategories && selectedCategories.length > 0) {
        selectedCategories.forEach((category) => {
          if (category.types && category.types.length > 0) {
            category.types.forEach((type) => {
              if (type.classes && type.classes.length > 0) {
                type.classes.sort((a, b) => a.name.localeCompare(b.name));
              }
            });
          }
        });
      }
    }
    return selectedCategories;
  },
  getListRiskTypesByCategoryName: (state) => (categoryName) => {
    return state.allRisks.find((category) => category.name === categoryName).types;
  },
  getTypesCountFromSelectedClasses: (state) => (categoryName) => {
    let count = 0;
    const { categories } = state.excursionDc;
    if (categories) {
      const itemCategory = categories.find((c) => c.name === categoryName);
      if (itemCategory != null) {
        Object.keys(itemCategory.types).forEach((typeKey) => {
          count += itemCategory.types[typeKey].classes.length;
        });
      }
    }

    return count;
  },
  getTypesCountForActivity: (state) => {
    let count = 0;

    const { categories } = state.excursionDc;
    if (categories) {
      const itemCategory = categories.find((c) => c.name === riskCategoryLabel.ACTIVITIES);
      if (itemCategory != null) {
        Object.keys(itemCategory.types).forEach((typeKey) => {
          Object.keys(itemCategory.types[typeKey].classes).forEach((classKey) => {
            if (itemCategory.types[typeKey].classes[classKey].risks) {
              count += itemCategory.types[typeKey].classes[classKey].risks.length;
            }
          });
        });
      }
    }

    return count;
  },
  getAllActivityRisks: (state) => {
    const result = [];
    const allActivityRisks = state.allRisks.find((r) => r.name === riskCategoryLabel.ACTIVITIES);
    if (allActivityRisks) {
      allActivityRisks.types.forEach(function (type) {
        type.classes.forEach(function (riskClass) {
          result.push({
            name: riskClass.name,
            typeName: type.name,
            risks: riskClass.risks
          });
        });
      });
    }
    return result;
  },
  getActivityRisksByClass: (state) => (typeName, className) => {
    let filterRisks = [];

    const allActivityRisks = state.allRisks.find((r) => r.name === riskCategoryLabel.ACTIVITIES);
    if (allActivityRisks) {
      allActivityRisks.types.forEach(function (type) {
        type.classes.forEach(function (riskClass) {
          filterRisks.push({
            name: riskClass.name,
            typeName: type.name,
            risks: riskClass.risks
          });
        });
      });
    }

    if (`${typeName}` !== '') {
      filterRisks = filterRisks.filter((r) => r.typeName === typeName);
    }
    if (`${className}` !== '') {
      filterRisks = filterRisks.filter((r) => r.name === className);
    }

    return filterRisks;
  },
  getSelectedActivityClasses: (state) => {
    const { categories } = state.excursionDc;
    if (categories) {
      return categories.filter((risk) => risk.name === riskCategoryLabel.ACTIVITIES);
    }
    return [];
  },
  getAllActivityTypes: (state) => {
    const activityTypes = [];
    const allActivityRisks = state.allRisks.find((r) => r.name === riskCategoryLabel.ACTIVITIES);
    if (allActivityRisks) {
      allActivityRisks.types.forEach(function (elementType) {
        activityTypes.push({
          id: elementType.id,
          name: elementType.name
        });
      });
    }
    return activityTypes;
  },
  getProvidersByCategory: (state) => (categoryName) => {
    const arrayProviders = state.allProviders.filter(
      (p) =>
        (p.type ? p.type.toLowerCase().trim().indexOf(categoryName.toLowerCase().trim()) : -1) >= 0
    );

    const { categories } = state.excursionDc;
    let selectedProviders = [];
    if (categories) {
      const category = state.excursionDc.categories.find((r) => r.name === categoryName);

      if (category && category.selectedProviders) {
        selectedProviders = JSON.parse(JSON.stringify(category.selectedProviders));
      }
    }

    if (selectedProviders && selectedProviders.length > 0) {
      selectedProviders.forEach((provider) => {
        const localProvider = provider;
        const providerCurrentItem = arrayProviders.find((p) => p.name === localProvider.name);
        if (!providerCurrentItem) {
          localProvider.previousItem = true;
          arrayProviders.push(localProvider);
        }
      });
    }
    return arrayProviders;
  },
  getSelectedApprovedProvidersCount: (state) => (categoryName) => {
    const { categories } = state.excursionDc;
    if (categories) {
      const category = state.excursionDc.categories.find((r) => r.name === categoryName);

      if (category && category.selectedProviders) {
        return category.selectedProviders ? category.selectedProviders.length : 0;
      }
    }

    return 0;
  },
  getSelectedProvidersByCategory: (state) => (categoryName) => {
    const { categories } = state.excursionDc;
    if (categories) {
      const category = state.excursionDc.categories.find((r) => r.name === categoryName);

      if (category && category.selectedProviders) {
        return category.selectedProviders;
      }
    }

    return [];
  },

  getCategories: (state) => {
    const { categories } = state.excursionDc;
    return categories;
  },
  getSelectedCategoryCount: (state) => {
    const riskCategories = state.excursionDc.categories.filter(
      (tlrc) => tlrc.name !== riskCategoryLabel.HIGH_RISK_STUDENT_MEDICAL_CONDITIONS
    );
    return riskCategories.length;
  },
  getActivityRiskClass: (state) => (typeName, className) => {
    let riskClass = {};
    const category = state.excursionDc.categories.find(
      (c) => c.name === riskCategoryLabel.ACTIVITIES
    );
    if (category && category.types) {
      const type = category.types.find((t) => t.name === typeName);
      if (type && type.classes) {
        const findRiskClass = type.classes.find((c) => c.name === className);
        if (findRiskClass) {
          riskClass = findRiskClass;
        }
      }
    }
    return riskClass;
  },
  getTlrc: (state) => (tlrcName) => {
    let localTlrc = {};
    const foundTlrc = state.excursionDc.categories.find((tlrc) => tlrc.name === tlrcName);
    if (foundTlrc) {
      localTlrc = foundTlrc;
    }
    return localTlrc;
  },

  getNonActivitiesExcursionDcCategories: (state) => {
    return state.excursionDc.categories.filter((c) => c.name !== 'Activities');
  },

  getHighRiskStudentMedicalConditionsRisks: (state) => {
    return state.allRisks.filter(
      (r) => r.name === riskCategoryLabel.HIGH_RISK_STUDENT_MEDICAL_CONDITIONS
    );
  },

  getNonHighRiskStudentMedicalConditionsRisks: (state) => {
    return state.allRisks.filter(
      (r) => r.name !== riskCategoryLabel.HIGH_RISK_STUDENT_MEDICAL_CONDITIONS
    );
  }
};
