import { render, staticRenderFns } from "./ClientAdminRiskMatrixManagement.vue?vue&type=template&id=26fa1b66&scoped=true"
import script from "./ClientAdminRiskMatrixManagement.vue?vue&type=script&lang=js"
export * from "./ClientAdminRiskMatrixManagement.vue?vue&type=script&lang=js"
import style0 from "./ClientAdminRiskMatrixManagement.vue?vue&type=style&index=0&id=26fa1b66&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26fa1b66",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VEditDialog } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VCard,VCardText,VContainer,VDataTable,VEditDialog,VIcon,VTab,VTabItem,VTabs,VTabsItems,VTabsSlider,VTextField,VTextarea,VToolbar,VToolbarTitle})
