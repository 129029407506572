<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <div>
    <cs-form-dialog
      v-model="propClientUserImportModalDisplayed"
      id="client-user-import-dialog"
      heading="Client Users Import"
      :primaryAction="{
        label: 'Save',
        eventName: 'primary-click',
        disabled: clientUserImportModalActionInProgress,
        loading: clientUserImportModalActionInProgress
      }"
      :secondaryAction1="{
        label: 'Cancel',
        eventName: 'secondary1-click'
      }"
      @primary-click="saveClientUserImportModal"
      @secondary1-click="resetThenCloseClientUserImportModal"
      @input="onClientUserImportModalDisplayedChange"
    >
      <template v-slot:cs-form-dialog-content>
        <v-form ref="client-user-import-modal--form" lazy-validation>
          <v-container grid-list-md>
            <div class="client-row client-modal-text">
              <v-flex xs12>
                <v-layout wrap>
                  <v-flex xs12 style="margin-bottom: 20px">
                    <v-layout wrap>
                      <v-flex xs12>
                        <div class="client-modal-text">
                          <span
                            >User list import/export is limited to roles: admin, admin assistant,
                            approver, and user. Other roles are excluded.</span
                          >
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs12>
                        <a href="/public/User_Import_Template.csv" target="_blank">
                          <v-btn
                            id="client-user-import-modal--user-list-template-download-button"
                            class="normal-btn white--text continue-btn excursion-stepper-upload-btn v-btn-upload primary"
                            style="margin-bottom: 20px"
                            :disabled="clientUserImportModalActionInProgress"
                          >
                            <v-icon
                              v-if="clientUserImportModalActionInProgress"
                              style="font-size: 14px"
                              >fa fa-spinner fa-pulse</v-icon
                            >
                            <v-icon
                              v-if="!clientUserImportModalActionInProgress"
                              style="font-size: 14px"
                              >fa fa-download</v-icon
                            >
                            <span>Download User List CSV Template</span>
                          </v-btn>
                        </a>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs12>
                        <v-btn
                          id="client-user-import-modal--user-list-export-csv-button"
                          class="normal-btn white--text continue-btn excursion-stepper-upload-btn v-btn-upload primary"
                          style="margin-bottom: 20px"
                          :disabled="clientUserImportModalActionInProgress"
                          @click="handleUserImportDownloadUserExportClick"
                        >
                          <v-icon
                            v-if="clientUserImportModalActionInProgress"
                            style="font-size: 14px"
                            >fa fa-spinner fa-pulse</v-icon
                          >
                          <v-icon
                            v-if="!clientUserImportModalActionInProgress"
                            style="font-size: 14px"
                            >fa fa-download</v-icon
                          >
                          <span>Download User List CSV (Export)</span>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs12>
                        <input
                          :id="clientUserImportFileInputId"
                          style="display: none"
                          type="file"
                          :accept="clientUserImportFileExtensionAttr"
                          @change="handleUserImportFileInputChange($event)"
                        />
                        <v-btn
                          id="client-user-import-modal--user-import-file-selection-button"
                          class="normal-btn white--text continue-btn excursion-stepper-upload-btn v-btn-upload primary"
                          :disabled="clientUserImportModalActionInProgress"
                          @click="handleUserImportFileSelectionClick"
                        >
                          <v-icon
                            v-if="clientUserImportModalActionInProgress"
                            style="font-size: 14px"
                            >fa fa-spinner fa-pulse</v-icon
                          >
                          <v-icon
                            v-if="!clientUserImportModalActionInProgress"
                            style="font-size: 14px"
                            >fa fa-upload</v-icon
                          >
                          <span>Upload User List CSV</span>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex
                    v-if="clientUserImportFileInfo"
                    xs12
                    class="form-label template-file-font"
                  >
                    File Name
                  </v-flex>
                  <v-flex v-if="clientUserImportFileInfo" xs12>
                    <v-layout wrap>
                      <v-flex xs12 style="margin-left: 3px">
                        {{ clientUserImportFileInfoName }}
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex
                    v-if="clientUserImportFileInputErrorMessage"
                    class="client-user-import-modal-error"
                  >
                    {{ clientUserImportFileInputErrorMessage }}
                  </v-flex>
                </v-layout>
              </v-flex>
            </div>
          </v-container>
        </v-form>
      </template>
    </cs-form-dialog>
    <cs-form-dialog
      v-model="clientUserImportGenericDialogDisplayed"
      id="dlg-client-user-import-generic"
      heading="Client Users Import - Status"
      bodyId="client-user-import-modal--generic-dialog"
      :primaryAction="{ label: 'OK', eventName: 'primary-click' }"
      @primary-click="clientUserImportGenericDialogDisplayed = false"
    >
      <template v-slot:cs-form-dialog-content>
        <v-card-text>
          <div v-safe-html="clientUserImportGenericDialogMessage"></div>
        </v-card-text>
      </template>
    </cs-form-dialog>
  </div>
</template>

<script>
import { populateErrorMessage } from '../lib/errorMessages';

export default {
  name: 'ClientUserImportDialog',

  props: {
    propClientUserImportModalDisplayed: Boolean
  },
  data() {
    return {
      clientUserImportFileExtensionAttr: `.csv`,
      clientUserImportFileInfo: '',
      clientUserImportFileInputErrorMessage: '',
      clientUserImportFileInputId: 'client-user-import-modal--user-import-file-input',
      clientUserImportGenericDialogDisplayed: false,
      clientUserImportGenericDialogMessage: '',
      clientUserImportModalActionInProgress: false
    };
  },
  computed: {
    clientUserImportFileInfoName() {
      return this.clientUserImportFileInfo && this.clientUserImportFileInfo.name
        ? this.clientUserImportFileInfo.name
        : '';
    }
  },
  methods: {
    onClientUserImportModalDisplayedChange(boolOpen) {
      if (!boolOpen) {
        this.resetThenCloseClientUserImportModal();
      }
    },
    resetClientUserImportFileInputErrorMessage() {
      this.clientUserImportFileInputErrorMessage = '';
    },
    resetThenCloseClientUserImportModal() {
      this.resetClientUserImportFileInputErrorMessage();
      this.$refs['client-user-import-modal--form'].reset();
      this.clientUserImportFileInfo = '';

      const clientUserImportFileInputElement = document.getElementById(
        this.clientUserImportFileInputId
      );
      clientUserImportFileInputElement.value = null;

      this.$emit('modalClosed', false);
    },
    handleUserImportFileSelectionClick() {
      document.getElementById(this.clientUserImportFileInputId).click();
    },
    handleUserImportFileInputChange(event) {
      this.resetClientUserImportFileInputErrorMessage();

      if (event && event.target && event.target.files && event.target.files.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        this.clientUserImportFileInfo = event.target.files[0];
      }
    },
    async saveClientUserImportModal() {
      this.resetClientUserImportFileInputErrorMessage();

      if (this.clientUserImportFileInfo) {
        const formData = new FormData();
        formData.append('file', this.clientUserImportFileInfo);

        const argHash = {
          payload: formData,
          store: this.$store
        };

        this.clientUserImportModalActionInProgress = true;
        const cbResponse = await this.$app.stbApiAdapter.postClientUserImports(argHash);
        this.clientUserImportModalActionInProgress = false;

        this.resetThenCloseClientUserImportModal();

        if (!cbResponse.error) {
          this.clientUserImportGenericDialogMessage = 'User list has been imported.';
          this.clientUserImportGenericDialogDisplayed = true;

          this.$emit('userListImported');
        } else {
          let dialogMessage = 'User list cannot be imported.';

          if (cbResponse.refinedMessage) {
            dialogMessage = `${dialogMessage} ${cbResponse.refinedMessage}.`;
          }

          this.clientUserImportGenericDialogMessage = populateErrorMessage({
            message: dialogMessage,
            store: this.$store
          });
          this.clientUserImportGenericDialogDisplayed = true;
        }
      } else {
        this.clientUserImportFileInputErrorMessage = 'User List CSV is required';
      }
    },
    async handleUserImportDownloadUserExportClick() {
      const argHash = {
        store: this.$store
      };

      this.clientUserImportModalActionInProgress = true;
      const cbResponse = await this.$app.stbApiAdapter.getClientUserExports(argHash);
      this.clientUserImportModalActionInProgress = false;

      if (!cbResponse.error && cbResponse.data) {
        const blob = new Blob([cbResponse.data], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'User_Export.csv';
        link.click();
      } else {
        let dialogMessage = 'User list cannot be exported.';

        if (cbResponse.refinedMessage) {
          dialogMessage = `${dialogMessage} ${cbResponse.refinedMessage}.`;
        }

        this.clientUserImportGenericDialogMessage = populateErrorMessage({
          message: dialogMessage,
          store: this.$store
        });
        this.clientUserImportGenericDialogDisplayed = true;
      }
    }
  },
  mounted() {},
  watch: {}
};
</script>
