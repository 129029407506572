import * as types from '@/store/mutationTypes';

import appInfo from './const/appInfo';
import excursionTypeInfo from './const/excursionTypeInfo';
import riskCategoryLabel from './const/riskCategoryLabel';
import trackableAttribute from './const/trackableAttribute';
import trackableEvent from './const/trackableEvent';
import exportRiskAssessmentDocx from './docx/exportRiskAssessmentDocx';

const riskTitleColumnIndex = 0;
const riskDescriptionColumnIndex = 1;
const riskResidualLikelihoodColumnIndex = 2;
const riskResidualConsequenceColumnIndex = 3;
const riskResidualRiskRatingColumnIndex = 4;
const riskMasterChecklistsColumnIndex = 5;
const riskMasterPoliciesColumnIndex = 6;
const riskInherentLikelihoodColumnIndex = 7;
const riskInherentConsequenceColumnIndex = 8;
const riskInherentRiskRatingColumnIndex = 9;

function checkIfRiskControlColumnDetected(rcaTableRow) {
  const riskControlInspectionColumn = rcaTableRow[riskInherentRiskRatingColumnIndex];
  const riskControlColumnDetected =
    rcaTableRow.length === 10 &&
    (typeof riskControlInspectionColumn === 'undefined' ||
      riskControlInspectionColumn === null ||
      `${riskControlInspectionColumn}` === '');

  return riskControlColumnDetected;
}

function appendRequestList(category, newCategory) {
  if (category.CompleteChecklist) {
    category.CompleteChecklist.forEach((completeChecklistItem) => {
      if (completeChecklistItem.checklist && completeChecklistItem.checklist.length > 0) {
        completeChecklistItem.checklist.forEach((item) => {
          newCategory.checklist.push({
            title: item.title.trim()
          });
        });
      }
    });
  }
}

function flattenArray(arr) {
  const newArr = [];
  arr.forEach((item) => {
    if (Array.isArray(item)) {
      item.forEach((subItem) => newArr.push(subItem));
    } else {
      newArr.push(item);
    }
  });

  return newArr;
}

function appendRiskChecklist(newCategory, itemChecklistString) {
  if (itemChecklistString) {
    const itemChecklists = itemChecklistString.split('\n');
    if (
      itemChecklists &&
      itemChecklists.length > 0 &&
      newCategory.checklist &&
      newCategory.checklist.length >= 0
    ) {
      itemChecklists.forEach((itemChecklist) => {
        const existChecklist = newCategory.checklist.filter((ck) => {
          return `${ck.title}` === `${itemChecklist.trim()}`;
        });

        if ((!existChecklist || existChecklist.length <= 0) && itemChecklist) {
          newCategory.checklist.push({
            title: itemChecklist.trim()
          });
        }
      });
    }
  }
}

function appendRiskPolicy(newCategory, itemPolicy) {
  if (itemPolicy) {
    const itemPolicies = itemPolicy.split('\n');
    if (
      itemPolicies &&
      itemPolicies.length > 0 &&
      newCategory.policies &&
      newCategory.policies.length >= 0
    ) {
      itemPolicies.forEach((ip) => {
        const existPolicy = newCategory.policies.filter((ck) => {
          return `${ck.title}` === `${ip.trim()}`;
        });

        if ((!existPolicy || existPolicy.length <= 0) && ip) {
          newCategory.policies.push({
            title: ip.trim()
          });
        }
      });
    }
  }
}

function enableFormControl(selectorDOM, toggleValue) {
  const selectors = ['input', 'select', 'textarea'];

  if (!toggleValue) {
    selectors.forEach((selector) => {
      $(selectorDOM).find(selector).prop('disabled', true);
    });
  }
}

function setListItemId(selector) {
  const options = $(selector);
  const id = 'listItem';
  options.each((index, option) => {
    $(option).prop('id', `${id}${index}`);
  });
}

function getRawRcaTable(riskResponse, tlrcName) {
  let assessment = {};
  // rca stands for: risk category assessment
  let rawRcaTable;

  if (riskResponse && riskResponse.optional && riskResponse.optional.length > 0) {
    assessment = riskResponse.optional.filter((item) => {
      return `${item.title}` === `${tlrcName}`;
    });
  }

  if (assessment && assessment.length > 0) {
    const rcaBase = assessment[0]['Risk Assessment'];

    if (rcaBase) {
      rawRcaTable = JSON.parse(rcaBase);
    }
  }

  return rawRcaTable;
}

function getActivityRcas(riskResponse, activityTlrcs, excursionInherentRiskRatingEnabled) {
  const activityLabelItems = [
    'Boating',
    'Camping',
    'Climbing',
    'Cycling',
    'Gymnastics',
    'Horse Riding',
    'Nature Walking',
    'Physical Conditioning',
    'Running',
    'Soccer',
    'Swimming'
  ];
  const sportLabelItems = ['Jumping', 'Lifting'];
  let activityRca = {};
  let newItem = 0;
  const activityRcas = [];

  const activityRawRcaTable = getRawRcaTable(riskResponse, riskCategoryLabel.ACTIVITIES);

  if (activityRawRcaTable && activityRawRcaTable.length > 0) {
    activityRawRcaTable.forEach((rcaTableRow, index) => {
      const classes = activityTlrcs.types.filter((tp) => {
        let riskClassFound;
        let riskItemClassName;

        if (tp.classes && tp.classes.length > 0) {
          riskClassFound = tp.classes.find((item) => {
            const cl = item;
            if (cl.name && activityLabelItems.includes(cl.name)) {
              cl.name += ' Activities';
            } else if (cl.name && sportLabelItems.includes(cl.name)) {
              cl.name += ' Sports';
            }
            return `${cl.name}` === `${rcaTableRow[0]}`;
          });
        }

        if (riskClassFound) {
          riskItemClassName = riskClassFound.name;
        }

        return `${riskItemClassName}` === `${rcaTableRow[0]}`;
      });

      let riskClassName;
      if (classes && classes.length > 0 && classes[0].classes.length > 0) {
        const existClass = classes[0].classes.find((item) => {
          const cl = item;
          if (cl.name && activityLabelItems.includes(cl.name)) {
            cl.name += ' Activities';
          } else if (cl.name && sportLabelItems.includes(cl.name)) {
            cl.name += ' Sports';
          }
          return `${cl.name}` === `${rcaTableRow[0]}`;
        });

        if (existClass) {
          riskClassName = existClass.name;
        }
      }

      if (!riskClassName && index === 0) {
        return;
      }

      const riskControlColumnDetected = checkIfRiskControlColumnDetected(rcaTableRow);

      if (
        `${activityRca.title}` !== `${rcaTableRow[0]}` &&
        classes &&
        classes.length > 0 &&
        riskClassName === rcaTableRow[0]
      ) {
        if (`${newItem}` === '1') {
          activityRcas.push(activityRca);
          newItem = 0;
        }

        const residualLikelihood = rcaTableRow[riskResidualLikelihoodColumnIndex];
        const residualConsequence = rcaTableRow[riskResidualConsequenceColumnIndex];
        const residualRiskRating = rcaTableRow[riskResidualRiskRatingColumnIndex];

        activityRca = {
          categoryName: riskCategoryLabel.ACTIVITIES,
          title: rcaTableRow[riskTitleColumnIndex],
          description: rcaTableRow[riskDescriptionColumnIndex],
          likelihood: residualLikelihood,
          consequence: residualConsequence,
          'risk-rating': residualRiskRating,
          checklist: [],
          policies: []
        };

        if (excursionInherentRiskRatingEnabled) {
          activityRca.residualLikelihood = residualLikelihood || '';
          activityRca.residualConsequence = residualConsequence || '';
          activityRca.residualRiskRating = residualRiskRating || '';

          let resolvedRiskInherentLikelihoodColumnIndex = riskInherentLikelihoodColumnIndex;
          let resolvedRiskInherentConsequenceColumnIndex = riskInherentConsequenceColumnIndex;
          let resolvedRiskInherentRiskRatingColumnIndex = riskInherentRiskRatingColumnIndex;

          if (riskControlColumnDetected) {
            resolvedRiskInherentLikelihoodColumnIndex -= 1;
            resolvedRiskInherentConsequenceColumnIndex -= 1;
            resolvedRiskInherentRiskRatingColumnIndex -= 1;
          }

          const inherentLikelihood = rcaTableRow[resolvedRiskInherentLikelihoodColumnIndex];
          const inherentConsequence = rcaTableRow[resolvedRiskInherentConsequenceColumnIndex];
          const inherentRiskRating = rcaTableRow[resolvedRiskInherentRiskRatingColumnIndex];

          activityRca.inherentLikelihood = inherentLikelihood || '';
          activityRca.inherentConsequence = inherentConsequence || '';
          activityRca.inherentRiskRating = inherentRiskRating || '';
        }

        newItem += 1;
        appendRequestList(activityTlrcs, activityRca);
      } else if (rcaTableRow[riskTitleColumnIndex] !== 'Risk Title') {
        const checklistAndPolicySkipped =
          excursionInherentRiskRatingEnabled && riskControlColumnDetected;

        const checklistCell = checklistAndPolicySkipped
          ? ''
          : rcaTableRow[riskMasterChecklistsColumnIndex];
        const policyCell = checklistAndPolicySkipped
          ? ''
          : rcaTableRow[riskMasterPoliciesColumnIndex];

        appendRiskChecklist(activityRca, checklistCell);
        appendRiskPolicy(activityRca, policyCell);
      }

      if (`${index}` === `${activityRawRcaTable.length - 1}`) {
        activityRcas.push(activityRca);
      }
    });
  }

  return activityRcas;
}

function getStudentMedicalRcas(
  riskResponse,
  studentMedicalTlrcs,
  excursionInherentRiskRatingEnabled
) {
  const studentMedicalRcas = [];

  const studentMedicalRawRcaTable = getRawRcaTable(
    riskResponse,
    riskCategoryLabel.HIGH_RISK_STUDENT_MEDICAL_CONDITIONS
  );
  if (studentMedicalRawRcaTable && studentMedicalRawRcaTable.length > 0) {
    // Build the risk category hash
    const newRcaHash = {};
    const commonRcaTableRows = [];
    let currentNewRcaHashKey = '';

    for (let i = 0; i < studentMedicalRawRcaTable.length; i += 1) {
      const rcaTableRow = studentMedicalRawRcaTable[i];

      if (
        (((rcaTableRow[5] && rcaTableRow[5].length === 0) || !rcaTableRow[5]) &&
          ((rcaTableRow[6] && rcaTableRow[6].length === 0) || !rcaTableRow[6])) ||
        String(rcaTableRow[0]).startsWith('Students with')
      ) {
        // Handle rows that contain risk categories
        const newRcaHashKey = rcaTableRow[0];
        currentNewRcaHashKey = newRcaHashKey;
        newRcaHash[newRcaHashKey] = {
          index: i,
          rcaTableRow,
          childRcaTableRows: []
        };
      } else if (rcaTableRow[0] === 'Risk Title') {
        // Handle rows that contain 'Risk Title' headings
        if (currentNewRcaHashKey !== '') {
          const currentNewRcaHashValue = newRcaHash[currentNewRcaHashKey];
          const currentNewRcaHashIndex = currentNewRcaHashValue.index;
          const rcaTableRowCount = i - currentNewRcaHashIndex;

          // Treat 'Risk Title' rows that are
          // not immediately below risk category rows as delimiters
          if (rcaTableRowCount > 1) {
            currentNewRcaHashKey = '';
          }
        }
      } else if (currentNewRcaHashKey !== '') {
        // Handle rows that contain checklists and policies
        // Handle risk-specific checklists and policies
        const currentNewRcaHashValue = newRcaHash[currentNewRcaHashKey];
        currentNewRcaHashValue.childRcaTableRows.push(rcaTableRow);
      } else {
        // Handle common checklists and policies
        commonRcaTableRows.push(rcaTableRow);
      }
    }

    // Append common risk assessment rows
    Object.keys(newRcaHash).forEach((newRcaHashKey) => {
      const newRcaHashValue = newRcaHash[newRcaHashKey];
      commonRcaTableRows.forEach((commonRcaTableRow) => {
        newRcaHashValue.childRcaTableRows.push(commonRcaTableRow);
      });
    });

    const newRcaHashKeys = Object.keys(newRcaHash);
    for (let i = 0; i < newRcaHashKeys.length; i += 1) {
      const newRcaHashKey = newRcaHashKeys[i];
      const newRcaHashValue = newRcaHash[newRcaHashKey];
      const { rcaTableRow, childRcaTableRows } = newRcaHashValue;

      const residualLikelihood = rcaTableRow[riskResidualLikelihoodColumnIndex];
      const residualConsequence = rcaTableRow[riskResidualConsequenceColumnIndex];
      const residualRiskRating = rcaTableRow[riskResidualRiskRatingColumnIndex];

      const studentMedicalRca = {
        categoryName: riskCategoryLabel.HIGH_RISK_STUDENT_MEDICAL_CONDITIONS,
        title: rcaTableRow[riskTitleColumnIndex],
        description: rcaTableRow[riskDescriptionColumnIndex],
        likelihood: residualLikelihood,
        consequence: residualConsequence,
        'risk-rating': residualRiskRating,
        checklist: [],
        policies: []
      };

      if (excursionInherentRiskRatingEnabled) {
        studentMedicalRca.residualLikelihood = residualLikelihood || '';
        studentMedicalRca.residualConsequence = residualConsequence || '';
        studentMedicalRca.residualRiskRating = residualRiskRating || '';

        let resolvedRiskInherentLikelihoodColumnIndex = riskInherentLikelihoodColumnIndex;
        let resolvedRiskInherentConsequenceColumnIndex = riskInherentConsequenceColumnIndex;
        let resolvedRiskInherentRiskRatingColumnIndex = riskInherentRiskRatingColumnIndex;

        const riskControlColumnDetected = checkIfRiskControlColumnDetected(rcaTableRow);

        if (riskControlColumnDetected) {
          resolvedRiskInherentLikelihoodColumnIndex -= 1;
          resolvedRiskInherentConsequenceColumnIndex -= 1;
          resolvedRiskInherentRiskRatingColumnIndex -= 1;
        }

        const inherentLikelihood = rcaTableRow[resolvedRiskInherentLikelihoodColumnIndex];
        const inherentConsequence = rcaTableRow[resolvedRiskInherentConsequenceColumnIndex];
        const inherentRiskRating = rcaTableRow[resolvedRiskInherentRiskRatingColumnIndex];

        studentMedicalRca.inherentLikelihood = inherentLikelihood || '';
        studentMedicalRca.inherentConsequence = inherentConsequence || '';
        studentMedicalRca.inherentRiskRating = inherentRiskRating || '';
      }

      appendRequestList(studentMedicalTlrcs, studentMedicalRca);

      childRcaTableRows.forEach((childRcaTableRow) => {
        const riskControlColumnDetected = checkIfRiskControlColumnDetected(childRcaTableRow);
        const checklistAndPolicySkipped =
          excursionInherentRiskRatingEnabled && riskControlColumnDetected;

        const checklistCell = checklistAndPolicySkipped
          ? ''
          : childRcaTableRow[riskMasterChecklistsColumnIndex];
        const policyCell = checklistAndPolicySkipped
          ? ''
          : childRcaTableRow[riskMasterPoliciesColumnIndex];

        appendRiskChecklist(studentMedicalRca, checklistCell);
        appendRiskPolicy(studentMedicalRca, policyCell);
      });

      studentMedicalRcas.push(studentMedicalRca);
    }
  }

  return studentMedicalRcas;
}

function getGenericRcas(riskResponse, genericTlrcs, excursionInherentRiskRatingEnabled) {
  const genericRcas = [];

  genericTlrcs.forEach((genericTlrc) => {
    const rawRcaTable = getRawRcaTable(riskResponse, genericTlrc.name);
    const rcaTableRow = rawRcaTable[0];

    const residualLikelihood = rcaTableRow[riskResidualLikelihoodColumnIndex];
    const residualConsequence = rcaTableRow[riskResidualConsequenceColumnIndex];
    const residualRiskRating = rcaTableRow[riskResidualRiskRatingColumnIndex];

    const genericRca = {
      categoryName: genericTlrc.name,
      title: rcaTableRow[riskTitleColumnIndex],
      description: rcaTableRow[riskDescriptionColumnIndex],
      likelihood: residualLikelihood,
      consequence: residualConsequence,
      'risk-rating': residualRiskRating,
      checklist: [],
      policies: []
    };

    if (excursionInherentRiskRatingEnabled) {
      genericRca.residualLikelihood = residualLikelihood || '';
      genericRca.residualConsequence = residualConsequence || '';
      genericRca.residualRiskRating = residualRiskRating || '';

      let resolvedRiskInherentLikelihoodColumnIndex = riskInherentLikelihoodColumnIndex;
      let resolvedRiskInherentConsequenceColumnIndex = riskInherentConsequenceColumnIndex;
      let resolvedRiskInherentRiskRatingColumnIndex = riskInherentRiskRatingColumnIndex;

      const riskControlColumnDetected = checkIfRiskControlColumnDetected(rcaTableRow);

      if (riskControlColumnDetected) {
        resolvedRiskInherentLikelihoodColumnIndex -= 1;
        resolvedRiskInherentConsequenceColumnIndex -= 1;
        resolvedRiskInherentRiskRatingColumnIndex -= 1;
      }

      const inherentLikelihood = rcaTableRow[resolvedRiskInherentLikelihoodColumnIndex];
      const inherentConsequence = rcaTableRow[resolvedRiskInherentConsequenceColumnIndex];
      const inherentRiskRating = rcaTableRow[resolvedRiskInherentRiskRatingColumnIndex];

      genericRca.inherentLikelihood = inherentLikelihood || '';
      genericRca.inherentConsequence = inherentConsequence || '';
      genericRca.inherentRiskRating = inherentRiskRating || '';
    }

    appendRequestList(genericTlrc, genericRca);

    rawRcaTable.forEach((rawRcaTableRow) => {
      if (rawRcaTableRow[riskTitleColumnIndex] !== 'Risk Title') {
        const riskControlColumnDetected = checkIfRiskControlColumnDetected(rawRcaTableRow);
        const checklistAndPolicySkipped =
          excursionInherentRiskRatingEnabled && riskControlColumnDetected;

        const checklistCell = checklistAndPolicySkipped
          ? ''
          : rawRcaTableRow[riskMasterChecklistsColumnIndex];
        const policyCell = checklistAndPolicySkipped
          ? ''
          : rawRcaTableRow[riskMasterPoliciesColumnIndex];

        appendRiskChecklist(genericRca, checklistCell);
        appendRiskPolicy(genericRca, policyCell);
      }
    });

    genericRcas.push(genericRca);
  });

  return genericRcas;
}

function getExcursionRcas(riskResponse, tlrcs, excursionInherentRiskRatingEnabled) {
  const rcas = [];

  // 1. Activity risk categories
  const activityTlrc = tlrcs.find((tlrc) => tlrc.name === riskCategoryLabel.ACTIVITIES);

  if (activityTlrc && activityTlrc.types && activityTlrc.types.length > 0) {
    const activityRcas = getActivityRcas(
      riskResponse,
      activityTlrc,
      excursionInherentRiskRatingEnabled
    );
    rcas.push(...activityRcas);
  }

  // 2. High-risk medical risk categories
  const studentMedicalTlrc = tlrcs.find(
    (tlrc) => tlrc.name === riskCategoryLabel.HIGH_RISK_STUDENT_MEDICAL_CONDITIONS
  );

  if (studentMedicalTlrc) {
    const studentMedicalRcas = getStudentMedicalRcas(
      riskResponse,
      studentMedicalTlrc,
      excursionInherentRiskRatingEnabled
    );
    rcas.push(...studentMedicalRcas);
  }

  // 3. Generic risk categories
  const genericTlrc = tlrcs.filter((tlrc) => {
    return (
      tlrc.name !== riskCategoryLabel.HIGH_RISK_STUDENT_MEDICAL_CONDITIONS &&
      tlrc.name !== riskCategoryLabel.ACTIVITIES
    );
  });

  if (genericTlrc) {
    const genericRcas = getGenericRcas(
      riskResponse,
      genericTlrc,
      excursionInherentRiskRatingEnabled
    );
    rcas.push(...genericRcas);
  }

  return rcas;
}

function getStaffToStudentRatio(staffSupervisorAttendanceCount, studentAttendanceCount) {
  // This function also exists in se-stb-api
  let staffToStudentRatio = '';

  if (
    studentAttendanceCount &&
    studentAttendanceCount > 0 &&
    staffSupervisorAttendanceCount &&
    staffSupervisorAttendanceCount > 0
  ) {
    const studentToStaffRatio = studentAttendanceCount / staffSupervisorAttendanceCount;
    const staffToStudentRatioDenominator = Math.round(studentToStaffRatio * 100) / 100;
    if (staffToStudentRatioDenominator % 1 === 0) {
      staffToStudentRatio = `1:${staffToStudentRatioDenominator}`;
    } else {
      staffToStudentRatio = `1:${staffToStudentRatioDenominator.toFixed(2)}`;
    }
  }

  return staffToStudentRatio;
}

function isNewExcursion(storeState) {
  const { excursionDc } = storeState.common;
  return excursionDc.id === '0' || excursionDc.id === null || typeof excursionDc.id === 'undefined';
}

function isWithinAustralia(storeState) {
  const mappedTypes = [excursionTypeInfo.DAY_EXCURSION, excursionTypeInfo.OVERNIGHT_EXCURSION].map(
    (excursionType) => excursionType.label
  );
  return mappedTypes.indexOf(storeState.common.excursionDc.typeId) > -1;
}

function showJurisdiction(storeState) {
  const { excursionDc } = storeState.common;
  return (
    storeState.common.excursionDestinationContentEnabled &&
    isWithinAustralia(storeState) &&
    (excursionDc.excursionDestinationContentEnabled || isNewExcursion(storeState))
  );
}

function getRiskAssessmentTrackableEventBaseArgHash(apiDataDefaultJurisdiction, excursionDc) {
  const localJurisdiction = excursionDc.jurisdiction;

  const trackableEventBaseArgHash = {};
  trackableEventBaseArgHash[trackableAttribute.APP_NAME] = appInfo.APP_NAME;
  trackableEventBaseArgHash[trackableAttribute.EXCURSION_ID] = excursionDc.id;
  trackableEventBaseArgHash[trackableAttribute.EXCURSION_NAME] = excursionDc.name;
  trackableEventBaseArgHash[trackableAttribute.EXCURSION_STATUS] = excursionDc.status;
  trackableEventBaseArgHash[trackableAttribute.EXCURSION_TYPE] = excursionDc.typeId;

  if (apiDataDefaultJurisdiction) {
    trackableEventBaseArgHash[trackableAttribute.JURISDICTION] = apiDataDefaultJurisdiction;
  }

  if (localJurisdiction) {
    trackableEventBaseArgHash[trackableAttribute.DESTINATION] = localJurisdiction;
  }

  return trackableEventBaseArgHash;
}

function getRiskAssessmentTrackableEventOptionArgHash() {
  const optionArgHash = {
    sentImmediately: true
  };

  return optionArgHash;
}

class Adapter {
  constructor(diHash) {
    const { store, app } = diHash;

    this.store = store;
    this.stbApiAdapter = app.stbApiAdapter;
    this.pdf = app.pdf;
    this.lodash = app.lodash;
    this.exportExcursions = app.exportExcursions;
    this.eventPluginCommonAdapter = app.eventPluginCommonAdapter;
  }

  getActivityParams() {
    const riskCategoryParams = [];
    const allSelectedCategories = this.store.state.common.excursionDc.categories;

    const selectedActivityRisk = allSelectedCategories.find(
      (c) => c.name === riskCategoryLabel.ACTIVITIES
    );
    if (typeof selectedActivityRisk !== 'undefined') {
      selectedActivityRisk.types.forEach((riskType) => {
        riskType.classes.forEach((riskClass) => {
          if (riskClass.risks) {
            for (let riskKey = 0; riskKey < riskClass.risks.length; riskKey += 1) {
              const riskName = riskClass.risks[riskKey].name;
              const paramRiskCategory = [
                riskCategoryLabel.ACTIVITIES,
                riskType.name,
                riskClass.name,
                riskName
              ];
              riskCategoryParams.push(paramRiskCategory);
            }
          }
        });
      });
    }

    return riskCategoryParams;
  }

  getRiskCategoryParams() {
    const allSelectedCategories = this.store.state.common.excursionDc.categories;
    const selectedRiskCategories = allSelectedCategories.filter(
      (c) => c.name !== riskCategoryLabel.ACTIVITIES
    );

    const riskCategoryParams = [];

    selectedRiskCategories.forEach((category) => {
      if (category.types && category.types.length === 0) {
        riskCategoryParams.push([category.name]);
      }
      category.types.forEach((riskType) => {
        riskType.classes.forEach((riskClass) => {
          const paramRiskCategory = [category.name, riskType.name, riskClass.name];
          riskCategoryParams.push(paramRiskCategory);
        });
      });
    });

    return riskCategoryParams;
  }

  getFrcs() {
    const activityRisks = this.getActivityParams();
    const riskCategoryRisks = this.getRiskCategoryParams();

    return riskCategoryRisks.concat(activityRisks);
  }

  downloadFile(href, filename) {
    this.pdf.downloadFile(href, filename);
  }

  async downloadAssessmentGuidePdf(
    excursionId,
    normalizedRequestParam,
    clientLogoEnabled,
    deviceDetector
  ) {
    await this.pdf.downloadPagePdf.downloadRiskAssignmentGuidePdf(
      this.stbApiAdapter,
      this.store,
      excursionId,
      normalizedRequestParam,
      clientLogoEnabled,
      deviceDetector
    );
  }

  async downloadAssessment(
    excursionId,
    normalizedRequestParam,
    allChecklistsExported,
    clientLogoEnabled,
    predepartureChecklistExported,
    allAttachmentsExported,
    format,
    deviceDetector
  ) {
    const exportParams = [
      this.stbApiAdapter,
      this.store,
      excursionId,
      normalizedRequestParam,
      allChecklistsExported,
      clientLogoEnabled,
      predepartureChecklistExported,
      allAttachmentsExported,
      deviceDetector
    ];
    switch (format) {
      case 'docx':
        return exportRiskAssessmentDocx(...exportParams);
      case 'pdf':
      default:
        return this.pdf.downloadPagePdf.downloadRiskAssignmentPdf(...exportParams);
    }
  }

  async exportExcursionsWithJSONFormat(filterParam, store) {
    const result = await this.exportExcursions.exportAllExcursions.exportExursionsWithJsonFormat(
      this.stbApiAdapter,
      filterParam,
      store
    );
    return result;
  }

  async exportExcursionsWithCSVFormat(filterParam, store) {
    const result = await this.exportExcursions.exportAllExcursions.exportExursionsWithCSVFormat(
      this.stbApiAdapter,
      filterParam,
      store
    );
    return result;
  }

  async downloadPredeparturePdf(queryParam, deviceDetector) {
    const argHash = {
      stbApiAdapter: this.stbApiAdapter,
      store: this.store,
      queryParam
    };
    const error = await this.pdf.downloadPagePdf.downloadPredeparturePdf(argHash, deviceDetector);
    return error;
  }

  async downloadExcursionRiskContentsPdf(queryParam, param, deviceDetector) {
    const frcs = this.getFrcs();

    const excursionPolicies = [];
    if (param.exportedItems && param.exportedItems.length > 0) {
      param.exportedItems.forEach((item) => {
        if (item.ticked) {
          excursionPolicies.push({ name: item.name });
        }
      });
    }

    const params = {
      clientLogoEnabled: param.clientLogoEnabled,
      excursionName: this.store.state.common.excursionDc.name,
      excursionId: this.store.state.common.excursionDc.id || 0,
      masterPolicyExported: param.masterPolicyExported,
      mandatoryChecklistsExported: param.mandatoryChecklistsExported,
      exportedCategoryChecklists: param.exportedCategoryChecklists,
      categoryChecklistsExported: param.categoryChecklistsExported,
      riskCategoryParams: frcs,
      excursionPolicies
    };

    await this.pdf.downloadPagePdf.downloadExcursionRiskContentsPdf(
      this.stbApiAdapter,
      this.store,
      queryParam,
      params,
      deviceDetector
    );
  }

  getExcursionRiskOverall(riskResponse, excursionInherentRiskRatingEnabled) {
    const excursionType = this.store.state.common.excursionDc.typeId;

    const riskOverall = {
      description: '',
      policies: [],
      checklists: [],
      /**
       * DB migration required after renaming these 'overall' attributes.
       * Therefore, avoid renaming these for now to allow backward compatibility:
       * - likelihood
       * - consequence
       * - risk-rating
       */
      likelihood: '',
      consequence: '',
      'risk-rating': ''
    };

    const { mandatory } = riskResponse;
    if (mandatory && mandatory.length > 0) {
      const master = mandatory.filter((item) => {
        return item.title === 'Master';
      });

      if (master && master.length > 0) {
        const masterRiskBase = master[0]['Risk Assessment'];
        const masterRisk = JSON.parse(masterRiskBase);
        if (masterRisk && masterRisk.length > 0) {
          const masterRiskAssessment = masterRisk.filter((item) => {
            return item[0] === `${excursionType}s`;
          });

          if (masterRiskAssessment && masterRiskAssessment.length > 0) {
            riskOverall.description = masterRiskAssessment[0][riskDescriptionColumnIndex];

            const residualLikelihood = masterRiskAssessment[0][riskResidualLikelihoodColumnIndex];
            const residualConsequence = masterRiskAssessment[0][riskResidualConsequenceColumnIndex];
            const residualRiskRating = masterRiskAssessment[0][riskResidualRiskRatingColumnIndex];

            riskOverall.likelihood = residualLikelihood;
            riskOverall.consequence = residualConsequence;
            riskOverall['risk-rating'] = residualRiskRating;

            if (excursionInherentRiskRatingEnabled) {
              riskOverall.residualLikelihood = residualLikelihood || '';
              riskOverall.residualConsequence = residualConsequence || '';
              riskOverall.residualRiskRating = residualRiskRating || '';

              const inherentLikelihood = masterRiskAssessment[0][riskInherentLikelihoodColumnIndex];
              const inherentConsequence =
                masterRiskAssessment[0][riskInherentConsequenceColumnIndex];
              const inherentRiskRating = masterRiskAssessment[0][riskInherentRiskRatingColumnIndex];

              riskOverall.inherentLikelihood = inherentLikelihood || '';
              riskOverall.inherentConsequence = inherentConsequence || '';
              riskOverall.inherentRiskRating = inherentRiskRating || '';
            }

            const masterChecklists =
              masterRiskAssessment[0][riskMasterChecklistsColumnIndex].split('\n');
            if (masterChecklists && masterChecklists.length > 0) {
              masterChecklists.forEach((masterChecklist) => {
                riskOverall.checklists.push({
                  title: masterChecklist
                });
              });
            }

            const masterPolicies =
              masterRiskAssessment[0][riskMasterPoliciesColumnIndex].split('\n');
            if (masterPolicies && masterPolicies.length > 0) {
              masterPolicies.forEach((masterPolicy) => {
                riskOverall.policies.push({
                  title: masterPolicy
                });
              });
            }
          }
        }
      }
    }

    return riskOverall;
  }

  async getExcursionRiskCombo(queryParam) {
    const { excursionInherentRiskRatingEnabled } = this.store.state.common;

    const allSelectedCategories = this.lodash.cloneDeep(
      this.store.state.common.excursionDc.categories
    );
    const frcs = this.getFrcs();

    if (frcs.length > 0) {
      const argHash = {
        queryParam,
        store: this.store,
        frcs
      };
      const riskResponse = await this.stbApiAdapter.getViewPolicy(argHash);
      this.store.commit(types.COMMON_SET_CE_COMMIT_OID, riskResponse.ceCommitOid);
      const riskOverall = this.getExcursionRiskOverall(
        riskResponse,
        excursionInherentRiskRatingEnabled
      );

      const allRisks = this.lodash.cloneDeep(this.store.state.common.allRisks);
      const selectedTlrcs = [];

      if (allRisks && allRisks.length > 0) {
        allRisks.forEach((item) => {
          const selectTlrc = allSelectedCategories.find((selected) => {
            return selected.name === item.name;
          });

          if (selectTlrc) {
            selectedTlrcs.push(selectTlrc);
          }
        });
      }

      const riskCategories = getExcursionRcas(
        riskResponse,
        selectedTlrcs,
        excursionInherentRiskRatingEnabled
      );

      return {
        riskOverall,
        riskCategories
      };
    }

    return null;
  }

  registerRiskManifestLinkClickEvent() {
    const trackableEventBaseArgHash = {
      defaultParams: true
    };
    const trackableEventOptionArgHash = {
      sentImmediately: true
    };

    this.eventPluginCommonAdapter.registerTrackableEvent(
      trackableEvent.RISK_MANIFEST_PDF_LINK_CLICK,
      trackableEventBaseArgHash,
      trackableEventOptionArgHash
    );
    window.open('/public/SafeTripBuilder - Risk Categories Manifest.pdf', '_blank').focus();
  }

  registerSelectedRisksEvent(apiDataDefaultJurisdiction, excursionDc) {
    if (excursionDc.categories.length === 0) {
      return;
    }

    const trackableEventBaseArgHash = { defaultParams: true };

    const localJurisdiction = excursionDc.jurisdiction;

    trackableEventBaseArgHash[trackableAttribute.APP_NAME] = appInfo.APP_NAME;
    trackableEventBaseArgHash[trackableAttribute.EXCURSION_ID] = excursionDc.id;
    trackableEventBaseArgHash[trackableAttribute.EXCURSION_NAME] = excursionDc.name;
    trackableEventBaseArgHash[trackableAttribute.EXCURSION_STATUS] = excursionDc.status;
    trackableEventBaseArgHash[trackableAttribute.EXCURSION_TYPE] = excursionDc.typeId;

    if (apiDataDefaultJurisdiction) {
      trackableEventBaseArgHash[trackableAttribute.JURISDICTION] = apiDataDefaultJurisdiction;
    }

    if (localJurisdiction) {
      trackableEventBaseArgHash[trackableAttribute.DESTINATION] = localJurisdiction;
    }

    const selectedRiskCategories = [];
    excursionDc.categories.forEach((category) => {
      if (category.name !== 'High-Risk Student Medical Conditions') {
        if (
          category.types &&
          category.types.length > 0 &&
          category.types[0].classes &&
          category.types[0].classes.length > 0 &&
          category.types[0].classes[0].risks &&
          category.types[0].classes[0].risks.length > 0
        ) {
          const result1 = category.types.map((t) =>
            t.classes.map((c) =>
              c.risks.map((r) => `${category.name}|${t.name}|${c.name}|${r.name}`)
            )
          );
          flattenArray(flattenArray(result1)).forEach((item) => selectedRiskCategories.push(item));
        } else if (
          category.types &&
          category.types.length > 0 &&
          category.types[0].classes &&
          category.types[0].classes.length > 0
        ) {
          const result2 = category.types.map((t) =>
            t.classes.map((c) => `${category.name}|${t.name}|${c.name}`)
          );
          flattenArray(result2).forEach((item) => selectedRiskCategories.push(item));
        } else if (category.types && category.types.length > 0) {
          const result3 = category.types.map((t) => `${category.name}|${t.name}`);
          result3.forEach((item) => selectedRiskCategories.push(item));
        }
      }
    });

    selectedRiskCategories.forEach((selectedCaegory, index) => {
      trackableEventBaseArgHash[`Selected Risk Categories ${index + 1}`] = selectedCaegory;
    });

    const trackableEventOptionArgHash = {
      sentImmediately: true
    };

    this.eventPluginCommonAdapter.registerTrackableEvent(
      trackableEvent.RISK_ASSESSMENT_RISK_CATEGORIES_UPDATE,
      trackableEventBaseArgHash,
      trackableEventOptionArgHash
    );
  }
}

function formatExcursionDate(date) {
  // Linked reference: apiui_bfd81d33400f4b209eb99378318bea50
  if (!date) {
    return '';
  }

  const [year, month, day] = date.split('-');
  return `${day}/${month}/${year}`;
}

function checkIfExcursionStartEndDateSetValid(argHash) {
  // Linked reference: apiui_7c2c362edad24f7d8d8264d7475b62a9
  const { excursionStartDate, excursionEndDate, excursionType } = argHash;
  let excursionStartEndDateSetValid = true;

  if (excursionStartDate && excursionEndDate) {
    const localExcursionStartDate = new Date(excursionStartDate);
    const excursionStartDateMs = localExcursionStartDate.getTime();
    const localExcursionEndDate = new Date(excursionEndDate);
    const excursionEndDateMs = localExcursionEndDate.getTime();
    excursionStartEndDateSetValid = excursionStartDateMs <= excursionEndDateMs;

    if (excursionStartEndDateSetValid) {
      switch (excursionType) {
        case excursionTypeInfo.DAY_EXCURSION.label:
          excursionStartEndDateSetValid = excursionStartDateMs === excursionEndDateMs;
          break;

        case excursionTypeInfo.OVERNIGHT_EXCURSION.label:
        case excursionTypeInfo.INTERNATIONAL_EXCURSION.label:
          excursionStartEndDateSetValid = excursionStartDateMs < excursionEndDateMs;
          break;

        default:
          break;
      }
    }
  }

  return excursionStartEndDateSetValid;
}

export default {
  Adapter,
  enableFormControl,
  getStaffToStudentRatio,
  isNewExcursion,
  isWithinAustralia,
  setListItemId,
  showJurisdiction,
  getRiskAssessmentTrackableEventBaseArgHash,
  getRiskAssessmentTrackableEventOptionArgHash,
  formatExcursionDate,
  checkIfExcursionStartEndDateSetValid,
  flattenArray
};
