<template>
  <div class="risk-assessment-rating" v-if=" excursionUserRiskRatingEnabled">
    <v-flex xs12 class="form-label safe-trip-font">
      Overall Rating
      <v-flex class="confirmation-info-text" v-if="excursionUserRiskRatingEnabled">
        <i class="fas fa-info-circle"></i>
        Confirm an overall risk assessment for this excursion. You can review <span v-if="!riskMatrixSelfDefinedDetected">our Risk Assessment Guide </span><span v-if="riskMatrixSelfDefinedDetected">your school's excursion risk matrix </span>
        <span v-if="riskMatrixSelfDefinedDetected" id="risk-rating-overall--guide-download--link" class="primary--text text-decoration-underline" style="cursor:pointer" @click="downloadRiskAssessmentGuidePdf">here</span><a v-if="!riskMatrixSelfDefinedDetected" id="risk-rating-overall--risk-assessment-guide--link" href="/public/Risk_Assessment_Guide.pdf" target="_blank">here</a>.
      </v-flex>
    </v-flex>
    <v-card class="d-flex p-2" color="#eeeeee">
      <v-flex class="assessment-title likelihood-assessment-title">
        Likelihood
      </v-flex>
      <v-select
        v-model="selectedLikelihoodType"
        class="rating-select likelihood-rating-select"
        :items="listLikelihoodTypes"
        item-text="name"
        item-value="name"
        id="selectedLikelihoodType"
        testing-id="selected-likelihood-type"
        :disabled="!userRatingFormControlEnabled || archivedExcursionDetected"
      >
        <template slot="item" slot-scope="data">
          <div :id="`risk-rating-overall--likelihood--option-${String(data.item).replaceAll(' ','')}`">
            {{ data.item }}
          </div>
        </template>
      </v-select>
      <v-flex class="assessment-title consequence-assessment-title">
        Consequence
      </v-flex>
      <v-select
        v-model="selectedConsequenceType"
        class="rating-select consequence-rating-select"
        :items="listConsequenceTypes"
        item-text="name"
        item-value="name"
        id="selectedConsequenceType"
        testing-id="selected-consequence-type"
        :disabled="!userRatingFormControlEnabled || archivedExcursionDetected"
      >
        <template slot="item" slot-scope="data">
          <div :id="`risk-rating-overall--consequence--option-${String(data.item).replaceAll(' ','')}`">
            {{ data.item }}
          </div>
        </template>
      </v-select>
      <v-flex class="assessment-title">
        Rating
      </v-flex>
      <v-select
        v-model="selectedRatingType"
        class="rating-select overall-rating-select"
        :items="RatingTypes"
        item-text="name"
        item-value="name"
        id="selectedRatingType"
        testing-id="selected-rating-type"
        :disabled="true"
      ></v-select>
      <v-flex class="rating-space"> </v-flex>
    </v-card>
  </div>
</template>

<script>
import * as types from "@/store/mutationTypes";
import excursionStatus from "../lib/const/excursionStatus";
import defaultRiskMatrix from "../lib/const/riskMatrixTemplate";
import ComplispaceLogo from "../../public/ComplispaceLogo.png";
import stbHorizontalLogo from "../../public/StbHorizontalLogo.svg";

export default {
  props: {
    headingLabel: String,
    riskRatingRefname: String,
  },

  data() {
    return {
      stbHorizontalLogo,
      ComplispaceLogo,
      pdfOptions:{
        margin: [10, 7, 10, 5],
        filename: "Risk_Assessment_Guide.pdf",
        pagebreak: {
          avoid: [".risk-matrix-row"]
        },
        jsPDF: {
          format: "letter",
          orientation: "landscape"
        }
      }
    };
  },
  computed: {
    resolvedExcursionStatus() {
      return this.$store.state.common.excursionDc.status;
    },
    userRatingFormControlEnabled() {
      return excursionStatus.APPROVED !== this.resolvedExcursionStatus;
    },
    excursionUserRiskRatingEnabled() {
      return this.$store.state.common.excursionDc.excursionUserRiskRatingEnabled;
    },
    riskMatrixSelfDefinedDetected(){
      return this.$store.state.common.excursionDc.riskMatrixSelfDefinedDetected;
    },
    listLikelihoodTypes(){
      if (this.$store.state.common.excursionDc) {
        const { riskOverallUserRating } = this.$store.state.common.excursionDc;
        if (riskOverallUserRating && 0 !== String(riskOverallUserRating.likelihood).length) {
          if (this.riskMatrixSelfDefinedDetected) {
            return this.convertConsequenceOrLikelihood(this.$store.state.common.excursionDc.riskMatrix.likelihood ?? this.$store.state.common.clientSettingRiskMatrix.likelihood);
          }
          return this.convertConsequenceOrLikelihood(defaultRiskMatrix.likelihood);
        }
      }
      return this.convertConsequenceOrLikelihood(this.$store.state.common.clientSettingRiskMatrix.likelihood ?? defaultRiskMatrix.likelihood);
    },
    listLikelihoodDefinitions(){
      if (this.$store.state.common.excursionDc) {
        const { riskOverallUserRating } = this.$store.state.common.excursionDc;
        if (riskOverallUserRating && 0 !== String(riskOverallUserRating.likelihood).length) {
          if (this.riskMatrixSelfDefinedDetected) {
            return this.convertConsequenceOrLikelihoodDefinition(this.$store.state.common.excursionDc.riskMatrix.likelihood ?? this.$store.state.common.clientSettingRiskMatrix.likelihood);
          }
          return this.convertConsequenceOrLikelihoodDefinition(defaultRiskMatrix.likelihood);
        }
      }
      return this.convertConsequenceOrLikelihoodDefinition(this.$store.state.common.clientSettingRiskMatrix.likelihood ?? defaultRiskMatrix.likelihood);
    },
    listConsequenceTypesDefinitions(){
      if (this.$store.state.common.excursionDc) {
        const { riskOverallUserRating } = this.$store.state.common.excursionDc;
        if (riskOverallUserRating && 0 !== String(riskOverallUserRating.consequence).length) {
          if (this.riskMatrixSelfDefinedDetected) {
            return this.convertConsequenceOrLikelihoodDefinition(this.$store.state.common.excursionDc.riskMatrix.consequence ?? this.$store.state.common.clientSettingRiskMatrix.consequence);
          }
          return this.convertConsequenceOrLikelihoodDefinition(defaultRiskMatrix.consequence);
        }
      }
      return this.convertConsequenceOrLikelihoodDefinition(this.$store.state.common.clientSettingRiskMatrix.consequence ?? defaultRiskMatrix.consequence);
    },
    listConsequenceTypes(){
      if (this.$store.state.common.excursionDc) {
        const { riskOverallUserRating } = this.$store.state.common.excursionDc;
        if (riskOverallUserRating && 0 !== String(riskOverallUserRating.consequence).length) {
          if (this.riskMatrixSelfDefinedDetected) {
            return this.convertConsequenceOrLikelihood(this.$store.state.common.excursionDc.riskMatrix.consequence ?? this.$store.state.common.clientSettingRiskMatrix.consequence);
          }
          return this.convertConsequenceOrLikelihood(defaultRiskMatrix.consequence);
        }
      }

      return this.convertConsequenceOrLikelihood(this.$store.state.common.clientSettingRiskMatrix.consequence ?? defaultRiskMatrix.consequence);
    },
    RatingTypes(){

      if (this.$store.state.common.excursionDc) {

        const { riskOverallUserRating } = this.$store.state.common.excursionDc;
        if (riskOverallUserRating && 0 !== String(riskOverallUserRating.riskRating).length) {
          if (this.riskMatrixSelfDefinedDetected && (this.$store.state.common.excursionDc.riskMatrix.riskRating || this.$store.state.common.clientSettingRiskMatrix.riskRating)) {
            return this.convertRiskRating(this.$store.state.common.excursionDc.riskMatrix.riskRating ?? this.$store.state.common.clientSettingRiskMatrix.riskRating);
          }
          return this.convertRiskRating(defaultRiskMatrix.riskRating);
        }
      }

      return this.convertRiskRating(this.$store.state.common.clientSettingRiskMatrix.riskRating ?? defaultRiskMatrix.riskRating);
    },
    selectedLikelihoodType: {
      get() {
        return this.$store.state.common.excursionDc.selectedLikelihood;
      },
      set(value) {
        this.$store.commit(types.COMMON_SET_SELECTED_LIKELIHOOD, value);
      }
    },
    selectedConsequenceType: {
      get() {
        return this.$store.state.common.excursionDc.selectedConsequence;
      },
      set(value) {
        this.$store.commit(types.COMMON_SET_SELECTED_CONSEQUENCE, value);
      }
    },
    selectedRatingType: {
      get() {
        if (0 !== String(this.selectedLikelihoodType).length && 0 !== String(this.selectedConsequenceType).length) {
          const selectedRating = this.getCalculatedRatingValue(this.selectedLikelihoodType, this.selectedConsequenceType);
          if (selectedRating !== this.$store.state.common.excursionDc.selectedRating) {
            this.$store.commit(types.COMMON_SET_SELECTED_RATING, selectedRating);
          }
          return selectedRating
        }
        return "";
      },
      set(value) {
        this.$store.commit(types.COMMON_SET_SELECTED_RATING, value);
      }
    },
    tooltipBubbleTextDisplayed() {
      return "undefined" !== typeof this.tooltipBubbleText && "" !== this.tooltipBubbleText;
    },
    archivedExcursionDetected() {
      return (excursionStatus.ARCHIVED === this.$store.state.common.excursionDc.status);
    },
  },
  methods: {
    async downloadRiskAssessmentGuidePdf() {
      const excursionMetadata = JSON.parse(JSON.stringify(this.$store.state.common.excursionDc));

      const { query } = this.$route;
      const clientLogoEnabled = !(query && query.referrer && "pcg" === query.referrer);

      const excursionCommonAdapter = new this.$app.excursionCommon.Adapter({
        store: this.$store,
        app: this.$app
      });

      await excursionCommonAdapter.downloadAssessmentGuidePdf(
        excursionMetadata.id,
        query,
        clientLogoEnabled,
        this.$app.deviceDetector
      );
    },
    getCalculatedRatingValue(likelihood, consequences) {
      let calculatedRating;
      if (this.listLikelihoodTypes[4] === likelihood) {
        switch (consequences) {
          case this.listConsequenceTypes[0]:
            calculatedRating = this.RatingTypes[1];
            break;
          case this.listConsequenceTypes[1]:
            calculatedRating = this.RatingTypes[1];
            break;
          case this.listConsequenceTypes[2]:
            calculatedRating = this.RatingTypes[2];
            break;
          case this.listConsequenceTypes[3]:
            calculatedRating = this.RatingTypes[3];
            break;
          case this.listConsequenceTypes[4]:
            calculatedRating = this.RatingTypes[3];
            break;
          default:
            break;
        }
      } else if (this.listLikelihoodTypes[3] === likelihood) {
        switch (consequences) {
          case this.listConsequenceTypes[0]:
            calculatedRating = this.RatingTypes[0];
            break;
          case this.listConsequenceTypes[1]:
            calculatedRating = this.RatingTypes[1];
            break;
          case this.listConsequenceTypes[2]:
            calculatedRating = this.RatingTypes[2];
            break;
          case this.listConsequenceTypes[3]:
            calculatedRating = this.RatingTypes[3];
            break;
          case this.listConsequenceTypes[4]:
            calculatedRating = this.RatingTypes[3];
            break;
          default:
            break;
        }
      } else if (this.listLikelihoodTypes[2] === likelihood) {
        switch (consequences) {
          case this.listConsequenceTypes[0]:
            calculatedRating = this.RatingTypes[0];
            break;
          case this.listConsequenceTypes[1]:
            calculatedRating = this.RatingTypes[1];
            break;
          case this.listConsequenceTypes[2]:
            calculatedRating = this.RatingTypes[1];
            break;
          case this.listConsequenceTypes[3]:
            calculatedRating = this.RatingTypes[2];
            break;
          case this.listConsequenceTypes[4]:
            calculatedRating = this.RatingTypes[3];
            break;
          default:
            break;
        }
      } else if (this.listLikelihoodTypes[1] === likelihood) {
        switch (consequences) {
          case this.listConsequenceTypes[0]:
            calculatedRating = this.RatingTypes[0];
            break;
          case this.listConsequenceTypes[1]:
            calculatedRating = this.RatingTypes[0];
            break;
          case this.listConsequenceTypes[2]:
            calculatedRating = this.RatingTypes[1];
            break;
          case this.listConsequenceTypes[3]:
            calculatedRating = this.RatingTypes[1];
            break;
          case this.listConsequenceTypes[4]:
            calculatedRating = this.RatingTypes[2];
            break;
          default:
            break;
        }
      } else if (this.listLikelihoodTypes[0] === likelihood) {
        switch (consequences) {
          case this.listConsequenceTypes[0]:
            calculatedRating = this.RatingTypes[0];
            break;
          case this.listConsequenceTypes[1]:
            calculatedRating = this.RatingTypes[0];
            break;
          case this.listConsequenceTypes[2]:
            calculatedRating = this.RatingTypes[0];
            break;
          case this.listConsequenceTypes[3]:
            calculatedRating = this.RatingTypes[1];
            break;
          case this.listConsequenceTypes[4]:
            calculatedRating = this.RatingTypes[2];
            break;
          default:
            break;
        }
      }

      return calculatedRating;
    },
    convertConsequenceOrLikelihood(value){
      const convertedValue = Object.keys(value).map(item=>({sid: item, label: value[item].label, description:value[item].description, orderIndex:value[item].orderIndex})).sort((a,b)=>a.orderIndex-b.orderIndex);
      return convertedValue.map(item=>item.label);
    },
    convertConsequenceOrLikelihoodDefinition(value){
      const convertedValue = Object.keys(value).map(item=>({sid: item, label: value[item].label, description:value[item].description, orderIndex:value[item].orderIndex})).sort((a,b)=>a.orderIndex-b.orderIndex);
      return convertedValue.map(item=>item.description);
    },
    convertRiskRating(value){
      const convertedValue = Object.keys(value).map(item=>({sid: item, label: value[item].label, orderIndex:value[item].orderIndex})).sort((a,b)=>a.orderIndex-b.orderIndex);
      return convertedValue.map(item=>item.label);
    },
  },
};
</script>
