<template>
  <div style="width:100%">
    <div v-if="studentMedicalConditions && 0 < studentMedicalConditions.length">
      <v-alert color="#ff7f32" outlined type="warning" style="margin-bottom: 15px;">
        <div>
          The selected students for this excursion have the following medical conditions:
          <br /><br />
          <ul class="high-risk-student-medical-conditions">
            <li
              :class="medical.supported ? 'high-risk-student-medical-supported' : ''"
              :key="medicalIndex"
              v-for="(medical, medicalIndex) in studentMedicalConditions"
            >
              {{ medical.name }}
            </li>
          </ul>
        </div>
      </v-alert>
      <v-alert color="#c80063" icon="info" outlined style="margin-bottom: 15px;">
        <div>
          Only the highlighted medical conditions are supported and available in the High-Risk
          Medical Conditions drop-down. For medical conditions that are not supported, please follow
          your School's process for managing student high-risk medical conditions.
        </div>
      </v-alert>
    </div>
    <select
      class="js-example-basic-single safe-select2-dropdown"
      name="selectControl"
      :id="objId"
      :disabled="!formControlEnabled"
    >
      <option value></option>
      <optgroup label="High-Risk Student Medical">
        <option
          v-for="(riskClass, cindex) in getRiskClasses()"
          :key="cindex"
          :value="riskClass.name"
          :disabled="riskClass.disabled"
          :title="riskClass.typeName"
          >{{ riskClass.name }}</option
        >
      </optgroup>
    </select>
    <risk-class
      :selectedriskClasses="selectedRiskClasses"
      :formControlEnabled="formControlEnabled"
      :riskTypeLabelDisplayed="false"
      @changeRiskCategoryDone="changeRiskCategory"
    ></risk-class>
  </div>
</template>

<script>
// TODO: Use dependency injections instead of directly importing dependencies
import "select2";

export default {
  props: ["name", "id", "formControlEnabled"],
  data() {
    return {};
  },
  computed: {
    placeholder() {
      return `Add ${this.name} Type`;
    },
    objId() {
      return `selectControl${this.id}`;
    },
    categoryName() {
      return this.name;
    },
    selectedRiskClasses() {
      return this.$store.getters["common/selectedClassByCategoryName"](this.name);
    },
    riskTypes() {
      return this.$store.getters["common/getListRiskTypesByCategoryName"](this.name);
    },
    studentMedicalConditions() {
      return this.$store.state.common.excursionDc.computed.studentMedicalConditions;
    }
  },
  methods: {
    changeRiskCategory() {
      this.$emit("changeRiskCategoryDone", this.name);
    },
    getRiskClasses() {
      const riskClasses = [];
      if (this.riskTypes && 0 < this.riskTypes.length) {
        this.riskTypes.forEach(t => {
          if (t.classes && 0 < t.classes.length) {
            t.classes.forEach(c => {
              const localC = c;
              localC.typeName = t.name;
              riskClasses.push(localC);
            });
          }
        });
      }
      riskClasses.sort((a, b) => a.name.localeCompare(b.name));
      return riskClasses;
    }
  },
  mounted() {
    const riskCategory = {
      name: this.categoryName
    };
    const store = this.$store;
    const $selector = $(`#${this.objId}`);
    if (
      "undefined" === typeof $selector.data("select2")
      && $selector.next().hasClass("select2-container")
    ) {
      $selector.select2("destroy");
    }

    $selector.select2({
      matcher: this.$app.selectCustom.matchCustom,
      placeholder: this.placeholder
    });

    const that = this;

    $selector.on("select2:select", function(e) {
      const riskClass = {
        name: e.params.data.text
      };
      const riskTypeName = e.params.data.title;
      $(this).val("");
      $(this).trigger("change.select2");
      store.dispatch("common/changeSelectRiskClass", {
        riskClass,
        riskTypeName,
        riskCategory
      });

      that.$emit("changeRiskCategoryDone", that.name);
    });
  },
  updated() {
    const $selector = $(`#${this.objId}`);
    if (
      "undefined" !== typeof $selector.data("select2")
      && $selector.next().hasClass("select2-container")
    ) {
      $selector.next().remove();
    }
    $selector.select2({
      matcher: this.$app.selectCustom.matchCustom,
      placeholder: this.placeholder
    });
  },
  destroyed() {
    const $selector = $(`#${this.objId}`);
    $selector.select2("destroy");
  }
};
</script>
