<template>
  <cs-view single-view>
    <div slot="content">
      <excursion-stepper v-if="showExcursionStepper"></excursion-stepper>
      <excursion-builder v-else propHasParentComponent></excursion-builder>
    </div>
  </cs-view>
</template>

<script>
import * as types from '@/store/mutationTypes';
import { CSBase } from '@complispace/cs-design-system';
import { mapState } from 'vuex';
import excursionStatus from '../lib/const/excursionStatus';

export default {
  extends: CSBase,

  data() {
    return {};
  },

  computed: {
    ...mapState({
      showExcursionStepper: (state) => state.common.showExcursionStepper,
      createAndUpdateExcursionEnabled: (state) => state.common.createAndUpdateExcursionEnabled,
      excursionCreationButtonDisplayed: (state) => state.common.excursionCreationButtonDisplayed,
      isAuthenticated: (state) => state.authorisation.isAuthenticated
    })
  },
  async mounted() {
    const { query, params } = this.$route;
    const { axiosInstance, eventPluginCommonAdapter, stbApiAdapter } = this.$app;
    const isVendorAuthScheme =
      !!this.$route.query['vendor-sid'] && !!this.$route.query['oauth-signature'];
    const queryParam = isVendorAuthScheme ? this.$route.query : {};
    if (!isVendorAuthScheme) {
      await this.$store.dispatch('authorisation/validateAndSetUserTokenOrLogout', {
        query: this.$route.query,
        axiosInstance
      });
      if (this.$route.params?.id > 0) {
        queryParam['excursion-id'] = this.$route.params.id;
      }
    }
    await this.$store.dispatch('common/initClientConfig', {
      queryParam,
      eventPluginCommonAdapter,
      stbApiAdapter
    });
    if (
      this.isAuthenticated &&
      !this.excursionCreationButtonDisplayed &&
      this.$route.name === 'SafeTripBuilderNewRecord'
    ) {
      this.$router.replace('/excursions');
      return;
    }
    this.$store.dispatch('common/setLogoImgUrl');

    if (query.referrer || query['vendor-sid'] || query['oauth-signature']) {
      $(window).resize(() => {
        const w = 960;
        const h = window.screen.availHeight;
        window.resizeTo(w, h);
      });
    }

    const excursionId = params && params.id ? params.id : '0';
    const store = this.$store;
    const app = this.$app;

    const that = this;
    if (this.isAuthenticated) {
      const getExcursionsArgHash = {
        stbApiAdapter: app.stbApiAdapter,
        store,
        excursionId,
        query
      };

      await store.dispatch('common/getExcursions', getExcursionsArgHash);
      const argHash = {
        stbApiAdapter: app.stbApiAdapter,
        store,
        queryParam: query
      };
      const resolvedExcursionStatus = store.state.common.excursionDc.status;
      // check archive status
      const archivedExcursionDetected = excursionStatus.ARCHIVED === resolvedExcursionStatus;
      if (!archivedExcursionDetected) {
        await store.dispatch('common/getExcursionTypes', argHash);
      }
      if (!that.createAndUpdateExcursionEnabled) {
        store.commit(types.COMMON_SET_SHOW_EXCURSION_STEPPER, false);
      } else if (!archivedExcursionDetected) {
        store.dispatch('common/updateProviders', argHash);
      }

      const excursionTypeName = store.state.common.excursionDc.typeId;
      await store.dispatch('common/getAllRisksByExcursionTypeName', {
        stbApiAdapter: app.stbApiAdapter,
        store,
        queryParam: query,
        excursionTypeName
      });
      if (!archivedExcursionDetected) {
        await store.dispatch('common/getDefaultJurisdiction', {
          stbApiAdapter: app.stbApiAdapter,
          store,
          queryParam: query,
          excursionTypeName
        });
      }
    }

    this.$nextTick(() => {
      if (this.$store.state.common.excursionDc.status === excursionStatus.APPROVED) {
        this.$app.excursionCommon.enableFormControl('.excursion-builder', false);
      }
    });

    window.onbeforeunload = function () {
      if (window.opener) {
        window.opener.postMessage('refresh', '*');
        window.self.close();
      }
    };
  },

  watch: {
    showExcursionStepper() {
      this.$store.dispatch('common/setLogoImgUrl');
    }
  }
};
</script>
