export default async (axios, baseURL) => {
  const url = '/health/alive';
  const axiosConfig = {
    method: 'GET',
    baseURL,
    url
  };
  const axiosResponse = await axios(axiosConfig);

  if (!axiosResponse || !axiosResponse.data || typeof axiosResponse.data !== 'object') {
    throw new Error('Invalid response from gateway');
  }
  if (!axiosResponse.data.version) {
    throw new Error('Invalid response from gateway: version is missing');
  }

  const data = {
    version: axiosResponse.data.version
  };
  return data;
};
