export default {
  GROUP: {
    label: 'Group',
    querystringRef: 'group',
    tableColumnRef: 'group'
  },
  SCHOOL_TYPE: {
    label: 'School Type',
    querystringRef: 'school-type',
    tableColumnRef: 'school'
  },
  PARENT: {
    label: 'Parent',
    querystringRef: 'parent',
    tableColumnRef: 'parent'
  },
  SUBJECT_GROUP: {
    label: 'Subject Group',
    querystringRef: 'subject-group',
    tableColumnRef: 'subjectGroup'
  },
  CUSTOM_GROUP: {
    label: 'Custom Group',
    querystringRef: 'custom-group',
    tableColumnRef: 'customGroup'
  }
};
