module.exports = {
  // client_acl_role
  CLIENT_ADMIN: 'client-admin',
  CLIENT_ADMIN_ASSISTANT: 'client-admin-assistant',
  CLIENT_APPROVER: 'client-approver',
  CLIENT_GROUP_ADMIN: 'client-group-admin',
  CLIENT_GROUP_APPROVER: 'client-group-approver',
  CLIENT_GROUP_USER: 'client-group-user',
  CLIENT_GROUP_WATCHER: 'client-group-watcher',
  CLIENT_LITE_USER: 'client-lite-user',
  CLIENT_USER: 'client-user',
  CLIENT_WATCHER: 'client-watcher',

  // excursion_acl_role
  EXCURSION_AUTHOR: 'excursion-author',

  // system_acl_role
  SYSTEM_ADMIN: 'system-admin', // Can do everything
  SYSTEM_GUEST: 'system-guest', // Default system role; very limited privileges
  SYSTEM_OPS_ADMIN: 'system-ops-admin',
  SYSTEM_USER: 'system-user',
  SYSTEM_WATCHER: 'system-watcher'
};
