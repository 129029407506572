import * as types from '@/store/mutationTypes';

export default {
  [types.SET_IS_INIT_CLIENT_CONFIG_IN_PROGRESS](state, isInProgress) {
    state.isInitClientConfigInProgress = isInProgress;
  },
  [types.SET_USER_PROFILE](state, userProfile) {
    state.userProfile = userProfile;
  },
  [types.SET_IS_AUTHENTICATED](state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated;
  }
};
