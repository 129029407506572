import * as types from '@/store/mutationTypes';

function setInterceptorBatch(argHash) {
  /**
   * This function needs to be called after the store modularization process to ensure
   * it refers to the correct store module.
   */
  const { axiosInstance, store } = argHash;

  // http request interceptors
  axiosInstance.interceptors.request.use(
    (config) => {
      if (
        store.state.common.impersonatedClientSid &&
        `${store.state.common.impersonatedClientSid}` !== '0'
      ) {
        // eslint-disable-next-line no-param-reassign
        config.headers['x-impersonated-client-sid'] = store.state.common.impersonatedClientSid;
      } else {
        // eslint-disable-next-line no-param-reassign
        config.headers['x-impersonated-client-sid'] = '';
      }
      return config;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  // http response interceptors
  axiosInstance.interceptors.response.use(
    (response) => {
      store.commit(types.COMMON_SET_IS_RESPONSE_WITH_ERROR, false);
      return response;
    },
    (error) => {
      if (
        error &&
        error.response &&
        (`${error.response.status}` === '401' || `${error.response.status}` === '403')
      ) {
        store.commit(types.AUTHORISATION_SET_IS_AUTHENTICATED, false);
      } else if (
        error &&
        error.response &&
        (`${error.response.status}` === '400' || `${error.response.status}` === '409')
      ) {
        // eslint-disable-next-line no-param-reassign
        error.response.errorDetected = true;
        const { errorSid, sentinelEventSid } = error.response.data;
        if (Boolean(errorSid) || Boolean(sentinelEventSid)) {
          store.commit(types.COMMON_SET_IS_RESPONSE_WITH_ERROR, true);
          store.commit(types.COMMON_SET_API_ERROR_MESSAGE, { errorSid, sentinelEventSid });
        }
      } else if (
        error &&
        error.response &&
        `${error.response.status}` === '503' &&
        error.response.data &&
        error.response.data.errorCaseSid
      ) {
        // eslint-disable-next-line no-param-reassign
        error.response.errorCaseSidDetected = true;
        const { errorSid, sentinelEventSid } = error.response.data;
        if (Boolean(errorSid) || Boolean(sentinelEventSid)) {
          store.commit(types.COMMON_SET_IS_RESPONSE_WITH_ERROR, true);
          store.commit(types.COMMON_SET_API_ERROR_MESSAGE, { errorSid, sentinelEventSid });
        }
      } else if (error && error.response && error.response.data) {
        const { errorSid, sentinelEventSid } = error.response.data;
        if (Boolean(errorSid) || Boolean(sentinelEventSid)) {
          store.commit(types.COMMON_SET_IS_RESPONSE_WITH_ERROR, true);
          store.commit(types.COMMON_SET_API_ERROR_MESSAGE, { errorSid, sentinelEventSid });
        } else {
          store.commit(types.COMMON_SET_IS_RESPONSE_WITH_ERROR, true);
        }
      } else {
        store.commit(types.COMMON_SET_IS_RESPONSE_WITH_ERROR, true);
      }

      return Promise.reject(error);
    }
  );
}

export default {
  setInterceptorBatch
};
