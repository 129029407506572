const getGroupSyncDiffs = (existingMapping, newGroupList) => {
  const groupSyncDiffs = Object.keys(existingMapping.associationHash)
    .flatMap((category) => {
      return Object.keys(existingMapping.associationHash[category]).flatMap((groupId) => {
        const retDiffs = [];
        const currentEntry = existingMapping.entryHash[groupId];
        const latestGroupSynced = newGroupList.find((g) => `${g.id}` === `${groupId}`);

        if (latestGroupSynced && latestGroupSynced.name !== currentEntry.name) {
          retDiffs.push({
            id: `${groupId}`,
            type: 'warning',
            label: 'Change Name',
            message: `"${currentEntry.name}" changed to "${latestGroupSynced.name}"`
          });
        }

        if (latestGroupSynced && currentEntry.parentType === 'group') {
          const { parentId } = currentEntry;
          const currentParentEntry = existingMapping.entryHash[parentId];
          const isExistInRemoteGroup = newGroupList.find((g) => `${g.id}` === `${parentId}`);

          if (!isExistInRemoteGroup) {
            retDiffs.push({
              id: `${groupId}`,
              type: 'danger',
              label: 'Missing Parent Group',
              message: currentParentEntry
                ? `"${latestGroupSynced.name}" was previously mapped under "${currentParentEntry.name}"`
                : `"${latestGroupSynced.name}" was previously mapped under removed group`
            });
          }
        }

        if (!latestGroupSynced) {
          retDiffs.push({
            id: `${groupId}`,
            type: 'danger',
            label: 'Missing Group',
            message: `"${currentEntry.name}" removed`
          });
        }

        return retDiffs;
      });
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  return groupSyncDiffs;
};

export default {
  getGroupSyncDiffs
};
