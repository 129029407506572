<template>
  <v-alert color="#ff7f32" outlined type="warning" style="margin-bottom: 15px">
    <div class="group-sync-diff">
      <div style="margin-bottom: 10px">
        The existing group mappings will be affected by the following changes:
      </div>
      <ul class="group-sync-diff-list">
        <li v-for="item in studentGroupSyncDiffs" :key="`dlg-${item.label}-${item.id}`">
          <span
            :class="item.type === 'danger' ? 'group-sync-diff-danger' : 'group-sync-diff-warning'"
            >[{{ item.label }}]&nbsp;</span
          >{{ item.message }}
        </li>
      </ul>
      <div style="margin-top: 10px" v-if="displayIgnoreMessage">
        <i>
          Note: Please ignore the [Missing Parent Group] message if it has already been handled.
        </i>
      </div>
    </div>
  </v-alert>
</template>

<script>
export default {
  name: 'StudentGroupSyncDiffAlert',
  props: {
    studentGroupSyncDiffs: {
      type: Array,
      required: true
    },
    displayIgnoreMessage: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
.group-sync-diff {
  color: #000000de;
  text-align: left;
}

.group-sync-diff-list {
  padding-left: 15px;
  margin-bottom: 0px;
}

.group-sync-diff-danger {
  color: #c80063;
}

.group-sync-diff-warning {
  color: #ff7f32;
}
</style>
