import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const state = {
  tokenExpiryInMS: 0,
  idpUrl: '',
  token: null
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
