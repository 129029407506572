import DOMPurify from "dompurify";

export default {
  bind: (el, binding) => {
    el.innerHTML = DOMPurify.sanitize(binding.value);
  },
  update: (el, binding) => {
    el.innerHTML = DOMPurify.sanitize(binding.value);
  },
};
