<template>
  <div style="width: 100%">
    <div class="search-container">
      <v-flex xs4>
        <v-select
          xs5
          v-model="selectedActivityType"
          class="type-dropdown excursion-selection"
          :items="listActivityTypes"
          item-text="name"
          item-value="name"
          label="Select an Activity Type"
          id="selectedActivityType"
          testing-id="selected-activity-type"
          solo
          @change="resetSelectedClass"
          :return-object="true"
          :clearable="isClearable"
          :disabled="!formControlEnabled"
        >
          <template slot="item" slot-scope="data">
            <div
              :id="`activity-risk-category--activity-type--option-${String(
                data.item.name
              ).replaceAll(' ', '')}`"
            >
              {{ data.item.name }}
            </div>
          </template>
        </v-select>
      </v-flex>
      <div class="search-arrow">
        <v-icon medium>chevron_right</v-icon>
      </div>

      <v-flex xs4>
        <v-select
          v-model="selectedActivityClass"
          id="activity-risk-category--activity-class--selection"
          class="type-dropdown excursion-selection"
          :items="listActivityClasses"
          item-text="name"
          item-value="name"
          :label="placeholderActivityRiskClass"
          solo
          :clearable="isClearable"
          :return-object="true"
          :disabled="!formControlEnabled"
          :menu-props="{
            maxHeight: 400
          }"
        >
          <template slot="item" slot-scope="data">
            <div
              :id="`activity-risk-category--activity-class--option-${String(
                data.item.name
              ).replaceAll(' ', '')}`"
            >
              {{ data.item.name }}
            </div>
          </template>
        </v-select>
      </v-flex>
    </div>
    <select
      class="js-example-basic-single excursion-selection safe-select2-dropdown"
      style="margin-left: 2px; width: calc(100% - 4px)"
      name="selectActivityRisk"
      id="selectActivityRisk"
      :disabled="!formControlEnabled"
      aria-labelledby="selectActivityRisk"
    >
      <option value></option>
      <optgroup
        v-for="(riskClass, cindex) in filterActivityRisks(
          selectedActivityType,
          selectedActivityClass
        )"
        :key="cindex"
        :label="riskClass.name"
      >
        <option
          v-for="(risk, rindex) in riskClass.risks"
          :key="rindex"
          :value="risk.name"
          :title="riskClass.name + ',' + riskClass.typeName"
          :disabled="risk.disabled"
        >
          {{ risk.name }}
        </option>
      </optgroup>
    </select>
    <div
      v-if="!selectedActivityClasses || 0 >= selectedActivityClasses.length"
      class="builder-activity-sub"
    ></div>
    <div
      class="activity sub-category-section-margin builder-activity-sub activity-category-class"
      v-if="0 < selectedActivityClasses.length"
    >
      <div v-for="(riskType, tindex) in selectedActivityClasses[0].types" :key="tindex">
        <div
          v-for="riskClass in riskType.classes"
          :key="setKey(riskClass)"
          class="sub-category-section"
        >
          <v-layout align-center justify-space-between>
            <v-flex xs4>
              <v-layout align-center justify-space-between>
                <div class="name">{{ riskClass.name }}</div>
              </v-layout>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs8 class="sub-category-view-policy">
              <div class="float-right">
                <view-policy
                  v-if="checklistAndPolicyDisplayed"
                  :categoryName="selectedActivityClasses[0].name"
                  :riskTypeName="riskType.name"
                  :riskClassName="riskClass.name"
                  :elementDisabled="!riskClass.risks"
                  :activitySupervisorScrollDisplayed="true"
                />
              </div>
              <div class="float-right">
                <complete-checklist
                  v-if="checklistAndPolicyDisplayed"
                  ref="completeChecklist"
                  :categoryName="selectedActivityClasses[0].name"
                  :riskTypeName="riskType.name"
                  :riskClassName="riskClass.name"
                  :associatedChecklistCompleted="getTicked(riskClass)"
                  :formControlEnabled="formControlEnabled"
                  :elementDisabled="
                    (riskClass.risks &&
                      elementDisabled &&
                      riskType.name === checklistFetchActionRiskTypeName &&
                      riskClass.name === checklistFetchActionRiskClassName) ||
                    !riskClass.risks
                  "
                  :checklistFetchActionCategoryName="selectedActivityClasses[0].name"
                  :checklistFetchActionRiskTypeName="checklistFetchActionRiskTypeName"
                  :checklistFetchActionRiskClassName="checklistFetchActionRiskClassName"
                  :checklistFetchActionInProgress="checklistFetchActionInProgress"
                  :saveDraft="saveDraft"
                />
              </div>
            </v-flex>
          </v-layout>
          <v-layout justify-center v-if="!riskClass.risks">
            <v-select
              class="type-dropdown risk-class-select excursion-selection empty-risk-selection"
              :items="listActivityRisks('', riskClass.name, riskType.name)"
              item-text="name"
              item-value="name"
              item-disabled="disabled"
              solo
              id="changeActivityPreSelectedRisk"
              testing-id="list-risk"
              @change="changeActivitySelectedRiskId(riskClass.name, riskType.name, {}, $event)"
              label="Select a risk"
            >
              <template slot="item" slot-scope="data">
                <div
                  :id="`activity-risk-category--activity-pre-select-risk--option-${String(
                    data.item.name
                  ).replaceAll(' ', '')}`"
                >
                  {{ data.item.name }}
                </div>
              </template>
            </v-select>
            <div>
              <v-btn
                icon
                class="remove-icon"
                :disabled="!formControlEnabled"
                @click.native="displayDeleteRiskConfirm(riskType, riskClass, null)"
                :id="getActivityRiskDeleteId(riskType, riskClass, null)"
              >
                <v-icon>delete</v-icon>
              </v-btn>
            </div>
          </v-layout>
          <div v-if="riskClass.risks && 0 < riskClass.risks.length">
            <v-layout justify-center v-for="(selectedRisk, index) in riskClass.risks" :key="index">
              <v-select
                class="type-dropdown risk-class-select excursion-selection"
                :items="listActivityRisks(selectedRisk.name, riskClass.name, riskType.name)"
                item-text="name"
                item-value="name"
                item-disabled="disabled"
                solo
                :value="selectedRisk.name"
                :id="getActivityRiskId(riskType, riskClass, selectedRisk)"
                testing-id="list-risk"
                @change="
                  changeActivitySelectedRiskId(riskClass.name, riskType.name, selectedRisk, $event)
                "
                :disabled="!formControlEnabled"
              >
                <template slot="item" slot-scope="data">
                  <div
                    :id="`activity-risk-category--activity-selected-risk--option-${String(
                      data.item.name
                    ).replaceAll(' ', '')}`"
                  >
                    {{ data.item.name }}
                  </div>
                </template>
              </v-select>
              <div>
                <v-btn
                  icon
                  class="remove-icon"
                  :disabled="!formControlEnabled"
                  @click.native="displayDeleteRiskConfirm(riskType, riskClass, selectedRisk)"
                  :id="getActivityRiskDeleteId(riskType, riskClass, selectedRisk)"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </div>
            </v-layout>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="deleteActivityRiskDisplayed" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Are you Sure?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="normal-btn white--text primary"
            testing-id="remove-risk"
            @click.native="removeActivitySelectedRiskId()"
            id="activity-risk--remove-risk"
            >Delete</v-btn
          >
          <v-btn
            id="activity-risk-category--activity-risk-removal-confirmation-dialog--cancellation--button"
            class="normal-btn"
            @click="deleteActivityRiskDisplayed = false"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as types from '@/store/mutationTypes';
import riskCategoryLabel from '../lib/const/riskCategoryLabel';

export default {
  props: ['formControlEnabled', 'checklistAndPolicyDisplayed', 'saveDraft'],
  data() {
    return {
      isClearable: true,
      checklistFetchActionRiskTypeName: '',
      checklistFetchActionRiskClassName: '',
      checklistFetchActionInProgress: false,
      checklistFetchActionCount: 0,
      checklistFetchActionDoneCount: 0,
      elementDisabled: true,
      currentSelectedType: {},
      currentSelectedClass: {},
      currentSelectedRisk: {},
      deleteActivityRiskDisplayed: false
    };
  },
  computed: {
    selectedActivityType: {
      get() {
        return this.$store.state.common.selectedActivityTypeFilter;
      },
      set(value) {
        this.$store.commit(types.COMMON_SET_SELECTED_ACTIVITY_TYPE_FILTER, value);
      }
    },
    selectedActivityClass: {
      get() {
        return this.$store.state.common.selectedActivityClassFilter;
      },
      set(value) {
        this.$store.commit(types.COMMON_SET_SELECTED_ACTIVITY_CLASS_FILTER, value);
      }
    },
    allActivityRisks() {
      return this.$store.getters['common/getAllActivityRisks'];
    },
    filterActivityRisks() {
      return (type, riskClass) => {
        const typeName = type ? type.name : '';
        const className = riskClass ? riskClass.name : '';
        return this.$store.getters['common/getActivityRisksByClass'](typeName, className);
      };
    },
    selectedActivityClasses() {
      return this.$store.getters['common/getSelectedActivityClasses'];
    },
    listActivityClasses() {
      let selectActivityName = '';
      if (this.selectedActivityType) {
        selectActivityName = this.selectedActivityType.name;
      }
      return this.allActivityRisks.filter((r) => r.typeName === selectActivityName);
    },
    listActivityTypes() {
      return this.$store.getters['common/getAllActivityTypes'];
    },
    placeholderActivityRiskClass() {
      const activityType = this.$store.state.common.selectedActivityTypeFilter;
      if (activityType && activityType.name !== '') {
        return `Select ${activityType.name} Type`;
      }

      return '';
    }
  },
  methods: {
    listActivityRisks(selectedRiskName, riskClassName, riskTypeName) {
      let risks;
      const riskClass = this.allActivityRisks.find(
        (r) => r.name === riskClassName && r.typeName === riskTypeName
      );
      if (!riskClass || !riskClass.risks) {
        risks = [];
      } else {
        risks = riskClass.risks;
      }

      if (selectedRiskName) {
        if (risks.findIndex((r) => r.name === selectedRiskName) === -1) {
          return [{ name: selectedRiskName }];
        }
      }

      // Prevent "Complete Checklist" button from being disabled when the activity risk loads
      if (!this.checklistFetchActionInProgress) {
        this.elementDisabled = false;
      }

      return risks;
    },
    getActivityRiskFromAllRisks(className, typeName, selectedRisk) {
      const riskClass = this.allActivityRisks.find(
        (c) => c.name === className && c.typeName === typeName
      );
      if (riskClass && riskClass.risks) {
        return riskClass.risks.find((r) => r.name === selectedRisk);
      }

      return undefined;
    },
    changeActivitySelectedRiskId(className, typeName, selectedRisk, event) {
      const localSelectedRisk = selectedRisk;
      const currentSelected = this.getActivityRiskFromAllRisks(className, typeName, event);

      if (localSelectedRisk.name) {
        this.$store.dispatch('common/setRiskClassRiskDisabled', {
          categoryName: riskCategoryLabel.ACTIVITIES,
          typeName,
          className,
          riskName: localSelectedRisk.name,
          disabled: false
        });
      }
      this.$store.dispatch('common/setExcursionSelectedCategoryTypeClassRisk', {
        categoryName: riskCategoryLabel.ACTIVITIES,
        typeName,
        className,
        oldRiskName: localSelectedRisk.name,
        newRiskName: currentSelected.name
      });

      this.$store.dispatch('common/setRiskClassRiskDisabled', {
        categoryName: riskCategoryLabel.ACTIVITIES,
        typeName,
        className,
        riskName: event,
        disabled: true
      });

      this.changeActivityRiskClassChecklist(typeName, className);
      this.$store.dispatch('common/setActivitySelectionValid');
      this.$emit('changeActivityDone');
    },
    removeActivitySelectedRiskId() {
      const typeName = this.currentSelectedType.name;
      const className = this.currentSelectedClass.name;

      if (!this.currentSelectedRisk) {
        const selectedClasses = this.selectedActivityClasses[0].types.find(
          (t) => t.name === typeName
        ).classes;
        const selectedClass = selectedClasses.find((c) => c.name === className);
        const index = selectedClasses.indexOf(selectedClass);
        selectedClasses.splice(index, 1);
      } else {
        const riskName = this.currentSelectedRisk.name;
        const deleteRisk = this.getActivityRiskFromAllRisks(className, typeName, riskName);
        if (deleteRisk) {
          this.$store.dispatch('common/setRiskClassRiskDisabled', {
            categoryName: riskCategoryLabel.ACTIVITIES,
            typeName,
            className,
            riskName,
            disabled: false
          });
        }

        this.$store.dispatch('common/deleteExcursionSelectedCategoryTypeClassRisk', {
          categoryName: riskCategoryLabel.ACTIVITIES,
          typeName,
          className,
          riskName
        });
        this.changeActivityRiskClassChecklist(typeName, className);
      }

      this.$store.dispatch('common/setActivitySelectionValid');
      this.currentSelectedType = {};
      this.currentSelectedClass = {};
      this.currentSelectedRisk = {};
      this.deleteActivityRiskDisplayed = false;
      return true;
    },
    resetSelectedClass() {
      this.$store.commit(types.COMMON_SET_SELECTED_ACTIVITY_CLASS_FILTER, { name: '' });
    },
    getTicked(item) {
      let allBox = 0;
      let allChecked = 0;
      if (
        item &&
        item.CompleteChecklist &&
        item.CompleteChecklist.length > 0 &&
        item.CompleteChecklist[0].checklist &&
        item.CompleteChecklist[0].checklist.Strategy &&
        item.CompleteChecklist[0].checklist.Strategy.data
      ) {
        const { data } = item.CompleteChecklist[0].checklist.Strategy;
        const dataKey = Object.keys(data);

        if (
          data[dataKey] &&
          data[dataKey].children &&
          data[dataKey].children.length > 0 &&
          data[dataKey].children[0].children
        ) {
          const checklist = data[dataKey].children[0].children;

          if (checklist && checklist.length > 0) {
            checklist.forEach((box) => {
              if (box.children && box.children.length > 0) {
                const boxes = box.children.filter((ck) => {
                  return ck.contentType === 'checkbox';
                });

                if (boxes && boxes.length > 0) {
                  allBox += boxes.length;
                }

                const checked = box.children.filter((ck) => {
                  return ck.ticked && ck.contentType === 'checkbox';
                });

                if (checked && checked.length > 0) {
                  allChecked += checked.length;
                }
              }
            });
          }
        }
      } else {
        allChecked = -1;
      }

      return allBox === allChecked;
    },
    selectedRiskExistInItems(selectedRiskName, riskClassName, riskTypeName) {
      const items = this.listActivityRisks(null, riskClassName, riskTypeName);
      if (items.findIndex((i) => i.name === selectedRiskName) > -1) {
        return true;
      }
      return false;
    },
    changeActivityRiskClassChecklist(riskTypeName, riskClassName) {
      const risk = this.$store.getters['common/getActivityRiskClass'](riskTypeName, riskClassName);
      const excursionCommonAdapter = new this.$app.excursionCommon.Adapter({
        store: this.$store,
        app: this.$app
      });
      const allParams = excursionCommonAdapter.getActivityParams();

      const params = allParams.filter((p) => p.indexOf(riskClassName) >= 0);
      const queryParam = this.$route.query;
      this.checklistFetchActionRiskTypeName = riskTypeName;
      this.checklistFetchActionRiskClassName = riskClassName;
      this.checklistFetchActionInProgress = true;
      this.$store.commit(types.COMMON_SET_CHECKLIST_FETCH_ACTION_IN_PROGRESS, true);
      this.elementDisabled = true;
      this.checklistFetchActionCount += 1;
      const that = this;
      const argHash = {
        stbApiAdapter: this.$app.stbApiAdapter,
        store: this.$store,
        queryParam,
        params,
        risk,
        resolve() {
          that.checklistFetchActionDoneCount += 1;
          if (that.checklistFetchActionCount === that.checklistFetchActionDoneCount) {
            that.checklistFetchActionInProgress = false;
            that.$store.commit(types.COMMON_SET_CHECKLIST_FETCH_ACTION_IN_PROGRESS, false);
            that.elementDisabled = false;
            that.checklistFetchActionCount = 0;
            that.checklistFetchActionDoneCount = 0;
          }
        }
      };

      this.$store.dispatch('common/changeActivityRiskCategoryCompleteChecklist', argHash);

      if (this.$refs.completeChecklist) {
        const currentButtons = this.$refs.completeChecklist.filter(
          (c) => c.completeChecklistBtnId === `btnChecklist${riskTypeName}${riskClassName}`
        );
        if (currentButtons && currentButtons.length > 0) {
          currentButtons[0].resetCompleteButton();
        }
      }
    },
    displayDeleteRiskConfirm(riskType, riskClass, risk) {
      this.deleteActivityRiskDisplayed = true;
      this.currentSelectedType = riskType;
      this.currentSelectedClass = riskClass;
      this.currentSelectedRisk = risk;
    },
    setKey(riskClass) {
      return riskClass.name.replace(/\s*/g, '');
    },
    getActivityRiskId(riskType, riskClass, selectedRisk) {
      const riskTypeId = riskType.name.replace(/\s*/g, '');
      const riskClassId = riskClass.name.replace(/\s*/g, '');
      const riskId = selectedRisk.name.replace(/\s*/g, '');
      return `activity-risk--${riskTypeId}-${riskClassId}-${riskId}`;
    },
    getActivityRiskDeleteId(riskType, riskClass, selectedRisk) {
      const riskTypeId = riskType.name.replace(/\s*/g, '');
      const riskClassId = riskClass.name.replace(/\s*/g, '');
      const riskId = selectedRisk ? `-${selectedRisk.name.replace(/\s*/g, '')}` : '';
      return `activity-risk--btn-delete-${riskTypeId}-${riskClassId}${riskId}`;
    }
  },
  mounted() {
    const store = this.$store;
    const that = this;
    const $selector = $('#selectActivityRisk');
    if (
      typeof $selector.data('select2') === 'undefined' &&
      $selector.next().hasClass('select2-container')
    ) {
      $selector.select2('destroy');
    }

    $selector.select2({
      matcher: this.$app.selectCustom.matchCustom,
      width: 'resolve',
      placeholder: 'Add Activity Type'
    });

    $selector.on('select2:select', (e) => {
      const risk = {
        // eslint-disable-next-line no-underscore-dangle
        name: e.params.data.element._value
      };
      const riskClassName = e.params.data.title;
      $('#selectActivityRisk').val('');
      $('#selectActivityRisk').trigger('change.select2');

      store.dispatch('common/changeActivitySelectRisk', {
        risk,
        riskClassName
      });

      const typeName = riskClassName.split(',')[1];
      const className = riskClassName.split(',')[0];
      that.changeActivityRiskClassChecklist(typeName, className);
      that.$emit('changeActivityDone');
    });

    $('.v-input').on('click', () => {
      $selector.select2('close');
    });
  },
  updated() {
    const $selector = $('#selectActivityRisk');
    if (
      typeof $selector.data('select2') === 'undefined' &&
      $selector.next().hasClass('select2-container')
    ) {
      $selector.next().remove();
    }

    let placeHolder = 'Add Activity Type';
    if (
      this.selectedActivityType &&
      this.selectedActivityClass &&
      typeof this.selectedActivityType !== 'undefined' &&
      typeof this.selectedActivityClass !== 'undefined' &&
      this.selectedActivityType.name !== '' &&
      this.selectedActivityClass.name !== ''
    ) {
      placeHolder = `Select ${this.selectedActivityClass.name} Type`;
    }
    $selector.select2({
      matcher: this.$app.selectCustom.matchCustom,
      width: 'resolve',
      placeholder: placeHolder
    });
  },
  destroyed() {
    const $selector = $(`#selectActivityRisk`);
    $selector.select2('destroy');
  }
};
</script>
