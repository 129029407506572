module.exports = {
  LIST_CLIENT: 'list-client',
  UPDATE_CLIENT_PROVIDER_SFTP_PATH: 'update-client-provider-sftp-path',
  APPROVE_EXCURSION: 'approve-excursion',
  UNAPPROVE_EXCURSION: 'unapprove-excursion',
  COPY_EXCURSION: 'copy-excursion',
  CREATE_EXCURSION: 'create-excursion',
  UPDATE_EXCURSION: 'update-excursion',
  DELETE_RISK_CONTENT_CACHE: 'delete-risk-content-cache',
  DISPLAY_EXCURSION_BUILDER: 'display-excursion-builder',
  DISPLAY_EXCURSION_EXPORT: 'display-excursion-export',
  DISPLAY_CLIENT_ADMIN_SECTION: 'display-client-admin-section',
  DISPLAY_CLIENT_MANAGEMENT: 'display-client-management',
  DISPLAY_CLIENT_USER_GROUP_ROLE: 'display-client-user-group-role',
  DISPLAY_CLIENT_USER_MANAGEMENT: 'display-client-user-management',
  DISPLAY_EXCURSION_HISTORY: 'display-excursion-history',
  DISPLAY_EXCURSION_LIST_CLIENT_COLUMN: 'display-excursion-list-client-column',
  DISPLAY_EXCURSION_LIST_FILTER_SECTION: 'display-excursion-list-filter-section',
  LIST_SYSTEM_ADMIN: 'list-system-admin',
  LIST_SYSTEM_USER: 'list-system-user',
  SYNC_EXCURSION: 'sync-excursion'
};
