<template>
  <div class="student-management-content-container">
    <div class="student-management-group-table-container d-flex flex-column">
      <v-flex
        id="testConnectionContainer"
        v-if="studentInfoIndexBgtEligibilityEnabled"
        d-flex
        align-center
      >
        <v-btn
          class="normal-btn white--text btn-update-groups client-individual-float-left primary ml-2"
          name="btnTestConnection"
          id="btnTestConnection"
          :disabled="studentManagementTestConnectionInProgress"
          @click.native="studentManagementTestConnection()"
        >
          <v-icon v-if="studentManagementTestConnectionInProgress">fa fa-spinner fa-pulse</v-icon>
          <span>Test Connection</span>
        </v-btn>
        <v-label
          :class="
            studentManagementTestConnectionLastFailedStatus.length > 0
              ? 'generic-error-message'
              : ''
          "
        >
          {{ studentManagementTestConnectionStatus }}
        </v-label>
      </v-flex>
      <div v-if="studentInfoIndexBgtEligibilityEnabled">
        <v-btn
          class="normal-btn white--text btn-update-groups client-individual-float-left primary ml-2"
          name="btnUpdateGroups"
          id="btnUpdateGroups"
          :disabled="groupsLoading"
          @click.native="updateGroups(true)"
          >Refresh Groups
        </v-btn>
      </div>
      <div v-else class="d-flex justify-start">
        <v-btn
          color="primary"
          :outlined="isReadFromCache && !groupsLoading"
          :disabled="groupsLoading"
          name="btnClientRetrieveGroupsFromVendor"
          id="btnClientRetrieveGroupsFromVendor"
          @click.native="updateGroups(false)"
          >Retrieve Groups from Vendor
        </v-btn>
        <v-btn
          color="primary"
          id="btnClientRetrieveGroupsFromCache"
          :disabled="!isReadFromCache || groupsLoading"
          @click="updateGroups(true)"
        >
          Retrieve Groups from Cache
        </v-btn>
      </div>
      <div class="pl-2">
        <v-autocomplete
          class="client-individual-bulk-parent-select client-individual-float-left"
          v-model="selectedApplyParentId"
          :items="originalGroupList"
          label="Set parent for selected group"
          item-text="group"
          item-value="id"
          :cache-items="false"
          clearable
          :multiple="false"
          ref="selectGroup"
          hide-details
          hide-selected
          :deletable-chips="false"
          solo
        >
          <template slot="item" slot-scope="data">
            <span
              :id="`student-management-panel--set-parent-for-selected-group--option--${String(
                data.item.group
              ).replaceAll(' ', '')}`"
            >
              {{ data.item.group }}
            </span>
          </template>
        </v-autocomplete>
        <div class="client-individual-float-left client-individual-apply-option">
          <v-checkbox
            id="client-individual-apply-subject-checkbox"
            class="client-individual-float-left"
            v-model="applySubjectEnabled"
          ></v-checkbox>
          <label for="client-individual-apply-subject-checkbox"> Subject </label>
        </div>
        <div class="client-individual-float-left client-individual-apply-option">
          <v-checkbox
            id="client-individual-apply-custom-checkbox"
            class="client-individual-float-left"
            v-model="applyCustomEnabled"
          ></v-checkbox>
          <label for="client-individual-apply-custom-checkbox"> Custom </label>
        </div>
        <v-btn
          style="margin-left: 10px"
          class="normal-btn white--text btn-update-groups client-individual-float-left primary"
          name="btnApply"
          id="btnApply"
          :disabled="applyButtonDisabled()"
          @click.native="applySelectedOption()"
          >Apply</v-btn
        >
        <v-flex>
          <v-btn
            style="margin-left: 10px"
            class="normal-btn white--text btn-update-groups client-individual-float-right primary"
            name="btnGroupsSave"
            id="btnGroupsSave"
            :disabled="saveButtonDisabled()"
            @click.native="confirmSaveDialogDisplayed = true"
            >Save</v-btn
          >
        </v-flex>
      </div>
    </div>
    <v-flex xs12 v-if="studentManagementGroupSyncDiffs.length > 0">
      <student-group-sync-diff-alert :studentGroupSyncDiffs="studentManagementGroupSyncDiffs" />
    </v-flex>
    <v-flex xs8 class="student-management-group-search-container">
      <v-text-field
        v-model="searchTerm"
        single-line
        clearable
        placeholder="Search Group Name"
        id="student-management-search-input"
        @click:clear="clearSearchInput()"
      ></v-text-field>
      <v-btn
        class="normal-btn white--text primary"
        name="btnStudentGroupSearch"
        id="btnStudentManagementPanelSearch"
        @click.native="filterGroups()"
        >Search Group</v-btn
      >
    </v-flex>
    <div>
      <v-flex style="overflow: auto; height: 90vh">
        <v-data-table
          :headers="groupHeaders"
          show-select
          v-model="selectedGroupItems"
          :items="groupList"
          class="elevation-2 excursion-table"
          :loading="groupsLoading"
          no-data-text="No group was found"
          :options.sync="studentGroupPaginationInfo"
          :footer-props="footerProps"
        >
          <template slot="item" slot-scope="props">
            <tr>
              <td class="excursion-name-cell">
                <v-checkbox
                  hide-details
                  v-model="props.isSelected"
                  class="client-individual-group-checkbox"
                  :id="getStudentManagementCheckboxId(props.item.group)"
                  @click.native="clickCheckbox(props)"
                ></v-checkbox>
              </td>
              <td class="text-xs-left">
                {{ props.item.group }}
                <span v-if="vendorGroupIdDisplayEnabled" class="studentGroupId">
                  [{{ props.item.id }}]</span
                >
              </td>
              <td class="excursion-name-cell">
                <v-select
                  clearable
                  v-model="props.item.school"
                  class="type-dropdown client-individual-student-select"
                  :items="schoolTypes"
                  :id="getStudentManagementSchoolTypeSelectionId(props.item.group)"
                  item-text="name"
                  item-value="id"
                  label="School type"
                  solo
                  :hide-details="true"
                >
                  <template slot="item" slot-scope="data">
                    <div
                      :id="`student-management-panel--school-type--option-${String(
                        data.item.name
                      ).replaceAll(' ', '')}`"
                    >
                      {{ data.item.name }}
                    </div>
                  </template>
                </v-select>
              </td>
              <td class="text-xs-left">
                <div v-if="!props.item.parentSelectionDisplayed">
                  <a
                    class="client-individual-parent"
                    :id="getStudentManagementParentSelectionId(props.item.group)"
                    @click="displayParentSelection(props.item)"
                  >
                    {{
                      !props.item.parent
                        ? '[select]'
                        : getParentGroupName(props.item.parent).length === 0
                        ? '[missing parent]'
                        : getParentGroupName(props.item.parent)
                    }}
                  </a>
                  <span
                    v-if="vendorGroupIdDisplayEnabled && props.item.parent"
                    class="studentGroupId"
                  >
                    [{{ getParentGroupId(props.item.parent) }}]</span
                  >
                </div>
                <v-autocomplete
                  v-if="props.item.parentSelectionDisplayed"
                  class="client-individual-student-select"
                  v-model="props.item.parent"
                  :items="originalGroupList"
                  label="Parent"
                  item-text="group"
                  item-value="id"
                  :cache-items="false"
                  clearable
                  :multiple="false"
                  ref="selectGroup"
                  hide-details
                  hide-selected
                  :deletable-chips="false"
                  solo
                  @blur="parentSelected(props.item)"
                >
                  <template slot="item" slot-scope="data">
                    <div
                      :id="`student-management-panel--group-parent--option-${String(
                        data.item.group
                      ).replaceAll(' ', '')}`"
                    >
                      {{ data.item.group }}
                    </div>
                  </template>
                </v-autocomplete>
              </td>
              <td class="text-xs-left">
                <v-checkbox
                  v-model="props.item.subjectGroup"
                  class="client-individual-checkbox"
                ></v-checkbox>
              </td>
              <td class="text-xs-left">
                <v-checkbox
                  v-model="props.item.customGroup"
                  class="client-individual-checkbox"
                ></v-checkbox>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </div>
    <cs-form-dialog
      :value="groupSaveGenericDialogDisplayed"
      name="CSDialog"
      max-width="490"
      heading="Student Management"
      bodyId="group-import-modal--generic-dialog"
      :primaryAction="{ label: 'OK', eventName: 'primary-click' }"
      @primary-click="genericDialogOkBtnClick"
      @input="genericDialogCloseBtnClick"
      persistent
    >
      <template v-slot:cs-form-dialog-content>
        <v-spacer></v-spacer>
        <v-card-text :class="isGenericDialogDisplayedAsError ? 'generic-error-message' : ''">
          <div v-safe-html="groupSaveGenericDialogMessage"></div>
        </v-card-text>
      </template>
    </cs-form-dialog>
    <cs-form-dialog
      v-model="confirmSaveDialogDisplayed"
      id="confirm-save-form-dialog"
      heading="Student Management"
      :primaryAction="{ label: 'Continue', eventName: 'primary-click' }"
      @primary-click="saveButtonClick"
    >
      <template v-slot:cs-form-dialog-content>
        <div>
          <div v-if="studentManagementGroupSyncDiffs.length > 0">
            <student-group-sync-diff-alert
              :studentGroupSyncDiffs="studentManagementGroupSyncDiffs"
              :displayIgnoreMessage="true"
            />
          </div>
          <span>You are about to save the group mappings. Are you sure you want to proceed?</span>
        </div>
      </template>
    </cs-form-dialog>
  </div>
</template>

<script>
import * as types from '@/store/mutationTypes';
import { CSBase } from '@complispace/cs-design-system';
import errorCaseSid from '../lib/const/errorCaseSid';
import studentGroupTableColumn from '../lib/const/studentGroupTableColumn';
import { populateErrorMessage } from '../lib/errorMessages';
import studentManagement from '../lib/studentManagement';
import dbEntityRelation from '../lib/const/dbEntityRelation';

export default {
  extends: CSBase,

  data() {
    return {
      studentManagementTestConnectionInProgress: false,
      studentManagementTestConnectionLastSucceedAtInUtc: '',
      studentManagementTestConnectionLastFailedStatus: '',
      isGenericDialogDisplayedAsError: false,
      footerProps: {
        'items-per-page-options': [10, 30, 50]
      },
      studentGroupPaginationInfo: {
        itemsPerPage: 30,
        page: 1,
        sortDesc: [false],
        sortBy: ['']
      },
      schoolTypes: [],
      savedSchoolTypes: [],
      schoolTypeValid: true,
      groupHeaders: [
        {
          text: studentGroupTableColumn.GROUP.label,
          value: studentGroupTableColumn.GROUP.tableColumnRef,
          class: 'header',
          width: 200
        },
        {
          text: studentGroupTableColumn.SCHOOL_TYPE.label,
          value: studentGroupTableColumn.SCHOOL_TYPE.tableColumnRef,
          class: 'header',
          width: 200
        },
        {
          text: studentGroupTableColumn.PARENT.label,
          value: studentGroupTableColumn.PARENT.tableColumnRef,
          class: 'header',
          width: 200
        },
        {
          text: studentGroupTableColumn.SUBJECT_GROUP.label,
          value: studentGroupTableColumn.SUBJECT_GROUP.tableColumnRef,
          class: 'header',
          width: 110
        },
        {
          text: studentGroupTableColumn.CUSTOM_GROUP.label,
          value: studentGroupTableColumn.CUSTOM_GROUP.tableColumnRef,
          class: 'header',
          width: 110
        }
      ],
      selectedGroupItems: [],
      groupList: [],
      groupsLoading: false,
      selectedApplyParentId: 0,
      applySubjectEnabled: false,
      applyCustomEnabled: false,
      clientStudentGroupSubsetAttributeKeys: ['sub_long', 'sub_short', 'sub_type'],
      componentKey: 0,
      groupSaveGenericDialogDisplayed: false,
      groupSaveGenericDialogMessage: '',
      groupSaveGenericDialogSuccessMessage: 'Groups have been saved.',
      querystringRefToTableColumnRefMap: {},
      tableColumnRefToQuerystringRefMap: {},
      searchTerm: '',
      originalGroupList: [],
      savedGroupManagement: {},
      studentManagementGroupSyncDiffs: [],
      confirmSaveDialogDisplayed: false,
      isReadFromCache: false,
      studentInfoIndexBgtEligibilityEnabled: false
    };
  },
  computed: {
    vendorGroupIdDisplayEnabled() {
      return this.$store.state.common.vendorGroupIdDisplayEnabled;
    },
    studentManagementTestConnectionButtonDisabled() {
      return this.studentManagementTestConnectionInProgress;
    },
    studentManagementTestConnectionStatus() {
      if (this.studentManagementTestConnectionLastSucceedAtInUtc.length > 0) {
        const lastSucceedAtInUtc = this.$app.moment.tz(
          this.studentManagementTestConnectionLastSucceedAtInUtc,
          'YYYYMMDDHHmmss',
          'UTC'
        );
        return `Last Succeed: ${lastSucceedAtInUtc.clone().local().format('YYYY-MM-DD HH:mm:ss')}`;
      }

      return this.studentManagementTestConnectionLastFailedStatus;
    }
  },
  methods: {
    getStudentManagementParentSelectionId(group) {
      return `student-management-panel--parent--selection_${group}`;
    },
    getStudentManagementSchoolTypeSelectionId(group) {
      return `student-management-panel--school-type--selection_${group}`;
    },
    getStudentManagementCheckboxId(group) {
      return `student-management-panel--group--checkbox-${group}`;
    },
    async updateGroups(readFromCache) {
      this.clearSearchInput();

      const requestParam = {
        'vendor-sid': this.$store.state.common.apiParam['vendor-sid'],
        'oauth-signature': this.$store.state.common.apiParam['oauth-signature']
      };

      const getClientsArgHash = {
        sid: this.$store.state.common.userClientSid,
        requestParam,
        store: this.$store
      };
      this.groupsLoading = true;
      const cbGetClientsResponse = await this.$app.stbApiAdapter.getClients(getClientsArgHash);
      if (!cbGetClientsResponse.error) {
        const schoolTypeData = JSON.parse(
          JSON.stringify(cbGetClientsResponse.data[0].metadata.schoolType)
        );
        this.schoolTypes = [];
        if (schoolTypeData) {
          Object.keys(schoolTypeData).forEach((schoolKey) => {
            this.schoolTypes.push({
              id: schoolKey,
              name: schoolTypeData[schoolKey]
            });
          });
        }
      }

      const cbResponse = await this.$app.stbApiAdapter.getClientStudentGroupListLite(readFromCache);
      this.groupsLoading = false;
      if (!cbResponse.error) {
        this.isReadFromCache = true;
        const remoteGroupList = cbResponse.data;
        const existingGroupList = JSON.parse(JSON.stringify(this.groupList));
        const existingGroupManagement = { ...this.savedGroupManagement };

        this.studentManagementGroupSyncDiffs = studentManagement.getGroupSyncDiffs(
          existingGroupManagement,
          remoteGroupList
        );

        this.groupList = remoteGroupList.map((remoteGroup) => {
          const existingGroupDetected = existingGroupList.find(
            (s) => `${s.id}` === `${remoteGroup.id}`
          );

          if (existingGroupDetected && existingGroupDetected.group !== remoteGroup.name) {
            existingGroupDetected.group = remoteGroup.name;
          }

          const resolvedGroup = existingGroupDetected
            ? JSON.parse(JSON.stringify(existingGroupDetected))
            : {
                id: remoteGroup.id,
                group: remoteGroup.name
              };

          this.clientStudentGroupSubsetAttributeKeys.forEach((subsetAttributeKey) => {
            const subsetAttributeValue = remoteGroup[subsetAttributeKey];

            if (typeof subsetAttributeValue !== 'undefined') {
              resolvedGroup[subsetAttributeKey] = subsetAttributeValue;
            }
          });

          return resolvedGroup;
        });

        this.originalGroupList = this.$app.lodash.cloneDeep(this.groupList);
      } else if (errorCaseSid.VENDOR_TOKEN_KEY_INVALID === `${cbResponse.errorCaseSid}`) {
        const apiEndpointField = this.studentManagementClientApiFields.filter(
          (item) => item.label === 'Token Key'
        )[0];
        const errors = [];
        errors.push(`Invalid Token Key`);
        apiEndpointField.errorMessage = errors;
        document.getElementsByClassName('clientEditForm')[0].scrollTop = 200;
        this.$forceUpdate();
      } else if (errorCaseSid.VENDOR_API_CREDENTIALS_INVALID === `${cbResponse.errorCaseSid}`) {
        const tokenKeyField = this.studentManagementClientApiFields.filter(
          (item) => item.label === 'Token Key'
        )[0];
        const errors = [];
        errors.push(this.invalidCredentials);
        tokenKeyField.errorMessage = errors;
        document.getElementsByClassName('clientEditForm')[0].scrollTop = 200;
        this.$forceUpdate();
      } else if (errorCaseSid.VENDOR_API_KEY_INVALID === `${cbResponse.errorCaseSid}`) {
        const apiKeyField = this.studentManagementClientApiFields.filter(
          (item) => item.label === 'API Key'
        )[0];
        const errors = [];
        errors.push(`Invalid API key`);
        apiKeyField.errorMessage = errors;
        this.$forceUpdate();
      } else if (errorCaseSid.VENDOR_ORGANIZATION_ID_INVALID === `${cbResponse.errorCaseSid}`) {
        const organizationIdField = this.studentManagementClientApiFields.filter(
          (item) => item.label === 'Organization ID'
        )[0];
        const errors = [];
        errors.push(`Invalid Organization ID`);
        organizationIdField.errorMessage = errors;
        this.$forceUpdate();
      } else if (errorCaseSid.VENDOR_API_ENDPOINT_INVALID === `${cbResponse.errorCaseSid}`) {
        const apiEndpointField = this.studentManagementClientApiFields.filter(
          (item) => item.label === 'API Endpoint'
        )[0];
        const errors = [];
        errors.push(`Invalid API Endpoint`);
        apiEndpointField.errorMessage = errors;
        document.getElementsByClassName('clientEditForm')[0].scrollTop = 0;
        this.$forceUpdate();
      }
    },
    parentSelected(item) {
      const localItem = item;
      localItem.parentSelectionDisplayed = false;
      this.$forceUpdate();
    },
    getParentGroupName(parentId) {
      const parentGroup = this.originalGroupList.find((g) => `${g.id}` === `${parentId}`);
      return parentGroup ? parentGroup.group : '';
    },
    getParentGroupId(parentId) {
      const parentGroup = this.originalGroupList.find((g) => `${g.id}` === `${parentId}`);
      return parentGroup ? parentGroup.id : '';
    },
    displayParentSelection(item) {
      /* eslint-disable no-param-reassign */
      this.groupList.forEach((g) => {
        g.parentSelectionDisplayed = false;
        if (g.id === item.id) {
          g.parentSelectionDisplayed = true;
        }
      });
      /* eslint-enable no-param-reassign */
      this.groupList = this.$app.lodash.cloneDeep(this.groupList);
      this.$forceUpdate();
    },
    applyButtonDisabled() {
      return !this.selectedGroupItems || this.selectedGroupItems.length === 0;
    },
    applySelectedOption() {
      const { selectedApplyParentId } = this;
      if (this.selectedGroupItems && this.selectedGroupItems.length > 0) {
        this.selectedGroupItems.forEach((group) => {
          const localGroup = group;
          if (this.applySubjectEnabled) {
            localGroup.subjectGroup = true;
          }
          if (this.applyCustomEnabled) {
            localGroup.customGroup = true;
          }
          localGroup.parent = selectedApplyParentId;
        });
      }
      this.selectedGroupItems = [];
      this.selectedApplyParentId = 0;
      this.applySubjectEnabled = false;
      this.applyCustomEnabled = false;
      this.$forceUpdate();
    },
    saveButtonDisabled() {
      return this.groupList.length === 0;
    },
    async saveButtonClick() {
      this.confirmSaveDialogDisplayed = false;
      const groupManagement = this.constructStudentManagementGroupManagement();
      const argHash = { payload: groupManagement, store: this.$store };
      const cbResponse = await this.$app.stbApiAdapter.putClientStudentGroupManagements(argHash);
      if (!cbResponse.error) {
        if (
          this.groupList.length !== this.originalGroupList &&
          this.searchTerm &&
          String(this.searchTerm).trim().length !== 0
        ) {
          const parsedSearchTerm = String(this.searchTerm).trim().toLowerCase();
          const leftOriginalGroupList = this.originalGroupList.filter(
            (item) => !String(item.group).toLowerCase().includes(parsedSearchTerm)
          );
          const updatedGroupList = leftOriginalGroupList.concat(this.groupList);
          this.originalGroupList = this.$app.lodash.cloneDeep(updatedGroupList);
        }
        this.groupSaveGenericDialogMessage = this.groupSaveGenericDialogSuccessMessage;
        this.groupSaveGenericDialogDisplayed = true;
      } else {
        let dialogMessage = 'Group cannot be saved.';

        if (cbResponse.refinedMessage) {
          dialogMessage = `${dialogMessage} ${cbResponse.refinedMessage}.`;
        }

        this.groupSaveGenericDialogMessage = populateErrorMessage({
          message: dialogMessage,
          store: this.$store
        });
        this.groupSaveGenericDialogDisplayed = true;
      }
      this.clearSearchInput();
    },
    async genericDialogOkBtnClick() {
      this.groupSaveGenericDialogDisplayed = false;
      this.isGenericDialogDisplayedAsError = false;
      if (!this.studentManagementTestConnectionInProgress) {
        this.$app.window.location.reload();
      } else {
        this.studentManagementTestConnectionInProgress = false;
      }
    },
    async genericDialogCloseBtnClick(boolShow) {
      if (!boolShow) {
        this.groupSaveGenericDialogDisplayed = false;
        this.isGenericDialogDisplayedAsError = false;
        if (!this.studentManagementTestConnectionInProgress) {
          this.$app.window.location.reload();
        } else {
          this.studentManagementTestConnectionInProgress = false;
        }
      }
    },
    clickCheckbox(props) {
      if (props.isSelected) {
        this.selectedGroupItems.push(props.item);
      } else {
        this.selectedGroupItems = this.selectedGroupItems.filter(
          (item) => item.id !== props.item.id
        );
      }
    },
    constructStudentManagementGroupManagement() {
      const groupManagement = {
        entryHash: {},
        associationHash: {
          year: {},
          class: {},
          custom: {},
          subject: {}
        }
      };

      if (
        this.groupList.length !== this.originalGroupList &&
        this.searchTerm &&
        String(this.searchTerm).trim().length !== 0
      ) {
        const parsedSearchTerm = String(this.searchTerm).trim().toLowerCase();
        const leftOriginalGroupList = this.originalGroupList.filter(
          (item) => !String(item.group).toLowerCase().includes(parsedSearchTerm)
        );
        const updatedGroupList = leftOriginalGroupList.concat(this.groupList);
        updatedGroupList.forEach((clientStudentGroup) => {
          const entryId = clientStudentGroup.id;
          const entry = {
            name: clientStudentGroup.group,
            parentId: '',
            parentType: ''
          };
          groupManagement.entryHash[entryId] = entry;

          this.clientStudentGroupSubsetAttributeKeys.forEach((subsetAttributeKey) => {
            const subsetAttributeValue = clientStudentGroup[subsetAttributeKey];

            if (typeof subsetAttributeValue !== 'undefined') {
              entry[subsetAttributeKey] = subsetAttributeValue;
            }
          });

          if (clientStudentGroup.school) {
            entry.parentId = `${clientStudentGroup.school}`;
            entry.parentType = 'school';
            groupManagement.associationHash.year[entryId] = true;
          } else if (clientStudentGroup.parent) {
            entry.parentId = `${clientStudentGroup.parent}`;
            entry.parentType = 'group';
            if (clientStudentGroup.subjectGroup) {
              groupManagement.associationHash.subject[entryId] = true;
            } else {
              groupManagement.associationHash.class[entryId] = true;
            }
          } else if (clientStudentGroup.customGroup) {
            groupManagement.associationHash.custom[entryId] = true;
          }
        });
      } else {
        this.groupList.forEach((clientStudentGroup) => {
          const entryId = clientStudentGroup.id;
          const entry = {
            name: clientStudentGroup.group,
            parentId: '',
            parentType: ''
          };
          groupManagement.entryHash[entryId] = entry;

          this.clientStudentGroupSubsetAttributeKeys.forEach((subsetAttributeKey) => {
            const subsetAttributeValue = clientStudentGroup[subsetAttributeKey];

            if (typeof subsetAttributeValue !== 'undefined') {
              entry[subsetAttributeKey] = subsetAttributeValue;
            }
          });

          if (clientStudentGroup.school && !clientStudentGroup.parent) {
            entry.parentId = `${clientStudentGroup.school}`;
            entry.parentType = 'school';
            groupManagement.associationHash.year[entryId] = true;
          } else if (clientStudentGroup.school && clientStudentGroup.parent) {
            delete entry.school;
            entry.parentId = `${clientStudentGroup.parent}`;
            entry.parentType = 'group';
            if (clientStudentGroup.subjectGroup) {
              groupManagement.associationHash.subject[entryId] = true;
            } else {
              groupManagement.associationHash.class[entryId] = true;
            }
          } else if (clientStudentGroup.parent) {
            entry.parentId = `${clientStudentGroup.parent}`;
            entry.parentType = 'group';
            if (clientStudentGroup.subjectGroup) {
              groupManagement.associationHash.subject[entryId] = true;
            } else {
              groupManagement.associationHash.class[entryId] = true;
            }
          } else if (clientStudentGroup.customGroup) {
            groupManagement.associationHash.custom[entryId] = true;
          }
        });
      }

      return groupManagement;
    },
    async populateGroupTable(entryHash, associationHash) {
      this.groupsLoading = true;
      const requestParam = {
        'vendor-sid': this.$store.state.common.apiParam['vendor-sid'],
        'oauth-signature': this.$store.state.common.apiParam['oauth-signature']
      };

      if (!this.$store.state.common.userClientSid) {
        this.groupsLoading = false;
        return;
      }
      const getClientsArgHash = {
        sid: this.$store.state.common.userClientSid,
        requestParam,
        store: this.$store
      };
      const cbGetClientsResponse = await this.$app.stbApiAdapter.getClients(getClientsArgHash);
      if (!cbGetClientsResponse.error) {
        const schoolTypeData = JSON.parse(
          JSON.stringify(cbGetClientsResponse.data[0].metadata.schoolType)
        );
        this.schoolTypes = [];
        if (schoolTypeData) {
          Object.keys(schoolTypeData).forEach((schoolKey) => {
            this.schoolTypes.push({
              id: schoolKey,
              name: schoolTypeData[schoolKey]
            });
          });
        }
        const client = cbGetClientsResponse.data[0];
        const vendors = client[dbEntityRelation.VENDOR_LIST];
        const activeStudentManagementVendorSid = client.metadata?.activeStudentManagementVendorSid;
        if (activeStudentManagementVendorSid) {
          const vendor = vendors.find((v) => v.sid === `${activeStudentManagementVendorSid}`);
          if (vendor?.join_metadata?.studentManagement) {
            this.isReadFromCache = vendor.join_metadata.studentManagement.isStudentGroupListCached;
          }
        }
        this.studentInfoIndexBgtEligibilityEnabled =
          !!client.computed?.studentInfoIndexBgtEligibilityEnabled;
      }

      this.groupList = [];
      Object.keys(entryHash).forEach((entryHashKey) => {
        const entryHashValue = entryHash[entryHashKey];
        const clientStudentGroup = {
          id: entryHashKey,
          group: entryHashValue.name
        };

        this.clientStudentGroupSubsetAttributeKeys.forEach((subsetAttributeKey) => {
          const subsetAttributeValue = entryHashValue[subsetAttributeKey];

          if (typeof subsetAttributeValue !== 'undefined') {
            clientStudentGroup[subsetAttributeKey] = subsetAttributeValue;
          }
        });

        switch (entryHashValue.parentType) {
          case 'group':
            clientStudentGroup.parent = `${entryHashValue.parentId}`;
            break;

          case 'school':
            clientStudentGroup.school = `${entryHashValue.parentId}`;
            break;

          default:
            break;
        }

        if (associationHash.custom && associationHash.custom[entryHashKey]) {
          clientStudentGroup.customGroup = true;
        }

        if (associationHash.subject && associationHash.subject[entryHashKey]) {
          clientStudentGroup.subjectGroup = true;
        }

        this.groupList.push(clientStudentGroup);
      });
      this.groupList = this.$app.lodash.sortBy(
        JSON.parse(JSON.stringify(this.groupList)),
        (o) => o.group
      );
      this.groupList = this.$app.lodash.cloneDeep(this.groupList);
      this.originalGroupList = this.$app.lodash.cloneDeep(this.groupList);
      this.savedGroupManagement = {
        entryHash,
        associationHash
      };
      this.$forceUpdate();
      this.groupsLoading = false;
    },
    getStudentGroupFilterData(paginationOptions) {
      const searchQuery = {
        page: paginationOptions.page,
        length: paginationOptions.itemsPerPage
      };
      if (paginationOptions.sortBy.length !== 0 && paginationOptions.sortBy[0] !== '') {
        searchQuery.order = this.tableColumnRefToQuerystringRefMap[paginationOptions.sortBy[0]];
        if (paginationOptions.sortDesc.length !== 0) {
          searchQuery.direction = paginationOptions.sortDesc[0] ? 'DESC' : 'ASC';
        }
      }

      if (this.groupList.length === 0) {
        delete searchQuery.page;
        delete searchQuery.length;
        delete searchQuery.direction;
        delete searchQuery.order;
      }

      if (this.searchTerm && String(this.searchTerm).trim().length !== 0) {
        searchQuery.s = String(this.searchTerm).trim().toLocaleLowerCase();
      }

      if (paginationOptions.sortBy[0] === 'group') {
        if (paginationOptions.sortDesc[0]) {
          this.groupList = this.$app.lodash
            .sortBy(JSON.parse(JSON.stringify(this.groupList)), (o) => o.group)
            .reverse();
        } else {
          this.groupList = this.$app.lodash.sortBy(
            JSON.parse(JSON.stringify(this.groupList)),
            (o) => o.group
          );
        }
      } else {
        this.groupList = this.$app.lodash.sortBy(
          JSON.parse(JSON.stringify(this.groupList)),
          (o) => o.group
        );
      }
      this.groupList = this.$app.lodash.cloneDeep(this.groupList);
      this.$forceUpdate();
      this.$router.replace({ query: searchQuery }, () => {});
    },
    updateQueryString(query) {
      const searchQuery = {};
      const {
        page: parsedPage,
        length: parsedLength,
        direction: parsedDirection,
        order: parsedSortBy,
        s: parsedSearchGroup
      } = query;

      if (parsedSearchGroup && String(parsedSearchGroup).trim().length !== 0) {
        this.searchTerm = String(parsedSearchGroup).trim().toLowerCase();
        searchQuery.s = this.searchTerm;
        this.groupList = this.originalGroupList.filter((item) =>
          String(item.group).toLowerCase().includes(parsedSearchGroup)
        );
        this.groupList = this.$app.lodash.cloneDeep(this.groupList);
        this.$forceUpdate();
      }

      if (parsedLength === '10' || parsedLength === '30' || parsedLength === '50') {
        this.studentGroupPaginationInfo.itemsPerPage = parseInt(parsedLength, 10);
        searchQuery.length = parseInt(parsedLength, 10);
      } else {
        this.studentGroupPaginationInfo.itemsPerPage = 30;
        searchQuery.length = 30;
      }

      if (!Number.isNaN(parseInt(parsedPage, 10)) && parseInt(parsedPage, 10) > 0) {
        this.studentGroupPaginationInfo.page = parseInt(parsedPage, 10);
        searchQuery.page = parseInt(parsedPage, 10);
      } else {
        this.studentGroupPaginationInfo.page = 1;
        searchQuery.page = 1;
      }

      if (
        studentGroupTableColumn.GROUP.querystringRef === parsedSortBy ||
        studentGroupTableColumn.SCHOOL_TYPE.querystringRef === parsedSortBy ||
        studentGroupTableColumn.PARENT.querystringRef === parsedSortBy ||
        studentGroupTableColumn.SUBJECT_GROUP.querystringRef === parsedSortBy ||
        studentGroupTableColumn.CUSTOM_GROUP.querystringRef === parsedSortBy
      ) {
        this.studentGroupPaginationInfo.sortBy[0] =
          this.querystringRefToTableColumnRefMap[parsedSortBy];
        searchQuery.order = parsedSortBy;
        if (parsedDirection === 'DESC' || parsedDirection === 'ASC') {
          this.studentGroupPaginationInfo.sortDesc[0] = parsedDirection === 'DESC';
          searchQuery.direction = parsedDirection;
        } else {
          searchQuery.direction = 'ASC';
        }
      }
      if (this.groupList.length === 0) {
        delete searchQuery.page;
        delete searchQuery.length;
        delete searchQuery.direction;
        delete searchQuery.order;
      }
      this.$router.replace({ query: searchQuery }, () => {});
    },
    filterGroups() {
      this.resetStudentGroupPaginationInfo();
      const { query } = this.$route;
      const searchQuery = this.$app.lodash.cloneDeep(query);
      const parsedSearchTerm = String(this.searchTerm).trim().toLowerCase();
      if (this.searchTerm && parsedSearchTerm.length !== 0) {
        searchQuery.s = parsedSearchTerm;
        this.groupList = this.originalGroupList.filter((item) =>
          String(item.group).toLowerCase().includes(parsedSearchTerm)
        );
        this.groupList = this.$app.lodash.cloneDeep(this.groupList);
        this.$forceUpdate();
        this.updateQueryString(searchQuery);
      } else {
        this.clearSearchInput();
      }
    },
    clearSearchInput() {
      this.searchTerm = '';
      this.groupList = this.$app.lodash.cloneDeep(this.originalGroupList);
      const { query } = this.$route;
      delete query.s;
      const searchQuery = JSON.parse(JSON.stringify(query));
      this.$router.replace({ query: {} }, () => {});
      this.$router.replace({ query: { ...searchQuery } }, () => {});
      this.$forceUpdate();
    },
    resetStudentGroupPaginationInfo() {
      if (this.studentGroupPaginationInfo.itemsPerPage !== 30) {
        this.studentGroupPaginationInfo.itemsPerPage = 30;
      }
      if (this.studentGroupPaginationInfo.page !== 1) {
        this.studentGroupPaginationInfo.page = 1;
      }
      if (this.studentGroupPaginationInfo.sortDesc[0]) {
        this.studentGroupPaginationInfo.sortDesc = [false];
      }
      if (this.studentGroupPaginationInfo.sortBy[0]) {
        this.studentGroupPaginationInfo.sortBy = [''];
      }
    },
    async studentManagementTestConnection() {
      this.studentManagementTestConnectionInProgress = true;

      const result = await this.$app.stbApiAdapter.getWondeTestConnection();

      this.$store.commit(types.COMMON_SET_IS_RESPONSE_WITH_ERROR, false);
      this.groupSaveGenericDialogDisplayed = true;

      if (!result.error) {
        this.studentManagementTestConnectionLastSucceedAtInUtc =
          result.data.lastSucceedAtInUtc || '';
        this.studentManagementTestConnectionLastFailedStatus = result.data.message || '';
        this.isGenericDialogDisplayedAsError = !!result.data.error;
        this.groupSaveGenericDialogMessage = result.data.error
          ? `${result.data.message}`
          : `Successfully connect with student management vendor for school "${result.data.schoolName}".`;
      } else {
        this.studentManagementTestConnectionLastSucceedAtInUtc = '';
        this.studentManagementTestConnectionLastFailedStatus = result.message;
        this.isGenericDialogDisplayedAsError = true;
        this.groupSaveGenericDialogMessage = `${result.message}`;
      }
    }
  },
  async mounted() {
    const { query } = this.$route;

    Object.keys(studentGroupTableColumn).forEach((key) => {
      this.querystringRefToTableColumnRefMap[studentGroupTableColumn[key].querystringRef] =
        studentGroupTableColumn[key].tableColumnRef;
      this.tableColumnRefToQuerystringRefMap[studentGroupTableColumn[key].tableColumnRef] =
        studentGroupTableColumn[key].querystringRef;
    });
    const { axiosInstance, eventPluginCommonAdapter, stbApiAdapter } = this.$app;
    const isVendorAuthScheme =
      !!this.$route.query['vendor-sid'] && !!this.$route.query['oauth-signature'];
    if (!isVendorAuthScheme) {
      await this.$store.dispatch('authorisation/validateAndSetUserTokenOrLogout', {
        query: this.$route.query,
        axiosInstance
      });
    }
    await this.$store.dispatch('common/initClientConfig', {
      queryParam: isVendorAuthScheme ? this.$route.query : {},
      eventPluginCommonAdapter,
      stbApiAdapter
    });
    const { data } = await this.$app.stbApiAdapter.getClientStudentGroupManagements({
      store: this.$store
    });
    const entryHash = JSON.parse(JSON.stringify(data.entryHash));
    const associationHash = JSON.parse(JSON.stringify(data.associationHash));
    if (Object.keys(entryHash) !== 0) {
      await this.populateGroupTable(entryHash, associationHash);
    }
    this.updateQueryString(query);
  },
  watch: {
    studentGroupPaginationInfo: {
      handler(val) {
        this.getStudentGroupFilterData(val);
      },
      deep: true
    },
    '$route.query': {
      immediate: true,
      handler(newVal, oldVal) {
        if (Object.keys(newVal).length === 0 && oldVal && Object.keys(oldVal).length !== 0) {
          window.location.reload();
        }
      }
    },
    searchTerm() {
      this.resetStudentGroupPaginationInfo();
    }
  }
};
</script>

<style scoped>
.settings-container {
  width: 100%;
  height: 100%;
  max-width: unset;
  margin: 0;
  padding: 0;
}

.settings-content {
  text-align: left;
  height: 100vh;
}

.client-individual-float-right {
  float: right;
}

.student-management-content-container {
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: fit-content;
  background-color: #fff;
}

.student-management-group-search-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-height: 100px;
}

#student-management-search-input {
  width: 300px;
  padding-left: 4px;
}

#btnStudentManagementPanelSearch {
  margin-left: 100px;
  margin-bottom: 10px;
}

.studentGroupId {
  color: gray;
}

#testConnectionContainer {
  margin-bottom: 20px;
}

.generic-error-message {
  color: #ff0000;
  text-align: left;
}
</style>
