export default {
  USER_ID: {
    label: 'User ID',
    querystringRef: 'user-id',
    tableColumnRef: 'item.id'
  },
  USER_EMAIL: {
    label: 'User Email',
    querystringRef: 'user-email',
    tableColumnRef: 'item.email'
  },
  CLIENT: {
    label: 'Client',
    querystringRef: 'client',
    tableColumnRef: 'item.client_name'
  },
  DATE_CREATED: {
    label: 'Date Created',
    querystringRef: 'date-created',
    tableColumnRef: 'item.created_at'
  },
  ACTION: {
    label: 'Action',
    querystringRef: 'action',
    tableColumnRef: 'item.enabled'
  }
};
