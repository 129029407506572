export default {
  EXCURSION_LIST_LOADED: 'Excursion list loaded',
  FEEDBACK_LINK_CLICK: 'Product Feedback Portal Link Click',
  PAGE_LOAD: 'Page Load',
  RISK_ASSESSMENT_ADDITIONAL_INFORMATION_UPDATE: 'Risk Assessment Additional Information Update',
  RISK_ASSESSMENT_APPROVAL: 'Risk Assessment Approval',
  RISK_ASSESSMENT_BUILDER_VIEW_UPDATE: 'Risk Assessment Builder View Update',
  RISK_ASSESSMENT_DRAFT_CREATION: 'Risk Assessment Draft Creation',
  RISK_ASSESSMENT_EXCURSION_DETAILS_UPDATE: 'Risk Assessment Excursion Details Update',
  RISK_ASSESSMENT_EXCURSION_RISK_ASSESSMENT_EXPORT:
    'Risk Assessment Excursion Risk Assessment Export',
  RISK_ASSESSMENT_EXCURSION_RISK_ASSESSMENT_UPDATE:
    'Risk Assessment Excursion Risk Assessment Update',
  RISK_ASSESSMENT_EXCURSION_RISK_MANAGEMENT_CONFIRMATION_UPDATE:
    'Risk Assessment Excursion Risk Management Confirmation Update',
  RISK_ASSESSMENT_MANDATORY_EXCURSION_CHECKLISTS_UPDATE:
    'Risk Assessment Mandatory Excursion Checklists Update',
  RISK_ASSESSMENT_RISK_CATEGORIES_UPDATE: 'Risk Assessment Risk Categories Update',
  RISK_ASSESSMENT_STUDENT_MEDICAL_AND_RATIOS_UPDATE:
    'Risk Assessment Student Medical and Ratios Update',
  RISK_ASSESSMENT_SUBMISSION: 'Risk Assessment Submission',
  RISK_ASSESSMENT_UNAPPROVAL: 'Risk Assessment Unapproval',
  RISK_MANIFEST_PDF_LINK_CLICK: 'Risk Manifest PDF Link Click',
  SUPPORT_LINK_CLICK: 'Self-Help Portal Link Click'
};
