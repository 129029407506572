import { render, staticRenderFns } from "./StudentGroupSyncDiffAlert.vue?vue&type=template&id=682202b0&scoped=true"
import script from "./StudentGroupSyncDiffAlert.vue?vue&type=script&lang=js"
export * from "./StudentGroupSyncDiffAlert.vue?vue&type=script&lang=js"
import style0 from "./StudentGroupSyncDiffAlert.vue?vue&type=style&index=0&id=682202b0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "682202b0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
installComponents(component, {VAlert})
