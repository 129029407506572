import sessionManager from './sessionManager';

export default {
  isTokenNotExpired: (state) => () => {
    return state.tokenExpiryInMS - new Date().getTime() >= 600000; // 10 minutes in MS
  },
  authorizationUri: (state) => {
    const loginCallbackUrl = `${window.location.origin}/login/callback/idp`;
    const queryParams = `?response_type=token&client_id=pcg-data-api&redirect_uri=${loginCallbackUrl}`;
    return `${state.idpUrl}/oauth2/authorize${queryParams}`;
  },
  authorizationUriComplispaceStaff: (state) => {
    const loginCallbackUrl = `${window.location.origin}/login/callback/idp`;
    const queryParams = `?response_type=token&client_id=pcg-data-api&scope=staff.complispace&redirect_uri=${loginCallbackUrl}`;
    return `${state.idpUrl}/oauth2/authorize${queryParams}`;
  },
  getToken: (state) => {
    return state.token || sessionManager.getAuthTokenFromCookie();
  }
};
