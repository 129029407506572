<template>
  <cs-view single-view>
    <v-container class="feedback-container" slot="content">
      <iframe
        title="support-feedback-frame"
        class="support-feedback-iframe"
        src="https://portal.productboard.com/ryksles2e1n71xyjgjkvefhi?hide_header=1"
        frameborder="0"
      ></iframe>
    </v-container>
  </cs-view>
</template>

<script>
import { CSBase } from '@complispace/cs-design-system';

export default {
  extends: CSBase,

  data() {
    return {};
  },

  async mounted() {
    const { axiosInstance, eventPluginCommonAdapter, stbApiAdapter } = this.$app;
    const isVendorAuthScheme =
      !!this.$route.query['vendor-sid'] && !!this.$route.query['oauth-signature'];
    if (!isVendorAuthScheme) {
      await this.$store.dispatch('authorisation/validateAndSetUserTokenOrLogout', {
        query: this.$route.query,
        axiosInstance
      });
    }
    await this.$store.dispatch('common/initClientConfig', {
      queryParam: isVendorAuthScheme ? this.$route.query : {},
      eventPluginCommonAdapter,
      stbApiAdapter
    });
  }
};
</script>

<style scoped>
.feedback-container {
  width: 100%;
  height: 100%;
  max-width: unset;
}
</style>
