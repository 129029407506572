import * as types from '@/store/mutationTypes';

export default {
  [types.SET_TOKEN_EXPIRY_IN_MS](state, tokenExpiryInMS) {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(tokenExpiryInMS)) {
      state.tokenExpiryInMS = 0;
    } else {
      state.tokenExpiryInMS = tokenExpiryInMS;
    }
  },
  [types.SET_IDP_URL](state, idpUrl) {
    state.idpUrl = idpUrl;
  },
  [types.SET_TOKEN](state, token) {
    state.token = token;
  }
};
