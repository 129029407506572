export default {
  CLIENT_ID: {
    label: 'Client ID',
    querystringRef: 'client-id',
    tableColumnRef: 'sid'
  },
  CLIENT_NAME: {
    label: 'Client Name',
    querystringRef: 'client-name',
    tableColumnRef: 'client.metadata.name'
  },
  ORGANISATION_NAME: {
    label: 'Organisation Name',
    querystringRef: 'organisation-name',
    tableColumnRef: 'OrganisationName'
  },
  ACTION: {
    label: 'Action',
    querystringRef: 'action',
    tableColumnRef: 'item.enabled'
  }
};
