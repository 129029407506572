import * as types from '@/store/mutationTypes';
import sessionManager from '@/store/modules/session/sessionManager';

export default {
  async init({ commit }, axiosInstance) {
    const xClientSubdomain = sessionManager.getClientSubdomain();
    sessionManager.setClientSubdomainHeaders(axiosInstance, xClientSubdomain);
    commit(types.SET_TOKEN_EXPIRY_IN_MS, sessionManager.getTokenExpiryInMS());
    commit(types.SET_TOKEN, sessionManager.getAuthTokenFromCookie());
  },
  async handleLoginCallback({ commit }) {
    const locationHash = window.location.hash;
    if (locationHash.indexOf('access_token=') > -1) {
      const token = locationHash.split('access_token=')[1].split('&')[0];
      if (token) {
        if (process.env.APP_ENV !== 'automation') {
          try {
            window.gtag('event', 'login', { method: 'cs_idp' });
          } catch (err) {
            console.log('Login event not fired');
          }
        }
        sessionManager.saveAuthTokenToCookie(token);
        commit(types.SET_TOKEN_EXPIRY_IN_MS, sessionManager.getTokenExpiryInMS());
        commit(types.SET_TOKEN, token);
      }
    }
  },
  logout({ commit }, query) {
    const token = sessionManager.getAuthTokenFromCookie();
    sessionManager.removeAuthTokenFromCookie();
    commit(types.SET_TOKEN_EXPIRY_IN_MS, 0);
    commit(types.SET_TOKEN, null);
    if (!token || !query?.referrer) {
      localStorage.setItem('idp_redirect', window.location.pathname);
      window.location.href = '/login';
    } else {
      commit(types.AUTHORISATION_SET_IS_AUTHENTICATED, false, { root: true });
      commit(types.AUTHORISATION_SET_IS_INIT_CLIENT_CONFIG_IN_PROGRESS, false, { root: true });
    }
  }
};
