import getFileBlob from './getFileBlob';

const downloadFile = (href, filename, deviceDetector) => {
  if (deviceDetector && deviceDetector.checkIfDeviceInIosFamily()) {
    const blob = getFileBlob(href);
    const blobUrl = URL.createObjectURL(blob);

    window.location = blobUrl;
  } else {
    const link = document.createElement('a');
    link.href = href;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
};

export default downloadFile;
