<template>
  <div class="admin-management-container">
    <page-loading-animation :displayed="pageLoadingAnimationDisplayed()"></page-loading-animation>
    <client-list v-if="0 === this.active && clientsTabDisplayed"></client-list>
    <client-user-list v-if="1 === this.active && clientUsersTabDisplayed"> </client-user-list>
    <system-user-list v-if="2 === this.active && usersTabDisplayed"></system-user-list>
    <client-individual-editor
      v-if="3 === this.active && clientsTabDisplayed"
    ></client-individual-editor>
    <client-individual-editor
      v-if="4 === this.active && clientsTabDisplayed"
    ></client-individual-editor>
  </div>
</template>

<script>
import { CSBase } from '@complispace/cs-design-system';

import { mapState } from 'vuex';
import ClientList from './ClientList';
import ClientUserList from './ClientUserList';
import SystemUserList from './SystemUserList';
import ClientIndividualEditor from './ClientIndividualEditor';

export default {
  name: 'AdministrationManagement',

  components: {
    ClientUserList,
    ClientList,
    SystemUserList,
    ClientIndividualEditor
  },
  extends: CSBase,

  props: [],

  data() {
    return {
      active: 0
    };
  },
  computed: {
    ...mapState({
      clientUsersTabDisplayed: (state) => state.common.clientUsersTabDisplayed,
      usersTabDisplayed: (state) => state.common.usersTabDisplayed,
      clientsTabDisplayed: (state) => state.common.clientsTabDisplayed,
      isInitClientConfigInProgress: (state) => state.authorisation.isInitClientConfigInProgress
    })
  },
  methods: {
    getLinkItemId(linkItem) {
      return `client-admin-layout--menu-item--link-${linkItem}`;
    },
    pageLoadingAnimationDisplayed() {
      let loadingAnimationDisplayed = false;

      if (this.isInitClientConfigInProgress) {
        loadingAnimationDisplayed = true;
      }

      return loadingAnimationDisplayed;
    }
  },
  async mounted() {
    const route = this.$route;
    if (route) {
      if (`${route.name}` === 'SafeTripBuilderClientsList') {
        this.active = 0;
      } else if (`${route.name}` === 'SafeTripBuilderClientsUsersList') {
        this.active = 1;
      } else if (`${route.name}` === 'SafeTripBuilderUsersList') {
        this.active = 2;
      } else if (`${route.name}` === 'SafeTripBuilderClientExistingRecord') {
        this.active = 3;
      } else {
        this.active = 4;
      }
      const { axiosInstance, eventPluginCommonAdapter, stbApiAdapter } = this.$app;
      await this.$store.dispatch('authorisation/validateAndSetUserTokenOrLogout', {
        query: this.$route.query,
        axiosInstance
      });
      await this.$store.dispatch('common/initClientConfig', {
        eventPluginCommonAdapter,
        stbApiAdapter
      });
    }
  },
  watch: {
    $route(to) {
      if (`${to.name}` === 'SafeTripBuilderClientsList') {
        this.active = 0;
        if (Object.keys(to.query).length === 0) {
          window.location.reload();
        }
      } else if (`${to.name}` === 'SafeTripBuilderClientsUsersList') {
        this.active = 1;
        if (Object.keys(to.query).length === 0) {
          window.location.reload();
        }
      } else if (`${to.name}` === 'SafeTripBuilderUsersList') {
        this.active = 2;
        if (Object.keys(to.query).length === 0) {
          window.location.reload();
        }
      } else if (`${to.name}` === 'SafeTripBuilderClientExistingRecord') {
        this.active = 3;
      } else {
        this.active = 4;
      }
    }
  }
};
</script>
