import * as types from '@/store/mutationTypes';
import sessionManager from '@/store/modules/session/sessionManager';

export default {
  async logout({ rootGetters, dispatch }, query) {
    if (rootGetters['auth0/enabled']) {
      localStorage.setItem('redirect', window.location.pathname);
      await dispatch('cs-session/logout', false, { root: true });
    } else {
      await dispatch('session/logout', query, { root: true });
    }
  },
  async getUserProfile({ commit }, stbApiAdapter) {
    const cbResponse = await stbApiAdapter.getUserAuth({ store: this.$store });
    if (!cbResponse.error) {
      commit(types.SET_USER_PROFILE, cbResponse.data.computed);
    }
  },
  async validateAndSetUserTokenOrLogout({ dispatch, getters }, argHash) {
    const { query, axiosInstance } = argHash;
    if (!getters.isLoggedIn) {
      await dispatch('logout', query);
      return;
    }
    if (getters.auth0Enabled) return;
    const token = sessionManager.getAuthTokenFromCookie();
    axiosInstance.interceptors.request.use(
      (config) => {
        // eslint-disable-next-line no-param-reassign
        config.headers.authorization = `Bearer ${token}`;
        return config;
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }
};
