<template>
  <div>
    <div v-if="mandatoryChecklist !== undefined && mandatoryChecklist.length > 0">
      <v-layout v-for="item in mandatoryChecklist" :key="item.name">
        <v-layout>
          <v-flex xs12 class="form-label mandatory-checklist-sub-header">
            {{ item.title }}
          </v-flex>
          <view-checklist
            :title="item.title"
            :saveDraft="saveDraft"
            :formControlEnabled="formControlEnabled && !archivedExcursionDetected"
          />
        </v-layout>
      </v-layout>
    </div>
    <div class="excursion-waiting" v-if="!mandatoryChecklist || mandatoryChecklist.length <= 0">
      <v-progress-circular
        :size="30"
        color="rgb(29, 175, 236)"
        class="loading-img"
        indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import * as types from "@/store/mutationTypes";
import excursionStatus from "../lib/const/excursionStatus";

export default {
  props: {
    saveDraft: {
      type: Function
    },
    formControlEnabled: Boolean
  },
  data() {
    return {};
  },
  computed: {
    mandatoryChecklist() {
      const { mandatoryChecklist } = this.$store.state.common.excursionDc;
      const clonedMandatoryChecklist = JSON.parse(JSON.stringify(mandatoryChecklist));
      return clonedMandatoryChecklist.sort((a, b) => (a.title > b.title ? 1 : -1));
    },
    archivedExcursionDetected() {
      return excursionStatus.ARCHIVED === this.$store.state.common.excursionDc.status;
    }
  },
  async mounted() {
    const { mandatoryChecklist } = this.$store.state.common.excursionDc;
    this.$store.commit(types.COMMON_SET_MANDATORY_LOADING_COMPLETED, true);

    if (!mandatoryChecklist || 0 === mandatoryChecklist.length) {
      const argHash = {
        stbApiAdapter: this.$app.stbApiAdapter,
        store: this.$store,
        queryParam: this.$route.query
      };
      await this.$store.dispatch("common/getMandatoryChecklist", argHash);
    } else {
      this.$store.commit(types.COMMON_SET_MANDATORY_LOADING_COMPLETED, false);
    }
  }
};
</script>
