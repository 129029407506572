/* eslint-disable no-undef */

// CSS
import 'select2/dist/css/select2.min.css';
import './assets/css/main.css';

import CsDesignSystem, {
  i18n,
  vuetifyBuilder,
  axios as auth0Axios
} from '@complispace/cs-design-system';

// Component imports
import Vue from 'vue';
import VueCropper from 'vue-cropperjs';
import VueMarkdown from 'vue-markdown';
import VueRouter from 'vue-router';
import Vuex from 'vuex';
import axios from 'axios';
import enumerable from 'linq';
import jQuery from 'jquery';
import lodash from 'lodash';
import mixpanelBrowser from 'mixpanel-browser';
import moment from 'moment-timezone';
import store2 from 'store2';
import { v4 as uuid } from 'uuid';
import ActivityRiskCategory from './components/ActivityRiskCategory';
import AdditionalInfoList from './components/AdditionalInfoList';
import AdministrationManagement from './components/AdministrationManagement';
import App from './App';
import AppSideNav from './components/AppSideNav';
import ChecklistItemComment from './components/ChecklistItemComment';
import ClientAdminGroupSetting from './components/ClientAdminGroupSetting';
import ClientAdminLayout from './components/ClientAdminLayout';
import ClientAdminOperationManagement from './components/ClientAdminOperationManagement';
import ClientAdminRiskCategoryManagement from './components/ClientAdminRiskCategoryManagement';
import ClientAdminRiskMatrixManagement from './components/ClientAdminRiskMatrixManagement';
import ClientAdminSiteSetting from './components/ClientAdminSiteSetting';
import ClientAdminStudentManagement from './components/ClientAdminStudentManagement';
import ClientIndividualEditor from './components/ClientIndividualEditor';
import ClientList from './components/ClientList';
import ClientUserIndividualModal from './components/ClientUserIndividualModal';
import ClientUserList from './components/ClientUserList';
import CompleteChecklist from './components/CompleteChecklist';
import EntityManagement from './components/EntityManagement';
import ExcursionBuilder from './components/ExcursionBuilder';
import ExcursionConfirmation from './components/ExcursionConfirmation';
import ExcursionHistory from './components/ExcursionHistory';
import ExcursionList from './components/ExcursionList';
import ExcursionStepper from './components/ExcursionStepper';
import ExportExcursionPolicy from './components/ExportExcursionPolicy';
import ExportExcursionRiskAssessment from './components/ExportExcursionRiskAssessment';
import HighRisk from './components/HighRisk';
import LoginCallback from './components/LoginCallback';
import MandatoryChecklist from './components/MandatoryChecklist';
import PageLoadingAnimation from './components/PageLoadingAnimation';
import Providers from './components/Providers';
import RiskCategory from './components/RiskCategory';
import RiskClass from './components/RiskClass';
import RiskRatingOverall from './components/RiskRatingOverall';
import RiskRatingSummary from './components/RiskRatingSummary';
import SafeTripBuilder from './components/SafeTripBuilder';
import SelectControl from './components/SelectControl';
import StaffStudentSupervision from './components/StaffStudentSupervision';
import StudentSelection from './components/StudentSelection';
import StudentManagementHistory from './components/StudentManagementHistory';
import StudentManagementPanel from './components/StudentManagementPanel';
import StudentGroupSyncDiffAlert from './components/StudentGroupSyncDiffAlert';
import SupportFeedbackLayout from './components/SupportFeedbackLayout';
import SystemUserIndividualModal from './components/SystemUserIndividualModal';
import SystemUserList from './components/SystemUserList';
import UpdateProviders from './components/UpdateProviders';
import ViewAllMandatoryChecklist from './components/ViewAllMandatoryChecklist';
import ViewChecklist from './components/ViewMandatoryChecklist';
import ViewPolicy from './components/ViewPolicy';
import ViewPredepartureChecklist from './components/ViewPredepartureChecklist';
// Additional dependencies
import axiosCommon from './lib/axiosCommon';
import store from './store';
import componentOption from './lib/componentOption';
import deviceDetector from './lib/deviceDetector';
import eventPluginCommon from './lib/eventPluginCommon';
import excursionCommon from './lib/excursionCommon';
import exportExcursions from './lib/exportExcursions';
import pdf from './lib/pdf';
import routerBundle from './router/routerBundle';
// Directive imports
import safeHtmlDirective from './directives/SafeHtml';
import selectCustom from './lib/selectCustom';
import stbApi from './lib/stbApi';

const vuetify = vuetifyBuilder({ themeName: 'default' }).build();

const vueComponentHash = {
  ActivityRiskCategory,
  AdditionalInfoList,
  App,
  AppSideNav,
  ChecklistItemComment,
  ClientAdminGroupSetting,
  ClientAdminLayout,
  AdministrationManagement,
  ClientAdminOperationManagement,
  ClientAdminRiskCategoryManagement,
  ClientAdminRiskMatrixManagement,
  ClientAdminStudentManagement,
  ClientAdminSiteSetting,
  ClientList,
  ClientUserIndividualModal,
  ClientUserList,
  CompleteChecklist,
  EntityManagement,
  ExcursionBuilder,
  ExcursionConfirmation,
  ExcursionHistory,
  StudentManagementHistory,
  ExcursionList,
  ExcursionStepper,
  ExportExcursionPolicy,
  ExportExcursionRiskAssessment,
  HighRisk,
  LoginCallback,
  MandatoryChecklist,
  PageLoadingAnimation,
  Providers,
  RiskCategory,
  RiskClass,
  RiskRatingOverall,
  RiskRatingSummary,
  SafeTripBuilder,
  SelectControl,
  StaffStudentSupervision,
  StudentSelection,
  StudentManagementPanel,
  StudentGroupSyncDiffAlert,
  SupportFeedbackLayout,
  SystemUserIndividualModal,
  SystemUserList,
  UpdateProviders,
  ViewAllMandatoryChecklist,
  ViewChecklist,
  ViewPolicy,
  ViewPredepartureChecklist,
  ClientIndividualEditor
};

window.jQuery = jQuery;
window.$ = jQuery;

Vue.config.productionTip = false;

Vue.use(Vuex);

// Directive injections
Vue.directive('safe-html', safeHtmlDirective);
// Component injections
Vue.component('activity-risk', ActivityRiskCategory);
Vue.component('app-side-nav', AppSideNav);
Vue.component('additional-info-list', AdditionalInfoList);
Vue.component('checklist-item-comment', ChecklistItemComment);
Vue.component('client-admin-group-setting', ClientAdminGroupSetting);
Vue.component('client-admin-layout', ClientAdminLayout);
Vue.component('administration-management', AdministrationManagement);
Vue.component('client-admin-operation-management', ClientAdminOperationManagement);
Vue.component('client-admin-risk-category-management', ClientAdminRiskCategoryManagement);
Vue.component('client-admin-risk-matrix-management', ClientAdminRiskMatrixManagement);
Vue.component('client-admin-student-management', ClientAdminStudentManagement);
Vue.component('client-admin-site-setting', ClientAdminSiteSetting);
Vue.component('client-list', ClientList);
Vue.component('client-individual-editor', ClientIndividualEditor);
Vue.component('client-user-individual-modal', ClientUserIndividualModal);
Vue.component('client-user-list', ClientUserList);
Vue.component('complete-checklist', CompleteChecklist);
Vue.component('entity-management', EntityManagement);
Vue.component('excursion-builder', ExcursionBuilder);
Vue.component('excursion-confirmation', ExcursionConfirmation);
Vue.component('excursion-history', ExcursionHistory);
Vue.component('student-management-history', StudentManagementHistory);
Vue.component('excursion-list', ExcursionList);
Vue.component('excursion-stepper', ExcursionStepper);
Vue.component('export-excursion-policy', ExportExcursionPolicy);
Vue.component('export-excursion-risk-assessment', ExportExcursionRiskAssessment);
Vue.component('high-risk', HighRisk);
Vue.component('login-callback', LoginCallback);
Vue.component('mandatory-checklist', MandatoryChecklist);
Vue.component('page-loading-animation', PageLoadingAnimation);
Vue.component('providers', Providers);
Vue.component('risk-category', RiskCategory);
Vue.component('risk-class', RiskClass);
Vue.component('risk-rating-overall', RiskRatingOverall);
Vue.component('risk-rating-summary', RiskRatingSummary);
Vue.component('safe-trip-builder', SafeTripBuilder);
Vue.component('select-control', SelectControl);
Vue.component('staff-student-supervision', StaffStudentSupervision);
Vue.component('student-selection', StudentSelection);
Vue.component('student-management-panel', StudentManagementPanel);
Vue.component('student-group-sync-diff-alert', StudentGroupSyncDiffAlert);
Vue.component('support-feedback-layout', SupportFeedbackLayout);
Vue.component('system-user-individual-modal', SystemUserIndividualModal);
Vue.component('system-user-list', SystemUserList);
Vue.component('update-providers', UpdateProviders);
Vue.component('view-all-mandatory-checklist', ViewAllMandatoryChecklist);
Vue.component('view-checklist', ViewChecklist);
Vue.component('view-policy', ViewPolicy);
Vue.component('view-predeparture-checklist', ViewPredepartureChecklist);
Vue.component('vue-cropper', VueCropper);
Vue.component('vue-markdown', VueMarkdown);

// Additional dependency injections
const eventBus = new Vue();
const { Appcues } = window;

const eventPluginCommonAdapter = new eventPluginCommon.Adapter({
  Appcues,
  env,
  mixpanelBrowser,
  window
});

const router = routerBundle.routerComposer({
  vueComponentHash,
  VueRouter
});
Vue.use(VueRouter);

const options = {
  store,
  router,
  beforeEachRoute: routerBundle.beforeEachRoute,
  auth0: {
    appHost: window.location.host,
    appUrl: window.location.origin,
    auth0Domain: process.env.AUTH0_DOMAIN,
    auth0ClientId: process.env.AUTH0_CLIENT_ID,
    auth0Audience: process.env.AUTH0_AUDIENCE,
    appEnv: process.env.APP_ENV
  },
  gainsight: {
    gainsightSiteInfoApiHost: env.GAINSIGHT_SITE_INFO_API_HOST,
    gainsightProductTag: env.GAINSIGHT_PRODUCT_TAG
  },
  login: {
    resetBtnTimeout: 5
  }
};
Vue.use(CsDesignSystem, options);

const axiosInstance = store.getters['auth0/enabled'] ? auth0Axios : axios.create();

const stbApiAdapter = new stbApi.Adapter({
  axiosInstance,
  env
});

const app = {
  Appcues,
  axios,
  axiosCommon,
  axiosInstance,
  componentOption,
  deviceDetector,
  enumerable,
  env,
  eventBus,
  eventPluginCommonAdapter,
  excursionCommon,
  exportExcursions,
  lodash,
  mixpanelBrowser,
  moment,
  pdf,
  selectCustom,
  stbApiAdapter,
  store2,
  uuid,
  vueComponentHash,
  VueRouter,
  window
};
Object.defineProperty(Vue.prototype, '$app', { value: app });

store2({ initState: store.state });

axiosCommon.setInterceptorBatch({
  axiosInstance,
  store
});

i18n.setLocaleMessage('en', {
  cs: {
    login: {
      welcomeMessage: '',
      welcomeMessageSubText:
        'When you click Login, you may be redirected to your Single Sign On login page where you may need to provide your credentials to login to SafeTripBuilder.',
      loginButtonText: 'Login'
    },
    sideNav: {
      copyRightText: `2018-${new Date().getFullYear().toString()} CompliSpace`,
      class: 'my-class'
    },
    header: {
      homeMenuText: 'Home',
      loginButtonText: 'Login',
      logoutButtonText: 'Logout'
    }
  }
});

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');
