export default {
  consequence: {
    '3a565f3d-d486-4abb-b280-f287da081078': {
      orderIndex: 0,
      label: 'Insignificant',
      description: `Insignificant injury that may require basic first aid and disruption to excursion, however no injury is sustained
Little to no reputational harm done to the school
Little to no non-compliance with school policies.`
    },
    'ee2723aa-e3cc-43a9-911d-c9cc8721ece7': {
      orderIndex: 1,
      label: 'Minor',
      description: `Minor injury or harm requiring treatment by a doctor, or appropriate medical professional, but not hospitalisation
Minor reputational harm done to the school
Partial non-compliance with school policies.
`
    },
    '9d5b7bd7-8b38-4cbf-909c-c3f008cbd076': {
      orderIndex: 2,
      label: 'Moderate',
      description: `Injury or harm requiring hospitalisation, however full recovery is expected
Moderate reputational harm done to the school
Technical non-compliance with legal obligations and non-compliance with school policies.
`
    },
    'a4244a35-1a62-46c7-84da-9cde1c0ef6b8': {
      orderIndex: 3,
      label: 'Major',
      description: `Life threatening injury or harm requiring lengthy hospitalisation and rehabilitation. More than a month off school
Major reputational harm done to the school
Non-compliance with legal obligations and substantial non-compliance with internal policies.
`
    },
    '1dd25e87-cde3-4ece-9d54-a968c60cfa71': {
      orderIndex: 4,
      label: 'Catastrophic',
      description: `Death or serious permanent disability or harm. Unlikely to be able to return to school
Catastrophic reputational harm done to the school
Intentional or recklessly negligent non-compliance with legal obligations.
`
    }
  },
  likelihood: {
    '887c9f9f-d223-4c6b-8b27-f3901786458e': {
      orderIndex: 0,
      label: 'Rare',
      description: `Will only occur in exceptional circumstances (would be considered highly unusual).`
    },
    'a79ed2c4-1137-452d-8180-e7a53938c11e': {
      orderIndex: 1,
      label: 'Unlikely',
      description: `Not likely to occur during the course of an excursion.`
    },
    '01fd736b-4654-4e23-af25-9fd91939e4c4': {
      orderIndex: 2,
      label: 'Possible',
      description: `Has a chance of occurring during the course of an excursion.`
    },
    'be8742bd-eb1e-4cc1-90cb-f3bbc3f9289e': {
      orderIndex: 3,
      label: 'Likely',
      description: `May occur during the course of an excursion.`
    },
    'a4b4c11f-2743-42b5-a6f2-5b783cdc3d53': {
      orderIndex: 4,
      label: 'Almost Certain',
      description: `Will almost certainly happen during the course of an excursion.`
    }
  },
  riskRating: {
    'cde3e744-64d9-424c-a055-e57baa1e8cc1': { label: 'Low', orderIndex: 0 },
    'b78e68c1-2322-4eeb-8179-969bcad298a0': { label: 'Medium', orderIndex: 1 },
    'ef246774-087c-42cd-9177-94211cd9f848': { label: 'High', orderIndex: 2 },
    '2188cbb0-61b5-4eff-80e6-bfb61e5b6d2e': { label: 'Extreme', orderIndex: 3 }
  }
};
