import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const state = {
  isResponseWithError: false,
  apiErrorMessage: '',
  apiDataDefaultJurisdiction: '',
  excursionTypeChoiceList: {},
  allRisks: [],
  allRisksInitialized: false,
  tickedMandatoryChecklists: [],
  mandatoryChecklistsLength: 7, // TODO: Allow variable length of mandatory checklists
  tickedRiskCategoryChecklists: {},
  retrievedCategoriesAndRisks: [],
  selectedActivityTypeFilter: {
    id: 0,
    name: ''
  },
  selectedActivityClassFilter: {
    id: 0,
    name: ''
  },
  excursionDc: {
    id: 0,
    excursionDestinationContentEnabled: false,
    schemaVersion: 0,
    name: '',
    typeId: '',
    jurisdiction: '',
    startDate: '',
    endDate: '',
    status: 'draft',
    categories: [],
    vendorData: {
      vendorExcursionSid: ''
    },
    updateSignature: '',
    currentStep: 1,
    additionalInformationItems: [],
    mandatoryChecklist: [],
    agreementConfirmation: {
      ticked: false,
      statement1: '',
      statement2: ''
    },
    computed: {
      logoAttachmentUrl: '',
      studentMedicalConditions: []
    },
    validCategories: false,
    riskOverall: {},
    riskCategories: [],
    predepartures: [],
    predepartureChecklistCompleted: false,
    staffSupervisorAttendanceCount: '',
    studentAttendanceCount: '',
    syncAvailable: false,
    activitySelectionValid: true,
    auditList: [],
    clientTemplate: {},
    excursionCopyFlag: false,
    riskAssessmentNotes: '',
    riskAssessmentNotesEnabled: false,
    additionalRiskControlMitigationStrategiesEnabled: false,
    staffToStudentGenderRatioInfo: '',
    mandatoryChecklistBatchConfirmationAgreement: false,
    riskMatrix: {},
    riskMatrixSelfDefinedDetected: false,
    selectedLikelihood: '',
    selectedConsequence: '',
    selectedRating: '',
    studentInfo: null,
    client: {
      excursionAdditionalInformationEnabled: false,
      excursionHighRiskStudentMedicalEnabled: false
    }
  },
  allProviders: [],
  allProvidersInitialized: false,
  apiParam: {},
  userAuthSchemeApiParam: {},
  showError: false, // TODO: Classify errors e.g.: showExcursionError, showClientError
  allExcursions: {},
  excursionInitialized: false,
  studentManagementAuditList: [],
  excursionsList: {
    data: [],
    total: 0,
    loading: false
  },
  clientList: {
    data: [],
    total: 0,
    loading: false
  },
  clientUserList: {
    data: [],
    total: 0,
    loading: false
  },
  userList: {
    data: [],
    total: 0,
    loading: false
  },
  isGetClientList: false, // TODO: Use different route for client list
  showExcursionStepper: true,
  vendorClientLogoEnabled: false,
  vendorExcursionSidInputEnabled: false,
  providerUpdateActionDisplayed: false,
  providerUpdateActionEnabled: false,
  excursionAdditionalInformationEnabled: false,
  excursionChecklistEnforcementBeforeSubmissionEnabled: false,
  excursionCopyActionEnabled: false,
  excursionDestinationContentEnabled: false,
  excursionPersonResponsibleEnabled: false,
  excursionHighRiskStudentMedicalEnabled: false,
  excursionInherentRiskRatingEnabled: false,
  excursionUserRiskRatingEnabled: false,
  excursionStudentFetchTriggered: false,
  excursionStudentMedicalConditionsDisplayReady: false,
  publicSchoolFeatureSetEnabled: false,
  originalTypeId: '',
  logoAttachmentUrl: '',
  clearCreateRiskAssessment: false,
  excursionDisplayReady: false,
  excursionLogoDisplayReady: false,
  clientAdminSectionButtonDisplayed: false,
  clientsTabDisplayed: false,
  clientUsersTabDisplayed: false,
  usersTabDisplayed: false,
  clientTemplate: {},
  excursionBuilderButtonDisplayed: false,
  excursionCreationButtonDisplayed: false,
  systemAdminSelectionOptionDisplayed: false,
  excursionSyncButtonDisplayed: false,
  excursionApprovalButtonDisplayed: false,
  excursionExportButtonDisplayed: false,
  excursionUnapprovalButtonDisplayed: false,
  excursionArchivalButtonDiplayed: false,
  clientSiteSettingsButtonDiplayed: false,
  riskCategoryManagementMenuItemDisplayed: false,
  operationManagementMenuItemDisplayed: false,
  studentManagementSubjectManagementDisplayed: false,
  clientSelfGroupManagementDisplayed: false,
  vendorGroupIdDisplayEnabled: false,
  studentManagementSubjectSetupDetected: false,
  studentManagementExtraGroupFeatureSetEnabled: false,
  staffToStudentGenderRatioInfoEnabled: false,
  mandatoryChecklistBatchConfirmationAgreementEnabled: false,
  excursionStartEndDateSetEnabled: false,
  clientLogoBackgroundColor: '',
  prevRouteName: '',
  userInitials: '',
  mandatoryLoadActionCompleted: false,
  parentClientList: {
    loading: false,
    items: [],
    itemsCache: []
  },
  excursionTypeLoaded: false,
  ceCommitOid: '',
  clientClientList: {
    loading: false,
    items: [],
    itemsCache: []
  },
  moduleCacheClearanceButtonDisplayed: false,
  checklistFetchActionInProgress: false,
  excursionListClientColumnDisplayed: false,
  excursionListFilterSectionDisplayed: false,
  excursionListAllClientSuggestionDisplayed: false,
  clientParentId: false,
  parentClientSiteFlag: false,
  selectedExcursionTypeLabel: '',
  clientAclRoleInferiorityHash: {},
  mixedMedicalConditionHash: {},
  clientSettingRiskMatrix: {},
  userDisplayName: '',
  userEmail: ''
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
