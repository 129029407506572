<template>
  <v-layout>
    <v-btn
      :disabled="this.excursionPolicyExportActionEnabled"
      @click.native="open"
      class="normal-btn white--text continue-btn primary"
      id="btnCreateRiskAssessment"
      testing-id="btnCreateRiskAssessment"
    >
      <v-icon v-if="!riskAssessmentDownloadActionInProgress">fa-exclamation-triangle</v-icon>
      Export Risk Assessment
    </v-btn>
    <cs-form-dialog
      :value="exportRiskAssessmentErrorDialogDisplayed"
      heading="Export Risk Assessment"
      :primaryAction="{ label: 'OK', eventName: 'primary-click' }"
      @primary-click="exportRiskAssessmentErrorDialogDisplayed = false"
      @input="exportRiskAssessmentErrorDialogDisplayed = false"
    >
      <template v-slot:cs-form-dialog-content>
        <v-spacer></v-spacer>
        <v-card-text>
          <div v-safe-html="exportRiskAssessmentErrorDialogMessage"></div>
        </v-card-text>
      </template>
    </cs-form-dialog>
    <v-dialog
      v-model="riskAssessmentExportModalDisplayed"
      persistent
      max-width="40%"
      @keydown.enter="displayPreSaveConfirmationDialog()"
    >
      <v-card>
        <v-card-title class="modalTitle">
          <v-flex>
            <strong>Export Risk Assessment</strong>
          </v-flex>
          <v-btn
            id="export-excursion-risk-assessment--riskAssessment-export-modal-dialog--close--button"
            icon
            @click="close()"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="modalDivider"></v-divider>
        <v-card-text>
          <v-container class="export-excursion-policy">
            <div>
              <v-layout class="selected-risk-container">
                <v-flex xs1>
                  <v-checkbox
                    id="export-excursion-risk-assessment--export-all-checklists"
                    v-model="allChecklistsExported"
                    class="excursion-policy-row-title-checkbox"
                  ></v-checkbox>
                </v-flex>
                <v-flex
                  id="export-excursion-risk-assessment--export-all-checklists--label"
                  align-end
                  justify-end
                  class="excursion-policy-row-title"
                  @click="allChecklistsExported = !allChecklistsExported"
                  >Export All Checklists</v-flex
                >
              </v-layout>
              <v-layout class="selected-risk-container">
                <v-flex xs1>
                  <v-checkbox
                    id="export-excursion-risk-assessment--pre-departure-checklist"
                    v-model="predepartureChecklistExported"
                    class="excursion-policy-row-title-checkbox"
                  ></v-checkbox>
                </v-flex>
                <v-flex
                  id="export-excursion-risk-assessment--pre-departure-checklist--label"
                  align-end
                  justify-end
                  class="excursion-policy-row-title"
                  @click="predepartureChecklistExported = !predepartureChecklistExported"
                  >Pre-Departure Checklist</v-flex
                >
              </v-layout>
              <v-layout class="selected-risk-container">
                <v-flex xs1>
                  <v-checkbox
                    id="export-excursion-risk-assessment--all-attachments-checkbox"
                    v-model="allAttachmentsExported"
                    class="excursion-policy-row-title-checkbox"
                  ></v-checkbox>
                </v-flex>
                <v-flex
                  id="export-excursion-risk-assessment--all-attachments--label"
                  align-end
                  justify-end
                  class="excursion-policy-row-title"
                  @click="allAttachmentsExported = !allAttachmentsExported"
                  >Include All Attachments</v-flex
                >
              </v-layout>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="export-excursion-risk-assessment--riskAssessment-export-modal-dialog--confirmation--button"
            class="normal-btn white--text btnClear primary"
            :disabled="riskAssessmentDownloadActionInProgress"
            testing-id="btnDocxShowPreSaveConfirmationDialog"
            @click.native="exportBtnClicked('docx')"
          >
            <v-icon v-if="riskAssessmentDownloadActionInProgress">fa fa-spinner fa-pulse</v-icon>
            Export DOC
          </v-btn>
          <v-btn
            id="export-excursion-risk-assessment--riskAssessment-export-modal-dialog--confirmation--button"
            class="normal-btn white--text btnClear primary"
            :disabled="riskAssessmentDownloadActionInProgress"
            testing-id="btnPdfShowPreSaveConfirmationDialog"
            @click.native="exportBtnClicked('pdf')"
          >
            <v-icon v-if="riskAssessmentDownloadActionInProgress">fa fa-spinner fa-pulse</v-icon>
            Export PDF
          </v-btn>
          <v-btn
            id="export-excursion-risk-assessment--riskAssessment-export-modal-dialog--cancel--button"
            class="normal-btn"
            @click.native="close()"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div>
      <v-dialog v-model="preSaveConfirmationDialogDisplayed" persistent max-width="490">
        <v-card>
          <v-card-text>
            Changes have been made to this excursion. Do you want to save?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              id="export-excursion-risk-assessment--preSave-confirmation-dialog--save--button"
              class="normal-btn white--text primary"
              testing-id="btnExportRiskAssessmentWithExcursionPreSave"
              @click.native="
                preSaveConfirmationDialogDisplayed = false;
                exportContent(true);
              "
              >Save</v-btn
            >
            <v-btn
              id="export-excursion-risk-assessment--preSave-confirmation-dialog--cancel--button"
              class="normal-btn"
              testing-id="btnExportRiskAssessmentWithoutExcursionPreSave"
              @click.native="
                preSaveConfirmationDialogDisplayed = false;
                exportContent(false);
              "
              >Cancel</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-layout>
</template>

<script>
import excursionStepperHeading from '../lib/const/excursionStepperHeading';

export default {
  props: [
    'excursionPolicyExportActionEnabled',
    'valuesChanged',
    'registerRiskAssessmentExportEvent',
    'submitExcursion'
  ],

  data() {
    return {
      riskAssessmentExportModalDisplayed: false,
      riskAssessmentDownloadActionInProgress: false,
      allChecklistsExported: true,
      preSaveConfirmationDialogDisplayed: false,
      predepartureChecklistExported: false,
      allAttachmentsExported: false,
      exportRiskAssessmentErrorDialogDisplayed: false,
      exportRiskAssessmentErrorDialogMessage: ''
    };
  },

  methods: {
    close() {
      this.riskAssessmentDownloadActionInProgress = false;
      this.riskAssessmentExportModalDisplayed = false;
      this.allChecklistsExported = true;
      this.predepartureChecklistExported = false;
      this.allAttachmentsExported = false;
    },

    async open() {
      try {
        await this.validateExcursionHighRiskStudentMedicalSetting();
        this.riskAssessmentExportModalDisplayed = true;
      } catch (error) {
        this.exportRiskAssessmentErrorDialogMessage = error.message;
        this.exportRiskAssessmentErrorDialogDisplayed = true;
      }
    },

    async validateExcursionHighRiskStudentMedicalSetting() {
      const excursionStatus = this.$store.state.common.excursionDc.status;
      if (excursionStatus !== 'draft') {
        return;
      }

      this.validateClientSetup(this.$store.state.common.excursionDc.client);
    },

    validateClientSetup(clientSetup) {
      if (!clientSetup.excursionAdditionalInformationEnabled) {
        throw new Error(
          `"Additional Information" step is expected. Please check with your administrator to enable it in client options.`
        );
      }
      if (!clientSetup.excursionHighRiskStudentMedicalEnabled) {
        return;
      }
      const { currentStep } = this.$store.state.common.excursionDc;
      if (clientSetup.excursionHighRiskStudentMedicalEnabled && currentStep < 5) {
        throw new Error(
          `"Student Medical and Ratios" step is expected when high-risk student medical option is enabled. Please check with your administrator or make a new excursion with latest client's settings.`
        );
      }
    },

    async downloadAssessment(format) {
      this.riskAssessmentDownloadActionInProgress = true;

      if (typeof this.registerRiskAssessmentExportEvent === 'function') {
        this.registerRiskAssessmentExportEvent();
      }

      const excursionMetadata = JSON.parse(JSON.stringify(this.$store.state.common.excursionDc));

      const { query } = this.$route;
      const clientLogoEnabled = !(query && query.referrer && query.referrer === 'pcg');

      const excursionCommonAdapter = new this.$app.excursionCommon.Adapter({
        store: this.$store,
        app: this.$app
      });

      await excursionCommonAdapter.downloadAssessment(
        excursionMetadata.id,
        query,
        this.allChecklistsExported,
        clientLogoEnabled,
        this.predepartureChecklistExported,
        this.allAttachmentsExported,
        format,
        this.$app.deviceDetector
      );

      this.close();
    },
    exportBtnClicked(format) {
      this.displayPreSaveConfirmationDialog(format);
    },

    async displayPreSaveConfirmationDialog(format) {
      if (
        this.submitExcursion &&
        typeof this.submitExcursion === 'function' &&
        this.valuesChanged &&
        this.valuesChanged !== 'excursionUpdateThenRiskAssessmentExport'
      ) {
        this.preSaveConfirmationDialogDisplayed = true;
      } else {
        if (
          this.submitExcursion &&
          typeof this.submitExcursion === 'function' &&
          this.valuesChanged === 'excursionUpdateThenRiskAssessmentExport'
        ) {
          await this.submitExcursion(
            null,
            true,
            null,
            'excursionUserRatingSync',
            excursionStepperHeading.EXCURSION_RISK_ASSESSMENT
          );
        }
        await this.downloadAssessment(format);
      }
    },

    async exportContent(preSaveConfirmed) {
      if (preSaveConfirmed) {
        this.riskAssessmentDownloadActionInProgress = true;
        this.submitExcursion('downloadExcursionRiskAssessment', false, async () => {
          await this.downloadAssessment();
        });
      }
    }
  }
};
</script>
