<template>
  <div>
    <v-btn
      title="Update Providers"
      class="normal-btn toolbar-btn safe-update-provider"
      active-class="normal-btn"
      :disabled="!providerUpdateActionEnabled || actionDisabled || !buttonUpdateProvidersEnabled"
      @click.native="updateProviders"
      id="btnUpdateProviders"
      v-if="providerUpdateActionDisplayed"
    >
      <v-icon v-if="loaderDisplayed">fa fa-spinner fa-pulse</v-icon>
      Update Providers
    </v-btn>

    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline center">{{ updateProvidersStatusText }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn id="update-providers--update-providers-status-dialog--ok--button" @click.native="dialog = false" class="normal-btn white--text primary">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as types from "@/store/mutationTypes";

export default {
  props: {
    buttonUpdateProvidersEnabled: Boolean
  },
  data() {
    return {
      dialog: false,
      actionDisabled: false,
      loaderDisplayed: false,
      updateProvidersStatusText: ""
    };
  },
  computed: {
    providerUpdateActionEnabled() {
      return this.$store.state.common.providerUpdateActionEnabled;
    },
    providerUpdateActionDisplayed() {
      return this.$store.state.common.providerUpdateActionDisplayed;
    }
  },
  methods: {
    async updateProviders() {
      this.actionDisabled = true;
      this.loaderDisplayed = true;

      const argHash = {
        store: this.$store,
        queryParam: this.$route.query
      };

      const providers = await this.$app.stbApiAdapter.postProviders(argHash);
      this.dialog = true;
      this.actionDisabled = false;
      this.loaderDisplayed = false;

      if ("undefined" !== typeof providers.data) {
        this.$store.commit(types.COMMON_SET_PROVIDERS, providers.data);
        this.updateProvidersStatusText = "Update success";
      } else {
        this.updateProvidersStatusText = "Update failed";
      }
    }
  }
};
</script>
