<template>
  <div class="risk-assessment-rating" v-if="ratingValue">
    <v-flex xs12 class="form-label safe-trip-font">
      {{ headingLabel }}
      <v-flex class="confirmation-info-text" v-if="headingLabel === 'CompliSpace Safe Excursions Recommended Rating'">
        <i class="fas fa-info-circle"></i>
          Based on the CompliSpace Risk Assessment Guide available <a href="/public/Risk_Assessment_Guide.pdf" id="risk-rating-summary--risk-assessment-guide--link" target="_blank">here</a>.
      </v-flex>
      <v-tooltip v-if="tooltipBubbleTextDisplayed" right color="#ffffff">
        <template v-slot:activator="{ on: tooltip }">
          <v-btn id="risk-rating-summary--provider-tooltip--button" v-on="{ ...tooltip }" class="provider-tooltip-button" icon>
            <i class="fas fa-info-circle"></i>
          </v-btn>
        </template>
        <div style="color:#000000">
          {{ tooltipBubbleText }}
        </div>
      </v-tooltip>
    </v-flex>
    <v-card class="d-flex p-2" color="#eeeeee">
      <v-flex class="assessment-title">
        Likelihood
      </v-flex>
      <v-flex class="assessment-value">
        <v-chip class="ma-2" :color="ratingBgColor" :text-color="ratingTextColor" label>
          {{ likelihoodValue }}
        </v-chip>
      </v-flex>
      <v-flex class="assessment-title">
        Consequence
      </v-flex>
      <v-flex class="assessment-value">
        <v-chip class="ma-2" :color="ratingBgColor" :text-color="ratingTextColor" label>
          {{ consequenceValue }}
        </v-chip>
      </v-flex>
      <v-flex class="assessment-title">
        Rating
      </v-flex>
      <v-flex class="assessment-value">
        <v-chip class="ma-2" :color="ratingBgColor" :text-color="ratingTextColor" label>
          {{ ratingValue }}
        </v-chip>
      </v-flex>
      <v-flex class="rating-space"> </v-flex>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    headingLabel: String,
    tooltipBubbleText: String,
    riskRatingRefname: String,
  },

  data() {
    return {
      riskRatingTemplateHash: {
        L: {
          bgColor: "green",
          textColor: "#ffffff",
          ratingValue: "Low"
        },
        M: {
          bgColor: "yellow",
          textColor: "#000000",
          ratingValue: "Medium"
        },
        H: {
          bgColor: "#ff7f32",
          textColor: "#ffffff",
          ratingValue: "High"
        },
        E: {
          bgColor: "red",
          textColor: "#ffffff",
          ratingValue: "Extreme"
        }
      }
    };
  },
  computed: {
    likelihoodValue() {
      let localLikelihoodValue = "";

      if (this.$store.state.common.excursionDc) {
        const { riskOverall } = this.$store.state.common.excursionDc;

        if (riskOverall) {
          if ("inherent" === this.riskRatingRefname) {
            localLikelihoodValue = riskOverall.inherentLikelihood;
          } else {
            localLikelihoodValue = riskOverall.likelihood;
          }
        }
      }

      return localLikelihoodValue;
    },

    consequenceValue() {
      let localConsequenceValue = "";

      if (this.$store.state.common.excursionDc) {
        const { riskOverall } = this.$store.state.common.excursionDc;

        if (riskOverall) {
          if ("inherent" === this.riskRatingRefname) {
            localConsequenceValue = riskOverall.inherentConsequence;
          } else {
            localConsequenceValue = riskOverall.consequence;
          }
        }
      }

      return localConsequenceValue;
    },

    ratingLetter() {
      let localRatingLetter = "";

      if (this.$store.state.common.excursionDc) {
        const { riskOverall } = this.$store.state.common.excursionDc;

        if (riskOverall) {
          if ("inherent" === this.riskRatingRefname) {
            localRatingLetter = riskOverall.inherentRiskRating;
          } else {
            localRatingLetter = riskOverall["risk-rating"];
          }
        }
      }

      return localRatingLetter;
    },

    ratingValue() {
      let localRatingValue = "";
      const riskRatingTemplate = this.riskRatingTemplateHash[this.ratingLetter];

      if (riskRatingTemplate) {
        localRatingValue = riskRatingTemplate.ratingValue;
      }

      return localRatingValue;
    },

    ratingBgColor() {
      let bgColor = "";
      const riskRatingTemplate = this.riskRatingTemplateHash[this.ratingLetter];

      if (riskRatingTemplate) {
        bgColor = riskRatingTemplate.bgColor;
      }

      return bgColor;
    },

    ratingTextColor() {
      let textColor = "";
      const riskRatingTemplate = this.riskRatingTemplateHash[this.ratingLetter];

      if (riskRatingTemplate) {
        textColor = riskRatingTemplate.textColor;
      }

      return textColor;
    },

    tooltipBubbleTextDisplayed() {
      return "undefined" !== typeof this.tooltipBubbleText && "" !== this.tooltipBubbleText;
    }
  }
};
</script>
