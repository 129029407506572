import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

const COOKIE_KEY = 'token';

export default {
  getClientSubdomain() {
    const { hostname } = window.location;
    return hostname.substring(0, hostname.indexOf(process.env.UI_BASE_DOMAIN) - 1);
  },
  setClientSubdomainHeaders(axiosInstance, clientSubdomain) {
    axiosInstance.interceptors.request.use(
      (config) => {
        // eslint-disable-next-line no-param-reassign
        config.headers['x-client-subdomain'] = clientSubdomain;
        return config;
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  },
  getAuthTokenFromCookie() {
    return Cookies.get(COOKIE_KEY);
  },
  saveAuthTokenToCookie(token) {
    Cookies.set(COOKIE_KEY, token, {
      secure: true,
      sameSite: 'None'
    });
  },
  removeAuthTokenFromCookie() {
    Cookies.remove(COOKIE_KEY);
  },
  getTokenExpiryInMS() {
    const token = this.getAuthTokenFromCookie();
    if (!token) {
      return 0;
    }
    const decodedToken = jwtDecode(token);
    return decodedToken.exp * 1000;
  }
};
