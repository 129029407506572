<template>
  <v-container grid-list-md text-xs-center class="settings-container">
    <v-layout row wrap>
      <!-- start: content -->
      <v-flex>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                solo
                placeholder="CE module ID"
                class=""
                v-model="moduleId"
                :error-messages="moduleIdErrors"
                required
                @input="$v.moduleId.$touch()"
                @blur="$v.moduleId.$touch()"
                id="client-admin-operation-management--module-id"
              />
            </v-col>
            <v-col cols="12" sm="6" align="left">
              <v-btn
                color="primary"
                @click="syncRiskCategories"
                id="client-admin-operation-management--sync-risk"
              >
                <span>Sync</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-flex>
      <!-- end: content -->
    </v-layout>
    <cs-dialog
      :value="clientAdminOperationManagementGenericDialogDisplayed"
      name="CSDialog"
      max-width="490"
      bodyId="client-admin-risk-category-management-modal--generic-dialog"
      persistent
    >
      <template v-slot:dialog-body>
        <v-spacer></v-spacer>
        <v-card-text>{{ operationGenericDialogMessage }}</v-card-text>
      </template>
      <template v-slot:dialog-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click.native="clientAdminOperationManagementGenericDialogDisplayed = false"
          class="normal-btn white--text primary"
          id="client-admin-risk-category-management-modal--generic-dialog--ok-button"
        >
          OK
        </v-btn>
      </template>
    </cs-dialog>
  </v-container>
</template>

<script>
import { CSBase } from '@complispace/cs-design-system';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { mapState } from 'vuex';

export default {
  extends: CSBase,
  mixins: [validationMixin],
  validations: {
    moduleId: {
      required
    }
  },
  data() {
    return {
      componentKey: 0,
      moduleId: '',
      clientAdminOperationManagementGenericDialogDisplayed: false,
      operationGenericDialogMessage: '',
      operationGenericDialogSuccessMessage: 'Risk category table has been synced.'
    };
  },
  computed: {
    ...mapState({
      clientSiteSettingsButtonDiplayed: (state) => state.common.clientSiteSettingsButtonDiplayed,
      operationManagementMenuItemDisplayed: (state) =>
        state.common.operationManagementMenuItemDisplayed
    }),
    moduleIdErrors() {
      const errors = [];
      if (!this.$v.moduleId.$dirty) return errors;
      if (!this.moduleId) {
        errors.push('module ID is required');
      }

      return errors;
    }
  },
  methods: {
    async syncRiskCategories() {
      const independentArgHash = {
        payload: { moduleId: this.moduleId, documentTag: '' },
        store: this.$store
      };
      const governmentArgHash = {
        payload: { moduleId: this.moduleId, documentTag: 'STB government' },
        store: this.$store
      };

      const independentCb = await this.$app.stbApiAdapter.postPcgRiskCategorySync(
        independentArgHash
      );
      const governmentCb = await this.$app.stbApiAdapter.postPcgRiskCategorySync(governmentArgHash);
      if (independentCb.error || governmentCb.error) {
        const dialogMessage = 'Risk category table cannot be synced. Please try again';
        this.operationGenericDialogMessage = `${dialogMessage}`;
        this.clientAdminOperationManagementGenericDialogDisplayed = true;
      } else {
        this.operationGenericDialogMessage = `${this.operationGenericDialogSuccessMessage}`;
        this.clientAdminOperationManagementGenericDialogDisplayed = true;
      }
    }
  },
  async mounted() {
    const { axiosInstance, eventPluginCommonAdapter, stbApiAdapter } = this.$app;
    const isVendorAuthScheme =
      !!this.$route.query['vendor-sid'] && !!this.$route.query['oauth-signature'];
    if (!isVendorAuthScheme) {
      await this.$store.dispatch('authorisation/validateAndSetUserTokenOrLogout', {
        query: this.$route.query,
        axiosInstance
      });
    }
    await this.$store.dispatch('common/initClientConfig', {
      queryParam: isVendorAuthScheme ? this.$route.query : {},
      eventPluginCommonAdapter,
      stbApiAdapter
    });
    if (!this.clientSiteSettingsButtonDiplayed || !this.operationManagementMenuItemDisplayed) {
      this.$router.push('/excursions');
    }
  }
};
</script>

<style scoped>
.settings-container {
  width: 100%;
  height: 100%;
  max-width: unset;
  margin: 0;
  padding: 0;
}
.settings-content {
  text-align: left;
  height: 100vh;
}
</style>
