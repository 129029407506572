import downloadFile from './downloadFile';
import downloadFileForIEOrEdge from './downloadFileForIEOrEdge';

const downloadDocx = (base64Data, filename, deviceDetector) => {
  const data =
    deviceDetector && deviceDetector.checkIfDeviceInIosFamily()
      ? base64Data
      : `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${base64Data}`;

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    downloadFileForIEOrEdge(base64Data, filename);
  } else {
    downloadFile(data, filename, deviceDetector);
  }
};

export default downloadDocx;
