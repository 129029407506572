<template>
  <v-layout>
    <v-btn
      class="normal-btn white--text predeparture-checklist-button primary"
      @click.native="getData()"
      id="btnViewOrExportPredepartureChecklist"
      testing-id="btnViewOrExportPredepartureChecklist"
      :disabled="predepartureChecklistExportActionEnabled"
    >
      <v-icon v-if="predepartureChecklistCompleted">fa fa-check</v-icon>
      <font>Pre-Departure Checklist</font>
    </v-btn>
    <v-dialog v-model="dialog" scrollable persistent>
      <v-card>
        <v-card-title class="modalTitle">
          <v-flex>
            <strong>Pre-Departure Checklist</strong>
          </v-flex>
          <v-btn id="view-predeparture-checklist--predeparture-checklist-dialog--close--buton" icon @click="close">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="modalDivider"></v-divider>
        <v-card-text class="predeparture-check-list">
          <div v-if="checklistChildrenDisplayed()">
            <v-list v-for="(item, index) in items" :key="index">
              <v-subheader class="subhealder">
                <vue-markdown :source="item.label" :typographer="false"></vue-markdown>
              </v-subheader>
              <div v-for="(option, index) in item.children" :key="index">
                <v-layout>
                  <v-checkbox
                    :id="getPreCheckId(option, index, item.label)"
                    color="#c80063"
                    v-model="option.ticked"
                    class="pre-check"
                    :checked="option.ticked"
                    :disabled="archivedExcursionDetected"
                  ></v-checkbox>
                  <v-flex
                    :id="getPreCheckLabelId(option, index, item.label)"
                    class="pre-check-desc"
                    @click.stop="clickItemTitle(option)"
                    testing-id="clickItemTitle"
                  >
                    <vue-markdown :source="option.label" :typographer="false"></vue-markdown>
                  </v-flex>
                </v-layout>
                <checklist-item-comment
                  class="checklist-item-comment"
                  :checklistItem="option"
                  :commentBtnId="`view-pre-departture-checklist--${ String(item.label).replaceAll(' ', '-').replaceAll('*', '') }---comment--${index}`"
                ></checklist-item-comment>
              </div>
            </v-list>
          </div>
          <div v-if="!checklistChildrenDisplayed()">
            <v-list v-for="(item, index) in items" :key="index">
              <v-subheader class="subhealder">
                <h3>{{ item.item }}</h3>
              </v-subheader>
              <v-layout>
                <v-checkbox
                  :id="getPreCheckId(item, index, item.item)"
                  color="#c80063"
                  v-model="item.ticked"
                  class="pre-check"
                  :checked="item.ticked"
                  :disabled="archivedExcursionDetected"
                ></v-checkbox>
                <v-flex
                  class="pre-check-desc"
                  :id="getPreCheckLabelId(item, index, item.item)"
                  @click.stop="clickItemTitle(item)"
                  testing-id="clickItemTitle"
                >
                  {{ item.description }}
                </v-flex>
              </v-layout>
              <checklist-item-comment
                :checklistItem="item"
                :commentBtnId="`view-pre-departture-checklist--${ String(item.item).replaceAll(' ', '-').replaceAll('*', '') }---comment--${index}`"
              >
              </checklist-item-comment>
            </v-list>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="view-predeparture-checklist--export primary"
            class="normal-btn white--text"
            :disabled="predepartureDownloadActionInProgress && !predepartureExportActionCompleted"
            testing-id="btnExportPredepartureChecklist"
            @click.native="displayPresaveConfirmationDialog()"
          >
            <v-icon
              v-if="predepartureDownloadActionInProgress && !predepartureExportActionCompleted"
              >fa fa-spinner fa-pulse</v-icon
            >
            Export
          </v-btn>
          <v-btn
            id="view-predeparture-checklist--save"
            class="normal-btn white--text primary"
            :disabled="predepartureSaveActionInProgress || archivedExcursionDetected"
            @click.native="save"
          >
            <v-icon v-if="predepartureSaveActionInProgress">fa fa-spinner fa-pulse</v-icon>
            Save Pre-departure
          </v-btn>
          <v-btn class="normal-btn" id="btnClose" @click.native="close()" testing-id="btnClose"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div>
      <v-dialog v-model="presaveConfirmationDialogDisplayed" persistent max-width="490">
        <v-card>
          <v-card-text>
            Changes have been made to this excursion. Do you want to save?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              id="view-predeparture-checklist--confirmation-dialog-save"
              class="normal-btn white--text primary"
              testing-id="btnExportPredepartureChecklistWithExcursionPresave"
              @click.native="
                presaveConfirmationDialogDisplayed = false;
                exportContent(true);
              "
              :disabled="!formControlEnabled"
              >Save</v-btn
            >
            <v-btn
              id="view-predeparture-checklist--confirmation-dialog-cancel"
              class="normal-btn"
              testing-id="btnExportPredepartureChecklistWithoutExcursionPresave"
              @click.native="
                presaveConfirmationDialogDisplayed = false;
                exportContent(false);
              "
              >Cancel</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-layout>
</template>

<script>
import * as types from "@/store/mutationTypes";
import excursionStatus from "../lib/const/excursionStatus";

export default {
  props: ["predepartureChecklistExportActionEnabled", "formControlEnabled"],
  data: () => ({
    dialog: false,
    predepartureDownloadActionInProgress: false,
    predepartureSaveActionInProgress: false,
    predepartureExportActionCompleted: false,
    items: [],
    presaveConfirmationDialogDisplayed: false
  }),
  computed: {
    predepartures() {
      return this.$app.lodash.cloneDeep(this.$store.state.common.excursionDc.predepartures);
    },
    predepartureChecklistCompleted() {
      return this.$store.state.common.excursionDc.predepartureChecklistCompleted;
    },
    archivedExcursionDetected() {
      return (excursionStatus.ARCHIVED === this.$store.state.common.excursionDc.status);
    },
  },
  methods: {
    async getData() {
      this.dialog = true;
      this.items = this.$app.lodash.cloneDeep(this.$store.state.common.excursionDc.predepartures);

      if (!this.items || 0 >= this.items.length) {
        const excursionCommonAdapter = new this.$app.excursionCommon.Adapter({
          store: this.$store,
          app: this.$app
        });
        const genericFrcs = excursionCommonAdapter.getRiskCategoryParams();
        const activityFrcs = excursionCommonAdapter.getActivityParams();
        // frc stands for: factor risk category
        const frcs = genericFrcs.concat(activityFrcs) || [];
        frcs.push([this.$store.state.common.excursionDc.typeId]);
        let response;

        const argHash = {
          stbApiAdapter: this.$app.stbApiAdapter,
          store: this.$store,
          queryParam: this.$route.query,
          payload: {
            "risk-categories": frcs
          }
        };

        if (frcs) {
          response = await this.$app.stbApiAdapter.postPredepartureStrategies(argHash);

          if (response && response.checklist) {
            this.items = [];
            this.items = this.$app.lodash.cloneDeep(response.checklist);
            this.items.forEach(p => {
              const localP = p;
              localP.ticked = false;
            });
            this.$store.commit(types.COMMON_SET_PREDEPARTURE, this.$app.lodash.cloneDeep(
              response.checklist
            ));
          } else if (response && 0 < response.length) {
            this.$store.commit(types.COMMON_SET_PREDEPARTURE, this.$app.lodash.cloneDeep(response));
          }
        }
      }
    },
    close() {
      this.dialog = false;
      this.items = [];
    },
    async displayPresaveConfirmationDialog() {
      if (this.valuesChanged()) {
        this.presaveConfirmationDialogDisplayed = true;
      } else {
        await this.exportPredeparture();
      }
    },
    valuesChanged() {
      let valuesChanged = false;
      let originalChecked = 0;
      let newChecked = 0;

      if (this.predepartures && 0 < this.predepartures.length) {
        originalChecked = this.predepartures.filter(item => item.ticked);
      }

      if (this.items && 0 < this.items.length) {
        newChecked = this.items.filter(item => item.ticked);
      }

      if (originalChecked && newChecked && originalChecked.length !== newChecked.length) {
        valuesChanged = true;
      }
      return valuesChanged;
    },
    async exportPredeparture() {
      this.predepartureDownloadActionInProgress = true;
      this.predepartureExportActionCompleted = false;

      const excursionMetadata = JSON.parse(JSON.stringify(this.$store.state.common.excursionDc));

      const { query } = this.$route;
      const clientLogoEnabled = !(query && query.referrer && "pcg" === query.referrer);

      const excursionCommonAdapter = new this.$app.excursionCommon.Adapter({
        store: this.$store,
        app: this.$app
      });

      const queryParam = {
        params: {
          query,
          payload: {
            "client-logo-enabled": clientLogoEnabled
          }
        },
        excursionId: excursionMetadata.id
      };

      const error = await excursionCommonAdapter.downloadPredeparturePdf(
        queryParam,
        this.$app.deviceDetector
      );

      if (!error) {
        this.dialog = false;
      }

      this.predepartureDownloadActionInProgress = false;
      this.predepartureExportActionCompleted = true;
    },
    clickItemTitle(item) {
      const localItem = item;
      localItem.ticked = !localItem.ticked;
      this.$forceUpdate();
    },
    async save(callback) {
      this.predepartureSaveActionInProgress = true;
      this.$store.commit(types.COMMON_SET_PREDEPARTURE, this.items);
      try {
        const excursionId = this.$store.state.common.excursionDc.id;
        const queryParam = {
          params: {
            query: this.$route.query,
            payload: {
              predepartures: this.items,
              updateSignature: this.$store.state.common.excursionDc.updateSignature
            }
          },
          excursionId
        };

        const that = this;
        const argHash = {
          stbApiAdapter: this.$app.stbApiAdapter,
          store: this.$store,
          queryParam
        };
        await this.$store.dispatch("common/updateExcursionPredepartureStrategies", argHash);
        that.predepartureSaveActionInProgress = false;
        if (callback && "function" === typeof callback) {
          callback();
        } else {
          that.predepartureDownloadActionInProgress = false;
          that.predepartureExportActionCompleted = true;
        }
      } catch (e) {
        this.predepartureDownloadActionInProgress = false;
        this.predepartureExportActionCompleted = true;
        this.predepartureSaveActionInProgress = false;
      }
    },
    async exportContent(presaveConfirmed) {
      if (presaveConfirmed) {
        this.predepartureDownloadActionInProgress = true;
        this.predepartureExportActionCompleted = false;
        await this.save(async () => {
          await this.exportPredeparture();
        });
      } else {
        await this.exportPredeparture();
      }
    },
    getPreCheckLabelId(item, index, section) {
      let preCheckId = "";
      if (item && item.item) {
        preCheckId = `view-predeparture-checklist--${String(section).replace(/[* ]/g, "")}--label-${item.item.replace(/\s*/g, "")}-${index}`;
      } else {
        preCheckId = `view-predeparture-checklist--${String(section).replace(/[* ]/g, "")}--label-${index}`;
      }
      return preCheckId;
    },
    getPreCheckId(item, index, section) {
      let preCheckId = "";
      if (item && item.item) {
        preCheckId = `view-predeparture-checklist--${String(section).replace(/[* ]/g, "")}--pre-check-${item.item.replace(/\s*/g, "")}-${index}`;
      } else {
        preCheckId = `view-predeparture-checklist--${String(section).replace(/[* ]/g, "")}--pre-check-${index}`;
      }
      return preCheckId;
    },
    checklistChildrenDisplayed() {
      const item = this.items[0];
      let displayed = false;
      if (item && item.children) {
        displayed = true;
      }
      return displayed;
    }
  },
  mounted() {
    this.items = [];
  }
};
</script>
