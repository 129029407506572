export default {
  APP_LIST: 'appList',
  ATTACHMENT_LIST: 'attachmentList',
  CLIENT: 'client',
  CLIENT_LIST: 'clientList',
  EXCURSION: 'excursion',
  EXCURSION_LIST: 'excursionList',
  PROVIDER: 'provider',
  PROVIDER_LIST: 'providerList',
  VENDOR: 'vendor',
  VENDOR_LIST: 'vendorList'
};
