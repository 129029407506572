<template>
  <div>
    <v-list-item class="checklist-item-comment-button-container" v-if="!commentDisplayed">
      <v-layout>
        <v-tooltip right color="#ffffff">
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              :id="commentBtnId"
              v-on="{ ...tooltip }"
              small
              class="checklist-item-comment-add-button"
              :disabled="commentDisabled"
              @click.native="commentDisplayed = true"
            >
              <v-icon>fa fa-comment</v-icon>Comment
            </v-btn>
          </template>
          <span class="checklist-item-add-comment-tooltip-content">Add Comment</span>
        </v-tooltip>
      </v-layout>
    </v-list-item>
    <v-list-item class="checklist-item-comment-content-container" v-if="commentDisplayed">
      <v-layout>
        <v-textarea
          :id="`checklist-item-comment--${checklistItem.id}`"
          class="checklist-item-comment"
          outlined
          light
          v-model="comment"
          rows="2"
          auto-grow
          :disabled="commentDisabled"
          @change="commentValueChanged()"
        >
        </v-textarea>
      </v-layout>
    </v-list-item>
  </div>
</template>

<script>
export default {
  props: ["checklistItem", "commentDisabled", "commentBtnId"],
  data: () => ({
    comment: "",
    commentDisplayed: false
  }),
  created() {
    this.loadData();
    this.$app.eventBus.$on("saveCompleteChecklist", this.loadData);
  },
  beforeDestroy() {
    this.$app.eventBus.$off("saveCompleteChecklist");
  },
  methods: {
    commentValueChanged() {
      this.checklistItem.comment = this.comment.trim();
    },
    loadData() {
      if (undefined === this.checklistItem.comment) {
        this.comment = "";
      } else {
        this.comment = this.checklistItem.comment.trim();
      }

      if (this.comment) {
        this.commentDisplayed = true;
      } else {
        this.commentDisplayed = false;
      }
    }
  },
  watch: {
    checklistItem() {
      this.loadData();
    }
  }
};
</script>
