import * as types from '@/store/mutationTypes';
import componentOption from '@/lib/componentOption';
import defaultClientLogo from '../../../../public/YourSchoolLogo.png';

import excursionStatus from '../../../lib/const/excursionStatus';
import excursionTypeInfo from '../../../lib/const/excursionTypeInfo';
import riskCategoryLabel from '../../../lib/const/riskCategoryLabel';

export default {
  resetExcursionStoreState({ commit }, argHash) {
    commit(types.RESET_EXCURSION_STORE_STATE, argHash); // TODO: This might be unused
  },
  // TODO: 'getAllRisksByExcursionTypeName' actually sets all form inputs
  // instead of just risk-specific inputs, so this might need to be renamed
  async getAllRisksByExcursionTypeName({ commit }, argHash) {
    const {
      stbApiAdapter,
      store,
      queryParam,
      excursionTypeName,
      excursionCopyModalHandlingExpected
    } = argHash;

    if (excursionStatus.ARCHIVED !== store.state.common.excursionDc.status) {
      const risksFromApi = await stbApiAdapter.getRisks({
        store,
        queryParam,
        excursionTypeName,
        excursionCopyModalHandlingExpected
      });
      commit(types.SET_CE_COMMIT_OID, risksFromApi.ceCommitOid);
      commit(types.SET_RISKS, risksFromApi.relevant);
    }

    commit(types.RESET_RISKS_DISABLED);
  },
  async getExcursionTypes({ commit }, argHash) {
    const { stbApiAdapter, store, queryParam } = argHash;
    commit(types.SET_EXCURSION_TYPE_LOADED, true);
    const response = await stbApiAdapter.getExcursionTypes({ store, queryParam });
    if (!response.error) {
      commit(types.SET_CE_COMMIT_OID, response.data.ceCommitOid);
      commit(types.SET_EXCURSION_TYPE_CHOICE_LIST, response.data.excursionTypes);
    }
    commit(types.SET_EXCURSION_TYPE_LOADED, false);
  },
  async getDefaultJurisdiction({ commit }, argHash) {
    const { stbApiAdapter, store, queryParam } = argHash;
    if (store.state.common.excursionDestinationContentEnabled) {
      const response = await stbApiAdapter.getClientJurisdiction({ store, queryParam });

      if (!response.error) {
        commit(types.SET_DEFAULT_JURISDICTION, response.data.data.jurisdiction);
      }
    }
  },
  resetCategoriesAndRisks({ commit }) {
    commit(types.RESET_CATEGORIES);
  },
  removeSelectRisk({ commit }, selectedRisk) {
    commit('removeSelectRisk', selectedRisk);
  },
  changeSelectRisk({ commit }, selectedRisk) {
    commit('changeSelectRisk', selectedRisk);
  },
  removeSelectRiskClass({ commit }, selectedRiskClass) {
    commit('removeSelectRiskClass', selectedRiskClass);
  },
  changeSelectRiskClass({ commit }, { riskClass, riskTypeName, riskCategory }) {
    commit(types.SET_SELECT_RISK_CLASS, { riskClass, riskTypeName, riskCategory });
  },
  changeActivitySelectRisk({ commit }, { risk, riskClassName }) {
    commit(types.SET_ACTIVITY_SELECT_RISK, { risk, riskClassName });
  },
  changeHighRiskType({ commit }, { riskTypeName, riskCategory }) {
    commit(types.SET_HIGH_RISK_TYPE, { riskTypeName, riskCategory });
  },
  async getProviders({ commit }, argHash) {
    const { stbApiAdapter, store, queryParam } = argHash;

    const apiArgHash = {
      store,
      queryParam
    };
    const providers = await stbApiAdapter.getProviders(apiArgHash);
    commit(types.SET_PROVIDERS, providers);
  },
  async updateProviders({ commit }, argHash) {
    const { stbApiAdapter, store, queryParam, excursionCopyModalHandlingExpected } = argHash;

    const apiArgHash = {
      store,
      queryParam,
      excursionCopyModalHandlingExpected
    };
    const providers = await stbApiAdapter.postProviders(apiArgHash);
    if (typeof providers.data !== 'undefined') {
      commit(types.SET_PROVIDERS, providers.data);
    }
  },
  setExcursionSelectedProviders({ commit }, { categoryName, selectedProviderNames, providers }) {
    commit(types.SET_EXCURSION_SELECTED_PROVIDERS, {
      categoryName,
      selectedProviderNames,
      providers
    });
  },
  async getExcursionList({ commit }, argHash) {
    const { stbApiAdapter, filterParam, store } = argHash;

    const apiArgHash = {
      filterParam,
      store
    };
    const cbResponse = await stbApiAdapter.getExcursionList(apiArgHash);
    commit(types.RESET_EXCURSION_LIST, cbResponse);
  },
  async getExcursions({ commit }, argHash) {
    let { query } = argHash;
    const { stbApiAdapter, store, excursionId, excursionCopyModalHandlingExpected } = argHash;

    const apiArgHash = {
      excursionId,
      query,
      store,
      excursionCopyModalHandlingExpected
    };

    if (!query) {
      query = {};
    }
    const apiParam = JSON.parse(JSON.stringify(query));
    commit(types.SET_API_PARAM, apiParam);

    let studentMedicalConditions = [];

    if (`${excursionId}` === '0') {
      const newExcursion = {
        id: '0',
        metadata: {
          name: '',
          categories: []
        }
      };

      if (query) {
        if (typeof query['excursion-name'] !== 'undefined') {
          newExcursion.metadata.name = query['excursion-name'];
        }

        if (typeof query['vendor-excursion-type'] !== 'undefined') {
          let vendorExcursionTypeCompatible = false;
          const { selectedExcursionTypeLabel } = store.state.common;
          const excursionTypeInfoKeys = Object.keys(excursionTypeInfo);

          for (let i = 0; i < excursionTypeInfoKeys.length; i += 1) {
            const excursionTypeInfoKey = excursionTypeInfoKeys[i];
            const excursionTypeInfoValue = excursionTypeInfo[excursionTypeInfoKey];
            const excursionTypeLabel = excursionTypeInfoValue.label;

            if (selectedExcursionTypeLabel === excursionTypeLabel) {
              vendorExcursionTypeCompatible = true;
              break;
            }
          }

          if (vendorExcursionTypeCompatible) {
            newExcursion.metadata.typeId = selectedExcursionTypeLabel;
          }
        }
      }

      commit(types.SET_EXCURSION, { data: newExcursion });
      commit(types.SET_ACTIVITY_SELECTION_VALID);
    } else {
      const getExcursionsCbResponse = await stbApiAdapter.getExcursions(apiArgHash);
      commit(types.SET_EXCURSION, getExcursionsCbResponse);
      commit(types.SET_EXCURSION_CLIENT, getExcursionsCbResponse?.data?.client?.metadata);
      commit(types.SET_ACTIVITY_SELECTION_VALID);

      const excursionFromCb = getExcursionsCbResponse.data;

      if (excursionFromCb) {
        if (excursionFromCb.computed) {
          const excursionStudentFetchTriggered =
            !!excursionFromCb.computed.excursionStudentFetchTriggered;

          if (excursionStudentFetchTriggered) {
            commit(types.SET_EXCURSION_STUDENT_FETCH_TRIGGERED, excursionStudentFetchTriggered);

            let localExcursionStatus;
            if (excursionFromCb.metadata && excursionFromCb.metadata.status) {
              localExcursionStatus = excursionFromCb.metadata.status;
            }

            const archivedExcursionDetected = excursionStatus.ARCHIVED === localExcursionStatus;
            if (archivedExcursionDetected) {
              const { studentMedicalConditionInfo } = excursionFromCb.metadata;

              if (
                studentMedicalConditionInfo &&
                studentMedicalConditionInfo.mixedMedicalConditionHash
              ) {
                const { mixedMedicalConditionHash } = studentMedicalConditionInfo;
                const mixedMedicalConditionHashKeyList = Object.keys(mixedMedicalConditionHash);

                for (let i = 0; i < mixedMedicalConditionHashKeyList.length; i += 1) {
                  const mixedMedicalConditionHashKey = mixedMedicalConditionHashKeyList[i];
                  const mixedMedicalConditionHashValue =
                    mixedMedicalConditionHash[mixedMedicalConditionHashKey];

                  const studentMedicalCondition = {
                    name: mixedMedicalConditionHashKey,
                    supported: mixedMedicalConditionHashValue.supported
                  };

                  const { orderIndex } = mixedMedicalConditionHashValue;
                  if (typeof orderIndex !== 'undefined' && !studentMedicalConditions[orderIndex]) {
                    studentMedicalConditions[orderIndex] = studentMedicalCondition;
                  } else {
                    studentMedicalConditions.push(studentMedicalCondition);
                  }
                }
              }
            } else {
              const getExcursionStudentMedicalConditionListCbResponse =
                await stbApiAdapter.getExcursionStudentMedicalConditionList(apiArgHash);
              studentMedicalConditions =
                getExcursionStudentMedicalConditionListCbResponse.data.data;
            }
          }
        }
      }
    }

    commit(types.SET_EXCURSION_STUDENT_MEDICAL_CONDITIONS, studentMedicalConditions);
    commit(types.SET_EXCURSION_STUDENT_MEDICAL_CONDITIONS_DISPLAY_READY, true);
  },
  async getClientList({ commit }, argHash) {
    const { stbApiAdapter, filterParam, store } = argHash;

    const apiArgHash = {
      filterParam,
      store
    };
    const cbResponse = await stbApiAdapter.getClientList(apiArgHash);
    commit(types.RESET_CLIENT_LIST, cbResponse);
  },
  // TODO: refactor required for removing crazy injection
  async initClientConfig({ commit }, argHash) {
    const { queryParam, eventPluginCommonAdapter, stbApiAdapter } = argHash;
    const apiArgHash = { queryParam };
    commit(types.AUTHORISATION_SET_IS_INIT_CLIENT_CONFIG_IN_PROGRESS, true, { root: true });
    const cbResponse = await stbApiAdapter.getClientSetups(apiArgHash);
    commit(types.SET_COMPONENT_OPTIONS, { cbResponse, componentOption, eventPluginCommonAdapter });
    commit(types.SESSION_SET_IDP_URL, cbResponse.data.computed.idpUrl, { root: true });
    commit(types.SET_AUTH_SCHEME_API_PARAM, cbResponse);
    commit(types.SET_CLIENT_ACL_ROLE_INFERIORITY_HASH, cbResponse);
    commit(types.AUTHORISATION_SET_IS_INIT_CLIENT_CONFIG_IN_PROGRESS, false, { root: true });
  },
  async getParentClientList({ commit }, argHash) {
    const { stbApiAdapter, filterParam, store } = argHash;

    const apiArgHash = {
      filterParam,
      store
    };
    const cbResponse = await stbApiAdapter.getClientList(apiArgHash);
    if (cbResponse.data) {
      cbResponse.data.key = filterParam.s ? filterParam.s : '';
    }
    commit(types.RESET_PARENT_CLIENT_LIST, cbResponse);
  },
  async getClientClientList({ commit }, argHash) {
    const { stbApiAdapter, filterParam, store } = argHash;

    const apiArgHash = {
      filterParam,
      store
    };

    const cbResponse = await stbApiAdapter.getClientClientList(apiArgHash);
    if (cbResponse.data) {
      cbResponse.data.key = filterParam.s ? filterParam.s : '';
    }
    commit(types.SET_CLIENT_CLIENT_LIST, cbResponse);
  },
  async getMandatoryChecklist({ commit }, argHash) {
    const { stbApiAdapter, store, queryParam } = argHash;
    const frcs = [];
    if (store.state.common.excursionDc.typeId) {
      frcs.unshift([store.state.common.excursionDc.typeId]);
    }

    const apiArgHash = {
      store,
      queryParam,
      frcs
    };
    const response = await stbApiAdapter.getCompleteChecklist(apiArgHash);

    if (response) {
      this.optional = response;
      commit(types.SET_CE_COMMIT_OID, response.ceCommitOid);
      if (response.controls) {
        commit(types.SET_INIT_MANDATORY_CHECKLIST, response.controls);
      }
    }
  },
  async postPredepartureStrategies({ commit }, argHash) {
    const { stbApiAdapter, queryParam, payload, store } = argHash;
    const apiArgHash = {
      queryParam,
      payload,
      store
    };

    if (payload) {
      const response = await stbApiAdapter.postPredepartureStrategies(apiArgHash);

      if (response) {
        commit(types.SET_PREDEPARTURE, response);
      }
    } else {
      commit(types.SET_PREDEPARTURE, []);
    }
  },
  async getClientUserList({ commit }, argHash) {
    const { stbApiAdapter, filterParam, store } = argHash;
    const apiArgHash = {
      filterParam,
      store
    };
    const cbResponse = await stbApiAdapter.getClientUserList(apiArgHash);
    commit(types.RESET_CLIENT_USER_LIST, cbResponse);
  },
  async getUserList({ commit }, argHash) {
    const { stbApiAdapter, filterParam, store } = argHash;
    const apiArgHash = {
      filterParam,
      store
    };
    const cbResponse = await stbApiAdapter.getUserList(apiArgHash);
    commit(types.RESET_USER_LIST, cbResponse);
  },
  async changeActivityRiskCategoryCompleteChecklist({ commit }, argHash) {
    const { stbApiAdapter, store, queryParam, params, risk, resolve } = argHash;

    const result = [];
    if (!risk) {
      resolve();
      return;
    }

    if (params && params.length === 0) {
      commit(types.DELETE_RISK_COMPLETE_CHECKLIST, risk);
      resolve();
      return;
    }

    const apiArgHash = {
      store,
      queryParam,
      frcs: params
    };
    const response = await stbApiAdapter.getCompleteChecklist(apiArgHash);
    result.push({
      checklist: response.optional.find(
        (optionalContent) => optionalContent.title === riskCategoryLabel.ACTIVITIES
      )
    });
    commit(types.SET_CE_COMMIT_OID, response.ceCommitOid);

    // filter completechecklist for the risk
    commit(types.SET_RISK_COMPLETE_CHECKLIST, { risk, result });

    resolve();
  },
  async changeRiskCategoryCompleteChecklist({ commit }, argHash) {
    const { stbApiAdapter, store, queryParam, params, risk, resolve } = argHash;

    const result = [];
    if (params && params.length === 0) {
      commit(types.DELETE_RISK_COMPLETE_CHECKLIST, risk);
    }
    const apiArgHash = {
      store,
      queryParam,
      frcs: params
    };
    const response = await stbApiAdapter.getCompleteChecklist(apiArgHash);
    result.push({
      checklist: response.optional.find((optionalContent) => optionalContent.title === risk.name)
    });
    commit(types.SET_CE_COMMIT_OID, response.ceCommitOid);
    commit(types.SET_RISK_COMPLETE_CHECKLIST, { risk, result });
    resolve();
  },
  async updateExcursionPredepartureStrategies({ commit }, argHash) {
    const { stbApiAdapter, store, queryParam } = argHash;

    const apiArgHash = {
      store,
      queryParam
    };

    const response = await stbApiAdapter.putExcursionPredepartureStrategies(apiArgHash);
    if (response && response.data) {
      commit(types.SET_EXCURSION_PREDEPARTURE_STRATEGIES, response.data);
    }
  },
  toggleClientUserEnabled({ commit }, userId) {
    commit(types.TOGGLE_CLIENT_USER_ENABLED, userId);
  },
  toggleUserEnabled({ commit }, userId) {
    commit(types.TOGGLE_USER_ENABLED, userId);
  },
  setActivitySelectionValid({ commit }) {
    commit(types.SET_ACTIVITY_SELECTION_VALID);
  },
  toggleClientEnabled({ commit }, sid) {
    commit(types.TOGGLE_CLIENT_ENABLED, sid);
  },
  async unapproveExcursion({ commit }, excursionArgs) {
    const { stbApiAdapter, putExcursionStatusParams } = excursionArgs;
    // trigger API request
    const { data: statusResponse } = await stbApiAdapter.putExcursionStatuses(
      putExcursionStatusParams
    );

    if (statusResponse && statusResponse.to) {
      commit(types.SET_EXCURSION_DC_STATUS, statusResponse.to);
    }
  },
  setRiskClassDisabled({ state, commit }, { categoryName, typeName, className, disabled }) {
    const newAllRisks = state.allRisks.map((categoryItem) => {
      if (categoryItem.name !== categoryName) {
        return categoryItem;
      }
      const newTypes = categoryItem.types.map((typeItem) => {
        if (typeItem.name !== typeName) {
          return typeItem;
        }
        const newClasses = typeItem.classes.map((classItem) => {
          if (classItem.name !== className) {
            return classItem;
          }
          return {
            ...classItem,
            disabled
          };
        });
        return {
          ...typeItem,
          classes: newClasses
        };
      });
      return {
        ...categoryItem,
        types: newTypes
      };
    });
    commit(types.SET_ALL_RISKS, newAllRisks);
  },
  setRiskClassRiskDisabled(
    { state, commit },
    { categoryName, typeName, className, riskName, disabled }
  ) {
    const newAllRisks = state.allRisks.map((categoryItem) => {
      if (categoryItem.name !== categoryName) {
        return categoryItem;
      }
      const newTypes = categoryItem.types.map((typeItem) => {
        if (typeItem.name !== typeName) {
          return typeItem;
        }
        const newClasses = typeItem.classes.map((classItem) => {
          if (classItem.name !== className) {
            return classItem;
          }
          const newRisks = classItem.risks.map((riskItem) => {
            if (riskItem.name !== riskName) {
              return riskItem;
            }
            return {
              ...riskItem,
              disabled
            };
          });
          return {
            ...classItem,
            risks: newRisks
          };
        });
        return {
          ...typeItem,
          classes: newClasses
        };
      });
      return {
        ...categoryItem,
        types: newTypes
      };
    });
    commit(types.SET_ALL_RISKS, newAllRisks);
  },
  setExcursionSelectedCategoryTypeClassBoolDialog(
    { state, commit },
    { categoryName, typeName, className, boolDialog }
  ) {
    const newCategories = state.excursionDc.categories.map((categoryItem) => {
      if (categoryItem.name !== categoryName) {
        return categoryItem;
      }
      const newTypes = categoryItem.types.map((typeItem) => {
        if (typeItem.name !== typeName) {
          return typeItem;
        }
        const newClasses = typeItem.classes.map((classItem) => {
          if (classItem.name !== className) {
            return classItem;
          }
          return {
            ...classItem,
            dialog: boolDialog
          };
        });
        return {
          ...typeItem,
          classes: newClasses
        };
      });
      return {
        ...categoryItem,
        types: newTypes
      };
    });
    commit(types.SET_EXCURSION_CATEGORIES, newCategories);
  },
  setExcursionSelectedCategoryTypeClass(
    { state, commit },
    { categoryName, typeName, oldClassName, newClassName }
  ) {
    const newCategories = state.excursionDc.categories.map((categoryItem) => {
      if (categoryItem.name !== categoryName) {
        return categoryItem;
      }
      const newTypes = categoryItem.types.map((typeItem) => {
        if (typeItem.name !== typeName) {
          return typeItem;
        }
        const newClasses = typeItem.classes.map((classItem) => {
          if (classItem.name !== oldClassName) {
            return classItem;
          }
          return {
            ...classItem,
            name: newClassName
          };
        });
        return {
          ...typeItem,
          classes: newClasses
        };
      });
      return {
        ...categoryItem,
        types: newTypes
      };
    });
    commit(types.SET_EXCURSION_CATEGORIES, newCategories);
  },
  setExcursionSelectedCategoryTypeClassRisk(
    { state, commit },
    { categoryName, typeName, className, oldRiskName, newRiskName }
  ) {
    const newCategories = state.excursionDc.categories.map((categoryItem) => {
      if (categoryItem.name !== categoryName) {
        return categoryItem;
      }
      const newTypes = categoryItem.types.map((typeItem) => {
        if (typeItem.name !== typeName) {
          return typeItem;
        }
        const newClasses = typeItem.classes.map((classItem) => {
          if (classItem.name !== className) {
            return classItem;
          }
          const newRisks = classItem.risks.map((riskItem) => {
            if (riskItem.name !== oldRiskName) {
              return riskItem;
            }
            return {
              ...riskItem,
              name: newRiskName
            };
          });
          return {
            ...classItem,
            risks: newRisks
          };
        });
        return {
          ...typeItem,
          classes: newClasses
        };
      });
      return {
        ...categoryItem,
        types: newTypes
      };
    });
    commit(types.SET_EXCURSION_CATEGORIES, newCategories);
  },
  deleteExcursionSelectedCategoryTypeClass(
    { state, commit },
    { categoryName, typeName, className }
  ) {
    const newCategories = state.excursionDc.categories
      .filter((categoryItem) => {
        if (
          categoryItem.name === categoryName &&
          categoryItem.types.length === 1 &&
          categoryItem.types[0].classes.length === 1
        ) {
          return false;
        }
        return true;
      })
      .map((categoryItem) => {
        if (categoryItem.name !== categoryName) {
          return categoryItem;
        }
        const newTypes = categoryItem.types
          .filter((typeItem) => {
            if (typeItem.name === typeName && typeItem.classes.length === 1) {
              return false;
            }
            return true;
          })
          .map((typeItem) => {
            if (typeItem.name !== typeName) {
              return typeItem;
            }
            const newClasses = typeItem.classes.filter((classItem) => classItem.name !== className);
            return {
              ...typeItem,
              classes: newClasses
            };
          });
        return {
          ...categoryItem,
          types: newTypes
        };
      });

    const newAllRisks = state.allRisks.map((allRiskCategory) => {
      if (allRiskCategory.name === categoryName && allRiskCategory.CompleteChecklist) {
        const { CompleteChecklist, ...rest } = allRiskCategory;
        return rest;
      }
      return allRiskCategory;
    });

    commit(types.SET_EXCURSION_CATEGORIES, newCategories);
    commit(types.SET_ALL_RISKS, newAllRisks);
  },
  deleteExcursionSelectedCategoryTypeClassRisk(
    { state, commit },
    { categoryName, typeName, className, riskName }
  ) {
    const newCategories = state.excursionDc.categories
      .map((categoryItem) => {
        if (categoryItem.name !== categoryName) return categoryItem;

        const newTypes = categoryItem.types
          .map((typeItem) => {
            if (typeItem.name !== typeName) return typeItem;

            const newClasses = typeItem.classes
              .map((classItem) => {
                if (classItem.name !== className) return classItem;

                const newRisks = classItem.risks.filter((riskItem) => riskItem.name !== riskName);
                return newRisks.length ? { ...classItem, risks: newRisks } : null;
              })
              .filter(Boolean);

            return newClasses.length ? { ...typeItem, classes: newClasses } : null;
          })
          .filter(Boolean);

        return newTypes.length ? { ...categoryItem, types: newTypes } : null;
      })
      .filter(Boolean);

    commit(types.SET_EXCURSION_CATEGORIES, newCategories);
  },
  setLogoImgUrl({ commit, state }) {
    const imageUrl = state.logoAttachmentUrl || defaultClientLogo;

    const logoContainer = document.getElementsByClassName('client-logo');
    if (logoContainer.length > 0) {
      commit(types.SET_SHOW_EXCURSION_LOGO, false);
      const logoImg = new Image();
      logoImg.src = imageUrl;
      logoImg.onerror = () => {
        commit(types.SET_SHOW_EXCURSION_LOGO, true);
      };
      logoImg.onload = () => {
        const img = document.getElementById('clientLogo');
        img.src = logoImg.src;
        commit(types.SET_SHOW_EXCURSION_LOGO, true);
      };
    }
  }
};
