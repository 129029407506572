export default [
  {
    title: 'Excursions List',
    to: '/excursions',
    router: '/excursions',
    label: 'Home',
    name: 'Excursions'
  },
  {
    title: 'Client List',
    to: '/clients',
    router: '/clients',
    label: 'Home',
    name: 'Clients'
  },
  {
    title: 'Excursion Builder',
    router: '/excursions/builder/new',
    to: '/excursions/builder/new',
    label: 'Excursion Builder',
    name: 'ExcursionBuilder'
  },
  {
    title: 'Create Excursion',
    router: '/excursions/new',
    to: '/excursions/new',
    label: 'Create Excursion',
    name: 'SafeTripBuilder'
  }
];
