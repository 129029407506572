/**
 * These entries are used to assign an identifier to each error case
 * in any 'handler' and/or 'common' file.
 * Each entry may only be referenced by multiple 'handler' and/or 'common' files.
 * These are also used by se-stb-ui to display specific error messages.
 *
 * Linked reference: apiui_0b6244548e2c4401910a138644a738a9
 */
export default {
  CLIENT_ACCESSIBILITY_INVALID: 'CLIENT_ACCESSIBILITY_INVALID',
  CLIENT_SUBDOMAIN_CONFLICTED: 'CLIENT_SUBDOMAIN_CONFLICTED',
  CONTENT_ENGINE_PARAMETER_INVALID: 'CONTENT_ENGINE_PARAMETER_INVALID',
  PROVIDER_PARAMETER_INVALID: 'PROVIDER_PARAMETER_INVALID',
  SUBJECTS_IMPORT_INVALID_DATA_LENGTH: 'SUBJECTS_IMPORT_INVALID_DATA_LENGTH', // Subjects csv import empty file
  SUBJECTS_IMPORT_INVALID_ROW_NUMBER: 'SUBJECTS_IMPORT_INVALID_ROW_NUMBER', // Subjects csv import no valid row
  SUBJECTS_IMPORT_INVALID_COLUMN_LABEL: 'SUBJECTS_IMPORT_INVALID_COLUMN_LABEL', // Subjects csv import Invalid number of column labels
  SUBJECTS_IMPORT_SCHOOL_TYPE_MISSING: 'SUBJECTS_IMPORT_SCHOOL_TYPE_MISSING', // Subjects csv import School Type in the first row is missing
  SUBJECTS_IMPORT_YEAR_MISSING: 'SUBJECTS_IMPORT_YEAR_MISSING', // Subjects csv import Year in the first row is missing
  SUBJECTS_IMPORT_SUBJECTS_MISSING: 'SUBJECTS_IMPORT_SUBJECTS_MISSING', // Subjects csv import Subjects in the first row is missing
  SUBJECTS_IMPORT_COLUMN_MISSING: 'SUBJECTS_IMPORT_COLUMN_MISSING', // Subjects csv import column missing
  SUBJECTS_IMPORT_INVALID_SCHOOL_TYPE_VALUE: 'SUBJECTS_IMPORT_INVALID_SCHOOL_TYPE_VALUE', // Subjects csv import school type can't be undefined or null
  SUBJECTS_IMPORT_INVALID_YEAR_VALUE: 'SUBJECTS_IMPORT_INVALID_YEAR_VALUE', // Subjects csv import year can't be undefined or null
  SUBJECTS_IMPORT_INVALID_CLIENT_SUBJECT_CODE_VALUE:
    'SUBJECTS_IMPORT_INVALID_CLIENT_SUBJECT_CODE_VALUE', // Subjects csv import client subject code can't be undefined or null
  SUBJECTS_IMPORT_INVALID_SUBJECTS_VALUE: 'SUBJECTS_IMPORT_INVALID_SUBJECTS_VALUE', // Subjects csv import subjects can't be undefined or null
  VENDOR_API_CREDENTIALS_INVALID: 'VENDOR_API_CREDENTIALS_INVALID', // TASS API credentials
  VENDOR_API_DATA_TRANSFER_ABORTED: 'VENDOR_API_DATA_TRANSFER_ABORTED',
  VENDOR_API_ENDPOINT_INVALID: 'VENDOR_API_ENDPOINT_INVALID', // TASS API endpoint
  VENDOR_API_ENDPOINT_SSL_CERTIFICATE_EXPIRED: 'VENDOR_API_ENDPOINT_SSL_CERTIFICATE_EXPIRED',
  VENDOR_API_KEY_INVALID: 'VENDOR_API_KEY_INVALID', // Caremonkey API key
  VENDOR_CLIENT_SID_CONFLICTED: 'VENDOR_CLIENT_SID_CONFLICTED',
  VENDOR_ORGANIZATION_ID_INVALID: 'VENDOR_ORGANIZATION_ID_INVALID', // Caremonkey organization ID
  VENDOR_TOKEN_KEY_INVALID: 'VENDOR_TOKEN_KEY_INVALID', // TASS token key
  VENDOR_SERVICE_REQUEST_CREDENTIALS_INVALID: 'VENDOR_SERVICE_REQUEST_CREDENTIALS_INVALID',
  VENDOR_SERVICE_RESPONSE_PREPARED_TIMEOUT: 'VENDOR_SERVICE_RESPONSE_PREPARED_TIMEOUT',
  VENDOR_SERVICE_RESPONSE_UNKNOWN: 'VENDOR_SERVICE_RESPONSE_UNKNOWN'
};
