/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */

import omit from 'lodash/omit';
import extend from 'lodash/extend';
import moment from 'moment-timezone';
import store2 from 'store2';
import * as types from '../../mutationTypes';

import attachmentCategory from '../../../lib/const/attachmentCategory';
import dbEntityRelation from '../../../lib/const/dbEntityRelation';
import excursionStatus from '../../../lib/const/excursionStatus';
import riskCategoryLabel from '../../../lib/const/riskCategoryLabel';

export default {
  [types.SET_RISKS](state, risksFromApi) {
    if (!risksFromApi || risksFromApi.error) {
      return;
    }

    state.allRisks = [];

    risksFromApi.forEach((elementRisk, riskId) => {
      const existRiskCategory = state.allRisks.find((r) => r.name === elementRisk[1]);
      if (existRiskCategory != null) {
        return;
      }

      const riskCategory = {
        name: elementRisk[1],
        displayed: true,
        types: []
      };

      state.allRisks.push(riskCategory);
    });

    state.allRisks.forEach((riskCategory) => {
      const riskTypes = risksFromApi.filter((r) => r[1] === riskCategory.name);

      riskTypes.forEach((riskTypeItem) => {
        if (riskCategory.types.find((t) => t.name === riskTypeItem[2]) != null) {
          return;
        }
        riskCategory.types.push({
          name: riskTypeItem[2],
          classes: []
        });

        riskCategory.types.forEach((riskType) => {
          const riskClasses = risksFromApi.filter((r) => r[2] === riskType.name);

          riskClasses.forEach((riskClass) => {
            if (riskType.classes.find((c) => c.name === riskClass[3]) != null) {
              return;
            }

            const elementRiskClass = {
              name: riskClass[3],
              risks: [],
              disabled: false
            };

            const risks = risksFromApi.filter((r) => r[3] === elementRiskClass.name);

            risks.forEach((risk, riskIndex) => {
              if (risk === '') {
                return;
              }

              elementRiskClass.risks.push({
                name: risk[4],
                disabled: false
              });
            });

            riskType.classes.push(elementRiskClass);
          });
        });
      });
    });

    // set selected category person responsible
    if (
      state.allRisks &&
      state.allRisks.length > 0 &&
      state.excursionDc.categories &&
      state.excursionDc.categories.length > 0
    ) {
      state.allRisks.forEach((item) => {
        const category = state.excursionDc.categories.find((c) => {
          return c.name === item.name;
        });

        if (category) {
          item.personResponsible = category.personResponsible;
          item.additionalRiskControlMitigationStrategies =
            category.additionalRiskControlMitigationStrategies;
        }
      });
    }

    state.allRisksInitialized = true;
  },
  [types.RESET_RISKS_DISABLED](state) {
    const metaExcursion = state.excursionDc.categories;
    if (!metaExcursion) {
      return;
    }
    const { allRisks } = state;
    metaExcursion.forEach((category) => {
      if (category.name !== riskCategoryLabel.ACTIVITIES) {
        category.types.forEach((type) => {
          type.classes.forEach((elementClass) => {
            const setTypes = allRisks.find((r) => r.name === category.name);
            if (setTypes && setTypes.types) {
              const setClasses = setTypes.types.find((t) => t.name === type.name);
              if (setClasses && setClasses.classes) {
                const setElementClass = setClasses.classes.find(
                  (c) => c.name === elementClass.name
                );
                if (setElementClass) {
                  setElementClass.disabled = true;
                }
              }
            }
          });
        });
      } else {
        category.types.forEach((type) => {
          type.classes.forEach((elementClass) => {
            if (elementClass.risks) {
              elementClass.risks.forEach((risk) => {
                const setTypes = allRisks.find((r) => r.name === category.name);
                if (setTypes && setTypes.types) {
                  const setClasses = setTypes.types.find((t) => t.name === type.name);
                  if (setClasses && setClasses.classes) {
                    const setRiskClass = setClasses.classes.find(
                      (c) => c.name === elementClass.name
                    );
                    if (setRiskClass && setRiskClass.risks) {
                      const setRisk = setRiskClass.risks.find((r) => r.name === risk.name);
                      if (setRisk) {
                        setRisk.disabled = true;
                      }
                    }
                  }
                }
              });
            }
          });
        });
      }
    });
  },
  [types.SET_SELECT_RISK_CLASS](state, { riskClass, riskTypeName, riskCategory }) {
    // query riskType from risks
    const selectCategory = state.allRisks.find((risk) => risk.name === riskCategory.name);
    let selectType = {};
    selectCategory.types.forEach((type) => {
      if (type.name === riskTypeName) {
        selectType = {
          name: type.name
        };

        // set disabled
        type.classes.forEach((elementClass) => {
          if (elementClass.name === riskClass.name) {
            elementClass.disabled = true;
          }
          return false;
        });
        return false;
      }
      return false;
    });

    // add category
    const existCategory = state.excursionDc.categories.find((r) => r.name === riskCategory.name);
    if (existCategory == null) {
      state.excursionDc.categories.push({
        name: selectCategory.name,
        types: [
          {
            name: selectType.name,
            classes: [
              {
                name: riskClass.name,
                dialog: false
              }
            ]
          }
        ]
      });
    } else {
      const existType = existCategory.types.find((c) => c.name === selectType.name);
      if (existType == null) {
        existCategory.types.push({
          name: selectType.name,
          classes: [
            {
              name: riskClass.name,
              dialog: false
            }
          ]
        });
      } else {
        existType.classes.push({
          name: riskClass.name,
          dialog: false
        });
      }
    }
  },
  [types.SET_ACTIVITY_SELECT_RISK](state, { risk, riskClassName }) {
    const selectedRisks = state.excursionDc.categories.find(
      (c) => c.name === riskCategoryLabel.ACTIVITIES
    );
    const names = riskClassName.split(',');
    const typeName = names[1];
    const className = names[0];
    const selectType = state.allRisks
      .find((c) => c.name === riskCategoryLabel.ACTIVITIES)
      .types.find((t) => t.name === typeName);
    const selectClass = selectType.classes.find((c) => c.name === className);
    const selectRisk = selectClass.risks.find((r) => r.name === risk.name);

    selectRisk.disabled = true;
    if (selectedRisks == null) {
      state.excursionDc.categories.push({
        name: riskCategoryLabel.ACTIVITIES,
        types: [
          {
            name: selectType.name,
            classes: [
              {
                name: selectClass.name,
                dialog: false,
                risks: [
                  {
                    name: selectRisk.name,
                    dialog: false
                  }
                ]
              }
            ]
          }
        ]
      });
    } else {
      const existType = selectedRisks.types.find((t) => t.name === selectType.name);
      if (existType == null) {
        selectedRisks.types.push({
          name: selectType.name,
          classes: [
            {
              name: selectClass.name,
              dialog: false,
              risks: [
                {
                  name: selectRisk.name,
                  dialog: false
                }
              ]
            }
          ]
        });
      } else {
        const existClass = selectedRisks.types
          .find((t) => t.name === selectType.name)
          .classes.find((c) => c.name === selectClass.name);
        if (existClass == null) {
          existType.classes.push({
            name: selectClass.name,
            dialog: false,
            risks: [
              {
                name: selectRisk.name,
                dialog: false
              }
            ]
          });
        } else {
          if (!existClass.risks) {
            existClass.risks = [];
          }
          existClass.risks.push({
            name: risk.name,
            dialog: false
          });
        }
      }
    }
  },
  [types.SET_HIGH_RISK_TYPE](state, { riskTypeName, riskCategory }) {
    // query riskType from risks
    const selectCategory = state.allRisks.find((risk) => risk.name === riskCategory.name);

    const type = selectCategory.types.find((t) => t.name === riskTypeName);
    type.disabled = true;

    // add category
    const existCategory = state.excursionDc.categories.find((r) => r.name === riskCategory.name);
    if (existCategory == null) {
      state.excursionDc.categories.push({
        name: selectCategory.name,
        types: [
          {
            name: riskTypeName
          }
        ]
      });
    } else {
      const existType = existCategory.types.find((c) => c.name === riskTypeName);
      if (existType == null) {
        existCategory.types.push({
          name: riskTypeName
        });
      }
    }
  },
  [types.SET_EXCURSION_NAME](state, name) {
    state.excursionDc.name = name;
  },
  [types.SET_EXCURSION_RISK_ASSESSMENT_NOTES](state, riskAssessmentNotes) {
    state.excursionDc.riskAssessmentNotes = riskAssessmentNotes;
  },
  [types.SET_RISK_MATRIX_SELF_DEFINED_DETECTED](state, riskMatrixSelfDefinedDetected) {
    state.excursionDc.riskMatrixSelfDefinedDetected = riskMatrixSelfDefinedDetected;
  },
  [types.SET_EXCURSION_RISK_MATRIX](state, riskMatrix) {
    state.excursionDc.riskMatrix = riskMatrix;
  },
  [types.SET_CLIENT_SETTING_RISK_MATRIX](state, riskMatrix) {
    state.clientSettingRiskMatrix = riskMatrix;
  },
  [types.SET_RISK_MATRIX_LIKELIHOOD](state, likelihood) {
    state.clientSettingRiskMatrix.likelihood = likelihood;
  },
  [types.SET_RISK_MATRIX_CONSEQUENCE](state, consequence) {
    state.clientSettingRiskMatrix.consequence = consequence;
  },
  [types.SET_CLIENT_SETTING_RISK_MATRIX_RISK_RATING](state, riskRating) {
    state.clientSettingRiskMatrix.riskRating = riskRating;
  },
  [types.SET_STAFF_TO_STUDENT_GENDER_RATIO_INFO](state, staffToStudentGenderRatioInfo) {
    state.excursionDc.staffToStudentGenderRatioInfo = staffToStudentGenderRatioInfo;
  },
  [types.SET_EXCURSION_STUDENT_INFO](state, studentInfo) {
    state.excursionDc.studentInfo = studentInfo;
  },
  [types.SET_STUDENT_ATTENDANCE_COUNT](state, studentAttendanceCount) {
    state.excursionDc.studentAttendanceCount = studentAttendanceCount;
  },
  [types.SET_MANDATORY_CHECKLIST_BATCH_CONFIRMATION_AGREEMENT](
    state,
    mandatoryChecklistBatchConfirmationAgreement
  ) {
    state.excursionDc.mandatoryChecklistBatchConfirmationAgreement =
      mandatoryChecklistBatchConfirmationAgreement;
  },
  [types.SET_EXCURSION_STEP](state, currentStep) {
    state.excursionDc.currentStep = currentStep;
  },
  [types.SET_VENDOR_EXCURSION_ID](state, vendorExcursionSid) {
    state.excursionDc.vendorData.vendorExcursionSid = vendorExcursionSid;
  },
  [types.SET_EXCURSION_TYPE](state, type) {
    state.excursionDc.typeId = type;
  },
  [types.SET_EXCURSION_JURISDICTION](state, jurisdiction) {
    state.excursionDc.jurisdiction = jurisdiction;
  },
  [types.SET_EXCURSION_START_DATE](state, startDate) {
    state.excursionDc.startDate = startDate;
  },
  [types.SET_EXCURSION_END_DATE](state, endDate) {
    state.excursionDc.endDate = endDate;
  },
  [types.SET_CLEAR_CREATE_RISK_ASSESSMENT](state, clearCreateRiskAssessment) {
    state.clearCreateRiskAssessment = clearCreateRiskAssessment;
  },
  [types.SET_SELECTED_ACTIVITY_TYPE_FILTER](state, filter) {
    state.selectedActivityTypeFilter = filter;
  },
  [types.SET_SELECTED_ACTIVITY_CLASS_FILTER](state, filter) {
    state.selectedActivityClassFilter = filter;
  },
  [types.SET_SELECTED_LIKELIHOOD](state, selectedLikelihood) {
    state.excursionDc.selectedLikelihood = selectedLikelihood;
  },
  [types.SET_SELECTED_CONSEQUENCE](state, selectedConsequence) {
    state.excursionDc.selectedConsequence = selectedConsequence;
  },
  [types.SET_SELECTED_RATING](state, selectedRating) {
    state.excursionDc.selectedRating = selectedRating;
  },
  [types.SET_ADDITIONAL_INFORMATIONS](state, additionalInformationItems) {
    state.excursionDc.additionalInformationItems = additionalInformationItems;
  },
  [types.SET_SHOW_EXCURSION_STEPPER](state, showExcursionStepper) {
    state.showExcursionStepper = showExcursionStepper;
  },
  [types.SET_PREV_ROUTE_NAME](state, prevRouteName) {
    state.prevRouteName = prevRouteName;
  },
  [types.SET_SHOW_EXCURSION_LOGO](state, isReady) {
    state.excursionLogoDisplayReady = isReady;
  },
  [types.SET_EXCURSION_CONFIRMATION](state, agreementConfirmation) {
    state.excursionDc.agreementConfirmation = agreementConfirmation;
  },
  [types.SET_MANDATORY_LOADING_COMPLETED](state, mandatoryLoadActionCompleted) {
    state.mandatoryLoadActionCompleted = mandatoryLoadActionCompleted;
  },
  [types.RESET_CATEGORIES](state) {
    state.excursionDc.categories = [];
  },
  [types.RESET_PARENT_CLIENT](state) {
    state.parentClientList.loading = false;
    state.parentClientList.items = [];
  },
  [types.SET_PARENT_CLIENT_ITEMS](state, items) {
    state.parentClientList.items = items;
  },
  [types.SET_EXCURSION_TYPE_CHOICE_LIST](state, excursionTypeChoiceList) {
    state.excursionTypeChoiceList = excursionTypeChoiceList;
  },
  [types.SET_DEFAULT_JURISDICTION](state, jurisdiction) {
    state.apiDataDefaultJurisdiction = jurisdiction;
  },
  [types.RESET_CLIENT_CLIENT_LIST](state) {
    state.clientClientList.loading = false;
    state.clientClientList.items = [];
  },
  [types.SET_CLIENT_CLIENT_LIST_ITEMS](state, items) {
    state.clientClientList.items = items;
  },
  [types.SET_PROVIDERS](state, providers) {
    const allProviders = [];
    state.allProvidersInitialized = true;
    providers.forEach((provider) => {
      allProviders.push({
        id: provider['Provider - Key'],
        name: provider['Provider - Name'],
        type: provider['Services Provided'],
        answer: provider['Provider - Answer']
      });
    });
    state.allProviders = allProviders;
  },
  [types.SET_MANDATORY_CHECKLIST](state, payload) {
    if (state.excursionDc.mandatoryChecklist && state.excursionDc.mandatoryChecklist.length > 0) {
      state.excursionDc.mandatoryChecklist = state.excursionDc.mandatoryChecklist.map((item) => {
        if (item.title === payload.title) {
          item.Strategy.data[Object.keys(item.Strategy.data)].children = payload.checklist;
        }
        return item;
      });
    }
  },
  [types.RESET_EXCURSION_STORE_STATE](state, argHash) {
    const { storeModuleName } = argHash;

    if (typeof storeModuleName !== 'undefined' && storeModuleName !== '') {
      state[storeModuleName].allRisks = [];
      state[storeModuleName].excursionDc.categories = [];
      state[storeModuleName].excursionDc.mandatoryChecklist = [];
      state[storeModuleName].excursionDc.additionalInformationItems = [];
      state[storeModuleName].excursionDc.additionalInformationNotes = [];
      state[storeModuleName].excursionDc.status = 'draft';
      state[storeModuleName].excursionDc.currentStep = 1;
      state[storeModuleName].excursionDc.agreementConfirmation = {
        ticked: false,
        statement1: '',
        statement2: ''
      };
      state[storeModuleName].excursionDc.name = '';
      state[storeModuleName].excursionDc.typeId = '';
      state[storeModuleName].excursionDc.riskOverall = {};
      state[storeModuleName].excursionDc.riskCategories = [];
      state[storeModuleName].excursionDc.clientTemplate = {};
      state[storeModuleName].excursionDc.staffSupervisorAttendanceCount = '';
      state[storeModuleName].excursionDc.studentAttendanceCount = '';

      if (
        state[storeModuleName].vendorExcursionSidInputEnabled &&
        state[storeModuleName].excursionDc.vendorData &&
        state[storeModuleName].excursionDc.vendorData.vendorExcursionSid
      ) {
        state[storeModuleName].excursionDc.vendorData.vendorExcursionSid = '';
      }

      state[storeModuleName].excursionDc.studentInfo = null;
    } else {
      state.allRisks = [];
      state.excursionDc.categories = [];
      state.excursionDc.mandatoryChecklist = [];
      state.excursionDc.additionalInformationItems = [];
      state.excursionDc.additionalInformationNotes = [];
      state.excursionDc.status = 'draft';
      state.excursionDc.currentStep = 1;
      state.excursionDc.agreementConfirmation = {
        ticked: false,
        statement1: '',
        statement2: ''
      };
      state.excursionDc.name = '';
      state.excursionDc.typeId = '';
      state.excursionDc.riskOverall = {};
      state.excursionDc.riskCategories = [];
      state.excursionDc.clientTemplate = {};
      state.excursionDc.staffSupervisorAttendanceCount = '';
      state.excursionDc.studentAttendanceCount = '';
      state.excursionDc.riskMatrix = {};
      state.excursionDc.riskOverallUserRating = {};
      state.excursionDc.selectedLikelihood = '';
      state.excursionDc.selectedConsequence = '';
      state.excursionDc.selectedRating = '';
      if (
        state.vendorExcursionSidInputEnabled &&
        state.excursionDc.vendorData &&
        state.excursionDc.vendorData.vendorExcursionSid
      ) {
        state.excursionDc.vendorData.vendorExcursionSid = '';
      }

      state.excursionDc.studentInfo = null;
    }
  },
  [types.SET_EXCURSION](state, cbResponse) {
    state.excursionInitialized = true;
    const defaultExcursion = {
      id: 0,
      metadata: {
        name: '',
        categories: [],
        vendorData: {}
      }
    };
    const excursionFromCb =
      typeof cbResponse.data !== 'undefined' ? cbResponse.data : defaultExcursion;

    state.excursionDc.id = excursionFromCb.id;
    state.excursionDc.created_at = excursionFromCb.created_at;
    state.excursionDc.name = excursionFromCb.metadata.name;
    state.excursionDc.currentStep = excursionFromCb.metadata.currentStep;
    state.excursionDc.excursionDestinationContentEnabled =
      excursionFromCb.metadata.excursionDestinationContentEnabled;
    state.excursionDc.typeId = excursionFromCb.metadata.typeId;
    state.excursionDc.jurisdiction = excursionFromCb.metadata.jurisdiction;
    state.excursionDc.schemaVersion = excursionFromCb.metadata.schemaVersion;
    state.originalTypeId = excursionFromCb.metadata.typeId;
    state.excursionDc.categories = excursionFromCb.metadata.categories
      ? excursionFromCb.metadata.categories
      : [];
    state.excursionDc.status = excursionFromCb.metadata.status
      ? excursionFromCb.metadata.status
      : excursionStatus.DRAFT;
    state.excursionDc.mandatoryChecklist = excursionFromCb.metadata.mandatoryChecklist
      ? excursionFromCb.metadata.mandatoryChecklist
      : [];
    state.excursionDc.updateSignature = excursionFromCb.metadata.updateSignature;
    state.excursionDc.vendorData.vendorExcursionSid =
      excursionFromCb.metadata.vendorData && excursionFromCb.metadata.vendorData.vendorExcursionSid
        ? excursionFromCb.metadata.vendorData.vendorExcursionSid
        : '';
    state.showError = cbResponse.error;

    state.showExcursionStepper =
      `${state.excursionDc.id}` === '0' || state.excursionDc.status === 'draft';
    state.excursionDc.agreementConfirmation = excursionFromCb.metadata.agreementConfirmation;

    let additionalInformationItems = [];
    if (
      excursionFromCb.metadata.additionalInformationNotes &&
      excursionFromCb.metadata.additionalInformationNotes.length > 0
    ) {
      const { additionalInformationNotes } = excursionFromCb.metadata;
      state.excursionDc.additionalInformationNotes = JSON.parse(
        JSON.stringify(additionalInformationNotes)
      );
      additionalInformationNotes.forEach((item) => {
        const noteItem = {
          orderId: item.orderId
        };
        const fileList = excursionFromCb[dbEntityRelation.ATTACHMENT_LIST];
        let fileFound;
        if (fileList && fileList.length > 0) {
          fileFound = fileList.find((file) => !file.deleted && file.sid === item.sid);
          if (!fileFound) {
            fileFound = fileList.find(
              (file) => !file.deleted && file.metadata.orderId === item.orderId
            );
          }
          if (fileFound) {
            const file = fileFound.metadata;
            noteItem.sid = fileFound.sid;
            noteItem.filename = file.filename;
            noteItem.comment = file.description;
            noteItem.fileSummary = file.summary;
            if (moment(fileFound.created_at).isValid()) {
              noteItem.fileUpdateDate = moment(fileFound.created_at);
            }
          }
        }

        if (!fileFound) {
          noteItem.notes = item.notes;
          noteItem.fileSummary = item.fileSummary;
        }
        additionalInformationItems.push(noteItem);
      });

      additionalInformationItems = additionalInformationItems.sort((a, b) => {
        return a.orderId - b.orderId;
      });
    }
    state.excursionDc.additionalInformationItems = additionalInformationItems;

    if (excursionFromCb.metadata.riskOverall) {
      state.excursionDc.riskOverall = excursionFromCb.metadata.riskOverall;
    }
    if (excursionFromCb.metadata.riskCategories) {
      state.excursionDc.riskCategories = excursionFromCb.metadata.riskCategories;
    }
    if (excursionFromCb.metadata.predepartures) {
      state.excursionDc.predepartures = excursionFromCb.metadata.predepartures;
    }

    state.excursionDc.predepartureChecklistCompleted = excursionFromCb.metadata
      .predepartureChecklistCompleted
      ? excursionFromCb.metadata.predepartureChecklistCompleted
      : false;

    state.excursionDc.staffSupervisorAttendanceCount =
      excursionFromCb.metadata.staffSupervisorAttendanceCount;
    state.excursionDc.studentAttendanceCount = excursionFromCb.metadata.studentAttendanceCount;
    state.excursionDc.syncAvailable = !!excursionFromCb.metadata.syncAvailable;
    state.excursionDc.excursionTemplateFlag = !!excursionFromCb.metadata.excursionTemplateFlag;

    if (
      excursionFromCb.client &&
      excursionFromCb.client.attachmentList &&
      excursionFromCb.client.attachmentList.length > 0
    ) {
      const clientAttachments = excursionFromCb.client.attachmentList;
      const clientTemplateFile = clientAttachments.filter(
        (a) => !a.deleted && attachmentCategory.PROFORMA_TEMPLATE === a.metadata.category
      );
      if (clientTemplateFile.length > 0) {
        state.excursionDc.clientTemplate = {
          sid: clientTemplateFile[0].sid,
          filename: clientTemplateFile[0].metadata.filename
        };
      }
    }

    state.excursionDc.excursionCopyFlag = !!excursionFromCb.metadata.excursionCopyFlag;
    state.excursionDc.excursionUserRiskRatingEnabled =
      !!excursionFromCb.metadata.excursionUserRiskRatingEnabled;
    state.excursionDc.riskAssessmentNotesEnabled =
      !!excursionFromCb.metadata.riskAssessmentNotesEnabled;
    state.excursionDc.additionalRiskControlMitigationStrategiesEnabled =
      !!excursionFromCb.metadata.additionalRiskControlMitigationStrategiesEnabled;
    state.excursionDc.startDate = excursionFromCb.metadata.startDate;
    state.excursionDc.endDate = excursionFromCb.metadata.endDate;
    state.staffToStudentGenderRatioInfoEnabled =
      !!excursionFromCb.metadata.staffToStudentGenderRatioInfoEnabled;
    state.mandatoryChecklistBatchConfirmationAgreementEnabled =
      !!excursionFromCb.metadata.mandatoryChecklistBatchConfirmationAgreementEnabled;
    state.excursionStartEndDateSetEnabled =
      !!excursionFromCb.metadata.excursionStartEndDateSetEnabled;

    state.excursionDisplayReady = true;

    if (excursionFromCb.metadata.riskOverallUserRating) {
      state.excursionDc.riskOverallUserRating = {
        ...excursionFromCb.metadata.riskOverallUserRating
      };
      state.excursionDc.selectedLikelihood =
        excursionFromCb.metadata.riskOverallUserRating.likelihood;
      state.excursionDc.selectedConsequence =
        excursionFromCb.metadata.riskOverallUserRating.consequence;
      state.excursionDc.selectedRating = excursionFromCb.metadata.riskOverallUserRating.rating;
    }
    if (excursionFromCb.metadata.riskAssessmentNotes) {
      state.excursionDc.riskAssessmentNotes = excursionFromCb.metadata.riskAssessmentNotes;
    }

    if (excursionFromCb.metadata.staffToStudentGenderRatioInfo) {
      state.excursionDc.staffToStudentGenderRatioInfo =
        excursionFromCb.metadata.staffToStudentGenderRatioInfo;
    }

    if (excursionFromCb.metadata.mandatoryChecklistBatchConfirmationAgreement) {
      state.excursionDc.mandatoryChecklistBatchConfirmationAgreement =
        excursionFromCb.metadata.mandatoryChecklistBatchConfirmationAgreement;
    }

    if (excursionFromCb.metadata.studentMedicalConditionInfo) {
      state.excursionDc.studentMedicalConditionInfo =
        excursionFromCb.metadata.studentMedicalConditionInfo;
    }

    if (excursionFromCb.metadata.riskMatrix) {
      state.excursionDc.riskMatrix = excursionFromCb.metadata.riskMatrix;
    }

    if (excursionStatus.ARCHIVED === state.excursionDc.status) {
      const allRisks = JSON.parse(JSON.stringify(excursionFromCb.metadata.categories));
      allRisks.forEach((item) => {
        item.displayed = true;
      });
      state.allRisks = allRisks;
      state.allRisksInitialized = true;
    }

    state.excursionDc.studentInfo = excursionFromCb.metadata.studentInfo;
  },
  [types.SET_EXCURSION_CLIENT](state, excursionClientMetadata) {
    state.excursionDc.client.excursionAdditionalInformationEnabled =
      excursionClientMetadata?.excursionAdditionalInformationEnabled ?? false;
    state.excursionDc.client.excursionHighRiskStudentMedicalEnabled =
      excursionClientMetadata?.excursionHighRiskStudentMedicalEnabled ?? false;
  },
  [types.SET_EXCURSION_STUDENT_FETCH_TRIGGERED](state, excursionStudentFetchTriggered) {
    state.excursionStudentFetchTriggered = excursionStudentFetchTriggered;
  },
  [types.SET_EXCURSION_STUDENT_MEDICAL_CONDITIONS](state, studentMedicalConditions) {
    state.excursionDc.computed.studentMedicalConditions = studentMedicalConditions;

    // Convert a list into a hash for better record indexing
    const mixedMedicalConditionHash = {};
    for (let i = 0; i < studentMedicalConditions.length; i += 1) {
      const studentMedicalCondition = studentMedicalConditions[i];
      const mixedMedicalConditionHashKey = studentMedicalCondition.name;

      if (mixedMedicalConditionHashKey) {
        const mixedMedicalConditionHashValue = {
          supported: studentMedicalCondition.supported,
          orderIndex: i
        };
        mixedMedicalConditionHash[mixedMedicalConditionHashKey] = mixedMedicalConditionHashValue;
      }
    }
    state.mixedMedicalConditionHash = mixedMedicalConditionHash;
  },
  [types.SET_EXCURSION_STUDENT_MEDICAL_CONDITIONS_DISPLAY_READY](
    state,
    excursionStudentMedicalConditionsDisplayReady
  ) {
    state.excursionStudentMedicalConditionsDisplayReady =
      excursionStudentMedicalConditionsDisplayReady;
  },
  [types.SET_COMPONENT_OPTIONS](state, { cbResponse, componentOption, eventPluginCommonAdapter }) {
    componentOption.setComponentOptions(state, cbResponse, eventPluginCommonAdapter);
  },
  [types.SET_EXCURSION_SELECTED_PROVIDERS](
    state,
    { categoryName, selectedProviderNames, providers }
  ) {
    const { categories } = state.excursionDc;
    let riskCategory = categories.find((c) => c.name === categoryName);

    if (!riskCategory) {
      const category = state.allRisks.find((c) => c.name === categoryName);
      riskCategory = {
        id: category.id,
        name: categoryName,
        types: []
      };
      categories.push(riskCategory);
    }

    const selectedProviders = [];
    if (selectedProviderNames && selectedProviderNames.length > 0) {
      selectedProviderNames.forEach((name) => {
        const provider = providers.find((p) => p.name === name);
        if (provider) {
          selectedProviders.push(provider);
        }
      });
    }

    riskCategory.selectedProviders = selectedProviders;

    if (selectedProviders.length === 0) {
      if (categoryName === 'Outdoor Education Providers') {
        categories.splice(categories.indexOf(riskCategory), 1);
      } else if (riskCategory.types && riskCategory.types.length === 0) {
        categories.splice(categories.indexOf(riskCategory), 1);
      }
    }

    // Let the store know the category have been changed
    categories.push({});
    categories.splice(categories.length - 1, 1);
  },
  [types.RESET_EXCURSION_LIST](state, payload) {
    const excursionList = payload.data;
    if (excursionList && excursionList.data.length > 0) {
      excursionList.data.map((item) => {
        if (item.created_at) {
          const createdTime = new Date(item.created_at);
          if (`${createdTime}` !== 'Invalid Date') {
            item.created_at = createdTime.toLocaleString();
            item.created_time = createdTime.getTime();
          }
        }
        return item;
      });

      state.excursionsList.data = excursionList.data;
      state.excursionsList.total = excursionList.total;
    } else {
      state.excursionsList.data = [];
      state.excursionsList.total = 0;
    }

    state.excursionsList.loading = false;
    state.showError = payload.error;
  },
  [types.RESET_CLIENT_LIST](state, payload) {
    const clientList = payload.data;
    if (clientList && clientList.data.length > 0) {
      clientList.data.map((item) => {
        if (item.created_at) {
          const createdTime = new Date(item.created_at);
          if (`${createdTime}` !== 'Invalid Date') {
            item.created_at = createdTime.toLocaleString();
            item.created_time = createdTime.getTime();
          }
        }
        return item;
      });
      state.clientList.data = clientList.data;
      state.clientList.total = clientList.total;
    } else {
      state.clientList.data = [];
      state.clientList.total = 0;
    }
    state.showError = payload.error;
    state.isGetClientList = true;
    state.clientList.loading = false;
  },
  [types.RESET_PARENT_CLIENT_LIST](state, payload) {
    const clientList = payload.data;
    if (clientList && clientList.data.length > 0) {
      state.parentClientList.items = clientList.data.map((item) => {
        return {
          sid: item.sid,
          name: `${item.sid} - ${item.metadata.name}`
        };
      });
    } else {
      state.parentClientList.items = [];
    }

    if (clientList) {
      state.parentClientList.itemsCache.push({
        key: clientList.key,
        items: state.parentClientList.items
      });
    }

    state.parentClientList.loading = false;
  },
  [types.SET_CLIENT_CLIENT_LIST](state, payload) {
    const clientList = payload.data;
    if (clientList && clientList.data.length > 0) {
      state.clientClientList.items = clientList.data.map((item) => {
        return {
          sid: item.sid,
          name: `${item.sid} - ${item.metadata.name}`
        };
      });
    } else {
      state.clientClientList.items = [];
    }

    if (clientList) {
      state.clientClientList.itemsCache.push({
        key: clientList.key,
        items: state.clientClientList.items
      });
    }

    state.clientClientList.loading = false;
  },
  [types.SET_IS_RESPONSE_WITH_ERROR](state, isResponseWithError) {
    state.isResponseWithError = isResponseWithError;
  },
  [types.SET_API_ERROR_MESSAGE](state, errorMessage) {
    state.apiErrorMessage = errorMessage;
  },
  [types.SET_INIT_MANDATORY_CHECKLIST](state, mandatoryChecklist) {
    mandatoryChecklist.forEach((arrayItem) => {
      if (arrayItem.Strategy && arrayItem.Strategy.data) {
        const strategyDataKeys = Object.keys(arrayItem.Strategy.data);
        const firstStrategyDataKey = strategyDataKeys[0];
        const firstStrategyDataValue = arrayItem.Strategy.data[firstStrategyDataKey];

        if (firstStrategyDataValue) {
          const items = firstStrategyDataValue.children;
          items.forEach((children) => {
            if (typeof children.children !== 'undefined') {
              children.children.forEach((option) => {
                if (option.contentType === 'checkbox') {
                  option.ticked = false;
                }
              });
            }
          });
        }
      }
    });

    state.excursionDc.mandatoryChecklist = mandatoryChecklist;
    state.mandatoryLoadActionCompleted = false;
  },
  [types.RESET_ALL_STATE](state, excludedAttributeList = []) {
    if (excludedAttributeList instanceof Array === false) {
      return;
    }

    const initState = store2('initState');
    const _initState = excludedAttributeList.length
      ? omit(initState, excludedAttributeList)
      : initState;
    extend(state, _initState);
  },
  [types.RESET_STORE_MODULE_STATE](state, argHash) {
    const { excludedAttributeList, storeModuleName } = argHash;

    if (excludedAttributeList instanceof Array === false) {
      return;
    }

    const initState = store2('initState');
    if (typeof storeModuleName !== 'undefined' && storeModuleName !== '') {
      const override = excludedAttributeList.length
        ? omit(initState[storeModuleName], excludedAttributeList)
        : initState[storeModuleName];
      extend(state[storeModuleName], override);
    } else {
      const override = excludedAttributeList.length
        ? omit(initState.common, excludedAttributeList)
        : initState;
      extend(state, override);
    }
  },
  [types.SET_PERSON_RESPONSIBLE](state, payload) {
    if (state.excursionDc.categories && state.excursionDc.categories.length > 0) {
      state.excursionDc.categories.forEach((item) => {
        if (item.name === payload.name) {
          item.personResponsible = payload.personResponsible;
        }
      });
    }

    if (state.excursionDc.riskCategories && state.excursionDc.riskCategories.length > 0) {
      state.excursionDc.riskCategories.forEach((item) => {
        if (item.title === payload.name) {
          item.personResponsible = payload.personResponsible;
        }
      });
    }

    if (state.allRisks && state.allRisks.length > 0) {
      state.allRisks.forEach((item) => {
        if (item.name === payload.name) {
          item.personResponsible = payload.personResponsible;
        }
      });
    }
  },

  [types.SET_ADDITIONAL_RISK_CONTROL_MITIGATION_STRATEGIES](state, payload) {
    if (state.excursionDc.categories && state.excursionDc.categories.length > 0) {
      state.excursionDc.categories.forEach((item) => {
        if (item.name === payload.name) {
          item.additionalRiskControlMitigationStrategies =
            payload.additionalRiskControlMitigationStrategies;
        }
      });
    }

    if (state.excursionDc.riskCategories && state.excursionDc.riskCategories.length > 0) {
      state.excursionDc.riskCategories.forEach((item) => {
        if (item.title === payload.name) {
          item.additionalRiskControlMitigationStrategies =
            payload.additionalRiskControlMitigationStrategies;
        }
      });
    }

    if (state.allRisks && state.allRisks.length > 0) {
      state.allRisks.forEach((item) => {
        if (item.name === payload.name) {
          item.additionalRiskControlMitigationStrategies =
            payload.additionalRiskControlMitigationStrategies;
        }
      });
    }
  },

  [types.SET_PREDEPARTURE](state, checklist) {
    state.excursionDc.predepartures = checklist;
  },
  [types.RESET_CLIENT_USER_LIST](state, payload) {
    const clientUserList = payload.data;
    if (clientUserList && clientUserList.data.length > 0) {
      clientUserList.data.map((item) => {
        if (item.created_at) {
          const createdTime = new Date(item.created_at);
          if (`${createdTime}` !== 'Invalid Date') {
            item.created_at = createdTime.toLocaleString();
            item.created_time = createdTime.getTime();
          }
        }
        return item;
      });
      state.clientUserList.data = clientUserList.data;
      state.clientUserList.total = clientUserList.total;
    } else {
      state.clientUserList.data = [];
      state.clientUserList.total = 0;
    }
    state.showError = payload.error;
    state.clientUserList.loading = false;
  },
  [types.RESET_USER_LIST](state, payload) {
    const userList = payload.data;
    if (userList && userList.data.length > 0) {
      userList.data.map((item) => {
        if (item.created_at) {
          const createdTime = new Date(item.created_at);
          if (`${createdTime}` !== 'Invalid Date') {
            item.created_at = createdTime.toLocaleString();
            item.created_time = createdTime.getTime();
          }
        }
        return item;
      });
      state.userList.data = userList.data;
      state.userList.total = userList.total;
    } else {
      state.userList.data = [];
      state.userList.total = 0;
    }
    state.showError = payload.error;
    state.userList.loading = false;
  },
  [types.SET_AUTH_SCHEME_API_PARAM](state, cbResponse) {
    state.userAuthSchemeApiParam = {};
    if (
      cbResponse &&
      cbResponse.data &&
      cbResponse.data.computed &&
      cbResponse.data.computed.vendorSid &&
      cbResponse.data.computed.vendorClientSid
    ) {
      state.userAuthSchemeApiParam = {
        'vendor-sid': cbResponse.data.computed.vendorSid,
        'vendor-client-sid': cbResponse.data.computed.vendorClientSid
      };
    }
  },
  [types.SET_CLIENT_ACL_ROLE_INFERIORITY_HASH](state, cbResponse) {
    state.clientAclRoleInferiorityHash = {};
    if (
      cbResponse &&
      cbResponse.data &&
      cbResponse.data.computed &&
      cbResponse.data.computed.clientAclRoleInferiorityHash
    ) {
      state.clientAclRoleInferiorityHash = cbResponse.data.computed.clientAclRoleInferiorityHash;
    }
  },
  [types.DELETE_RISK_COMPLETE_CHECKLIST](state, risk) {
    if (risk && risk.CompleteChecklist) {
      delete risk.CompleteChecklist;
    }
  },
  [types.SET_RISK_COMPLETE_CHECKLIST](state, { risk, result }) {
    if (risk) {
      risk.CompleteChecklist = result;
    }
  },
  [types.SET_EXCURSION_PREDEPARTURE_STRATEGIES](state, data) {
    state.excursionDc.predepartures = data.predepartures;
    state.excursionDc.predepartureChecklistCompleted = data.predepartureChecklistCompleted;
    state.excursionDc.updateSignature = data.updateSignature;
  },
  [types.SET_CE_COMMIT_OID](state, ceCommitOid) {
    state.ceCommitOid = ceCommitOid;
  },
  [types.TOGGLE_CLIENT_USER_ENABLED](state, userId) {
    if (state.clientUserList.data && Array.isArray(state.clientUserList.data)) {
      const users = state.clientUserList.data.filter((x) => x.id === userId);
      if (users.length > 0) {
        const user = users[0];
        user.enabled = !user.enabled;
      }
    }
  },
  [types.TOGGLE_USER_ENABLED](state, userId) {
    if (state.clientUserList.data && Array.isArray(state.clientUserList.data)) {
      const users = state.userList.data.filter((x) => x.id === userId);
      if (users.length > 0) {
        const user = users[0];
        user.enabled = !user.enabled;
      }
    }
  },
  [types.SET_ACTIVITY_SELECTION_VALID](state) {
    const selectedActivity = state.excursionDc.categories.find((c) => {
      return c.name === 'Activities';
    });

    let activityRiskValid = true;
    if (selectedActivity && selectedActivity.types && selectedActivity.types.length > 0) {
      activityRiskValid = selectedActivity.types.every((type) => {
        return type.classes.every((c) => {
          return c.risks && c.risks.length > 0;
        });
      });
    }

    state.excursionDc.activitySelectionValid = activityRiskValid;
  },
  [types.SET_EXCURSION_AUDIT_LIST](state, payload) {
    state.excursionDc.auditList = payload.data;
    if (state.excursionDc.auditList && state.excursionDc.auditList.length > 0) {
      state.excursionDc.auditList.map((item) => {
        if (item.timestamp) {
          const timestamp = new Date(item.timestamp);
          if (`${timestamp}` !== 'Invalid Date') {
            item.timestamp = timestamp.toLocaleString();
          }
        }
        return item;
      });
    }
  },
  [types.SET_STUDENT_MANAGEMENT_AUDIT_LIST](state, payload) {
    state.studentManagementAuditList = payload.data;
    if (state.studentManagementAuditList && state.studentManagementAuditList.length > 0) {
      state.studentManagementAuditList.map((item) => {
        if (item.timestamp) {
          const timestamp = new Date(item.timestamp);
          if (`${timestamp}` !== 'Invalid Date') {
            item.timestamp = timestamp.toLocaleString();
          }
        }
        return item;
      });
    }
  },
  [types.TOGGLE_CLIENT_ENABLED](state, sid) {
    if (state.clientList.data && Array.isArray(state.clientList.data)) {
      const client = state.clientList.data.find((x) => x.sid === sid);
      if (client) {
        client.enabled = !client.enabled;
      }
    }
  },
  [types.SET_CLIENT_LIST_LOADING](state, isLoading) {
    state.clientList.loading = isLoading;
  },
  [types.SET_USER_LIST_LOADING](state, isLoading) {
    state.userList.loading = isLoading;
  },
  [types.SET_PARENT_CLIENT_LIST_LOADING](state, isLoading) {
    state.parentClientList.loading = isLoading;
  },
  [types.SET_CLIENT_USER_LIST_LOADING](state, isLoading) {
    state.clientUserList.loading = isLoading;
  },
  [types.SET_CLIENT_CLIENT_LIST_LOADING](state, isLoading) {
    state.clientClientList.loading = isLoading;
  },
  [types.ADD_PARENT_CLIENT_LIST](state, item) {
    state.parentClientList.items.push(item);
  },
  [types.SET_API_PARAM](state, apiParam) {
    state.apiParam = apiParam;
  },
  [types.SET_EXCURSIONS_LIST_LOADING](state, isLoading) {
    state.excursionsList.loading = isLoading;
  },
  [types.SET_EXCURSION_TYPE_LOADED](state, isLoaded) {
    state.excursionTypeLoaded = isLoaded;
  },
  [types.SET_EXCURSION_DC_CATEGORIES](state, categories) {
    state.excursionDc.categories = categories;
  },
  [types.SET_EXCURSION_DC_STATUS](state, status) {
    state.excursionDc.status = status;
  },
  [types.SET_EXCURSION_CONFIRMATION_TICKED](state, ticked) {
    state.excursionDc.agreementConfirmation.ticked = ticked;
  },
  [types.SET_EXCURSION_DC_CLIENT_TEMPLATE](state, clientTemplate) {
    state.excursionDc.clientTemplate = clientTemplate;
  },
  [types.SET_EXCURSION_DC_ADDITIONAL_INFORMATION_NOTES](state, additionalInformationNotes) {
    state.excursionDc.additionalInformationNotes = additionalInformationNotes;
  },
  [types.SET_EXCURSION_DC_STAFF_SUPERVISOR_ATTENDANCE_COUNT](state, count) {
    state.excursionDc.staffSupervisorAttendanceCount = count;
  },
  [types.SET_EXCURSION_DC_STUDENT_ATTENDANCE_COUNT](state, count) {
    state.excursionDc.studentAttendanceCount = count;
  },
  [types.SET_EXCURSION_DC_CATEGORY_COMPLETE_CHECKLIST](state, payload) {
    if (state.excursionDc.categories[payload.categoryIndex] !== undefined) {
      state.excursionDc.categories[payload.categoryIndex].CompleteChecklist =
        payload.completeChecklist;
    }
  },
  [types.SET_EXCURSION_DC_CATEGORY_TYPE_CLASS_COMPLETE_CHECKLIST](state, payload) {
    const { categories } = state.excursionDc;
    if (categories?.[payload.categoryIndex]) {
      const type = categories[payload.categoryIndex].types?.[payload.typeIndex];
      if (type?.classes?.[payload.riskClassIndex]) {
        type.classes[payload.riskClassIndex].CompleteChecklist = payload.completeChecklist;
      }
    }
  },
  [types.SET_STUDENT_MANAGEMENT_SUBJECT_SETUP_DETECTED](state, isDetected) {
    state.studentManagementSubjectSetupDetected = isDetected;
  },
  [types.SET_RISK_CATEGORY_CHECKLISTS_TICKED](state, payload) {
    state.tickedRiskCategoryChecklists[payload.title] = payload.ticked;
  },
  [types.DELETE_FROM_TICKED_RISK_CATEGORY_CHECKLISTS](state, payload) {
    delete state.tickedRiskCategoryChecklists[payload.title];
  },
  [types.SET_ALL_RISKS](state, allRisks) {
    state.allRisks = allRisks;
  },
  [types.SET_CHECKLIST_FETCH_ACTION_IN_PROGRESS](state, isInProgress) {
    state.checklistFetchActionInProgress = isInProgress;
  },
  [types.ADD_TICKED_MANDATORY_CHECKLISTS](state, item) {
    state.tickedMandatoryChecklists.push(item);
  },
  [types.SET_ALL_MANDATORY_CHECKLISTS_TICKED](state, ticked) {
    state.tickedMandatoryChecklists.forEach((checklist) => {
      checklist.ticked = ticked;
    });
  },
  [types.SET_MANDATORY_CHECKLISTS_TICKED_BY_TITLE](state, payload) {
    const checklist = state.tickedMandatoryChecklists.find((obj) => obj.title === payload.title);
    if (checklist) {
      checklist.ticked = payload.ticked;
    }
  },
  [types.SET_ALL_RISKS_INITIALIZED](state, isInitialized) {
    state.allRisksInitialized = isInitialized;
  },
  [types.SET_ALL_PROVIDERS_INITIALIZED](state, isInitialized) {
    state.allProvidersInitialized = isInitialized;
  },
  [types.SET_EXCURSION_INITIALIZED](state, isInitialized) {
    state.excursionInitialized = isInitialized;
  },
  [types.SET_EXCURSION_CATEGORIES](state, categories) {
    state.excursionDc.categories = categories;
  }
};
