<template>
  <v-container grid-list-md text-xs-center class="settings-container">
    <student-management-panel></student-management-panel>
    <student-management-history></student-management-history>
  </v-container>
</template>

<script>
import { CSBase } from '@complispace/cs-design-system';
import { mapState } from 'vuex';
import StudentManagementHistory from './StudentManagementHistory';

export default {
  components: { StudentManagementHistory },
  extends: CSBase,

  data() {
    return {
      componentKey: 0
    };
  },
  computed: {
    ...mapState({
      clientSiteSettingsButtonDiplayed: (state) => state.common.clientSiteSettingsButtonDiplayed,
      clientSelfGroupManagementDisplayed: (state) => state.common.clientSelfGroupManagementDisplayed
    })
  },
  async mounted() {
    const { axiosInstance, eventPluginCommonAdapter, stbApiAdapter } = this.$app;
    const isVendorAuthScheme =
      !!this.$route.query['vendor-sid'] && !!this.$route.query['oauth-signature'];
    if (!isVendorAuthScheme) {
      await this.$store.dispatch('authorisation/validateAndSetUserTokenOrLogout', {
        query: this.$route.query,
        axiosInstance
      });
    }
    await this.$store.dispatch('common/initClientConfig', {
      queryParam: isVendorAuthScheme ? this.$route.query : {},
      eventPluginCommonAdapter,
      stbApiAdapter
    });
    if (!this.clientSiteSettingsButtonDiplayed || !this.clientSelfGroupManagementDisplayed) {
      this.$router.push('/excursions');
    }
  }
};
</script>

<style scoped>
.settings-container {
  width: 100%;
  height: 100%;
  max-width: unset;
  margin: 0;
  padding: 0;
}
.settings-content {
  text-align: left;
  height: 100vh;
}
</style>
