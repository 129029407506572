var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"excursion-confirmation"}},[(
      (_vm.excursionChecklistEnforcementBeforeSubmissionEnabled && !_vm.getAllChecklistTicked()) ||
        _vm.highRiskChecklistsNumbersMessageDisplayed
    )?_c('div',[_c('v-alert',{staticStyle:{"margin-bottom":"15px"},attrs:{"color":"#c80063","outlined":"","text":""}},[(_vm.excursionChecklistEnforcementBeforeSubmissionEnabled && !_vm.getAllChecklistTicked())?_c('div',[_vm._v(" "+_vm._s(_vm.excursionChecklistEnforcementBeforeSubmissionMessage)+" ")]):_vm._e(),(
          _vm.highRiskChecklistsNumbersMessageDisplayed &&
            _vm.excursionChecklistEnforcementBeforeSubmissionEnabled &&
            !_vm.getAllChecklistTicked()
        )?_c('div',{staticClass:"excursion-high-risk-checklists-message"},[_vm._v(" "+_vm._s(_vm.highRiskChecklistsEnforcementNumbersBeforeSubmissionMessage)+" ")]):_vm._e(),(
          _vm.highRiskChecklistsNumbersMessageDisplayed &&
            (!_vm.excursionChecklistEnforcementBeforeSubmissionEnabled || _vm.getAllChecklistTicked())
        )?_c('div',{staticClass:"excursion-high-risk-checklists-message"},[_vm._v(" "+_vm._s(_vm.highRiskChecklistsNumbersBeforeSubmissionMessage)+" ")]):_vm._e()]),_vm._m(0)],1):_vm._e(),_c('v-layout',[_c('v-checkbox',{attrs:{"id":"excursion-confirmation--agreement-checkbox","color":"#c80063","disabled":_vm.disabledCheckbox ||
          (_vm.excursionChecklistEnforcementBeforeSubmissionEnabled && !_vm.getAllChecklistTicked()) ||
          _vm.highRiskChecklistsNumbersMessageDisplayed},model:{value:(_vm.agreementConfirmationTicked),callback:function ($$v) {_vm.agreementConfirmationTicked=$$v},expression:"agreementConfirmationTicked"}}),_c('v-card',{attrs:{"color":"#EEEEEE"}},[_c('v-card-text',{attrs:{"id":"excursion-confirmation--agreement-checkbox--label"},on:{"click":function($event){!(
            _vm.disabledCheckbox ||
            (_vm.excursionChecklistEnforcementBeforeSubmissionEnabled && !_vm.getAllChecklistTicked()) ||
            _vm.highRiskChecklistsNumbersMessageDisplayed
          )
            ? (_vm.agreementConfirmationTicked = !_vm.agreementConfirmationTicked)
            : ''}}},[_c('span',[_vm._v(_vm._s(_vm.statement1)+";")]),_c('br'),_c('br'),_c('span',[_vm._v("and")]),_c('br'),_c('br'),_c('span',[_vm._v(_vm._s(_vm.statement2)+".")])])],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"arrow-div-outer"},[_c('div',{staticClass:"arrow-div-inner"})])}]

export { render, staticRenderFns }