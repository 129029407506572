const deleteCacheErrorMessage = "Oops, Content Cache cannot be cleared. (It might be cleared already or please contact support for help)";

const deleteCacheMessages = responseData => (responseData && responseData.message
  ? responseData.message : deleteCacheErrorMessage);

const populateErrorMessage = (argHash)=>{
  const { message, store } = argHash;
  let errorMessage = message;
  const { errorSid, sentinelEventSid } = store.state.common.apiErrorMessage
  if( Boolean(errorSid) || Boolean(sentinelEventSid)){
    errorMessage += `<p class="v-card__text debug-error-message mt-3">Debug Reference:</p>`;
    if(errorSid){
      errorMessage += `<p class="v-card__text debug-error-message">errorSid: ${errorSid}</p>`;
    }
    if(sentinelEventSid){
      errorMessage += `<p class="v-card__text debug-error-message">sentinelEventSid: ${sentinelEventSid}</p>`;
    }
  }

  return errorMessage;
}

export {
  // eslint-disable-next-line import/prefer-default-export
  deleteCacheMessages,
  populateErrorMessage,
};
