<template>
  <div class="client-row">
    <v-dialog v-model="systemUserIndividualModalDisplayed" scrollable width="768px" persistent>
      <v-card v-if="systemUserIndividualModalDisplayed">
        <v-card-title class="modalTitle">
          <v-flex>
            <strong>System User Individual Modal</strong>
          </v-flex>
          <v-btn id="system-user-individual-modal--close--button" icon @click="systemUserIndividualModalDisplayed = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="modalDivider"></v-divider>
        <v-card-text>
          <v-container grid-list-md>
            <div class="client-row client-modal-text">
              <v-flex xs12>
                Email
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  @keydown.space.prevent
                  solo
                  v-model="email"
                  required
                  :error-messages="emailErrors"
                  @input="$v.email.$touch()"
                  @blur="$v.email.$touch()"
                  class="excursion-input"
                  placeholder="Enter in the email of the user"
                  id="system-user-individual-modal--email"
                />
              </v-flex>
              <v-flex xs12>
                Given Name
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  solo
                  v-model="givenName"
                  id="system-user-individual-modal--given-name"
                  class="excursion-input"
                  placeholder="Enter in the given name of the user"
                />
              </v-flex>
              <v-flex xs12>
                Surname
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  solo
                  v-model="surname"
                  id="system-user-individual-modal--surname"
                  class="excursion-input"
                  placeholder="Enter in the surname of the user"
                />
              </v-flex>
              <v-flex xs12>
                System ACL Role
              </v-flex>
              <v-flex xs12>
                <v-select
                  class="type-dropdown excursion-selection"
                  placeholder="Select System ACL Role"
                  v-model="systemAclRole"
                  :items="getSystemAclRoles()"
                  required
                  :error-messages="systemAclRoleErrors"
                  @input="$v.systemAclRole.$touch()"
                  @blur="$v.systemAclRole.$touch()"
                  solo
                  id="system-user-individual-modal--system-acl-role"
                >
                  <template slot="item" slot-scope="data">
                    <span :id="`system-user-individual-modal--system-acl-role-item-${data.item}`">
                      {{ data.item }}
                    </span>
                  </template>
                </v-select>
              </v-flex>
              <v-flex xs12>
                Client ACL Role
              </v-flex>
              <v-flex xs12>
                <v-select
                  class="type-dropdown excursion-selection"
                  placeholder="Select Client ACL Role"
                  v-model="clientAclRole"
                  :items="clientAclRoles"
                  item-text="text"
                  item-value="value"
                  solo
                  clearable
                  id="system-user-individual-modal--client-acl-role"
                >
                  <template slot="item" slot-scope="data">
                    <span
                      :id="`system-user-individual-modal--system-acl-role-item-${data.item.text}`"
                    >
                      {{ data.item.text }}
                    </span>
                  </template>
                </v-select>
                <v-flex class="pre-error" v-if="clientAclRoleRequired"
                  >Client ACL Role is required</v-flex
                >
              </v-flex>
              <div v-if="inferiorRoleSetupDisplayed" style="padding-bottom:20px">
                <v-layout v-if="clientAdminAssistantRoleDisplaySetupEnabled">
                  <div class="client-individual-checkbox">
                    <v-checkbox
                      v-model="clientAdminAssistantRoleDisplayEnabled"
                      class="client-individual-checkbox"
                      id="user-client-admin-assistant-role-display-enabled-tickbox"
                    ></v-checkbox>
                  </div>
                  <span
                    class="client-individual-checkbox-label"
                    for="user-client-admin-assistant-role-display-enabled-tickbox"
                  >
                    Display as <b>client-admin-assistant</b>
                  </span>
                </v-layout>
                <v-layout v-if="clientApproverRoleDisplaySetupEnabled">
                  <div class="client-individual-checkbox">
                    <v-checkbox
                      v-model="clientApproverRoleDisplayEnabled"
                      class="client-individual-checkbox"
                      id="user-client-approver-role-display-enabled-tickbox"
                    ></v-checkbox>
                  </div>
                  <span
                    class="client-individual-checkbox-label"
                    for="user-client-approver-role-display-enabled-tickbox"
                  >
                    Display as <b>client-approver</b>
                  </span>
                </v-layout>
                <v-layout v-if="clientUserRoleDisplaySetupEnabled">
                  <div class="client-individual-checkbox">
                    <v-checkbox
                      v-model="clientUserRoleDisplayEnabled"
                      class="client-individual-checkbox"
                      id="user-client-user-role-display-enabled-tickbox"
                    ></v-checkbox>
                  </div>
                  <span
                    class="client-individual-checkbox-label"
                    for="user-client-user-role-display-enabled-tickbox"
                  >
                    Display as <b>client-user</b>
                  </span>
                </v-layout>
              </div>
              <v-flex xs12>
                Clients
              </v-flex>
              <v-flex xs12>
                <v-autocomplete
                  v-model="clientSids"
                  :items="clientItems"
                  label="Select one or more Clients"
                  item-text="name"
                  item-value="sid"
                  :search-input.sync="searchClients"
                  :cache-items="true"
                  clearable
                  :multiple="true"
                  @change="handleClientChange"
                  @focus="handleClientFocus"
                  @close="handleClientClose"
                  ref="selectClient"
                  chips
                  hide-details
                  :hide-selected="hideSelected"
                  :deletable-chips="true"
                  solo
                  id="system-user-individual-modal--clients"
                >
                  <template slot="item" slot-scope="data">
                    <span :id="`system-user-individual-modal--clients-item-${data.item.sid}`">
                      {{ data.item.name }}
                    </span>
                  </template>
                </v-autocomplete>
                <v-flex class="pre-clients-error" v-if="clientsRequired"
                  >Clients are required</v-flex
                >
              </v-flex>
            </div>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="normal-btn white--text btnSave primary"
            name="btnSave"
            id="btnSave"
            :disabled="systemUserSaveActionInProgress || this.$v.$invalid"
            @click.native="save"
            ><v-icon v-if="systemUserSaveActionInProgress">fa fa-spinner fa-pulse</v-icon
            >Save</v-btn
          >
          <v-btn class="normal-btn" @click.native="close()" id="btnClose">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div>
      <v-dialog v-model="genericDialogDisplayed" persistent max-width="390">
        <v-card>
          <v-card-title class="headline center">{{ genericDialogMessage }}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click.native="genericDialogDisplayed = false"
              class="normal-btn white--text primary"
              id="system-user-individual-modal--btn-ok"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import * as types from "@/store/mutationTypes";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import aclRole from "../lib/const/aclRole";

export default {
  mixins: [validationMixin],
  validations: {
    email: {
      required
    },
    systemAclRole: {
      required
    }
  },
  props: {
    propSystemUserIndividualModalRefreshed: Boolean,
    propUserId: String
  },
  data() {
    return {
      genericDialogDisplayed: false,
      systemUserSaveActionInProgress: false,
      genericDialogMessage: "",
      systemUserIndividualModalDisplayed: false,
      clientSids: [],
      selectedClients: [],
      searchClients: "",
      clientAclRoles: [
        { value: aclRole.CLIENT_ADMIN, text: aclRole.CLIENT_ADMIN },
        {
          value: aclRole.CLIENT_ADMIN_ASSISTANT,
          text: aclRole.CLIENT_ADMIN_ASSISTANT
        },
        { value: aclRole.CLIENT_APPROVER, text: aclRole.CLIENT_APPROVER },
        { value: aclRole.CLIENT_GROUP_ADMIN, text: aclRole.CLIENT_GROUP_ADMIN },
        {
          value: aclRole.CLIENT_GROUP_APPROVER,
          text: aclRole.CLIENT_GROUP_APPROVER
        },
        { value: aclRole.CLIENT_GROUP_USER, text: aclRole.CLIENT_GROUP_USER },
        {
          value: aclRole.CLIENT_GROUP_WATCHER,
          text: aclRole.CLIENT_GROUP_WATCHER
        },
        { value: aclRole.CLIENT_LITE_USER, text: aclRole.CLIENT_LITE_USER },
        { value: aclRole.CLIENT_USER, text: aclRole.CLIENT_USER },
        { value: aclRole.CLIENT_WATCHER, text: aclRole.CLIENT_WATCHER }
      ],
      pagination: {
        rowsPerPage: -1,
        descending: false,
        sortBy: "",
        page: 1,
        order: ""
      },
      clientAclRole: "",
      systemAclRole: "",
      email: "",
      emailConflicted: false,
      emailValid: true,
      clientAclRoleRequired: false,
      clientsRequired: false,
      givenName: "",
      surname: "",
      clientAdminAssistantRoleDisplayEnabled: false,
      clientApproverRoleDisplayEnabled: false,
      clientUserRoleDisplayEnabled: false,
      hideSelected: false
    };
  },
  computed: {
    systemUserIndividualModalRefreshed() {
      return this.propSystemUserIndividualModalRefreshed;
    },
    clientCache() {
      return this.$store.state.common.parentClientList.itemsCache;
    },
    clientItems() {
      const { items } = this.$store.state.common.parentClientList;
      this.selectedClients.forEach(client => {
        if (!items.some(e => e.sid === client.sid)) {
          this.$store.commit(types.COMMON_ADD_PARENT_CLIENT_LIST, client);
        }
      });
      return items;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      if (!this.$v.email.required) {
        errors.push("Email is required");
      }

      if (!this.emailValid) {
        errors.push("Invalid Email");
      }

      if (this.emailConflicted) {
        errors.push("Email conflicted");
      }

      return errors;
    },
    systemAclRoleErrors() {
      const errors = [];
      if (!this.$v.systemAclRole.$dirty) return errors;
      if (!this.$v.systemAclRole.required) {
        errors.push("System ACL Role is required");
      }

      return errors;
    },
    systemAdminSelectionOptionDisplayed() {
      return this.$store.state.common.systemAdminSelectionOptionDisplayed;
    },
    clientAdminAssistantRoleDisplaySetupEnabled() {
      return this.checkIfInferiorRoleDisplaySetupEnabled(
        this.clientAclRole,
        aclRole.CLIENT_ADMIN_ASSISTANT
      );
    },
    clientApproverRoleDisplaySetupEnabled() {
      return this.checkIfInferiorRoleDisplaySetupEnabled(
        this.clientAclRole,
        aclRole.CLIENT_APPROVER
      );
    },
    clientUserRoleDisplaySetupEnabled() {
      return this.checkIfInferiorRoleDisplaySetupEnabled(this.clientAclRole, aclRole.CLIENT_USER);
    },
    inferiorRoleSetupDisplayed() {
      return (
        this.clientAdminAssistantRoleDisplaySetupEnabled
        || this.clientApproverRoleDisplaySetupEnabled
        || this.clientUserRoleDisplaySetupEnabled
      );
    }
  },
  methods: {
    alertSaveResult(cbResponse) {
      this.genericDialogDisplayed = true;
      this.systemUserSaveActionInProgress = false;
      this.clientAclRoleRequired = false;
      this.close();
      this.clear();
      if (cbResponse && cbResponse.data) {
        // success
        this.genericDialogMessage = "User has been saved.";

        this.$emit("reloadUsers");
      } else {
        // failed
        this.genericDialogMessage = "Failed to save User information.";
      }
    },
    clear() {
      this.$v.$reset();
      this.clientSids = "";
      this.clientAclRole = "";
      this.email = "";
      this.systemAclRole = "";
      this.emailValid = true;
      this.givenName = "";
      this.surname = "";
      this.clientAdminAssistantRoleDisplayEnabled = false;
      this.clientApproverRoleDisplayEnabled = false;
      this.clientUserRoleDisplayEnabled = false;
    },
    close() {
      this.systemUserIndividualModalDisplayed = false;
      this.clear();
    },
    initSelectClient() {
      if (this.$refs.selectClient === undefined) {
        return;
      }
      // Initialize the v-autocomplete hide-selected attribute
      this.$refs.selectClient.$refs.input.click();
      this.$nextTick(() => {
        this.$refs.selectClient.blur();
      });
    },
    handleClientChange() {
      this.$refs.selectClient.blur();
      this.$nextTick(() => {
        this.$refs.selectClient.$refs.input.click();
      });
    },
    handleClientFocus() {
      this.hideSelected = true;
    },
    handleClientClose() {
      this.hideSelected = false;
    },
    async newUserModal(id) {
      if ("" !== `${id}`) {
        const argHash = {
          id,
          store: this.$store
        };

        const cbResponse = await this.$app.stbApiAdapter.getUsers(argHash);
        if (cbResponse && !cbResponse.error) {
          const user = cbResponse.data[0];
          this.email = user.email;
          this.systemAclRole = user["acl-role"];
          const userMetadata = user.metadata;

          if (userMetadata) {
            this.givenName = userMetadata.givenName;
            this.surname = userMetadata.surname;
            this.clientAdminAssistantRoleDisplayEnabled
              = userMetadata.clientAdminAssistantRoleDisplayEnabled;
            this.clientApproverRoleDisplayEnabled = userMetadata.clientApproverRoleDisplayEnabled;
            this.clientUserRoleDisplayEnabled = userMetadata.clientUserRoleDisplayEnabled;
          } else {
            this.givenName = "";
            this.surname = "";
            this.clientAdminAssistantRoleDisplayEnabled = false;
            this.clientApproverRoleDisplayEnabled = false;
            this.clientUserRoleDisplayEnabled = false;
          }

          if (user.clientList && 0 < user.clientList.length) {
            this.clientSids = user.clientList.map(item => item.sid);
            this.selectedClients = user.clientList.map(item => {
              return {
                sid: item.sid,
                name: `${item.sid} - ${item.metadata.name}`
              };
            });
            this.clientAclRole = user.clientList[0]["acl-role"];
          }
        }
        this.initSelectClient();
      } else {
        this.clear();
      }
    },
    async save() {
      this.emailValid = true;
      const regExp = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

      if (!regExp.test(this.email)) {
        this.emailValid = false;
        return;
      }

      if ((!this.clientSids || 0 >= this.clientSids.length) && this.clientAclRole) {
        this.clientsRequired = true;
        return;
      }

      if (this.clientSids && 0 < this.clientSids.length && !this.clientAclRole) {
        this.clientAclRoleRequired = true;
        return;
      }

      this.systemUserSaveActionInProgress = true;
      let clients = [];

      if (this.clientSids && 0 < this.clientSids.length) {
        clients = this.clientSids.map(item => {
          return {
            sid: item,
            "acl-role": this.clientAclRole
          };
        });
      }

      const apiParam = JSON.parse(JSON.stringify(this.$store.state.common.apiParam));
      const user = {
        "acl-role": this.systemAclRole,
        email: String(this.email).trim(),
        metadata: {
          givenName: String(this.givenName).trim(),
          surname: String(this.surname).trim(),
          clientAdminAssistantRoleDisplayEnabled: this.clientAdminAssistantRoleDisplayEnabled,
          clientApproverRoleDisplayEnabled: this.clientApproverRoleDisplayEnabled,
          clientUserRoleDisplayEnabled: this.clientUserRoleDisplayEnabled
        },
        clients
      };

      apiParam.user = user;

      if ("" !== `${this.propUserId}`) {
        const argHash = {
          id: this.propUserId,
          payload: apiParam,
          store: this.$store
        };
        const cbResponse = await this.$app.stbApiAdapter.putUsers(argHash);
        this.systemUserSaveActionInProgress = false;
        if ("409" === `${cbResponse.statusCode}`) {
          this.emailConflicted = true;
        } else {
          this.emailConflicted = false;
          this.alertSaveResult(cbResponse);
        }
      } else {
        const argHash = {
          payload: apiParam,
          store: this.$store
        };
        const cbResponse = await this.$app.stbApiAdapter.postUsers(argHash);
        this.systemUserSaveActionInProgress = false;
        if ("409" === `${cbResponse.statusCode}`) {
          this.emailConflicted = true;
        } else {
          this.emailConflicted = false;
          this.alertSaveResult(cbResponse);
        }
      }
    },
    remove(item) {
      const index = this.clientSids.indexOf(item.sid);
      if (0 <= index) this.clientSids.splice(index, 1);
    },
    getSystemAclRoles() {
      const systemAclRoles = [
        aclRole.SYSTEM_GUEST,
        aclRole.SYSTEM_OPS_ADMIN,
        aclRole.SYSTEM_USER,
        aclRole.SYSTEM_WATCHER
      ];

      if (this.systemAdminSelectionOptionDisplayed) {
        systemAclRoles.splice(0, 0, aclRole.SYSTEM_ADMIN);
      }

      return systemAclRoles;
    },
    checkIfInferiorRoleDisplaySetupEnabled(superiorClientAclRole, inferiorClientAclRole) {
      let inferiorRoleDisplaySetupEnabled = false;

      if (superiorClientAclRole) {
        const inferiorClientAclRoleHash = this.$store.state.common.clientAclRoleInferiorityHash[
        superiorClientAclRole
        ];

        inferiorRoleDisplaySetupEnabled
          = inferiorClientAclRoleHash && inferiorClientAclRoleHash[inferiorClientAclRole];
      }

      return inferiorRoleDisplaySetupEnabled;
    },
    async filterClients(val) {
      let cacheItem;
      const localVal = val || "";
      if (this.clientCache && 0 < this.clientCache.length) {
        cacheItem = this.clientCache.find(function(item) {
          return item.key === localVal;
        });
      }

      if (cacheItem) {
        this.$store.commit(types.COMMON_SET_PARENT_CLIENT_ITEMS, cacheItem.items);
      } else {
        this.$store.commit(types.COMMON_SET_PARENT_CLIENT_LIST_LOADING, true);
        const filterParam = {
          s: localVal,
          page: this.pagination.page,
          order: this.pagination.sortBy,
          length: 5,
          direction: false === this.pagination.descending ? "ASC" : "DESC"
        };

        Object.keys(filterParam).forEach(key => {
          if ("" === filterParam[key]) {
            delete filterParam[key];
          }
        });
        const argHash = {
          stbApiAdapter: this.$app.stbApiAdapter,
          filterParam,
          store: this.$store
        };
        await this.$store.dispatch("common/getParentClientList", argHash);
      }
    }
  },
  async mounted() {
    this.systemUserIndividualModalDisplayed = true;
    await this.newUserModal(this.propUserId);
    await this.filterClients("");
  },
  watch: {
    clientAclRole() {
      if (this.clientAclRole) {
        this.clientAclRoleRequired = false;
      }
    },
    clientSids() {
      if (this.clientSids && 0 < this.clientSids.length) {
        this.clientsRequired = false;
      }
    },
    systemUserIndividualModalRefreshed() {
      this.newUserModal(this.propUserId);
      this.systemUserIndividualModalDisplayed = true;
    },
    async searchClients(val) {
      await this.filterClients(val);
    }
  }
};
</script>
