<template>
  <div class="excursion-history" v-if="historyDisplayed">
    <hr class="mt-4 hr" />
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header id="excursion-history--expansion--header">
          History
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <template v-for="(item, index) in historyItems">
            <v-list-item :key="index">
              <v-list-item-content>
                <v-list-item-title>{{ item['audit-metadata'].userDisplayName }}</v-list-item-title>
                <v-list-item-subtitle class="detail-line">
                  {{ item['event-type'] }} | {{ item.metadata.status }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-action-text class="list-action-created-date">
                  {{ item.timestamp }}
                </v-list-item-action-text>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import * as types from "@/store/mutationTypes";

export default {
  computed: {
    historyDisplayed() {
      return (
        this.$store.state.common.excursionHistoryDisplayed
        && (0 < this.$store.state.common.excursionDc.id)
        && this.$store.state.common.excursionDc.auditList
        && (0 < this.$store.state.common.excursionDc.auditList.length)
      );
    },
    historyItems() {
      return this.$store.state.common.excursionDc.auditList;
    }
  },
  async mounted() {
    if (0 < this.$store.state.common.excursionDc.id) {
      const argHash = {
        id: this.$store.state.common.excursionDc.id,
        query: this.$route.query,
        store: this.$store
      };
      const cbResponse = await this.$app.stbApiAdapter.getAuditExcursions(argHash);
      if (!cbResponse.error) {
        this.$store.commit(types.COMMON_SET_EXCURSION_AUDIT_LIST, cbResponse);
      }
    }
  }
};
</script>
