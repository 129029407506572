<template>
  <div id="excursion-confirmation">
    <div
      v-if="
        (excursionChecklistEnforcementBeforeSubmissionEnabled && !getAllChecklistTicked()) ||
          highRiskChecklistsNumbersMessageDisplayed
      "
    >
      <v-alert color="#c80063" style="margin-bottom: 15px;" outlined text>
        <div
          v-if="excursionChecklistEnforcementBeforeSubmissionEnabled && !getAllChecklistTicked()"
        >
          {{ excursionChecklistEnforcementBeforeSubmissionMessage }}
        </div>
        <div
          v-if="
            highRiskChecklistsNumbersMessageDisplayed &&
              excursionChecklistEnforcementBeforeSubmissionEnabled &&
              !getAllChecklistTicked()
          "
          class="excursion-high-risk-checklists-message"
        >
          {{ highRiskChecklistsEnforcementNumbersBeforeSubmissionMessage }}
        </div>
        <div
          v-if="
            highRiskChecklistsNumbersMessageDisplayed &&
              (!excursionChecklistEnforcementBeforeSubmissionEnabled || getAllChecklistTicked())
          "
          class="excursion-high-risk-checklists-message"
        >
          {{ highRiskChecklistsNumbersBeforeSubmissionMessage }}
        </div>
      </v-alert>
      <div class="arrow-div-outer">
        <div class="arrow-div-inner"></div>
      </div>
    </div>
    <v-layout>
      <v-checkbox
        id="excursion-confirmation--agreement-checkbox"
        color="#c80063"
        v-model="agreementConfirmationTicked"
        :disabled="
          disabledCheckbox ||
            (excursionChecklistEnforcementBeforeSubmissionEnabled && !getAllChecklistTicked()) ||
            highRiskChecklistsNumbersMessageDisplayed
        "
      >
      </v-checkbox>
      <v-card color="#EEEEEE">
        <v-card-text
          id="excursion-confirmation--agreement-checkbox--label"
          @click="
            !(
              disabledCheckbox ||
              (excursionChecklistEnforcementBeforeSubmissionEnabled && !getAllChecklistTicked()) ||
              highRiskChecklistsNumbersMessageDisplayed
            )
              ? (agreementConfirmationTicked = !agreementConfirmationTicked)
              : ''
          "
        >
          <span>{{ statement1 }};</span><br /><br />
          <span>and</span><br /><br />
          <span>{{ statement2 }}.</span>
        </v-card-text>
      </v-card>
    </v-layout>
  </div>
</template>

<script>
import * as types from "@/store/mutationTypes";
import excursionConfirmation from "../lib/content/excursionConfirmation";

export default {
  props: {
    disabledCheckbox: Boolean
  },
  data() {
    return {
      statement1: "",
      statement2: "",
      excursionChecklistEnforcementBeforeSubmissionMessage: "",
      highRiskChecklistsNumbersBeforeSubmissionMessage: "",
      highRiskChecklistsEnforcementNumbersBeforeSubmissionMessage: ""
    };
  },
  computed: {
    agreementConfirmationTicked: {
      get() {
        if (!this.$store.state.common.excursionDc.agreementConfirmation) {
          return false;
        }

        // [SES-412] if ticked value is true, then agreementConfirmationTicked will be set to true
        if (
          this.$store.state.common.excursionDc.agreementConfirmation
          && this.$store.state.common.excursionDc.agreementConfirmation.ticked
        ) {
          return true;
        }

        return this.$store.state.common.excursionDc.agreementConfirmation.ticked;
      },
      set(value) {
        const confirmInfo = {
          ticked: value,
          statement1: this.statement1,
          statement2: this.statement2
        };
        this.$emit("setAgreementConfirmationManuallyTicked", true);
        this.$store.commit(types.COMMON_SET_EXCURSION_CONFIRMATION, confirmInfo);
      }
    },
    excursionChecklistEnforcementBeforeSubmissionEnabled() {
      return this.$store.state.common.excursionChecklistEnforcementBeforeSubmissionEnabled;
    },
    highRiskChecklistsNumbersMessageDisplayed() {
      return (
        this.$store.state.common.excursionHighRiskStudentMedicalEnabled
        && (
          !this.$store.state.common.excursionDc.staffSupervisorAttendanceCount
          || !this.$store.state.common.excursionDc.studentAttendanceCount
        )
      );
    }
  },
  methods: {
    getAllChecklistTicked() {
      const mandatoryChecklistString = JSON.stringify(this.$store.state.common.excursionDc.mandatoryChecklist);
      const categoriesString = JSON.stringify(this.$store.state.common.excursionDc.categories);

      const checklistString = mandatoryChecklistString + categoriesString;

      const checkboxCount = (checklistString.match(/"contentType":"checkbox"/g) || []).length;
      const tickedCheckboxCount = (checklistString.match(/"ticked":true/g) || []).length;
      const allTicked = checkboxCount === tickedCheckboxCount;

      if (!allTicked && this.agreementConfirmationTicked) {
        this.agreementConfirmationTicked = false;
      }

      return allTicked;
    }
  },
  mounted() {
    const confirmation = this.$store.state.common.excursionDc.agreementConfirmation;
    if (confirmation && confirmation.statement1 && confirmation.statement2) {
      this.statement1 = confirmation.statement1;
      this.statement2 = confirmation.statement2;
    } else {
      this.statement1 = excursionConfirmation.statement1;
      this.statement2 = excursionConfirmation.statement2;
    }
    this.excursionChecklistEnforcementBeforeSubmissionMessage = excursionConfirmation.excursionChecklistEnforcementBeforeSubmissionMessage;
    this.highRiskChecklistsNumbersBeforeSubmissionMessage = excursionConfirmation.highRiskChecklistsNumbersBeforeSubmissionMessage;
    this.highRiskChecklistsEnforcementNumbersBeforeSubmissionMessage = excursionConfirmation.highRiskChecklistsEnforcementNumbersBeforeSubmissionMessage;
  }
};
</script>
