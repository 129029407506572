import app from './app/store';
import common from './common/store';
import session from './session/store';
import authorisation from './authorisation/store';

const modules = {
  app,
  common,
  session,
  authorisation
};

export default modules;
