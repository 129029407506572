export default {
  ID: {
    label: 'ID',
    querystringRef: 'id',
    tableColumnRef: 'id'
  },
  DETAILS: {
    label: 'Details',
    querystringRef: 'name',
    tableColumnRef: 'name'
  },
  TYPE: {
    label: 'Type',
    querystringRef: 'type',
    tableColumnRef: 'typeId'
  },
  STATUS: {
    label: 'Status',
    querystringRef: 'status',
    tableColumnRef: 'status'
  },
  CLIENT: {
    label: 'Client',
    querystringRef: 'client',
    tableColumnRef: 'client.name'
  },
  ORGANISATION: {
    label: 'Organisation',
    querystringRef: 'organisation',
    tableColumnRef: 'OrganisationName'
  },
  ACTION: {
    label: 'Action',
    querystringRef: 'action',
    tableColumnRef: 'id'
  }
};
