import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const state = {
  isInitClientConfigInProgress: false,
  userProfile: {},
  isAuthenticated: true
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
