async function beforeEachRoute(to, from, next) {
  if (to.name === 'SafeTripBuilderExistingRecord' && to.params && to.params.id === '0') {
    next('/excursions/new');
  }
  if (to.name === 'ExcursionBuilderExistingRecord' && to.params && to.params.id === '0') {
    next('/excursions/builder/new');
  }
  if (to.path === '/' || !to.name) {
    next('/excursions');
  }

  next();
}

function routerComposer(diHash) {
  const { vueComponentHash, VueRouter } = diHash;
  const {
    ClientAdminGroupSetting,
    ClientAdminLayout,
    ClientAdminOperationManagement,
    ClientAdminRiskMatrixManagement,
    ClientAdminRiskCategoryManagement,
    ClientAdminStudentManagement,
    ClientAdminSiteSetting,
    EntityManagement,
    ExcursionBuilder,
    SafeTripBuilder,
    SupportFeedbackLayout,
    AdministrationManagement,
    LoginCallback
  } = vueComponentHash;

  const router = new VueRouter({
    mode: 'history',
    routes: [
      {
        path: '/login/callback/idp',
        name: 'LoginCallback',
        component: LoginCallback
      },
      {
        path: '/excursions/builder/new',
        name: 'ExcursionBuilderNewRecord',
        component: ExcursionBuilder
      },
      {
        path: '/excursions/builder/:id',
        name: 'ExcursionBuilderExistingRecord',
        component: ExcursionBuilder
      },
      {
        path: '/excursions/new',
        name: 'SafeTripBuilderNewRecord',
        component: SafeTripBuilder
      },
      {
        path: '/excursions',
        name: 'Excursions',
        component: EntityManagement
      },
      {
        path: '/excursions/templates',
        name: 'Templates',
        component: EntityManagement
      },
      {
        path: '/admin/group',
        name: 'ClientAdminGroupSetting',
        component: ClientAdminGroupSetting
      },
      {
        path: '/admin/student-management',
        name: 'ClientAdminStudentManagement',
        component: ClientAdminStudentManagement
      },
      {
        path: '/admin/risk-management',
        name: 'ClientAdminRiskMatrixManagement',
        component: ClientAdminRiskMatrixManagement
      },
      {
        path: '/admin/risk-category-management',
        name: 'ClientAdminRiskCategoryManagement',
        component: ClientAdminRiskCategoryManagement
      },
      {
        path: '/admin/operation-management',
        name: 'ClientAdminOperationManagement',
        component: ClientAdminOperationManagement
      },
      {
        path: '/admin/site-settings',
        name: 'ClientAdminSiteSetting',
        component: ClientAdminSiteSetting
      },
      {
        path: '/admin',
        name: 'ClientAdminLayout',
        component: ClientAdminLayout
      },
      {
        path: '/feedback',
        name: 'SupportFeedbackLayout',
        component: SupportFeedbackLayout
      },
      {
        path: '/admin/clients/users',
        name: 'SafeTripBuilderClientsUsersList',
        component: AdministrationManagement
      },
      {
        path: '/admin/clients/new',
        name: 'SafeTripBuilderClientNewRecord',
        component: AdministrationManagement
      },
      {
        path: '/admin/clients',
        name: 'SafeTripBuilderClientsList',
        component: AdministrationManagement
      },
      {
        path: '/admin/users',
        name: 'SafeTripBuilderUsersList',
        component: AdministrationManagement
      },
      {
        path: '/admin/clients/:id',
        name: 'SafeTripBuilderClientExistingRecord',
        component: AdministrationManagement
      },
      {
        path: '/excursions/:id',
        name: 'SafeTripBuilderExistingRecord',
        component: SafeTripBuilder
      }
    ]
  });

  router.beforeEach(beforeEachRoute);

  return router;
}

export default {
  beforeEachRoute,
  routerComposer
};
