const getFileBlob = (href, format) => {
  const byteCharacters = atob(href);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i += 1) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  if ("csv" === format) {
    return new Blob([byteArray], { type: "text/csv" });
  }

  return new Blob([byteArray], { type: "application/json" });
};

const downloadExcursionsFileForIE = (href, filename) => {
  const blob = getFileBlob(href);

  window.navigator.msSaveOrOpenBlob(blob, filename);
};

const exportExcursionJson = (base64Data, filename) => {
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    downloadExcursionsFileForIE(base64Data, filename);
  } else {
    const blob = getFileBlob(base64Data, "json");
    const blobUrl = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
};

const exportExcursionCSV = (base64Data, filename) => {
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    downloadExcursionsFileForIE(base64Data, filename);
  } else {
    const blob = getFileBlob(base64Data, "csv");
    const blobUrl = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
};

const exportAllExcursions = {
  exportExursionsWithJsonFormat: async (stbApiAdapter, filterParam, store) => {
    const response = await stbApiAdapter.getExcursionExports({
      filterParam,
      store
    });

    if (!response.error) {
      if (0 === response.data.total) {
        return { excursionsNotFound: true };
      }
      const stringifiedExcursions = JSON.stringify(response.data.excursions);
      const bufferedExcursions = Buffer.from(stringifiedExcursions).toString("base64");
      exportExcursionJson(bufferedExcursions, response.data.filename);
    }

    return response;
  },
  exportExursionsWithCSVFormat: async (stbApiAdapter, filterParam, store) => {
    const response = await stbApiAdapter.getExcursionExports({
      filterParam,
      store
    });

    if (!response.error) {
      if (0 === response.data.total) {
        return { excursionsNotFound: true };
      }
      exportExcursionCSV(response.data.computed.source, response.data.computed.filename);
    }

    return response;
  }
};

export default {
  exportExcursionCSV,
  exportExcursionJson,
  exportAllExcursions
};
