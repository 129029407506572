<template></template>

<script>
export default {
  name: 'LoginCallback',
  async mounted() {
    try {
      await this.$store.dispatch('session/handleLoginCallback');
      const redirectRoute = localStorage.getItem('idp_redirect');
      localStorage.removeItem('idp_redirect');
      localStorage.removeItem('loginErrorMessage');

      // logic kept for PCG access to risk assessment
      const redirectUrlQueryParam = window.location.search.split('redirect-url=')[1];
      if (redirectUrlQueryParam) {
        try {
          const url = new URL(decodeURIComponent(redirectUrlQueryParam));
          if (this.isUrlInWhitelist(url)) {
            window.location.href = url;
            return;
          }
        } catch (error) {
          console.error('Invalid URL');
        }
        await this.$store.dispatch('session/logout', this.$route.query);
        return;
      }

      window.location.href = redirectRoute || '/excursions';
    } catch (error) {
      localStorage.setItem('loginErrorMessage', error);
      await this.$store.dispatch('session/logout', this.$route.query);
    }
  },
  methods: {
    isUrlInWhitelist(url) {
      const domainWhitelist = ['safetripbuilder.com', 'plancheckgo.com', 'localhost'];
      const baseDomain = this.getBaseDomain(url);
      return domainWhitelist.includes(baseDomain);
    },
    getBaseDomain(url) {
      const { hostname } = url;
      const parts = hostname.split('.').reverse();
      if (parts[0] === 'localhost') {
        return 'localhost';
      }
      return parts.length > 1 ? `${parts[1]}.${parts[0]}` : hostname;
    }
  }
};
</script>
