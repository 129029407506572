<template>
  <v-container grid-list-md text-xs-center class="settings-container">
    <div class="client-admin-risk-category-management-container">
      <div class="risk-category-management-title">Risk Category Management</div>
      <div class="risk-category-management-description">
        {{
          `Here you can manage your Risk Categories that you want to remove as options in your Excursions.`
        }}
      </div>
      <div class="risk-category-management-description">
        Select and tick the check box next to the Risk you wish to remove from Excursions.
      </div>
      <div class="risk-category-management-selector-container">
        <div class="risk-category-management-selector">Risk Category Selector</div>
        <div>
          <v-btn
            color="primary"
            class="ma-2 risk-category-btn"
            outlined
            @click="riskCategoryPresaveDialogDisplayed = true"
            id="clientAdminRiskCategorySaveBtn"
          >
            <span>Save</span>
          </v-btn>
          <v-btn
            color="primary"
            class="ma-2 risk-category-btn"
            outlined
            @click.native="resetRiskCategorySelection"
            id="clientAdminRiskCategoryCancelBtn"
          >
            <span>Cancel</span>
          </v-btn>
        </div>
      </div>
      <v-flex style="overflow: auto; height: 90vh">
        <v-data-table
          :headers="groupHeaders"
          :items="riskCategoryList"
          class="elevation-2 excursion-table"
          :loading="riskCategoryLoading"
          no-data-text="No risk category was found"
          :footer-props="footerProps"
          single-select
        >
          <template slot="item" slot-scope="props">
            <tr>
              <td class="risk-catetory-cell">
                <v-simple-checkbox
                  hide-details
                  v-model="props.item.riskCategoryBlacklistFlag"
                  :class="props.item.riskCategoryBlacklistFlag ? 'risk-category-checkbox' : ''"
                  :ripple="false"
                  :id="getRiskCategoryManagementCheckboxId(props.item)"
                  @click="clickCheckbox(props)"
                ></v-simple-checkbox>
              </td>
              <td class="text-xs-left">{{ props.item.riskCategory }}</td>
              <td class="text-xs-left">{{ props.item.riskType }}</td>
              <td class="text-xs-left">{{ props.item.riskClass }}</td>
              <td class="text-xs-left">{{ props.item.riskName }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </div>
    <cs-form-dialog
      v-model="riskCategoryPresaveDialogDisplayed"
      id="dlg-risk-category-management"
      heading="Risk Category Management"
      bodyId="risk-category-pre-save-dialog"
      :primaryAction="{
        label: 'Save',
        eventName: 'primary-click',
        disabled: clientRiskCategoryModalSaveActionInProgress
      }"
      :secondaryAction1="{ label: 'Cancel', eventName: 'secondary1-click' }"
      @primary-click="saveClientRiskCategoryModal"
      @secondary1-click="riskCategoryPresaveDialogDisplayed = false"
    >
      <template v-slot:cs-form-dialog-content>
        <v-spacer></v-spacer>
        <v-card-text>Do you want to save the changes?</v-card-text>
      </template>
    </cs-form-dialog>
    <cs-form-dialog
      v-model="clientAdminRiskCategoryManagementGenericDialogDisplayed"
      id="dlg-risk-category-management-generic"
      heading="Risk Category Management"
      bodyId="client-admin-risk-category-management-modal--generic-dialog"
      :primaryAction="{ label: 'OK', eventName: 'primary-click' }"
      @primary-click="clientAdminRiskCategoryManagementGenericDialogDisplayed = false"
    >
      <template v-slot:cs-form-dialog-content>
        <v-spacer></v-spacer>
        <v-card-text>
          <div v-safe-html="riskCategoryGenericDialogMessage"></div>
        </v-card-text>
      </template>
    </cs-form-dialog>
  </v-container>
</template>

<script>
import { CSBase } from '@complispace/cs-design-system';
import { mapState } from 'vuex';
import { populateErrorMessage } from '../lib/errorMessages';

export default {
  extends: CSBase,

  data() {
    return {
      riskCategoryPresaveDialogDisplayed: false,
      groupHeaders: [
        {
          text: 'Select to Remove',
          value: 'riskCategoryBlacklistFlag',
          class: 'header',
          width: 100
        },
        {
          text: 'Risk Category',
          value: 'riskCategory',
          class: 'header',
          width: 200
        },
        {
          text: 'Risk Type',
          value: 'riskType',
          class: 'header',
          width: 200
        },
        {
          text: 'Risk Class',
          value: 'riskClass',
          class: 'header',
          width: 200
        },
        {
          text: 'Risk',
          value: 'riskName',
          class: 'header',
          width: 200
        }
      ],
      riskCategoryLoading: false,
      footerProps: {
        'items-per-page-options': [5, 10, 25]
      },
      riskCategoryList: [],
      originalRiskCategoryList: [],
      clientAdminRiskCategoryManagementGenericDialogDisplayed: false,
      riskCategoryGenericDialogMessage: '',
      riskCategoryGenericDialogSuccessMessage: 'Risk category blacklist has been updated.',
      clientRiskCategoryModalSaveActionInProgress: false
    };
  },
  computed: {
    ...mapState({
      clientSiteSettingsButtonDiplayed: (state) => state.common.clientSiteSettingsButtonDiplayed,
      riskCategoryManagementMenuItemDisplayed: (state) =>
        state.common.riskCategoryManagementMenuItemDisplayed,
      isAuthenticated: (state) => state.authorisation.isAuthenticated
    })
  },
  methods: {
    getRiskCategoryManagementCheckboxId(linkItem) {
      if (linkItem.riskType === '') {
        return `${linkItem.riskCategory.replaceAll(' ', '-')}`;
      }
      if (linkItem.riskClass === '') {
        return `${linkItem.riskCategory.replaceAll(' ', '-')}-${linkItem.riskType.replaceAll(
          ' ',
          '-'
        )}`;
      }
      if (linkItem.riskName === '') {
        return `${linkItem.riskCategory.replaceAll(' ', '-')}-${linkItem.riskType.replaceAll(
          ' ',
          '-'
        )}-${linkItem.riskClass.replaceAll(' ', '-')}`;
      }

      return `${linkItem.riskCategory.replaceAll(' ', '-')}-${linkItem.riskType.replaceAll(
        ' ',
        '-'
      )}-${linkItem.riskClass.replaceAll(' ', '-')}-${linkItem.riskName.replaceAll(' ', '-')}`;
    },
    resetgroupImportFileInputErrorMessage() {
      this.groupImportFileInputErrorMessage = '';
    },
    resetRiskCategorySelection() {
      this.riskCategoryList = JSON.parse(JSON.stringify(this.originalRiskCategoryList));
    },
    async saveClientRiskCategoryModal() {
      const newCategoryRiskBlacklist = this.riskCategoryList
        .filter((item) => item.riskCategoryBlacklistFlag)
        .map((item) => ({ [item.sid]: true }))
        .reduce((result, current) => ({ ...result, [Object.keys(current)[0]]: true }), {});

      const argHash = {
        payload: newCategoryRiskBlacklist,
        store: this.$store
      };
      this.clientRiskCategoryModalSaveActionInProgress = true;
      const cbResponse = await this.$app.stbApiAdapter.putClientRiskCategoryBlacklist(argHash);
      this.clientRiskCategoryModalSaveActionInProgress = false;
      this.riskCategoryPresaveDialogDisplayed = false;
      if (!cbResponse.error) {
        this.riskCategoryGenericDialogMessage = this.riskCategoryGenericDialogSuccessMessage;
        this.clientAdminRiskCategoryManagementGenericDialogDisplayed = true;
        this.originalRiskCategoryList = JSON.parse(JSON.stringify(this.riskCategoryList));
      } else {
        let dialogMessage = 'Can not update risk category blacklist.';
        if (cbResponse.refinedMessage) {
          dialogMessage = `${dialogMessage} ${cbResponse.refinedMessage}.`;
        }

        this.riskCategoryGenericDialogMessage = populateErrorMessage({
          message: dialogMessage,
          store: this.$store
        });
        this.clientAdminRiskCategoryManagementGenericDialogDisplayed = true;
      }
    },
    async getRiskCategories() {
      const { query } = this.$route;
      this.riskCategoryLoading = true;
      const cbResponse = await this.$app.stbApiAdapter.getClientRiskCategories({
        queryParam: query,
        store: this.$store
      });
      this.riskCategoryLoading = false;
      if (!cbResponse.error) {
        this.riskCategoryList = cbResponse.data;
        this.originalRiskCategoryList = JSON.parse(JSON.stringify(cbResponse.data));
      }
    },
    clickCheckbox(props) {
      const riskPath = String(props.item.path).split('|');
      if (riskPath.length === 1) {
        this.riskCategoryList.forEach((risk, riskIndex) => {
          if (risk.riskCategory === props.item.path) {
            this.riskCategoryList[riskIndex].riskCategoryBlacklistFlag =
              props.item.riskCategoryBlacklistFlag;
          }
        });
      }
      if (riskPath.length === 2) {
        this.riskCategoryList.forEach((risk, riskIndex) => {
          if (
            risk.riskCategory === props.item.riskCategory &&
            risk.riskType === props.item.riskType
          ) {
            this.riskCategoryList[riskIndex].riskCategoryBlacklistFlag =
              props.item.riskCategoryBlacklistFlag;
          }
        });
      }
      if (riskPath.length === 3) {
        this.riskCategoryList.forEach((risk, riskIndex) => {
          if (
            risk.riskCategory === props.item.riskCategory &&
            risk.riskType === props.item.riskType &&
            risk.riskClass === props.item.riskClass
          ) {
            this.riskCategoryList[riskIndex].riskCategoryBlacklistFlag =
              props.item.riskCategoryBlacklistFlag;
          }
        });
      }
    }
  },
  async mounted() {
    const { axiosInstance, eventPluginCommonAdapter, stbApiAdapter } = this.$app;
    const isVendorAuthScheme =
      !!this.$route.query['vendor-sid'] && !!this.$route.query['oauth-signature'];
    if (!isVendorAuthScheme) {
      await this.$store.dispatch('authorisation/validateAndSetUserTokenOrLogout', {
        query: this.$route.query,
        axiosInstance
      });
    }
    await this.$store.dispatch('common/initClientConfig', {
      queryParam: isVendorAuthScheme ? this.$route.query : {},
      eventPluginCommonAdapter,
      stbApiAdapter
    });
    if (!this.clientSiteSettingsButtonDiplayed || !this.riskCategoryManagementMenuItemDisplayed) {
      this.$router.push('/excursions');
    }
    if (this.isAuthenticated) {
      await this.getRiskCategories();
    }
  }
};
</script>
<style scoped>
.settings-container {
  width: 100%;
  height: 100%;
  max-width: unset;
  margin: 0;
  padding: 0;
}
.settings-content {
  text-align: left;
  height: 100vh;
}
.client-admin-risk-category-management-container {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 30px;
  height: 100%;
}
.risk-category-management-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
  display: flex;
}
.risk-category-management-description {
  display: flex;
  font-weight: 500;
  color: gray;
}
.risk-category-management-selector-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;
}
.risk-category-management-selector {
  font-weight: bold;
  color: gray;
}
.risk-category-btn {
  border: thin solid currentColor;
}
.risk-category-checkbox {
  padding-top: 1px;
}
.risk-category-checkbox > i {
  color: var(--v-primary-base) !important;
}
#clientAdminRiskCategorySaveBtn {
  padding: 8px 12px;
  text-transform: capitalize;
}
#clientAdminRiskCategoryCancelBtn {
  padding: 8px 12px;
  text-transform: capitalize;
}
</style>
