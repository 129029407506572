import * as types from '@/store/mutationTypes';
import stbAxios from '../../../../infrastructure/stbAxios/index';
import stbAPIGateway from '../../../services/stbAPIGateway/index';

export default {
  async setApiVersion({ rootGetters, commit }) {
    try {
      const axios = stbAxios.build(rootGetters['auth0/enabled']);
      const { version } = await stbAPIGateway(axios, process.env.API_URL).getHealthAlive();
      commit(types.SET_API_VERSION, version);
    } catch (err) {
      console.error(err);
    }
  }
};
