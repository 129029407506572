<template>
  <v-container grid-list-md text-xs-center class="settings-container">
    <v-flex>
      <v-card dark color="grey lighten-4" style="height: 100vh">
        <v-card-text class="px-0"></v-card-text>
      </v-card>
    </v-flex>
  </v-container>
</template>

<script>
import { CSBase } from '@complispace/cs-design-system';
import { mapState } from 'vuex';

export default {
  extends: CSBase,
  computed: {
    ...mapState({
      clientSiteSettingsButtonDiplayed: (state) => state.common.clientSiteSettingsButtonDiplayed
    })
  },
  async mounted() {
    const { axiosInstance, eventPluginCommonAdapter, stbApiAdapter } = this.$app;
    const isVendorAuthScheme =
      !!this.$route.query['vendor-sid'] && !!this.$route.query['oauth-signature'];
    if (!isVendorAuthScheme) {
      await this.$store.dispatch('authorisation/validateAndSetUserTokenOrLogout', {
        query: this.$route.query,
        axiosInstance
      });
    }
    await this.$store.dispatch('common/initClientConfig', {
      queryParam: isVendorAuthScheme ? this.$route.query : {},
      eventPluginCommonAdapter,
      stbApiAdapter
    });
    if (!this.clientSiteSettingsButtonDiplayed) {
      this.$router.push('/excursions');
    }
  }
};
</script>
<style scoped>
.settings-container {
  width: 100%;
  height: 100%;
  max-width: unset;
  margin: 0;
  padding: 0;
}
.settings-content {
  text-align: left;
  height: 100vh;
}
</style>
