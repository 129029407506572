import * as types from '@/store/mutationTypes';

import apiErrorMessage from './content/apiErrorMessage';
import errorCaseSid from './const/errorCaseSid';
import excursionStatus from './const/excursionStatus';

async function excursionResponseHandler(axiosInstance, axiosConfig, cbResponse) {
  const axiosResponse = await axiosInstance(axiosConfig);
  const localCbResponse = cbResponse;
  if (axiosResponse && axiosResponse.data.data) {
    const excursions = axiosResponse.data.data;
    localCbResponse.error = !Array.isArray(excursions);

    if (excursions.length > 0) {
      [localCbResponse.data] = excursions;
    }
  }

  return localCbResponse;
}

async function providerResponseHandler(axiosInstance, axiosConfig) {
  const axiosResponse = await axiosInstance(axiosConfig);
  return axiosResponse.data;
}

async function riskResponseHandler(axiosInstance, axiosConfig) {
  const axiosResponse = await axiosInstance(axiosConfig);
  return axiosResponse.data.data;
}
class Adapter {
  constructor(diHash) {
    const { axiosInstance, env } = diHash;
    this.axiosInstance = axiosInstance;
    this.apiUrl = `${env.API_URL}`.replace(/\/$/, '');
  }

  async getHealthAlive() {
    const url = '/health/alive';
    const axiosConfig = {
      method: 'GET',
      baseURL: this.apiUrl,
      url
    };
    const axiosResponse = await this.axiosInstance(axiosConfig);
    return axiosResponse.data;
  }

  async getRisks(argHash) {
    const { store, queryParam, excursionTypeName, excursionCopyModalHandlingExpected } = argHash;

    let cbResponse = {
      error: true
    };

    if (excursionTypeName) {
      let query = queryParam;
      if (!query) {
        query = {};
      }

      const apiParam = JSON.parse(JSON.stringify(query));
      if (!apiParam['vendor-sid'] && !apiParam['oauth-signature']) {
        const { userAuthSchemeApiParam } = store.state.common;
        $.extend(apiParam, userAuthSchemeApiParam);
      }
      const querystring = Object.keys(apiParam)
        .map((key) => `${key}=${encodeURIComponent(apiParam[key])}`)
        .join('&');

      const url = `/v1/cascading-risks/pcg/risk-categories/?${querystring}`;

      const axiosConfig = {
        method: 'POST',
        baseURL: this.apiUrl,
        url,
        data: {
          'excursion-type': excursionTypeName
        }
      };

      if (excursionCopyModalHandlingExpected) {
        try {
          cbResponse = await riskResponseHandler(this.axiosInstance, axiosConfig);
        } catch (error) {
          store.commit(types.COMMON_SET_ALL_RISKS_INITIALIZED, true);
          store.commit(types.COMMON_SET_IS_RESPONSE_WITH_ERROR, false);
          cbResponse = [];
        }
      } else {
        cbResponse = await riskResponseHandler(this.axiosInstance, axiosConfig);
      }
    }

    return cbResponse;
  }

  async getCompleteChecklist(argHash) {
    const { store, queryParam, frcs } = argHash;

    let localFrcs = [];
    if (frcs) {
      localFrcs = JSON.parse(JSON.stringify(frcs));

      if (store.state.common.excursionDc.typeId) {
        localFrcs.unshift([store.state.common.excursionDc.typeId]);
      }
    }

    let query = queryParam;
    if (!query) {
      query = {};
    }
    query['excursion-id'] = store.state.common.excursionDc.id;
    const apiParam = JSON.parse(JSON.stringify(query));
    if (!apiParam['vendor-sid'] && !apiParam['oauth-signature']) {
      const { userAuthSchemeApiParam } = store.state.common;
      $.extend(apiParam, userAuthSchemeApiParam);
    }
    const querystring = Object.keys(apiParam)
      .map((key) => `${key}=${encodeURIComponent(apiParam[key])}`)
      .join('&');
    const url = `/v1/cascading-risks/pcg/risk-bundles/?${querystring}`;
    const axiosConfig = {
      method: 'POST',
      baseURL: this.apiUrl,
      url,
      data: {
        'risk-categories': localFrcs
      }
    };
    const axiosResponse = await this.axiosInstance(axiosConfig);
    return axiosResponse.data.data;
  }

  async getExcursionTypes(argHash) {
    const { store, queryParam } = argHash;
    let query = queryParam;
    if (!query) {
      query = {};
    }
    const apiParam = JSON.parse(JSON.stringify(query));
    if (!apiParam['vendor-sid'] && !apiParam['oauth-signature']) {
      const { userAuthSchemeApiParam } = store.state.common;
      $.extend(apiParam, userAuthSchemeApiParam);
    }
    const querystring = Object.keys(apiParam)
      .map((key) => `${key}=${encodeURIComponent(apiParam[key])}`)
      .join('&');

    const url = `/v1/cascading-risks/pcg/excursion-types?${querystring}`;
    const axiosConfig = {
      method: 'GET',
      baseURL: this.apiUrl,
      url
    };
    const cbResponse = {
      error: false
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      cbResponse.data = axiosResponse.data.data;
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async getViewPolicy(argHash) {
    let { queryParam } = argHash;
    const { store, frcs } = argHash;

    let localFrcs = [];
    if (frcs) {
      localFrcs = JSON.parse(JSON.stringify(frcs));

      if (store.state.common.excursionDc.typeId) {
        localFrcs.unshift([store.state.common.excursionDc.typeId]);
      }
    }

    if (!queryParam) {
      queryParam = {};
    }
    queryParam['excursion-id'] = store.state.common.excursionDc.id;
    const apiParam = JSON.parse(JSON.stringify(queryParam));
    if (!apiParam['vendor-sid'] && !apiParam['oauth-signature']) {
      const { userAuthSchemeApiParam } = store.state.common;
      $.extend(apiParam, userAuthSchemeApiParam);
    }
    const querystring = Object.keys(apiParam)
      .map((key) => `${key}=${encodeURIComponent(apiParam[key])}`)
      .join('&');

    const url = `/v1/cascading-risks/pcg/risk-bundles?${querystring}`;
    const axiosConfig = {
      method: 'POST',
      baseURL: this.apiUrl,
      url,
      data: {
        'risk-categories': localFrcs
      }
    };
    const axiosResponse = await this.axiosInstance(axiosConfig);
    return axiosResponse.data.data;
  }

  async postExcursionAssessments(argHash) {
    const {
      store,
      queryParam,
      excursionId,
      allChecklistsExported,
      clientLogoEnabled,
      predepartureChecklistExported,
      allAttachmentsExported,
      format = 'pdf'
    } = argHash;
    let query = queryParam;
    if (!query) {
      query = {};
    }
    const apiParam = JSON.parse(JSON.stringify(query));
    store.commit(types.COMMON_SET_API_PARAM, apiParam);
    const querystring = Object.keys(apiParam)
      .map((key) => `${key}=${encodeURIComponent(apiParam[key])}`)
      .join('&');
    const url = `/v1/excursions/${excursionId}/assessments?${querystring}`;
    const axiosConfig = {
      method: 'POST',
      baseURL: this.apiUrl,
      url,
      data: {
        'all-checklists-exported': !!allChecklistsExported,
        'predeparture-checklist-exported': !!predepartureChecklistExported,
        'client-logo-enabled': !!clientLogoEnabled,
        'all-attachments-exported': !!allAttachmentsExported,
        format
      }
    };
    const axiosResponse = await this.axiosInstance(axiosConfig);
    return axiosResponse.data;
  }

  async postExcursionRiskAssessmentGuides(argHash) {
    const { store, queryParam, excursionId, clientLogoEnabled } = argHash;
    let query = queryParam;
    if (!query) {
      query = {};
    }
    const apiParam = JSON.parse(JSON.stringify(query));
    store.commit(types.COMMON_SET_API_PARAM, apiParam);
    const querystring = Object.keys(apiParam)
      .map((key) => `${key}=${encodeURIComponent(apiParam[key])}`)
      .join('&');
    const url = `/v1/excursions/${excursionId}/risk/assessments?${querystring}`;
    const axiosConfig = {
      method: 'POST',
      baseURL: this.apiUrl,
      url,
      data: {
        'client-logo-enabled': !!clientLogoEnabled
      }
    };
    const axiosResponse = await this.axiosInstance(axiosConfig);
    return axiosResponse.data;
  }

  async postExcursionPredepartureStrategies(argHash) {
    const { store } = argHash;
    let { queryParam } = argHash;
    if (!queryParam) {
      queryParam = {};
    }

    const { query } = queryParam.params;
    const { payload } = queryParam.params;

    const apiParam = JSON.parse(JSON.stringify(query));
    if (!apiParam['vendor-sid'] && !apiParam['oauth-signature']) {
      const { userAuthSchemeApiParam } = store.state.common;
      $.extend(apiParam, userAuthSchemeApiParam);
    }

    const querystring = Object.keys(apiParam)
      .map((key) => `${key}=${encodeURIComponent(apiParam[key])}`)
      .join('&');

    const url = `/v1/excursions/${queryParam.excursionId}/strategies/predeparture?${querystring}`;
    const axiosConfig = {
      method: 'POST',
      baseURL: this.apiUrl,
      url,
      'Content-Type': 'application/json',
      data: payload
    };
    const axiosResponse = await this.axiosInstance(axiosConfig);
    return axiosResponse.data;
  }

  async putExcursionPredepartureStrategies(argHash) {
    let { queryParam } = argHash;
    const { store } = argHash;
    if (!queryParam) {
      queryParam = {};
    }

    const { query } = queryParam.params;
    const { payload } = queryParam.params;

    const apiParam = JSON.parse(JSON.stringify(query));
    if (!apiParam['vendor-sid'] && !apiParam['oauth-signature']) {
      const { userAuthSchemeApiParam } = store.state.common;
      $.extend(apiParam, userAuthSchemeApiParam);
    }

    const { predepartures } = payload;
    predepartures.forEach((predeparture) => {
      const localPredeparture = predeparture;
      localPredeparture.id = `${localPredeparture.id}`;
    });

    const querystring = Object.keys(apiParam)
      .map((key) => `${key}=${encodeURIComponent(apiParam[key])}`)
      .join('&');
    const url = `/v1/excursions/${queryParam.excursionId}/strategies/predeparture?${querystring}`;
    const axiosConfig = {
      method: 'PUT',
      baseURL: this.apiUrl,
      url,
      'Content-Type': 'application/json',
      data: payload
    };
    const axiosResponse = await this.axiosInstance(axiosConfig);
    return axiosResponse.data;
  }

  async getProviders(argHash) {
    const { store } = argHash;
    let { queryParam } = argHash;
    if (!queryParam) {
      queryParam = {};
    }
    const apiParam = JSON.parse(JSON.stringify(queryParam));
    store.commit(types.COMMON_SET_API_PARAM, apiParam);
    const querystring = Object.keys(apiParam)
      .map((key) => `${key}=${encodeURIComponent(apiParam[key])}`)
      .join('&');

    const url = `/v1/providers?${querystring}`;
    const axiosConfig = {
      method: 'GET',
      baseURL: this.apiUrl,
      url,
      'Content-Type': 'application/json'
    };
    const axiosResponse = await this.axiosInstance(axiosConfig);
    return axiosResponse.data.data;
  }

  async postProviders(argHash) {
    const { store, excursionCopyModalHandlingExpected } = argHash;
    let { queryParam } = argHash;
    if (!queryParam) {
      queryParam = {};
    }
    const apiParam = JSON.parse(JSON.stringify(queryParam));
    store.commit(types.COMMON_SET_API_PARAM, apiParam);
    const querystring = Object.keys(apiParam)
      .map((key) => `${key}=${encodeURIComponent(apiParam[key])}`)
      .join('&');

    const url = `/v1/providers?${querystring}`;
    const axiosConfig = {
      method: 'POST',
      baseURL: this.apiUrl,
      url,
      'Content-Type': 'application/json'
    };

    if (excursionCopyModalHandlingExpected) {
      try {
        return await providerResponseHandler(this.axiosInstance, axiosConfig);
      } catch (error) {
        store.commit(types.COMMON_SET_ALL_PROVIDERS_INITIALIZED, true);
        store.commit(types.COMMON_SET_IS_RESPONSE_WITH_ERROR, false);
        return [];
      }
    }

    return providerResponseHandler(this.axiosInstance, axiosConfig);
  }

  async getExcursionList(argHash) {
    const { filterParam } = argHash;
    const cbResponse = {
      error: true
    };
    let querystring = '';
    if (filterParam) {
      querystring = Object.keys(filterParam)
        .map((key) => `${key}=${encodeURIComponent(filterParam[key])}`)
        .join('&');
    }
    const url = `/v1/excursions?${querystring}`;
    const axiosConfig = {
      method: 'GET',
      baseURL: this.apiUrl,
      url
    };
    const axiosResponse = await this.axiosInstance(axiosConfig);
    if (axiosResponse && axiosResponse.data.data) {
      const excursions = axiosResponse.data.data;
      cbResponse.error = !Array.isArray(excursions);
      cbResponse.data = axiosResponse.data;
    }
    return cbResponse;
  }

  async getExcursionExports(argHash) {
    const { filterParam } = argHash;
    const cbResponse = {
      error: true
    };
    let querystring = '';
    if (filterParam) {
      querystring = Object.keys(filterParam)
        .map((key) => `${key}=${encodeURIComponent(filterParam[key])}`)
        .join('&');
    }
    const url = `/v1/excursions/exports?${querystring}`;
    const axiosConfig = {
      method: 'GET',
      baseURL: this.apiUrl,
      url
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      if (apiErr && apiErr.response && apiErr.response.data) {
        cbResponse.message = apiErr.response.data.message;
      }
    }

    return cbResponse;
  }

  async getExcursions(argHash) {
    const { excursionId, query, store, excursionCopyModalHandlingExpected } = argHash;

    // This querystring appended to the request URL to handle authentication using oauth-signature
    const querystring = Object.keys(query)
      .map((key) => `${key}=${encodeURIComponent(query[key])}`)
      .join('&');

    const cbResponse = {
      error: true
    };

    const url = `/v1/excursions/${excursionId}?${querystring}`;
    const axiosConfig = {
      method: 'GET',
      baseURL: this.apiUrl,
      url
    };

    if (excursionCopyModalHandlingExpected) {
      try {
        return await excursionResponseHandler(this.axiosInstance, axiosConfig, cbResponse);
      } catch (error) {
        store.commit(types.COMMON_SET_EXCURSION_INITIALIZED, true);
        store.commit(types.COMMON_SET_IS_RESPONSE_WITH_ERROR, false);
        return [];
      }
    }

    return excursionResponseHandler(this.axiosInstance, axiosConfig, cbResponse);
  }

  async getExcursionStudentMedicalConditionList(argHash) {
    const { excursionId, query } = argHash;

    const querystring = Object.keys(query)
      .map((key) => `${key}=${encodeURIComponent(query[key])}`)
      .join('&');

    const cbResponse = {
      error: true
    };

    const url = `/v1/excursions/${excursionId}/students/medical-conditions?${querystring}`;
    const axiosConfig = {
      method: 'GET',
      baseURL: this.apiUrl,
      url
    };

    const axiosResponse = await this.axiosInstance(axiosConfig);
    if (axiosResponse && axiosResponse.data.data) {
      const studentMedicalConditions = axiosResponse.data.data;
      cbResponse.error = !Array.isArray(studentMedicalConditions);
      cbResponse.data = axiosResponse.data;
    }

    return cbResponse;
  }

  async putExcursionStudentMedicalConditionSync(argHash) {
    const { excursionId } = argHash;

    const cbResponse = {
      error: true
    };

    const url = `/v1/excursions/${excursionId}/students/medical-conditions/sync`;
    const axiosConfig = {
      method: 'PUT',
      baseURL: this.apiUrl,
      url
    };

    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      if (apiErr && apiErr.response && apiErr.response.data) {
        cbResponse.message = apiErr.response.data.message;
      }
    }
    return cbResponse;
  }

  async getExcursionStudentMedicalConditionSync(argHash) {
    const { excursionId } = argHash;

    const cbResponse = {
      error: true
    };

    const url = `/v1/excursions/${excursionId}/students/medical-conditions/sync`;
    const axiosConfig = {
      method: 'GET',
      baseURL: this.apiUrl,
      url
    };

    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data.studentMedicalConditionChangeDetected;
      }
    } catch (apiErr) {
      if (apiErr && apiErr.response && apiErr.response.data) {
        cbResponse.message = apiErr.response.data.message;
      }
    }
    return cbResponse;
  }

  async postExcursions(argHash) {
    let { queryParam } = argHash;
    const { store, payload } = argHash;
    if (!queryParam) {
      queryParam = {};
    }
    const apiParam = JSON.parse(JSON.stringify(queryParam));
    store.commit(types.COMMON_SET_API_PARAM, apiParam);
    const querystring = Object.keys(apiParam)
      .map((key) => `${key}=${encodeURIComponent(apiParam[key])}`)
      .join('&');
    payload.metadata.ceCommitOid = store.state.common.ceCommitOid;
    const cbResponse = {
      error: true
    };
    const url = `/v1/excursions?${querystring}`;
    const axiosConfig = {
      method: 'POST',
      baseURL: this.apiUrl,
      url,
      data: payload
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      if (apiErr && apiErr.response && apiErr.response.data) {
        cbResponse.message = apiErr.response.data.message;
      }
    }
    return cbResponse;
  }

  async putExcursions(argHash) {
    let { queryParam } = argHash;
    const { store, id, payload } = argHash;
    if (!queryParam) {
      queryParam = {};
    }
    const apiParam = JSON.parse(JSON.stringify(queryParam));
    store.commit(types.COMMON_SET_API_PARAM, apiParam);
    const querystring = Object.keys(apiParam)
      .map((key) => `${key}=${encodeURIComponent(apiParam[key])}`)
      .join('&');
    payload.metadata.ceCommitOid = store.state.common.ceCommitOid;

    const cbResponse = {
      error: true
    };
    const url = `/v1/excursions/${id}?${querystring}`;
    const axiosConfig = {
      method: 'PUT',
      baseURL: this.apiUrl,
      url,
      data: payload
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      if (
        apiErr &&
        apiErr.response &&
        apiErr.response.data &&
        `${apiErr.response.data.statusCode}` === '409'
      ) {
        store.commit(types.COMMON_SET_IS_RESPONSE_WITH_ERROR, false);
        cbResponse.message = apiErrorMessage.excursion_conflict_message;
      }
    }

    return cbResponse;
  }

  async postExcursionRiskContents(argHash) {
    let { queryParam } = argHash;
    const { store, params } = argHash;
    if (!queryParam) {
      queryParam = {};
    }
    const excursionId = params['excursion-id'] ? params['excursion-id'] : 0;
    const apiParam = JSON.parse(JSON.stringify(queryParam));
    store.commit(types.COMMON_SET_API_PARAM, apiParam);
    const querystring = Object.keys(apiParam)
      .map((key) => `${key}=${encodeURIComponent(apiParam[key])}`)
      .join('&');

    const url = `/v1/excursions/${excursionId}/risk-contents?${querystring}`;
    const axiosConfig = {
      method: 'POST',
      baseURL: this.apiUrl,
      url,
      data: {
        'client-logo-enabled': params['client-logo-enabled']
          ? params['client-logo-enabled']
          : false,
        'mandatory-checklists-exported': params['mandatory-checklists-exported']
          ? params['mandatory-checklists-exported']
          : false,
        'category-checklists': params['category-checklists'] ? params['category-checklists'] : [],
        'master-policy-exported': params['master-policy-exported']
          ? params['master-policy-exported']
          : false,
        'category-checklists-exported': params['category-checklists-exported']
          ? params['category-checklists-exported']
          : false,
        'risk-categories': params['risk-categories'] ? params['risk-categories'] : [],
        'excursion-policies': params['excursion-policies'] ? params['excursion-policies'] : []
      }
    };
    const axiosResponse = await this.axiosInstance(axiosConfig);
    return axiosResponse.data;
  }

  async getClientList(argHash) {
    const { filterParam } = argHash;
    const querystring = Object.keys(filterParam)
      .map((key) => `${key}=${encodeURIComponent(filterParam[key])}`)
      .join('&');

    const cbResponse = {
      error: true
    };
    const url = `/v1/clients?${querystring}`;
    const axiosConfig = {
      method: 'GET',
      baseURL: this.apiUrl,
      url
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        const clients = axiosResponse.data.data;
        cbResponse.error = !Array.isArray(clients);
        cbResponse.data = axiosResponse.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async getClientClientList(argHash) {
    const { filterParam } = argHash;
    const querystring = Object.keys(filterParam)
      .map((key) => `${key}=${encodeURIComponent(filterParam[key])}`)
      .join('&');

    const cbResponse = {
      error: true
    };
    const url = `/v1/clients/clients?${querystring}`;
    const axiosConfig = {
      method: 'GET',
      baseURL: this.apiUrl,
      url
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        const clients = axiosResponse.data.data;
        cbResponse.error = !Array.isArray(clients);
        cbResponse.data = axiosResponse.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async postClients(argHash) {
    const { payload, store } = argHash;
    let cbResponse = {
      error: true
    };
    const url = `/v1/clients`;
    const axiosConfig = {
      method: 'POST',
      baseURL: this.apiUrl,
      url,
      data: payload
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      store.commit(types.COMMON_SET_IS_RESPONSE_WITH_ERROR, false);
      if (
        apiErr &&
        apiErr.response &&
        apiErr.response.data &&
        (`${apiErr.response.data.statusCode}` === '409' ||
          (`${apiErr.response.data.statusCode}` === '400' &&
            errorCaseSid.CONTENT_ENGINE_PARAMETER_INVALID === apiErr.response.data.errorCaseSid))
      ) {
        cbResponse = apiErr.response;
      } else {
        cbResponse.error = true;
      }
    }
    return cbResponse;
  }

  async putClients(argHash) {
    const { sid, payload, store } = argHash;
    let cbResponse = {
      error: true
    };
    const url = `/v1/clients/${sid}`;
    const axiosConfig = {
      method: 'PUT',
      baseURL: this.apiUrl,
      url,
      data: payload
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      store.commit(types.COMMON_SET_IS_RESPONSE_WITH_ERROR, false);
      if (
        apiErr &&
        apiErr.response &&
        apiErr.response.data &&
        (`${apiErr.response.data.statusCode}` === '409' ||
          (`${apiErr.response.data.statusCode}` === '400' &&
            errorCaseSid.CONTENT_ENGINE_PARAMETER_INVALID === apiErr.response.data.errorCaseSid) ||
          (`${apiErr.response.data.statusCode}` === '400' &&
            errorCaseSid.PROVIDER_PARAMETER_INVALID === apiErr.response.data.errorCaseSid))
      ) {
        cbResponse = apiErr.response;
      } else {
        cbResponse.error = true;
      }
    }
    return cbResponse;
  }

  async postExcursionAttachments(argHash) {
    let { queryParam } = argHash;
    const { excursionId, payload } = argHash;
    if (!queryParam) {
      queryParam = {};
    }
    const apiParam = JSON.parse(JSON.stringify(queryParam));
    const querystring = Object.keys(apiParam)
      .map((key) => `${key}=${encodeURIComponent(apiParam[key])}`)
      .join('&');

    const cbResponse = {
      error: true
    };
    const url = `/v1/excursions/${excursionId}/attachments?${querystring}`;
    const axiosConfig = {
      method: 'POST',
      baseURL: this.apiUrl,
      url,
      data: payload
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async postClientAttachmentCategories(argHash) {
    const { sid, payload } = argHash;
    const cbResponse = {
      error: true
    };
    const url = `/v1/clients/${sid}/attachments/categories`;
    const axiosConfig = {
      method: 'POST',
      baseURL: this.apiUrl,
      url,
      data: payload
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async postClientSubjectImportPreviews(argHash) {
    const { payload } = argHash;
    const cbResponse = {
      error: true
    };
    const url = `/v1/clients/subjects/imports/previews`;
    const axiosConfig = {
      method: 'POST',
      baseURL: this.apiUrl,
      url,
      data: payload
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (
        axiosResponse &&
        axiosResponse.data &&
        axiosResponse.data.data &&
        axiosResponse.data.data.clientApiMetadata &&
        axiosResponse.data.data.schoolType
      ) {
        cbResponse.error = false;
        cbResponse.clientApiMetadata = axiosResponse.data.data.clientApiMetadata;
        cbResponse.schoolType = axiosResponse.data.data.schoolType;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async getClients(argHash) {
    const { sid, requestParam } = argHash;
    const cbResponse = {
      error: true
    };
    const url = `/v1/clients/${sid}`;
    const axiosConfig = {
      method: 'GET',
      baseURL: this.apiUrl,
      url,
      params: requestParam
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async getClientDomains(argHash) {
    const { domain } = argHash;
    const cbResponse = {
      error: true
    };
    const url = `/v1/clients/domains/${domain}`;
    const axiosConfig = {
      method: 'GET',
      baseURL: this.apiUrl,
      url
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async putExcursionAttachments(argHash) {
    let { queryParam } = argHash;
    const { excursionId, attachmentSid, payload } = argHash;
    const cbResponse = {
      error: true
    };
    if (!queryParam) {
      queryParam = {};
    }
    const apiParam = JSON.parse(JSON.stringify(queryParam));
    const querystring = Object.keys(apiParam)
      .map((key) => `${key}=${encodeURIComponent(apiParam[key])}`)
      .join('&');

    const url = `/v1/excursions/${excursionId}/attachments/${attachmentSid}?${querystring}`;
    const axiosConfig = {
      method: 'PUT',
      baseURL: this.apiUrl,
      url,
      data: payload
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async deleteExcursionAttachments(argHash) {
    let { queryParam } = argHash;
    const { excursionId, attachmentSid } = argHash;
    const cbResponse = {
      error: true
    };
    if (!queryParam) {
      queryParam = {};
    }
    const apiParam = JSON.parse(JSON.stringify(queryParam));
    const querystring = Object.keys(apiParam)
      .map((key) => `${key}=${encodeURIComponent(apiParam[key])}`)
      .join('&');

    const url = `/v1/excursions/${excursionId}/attachments/${attachmentSid}?${querystring}`;
    const axiosConfig = {
      method: 'DELETE',
      baseURL: this.apiUrl,
      url
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async deleteRiskCaches(argHash) {
    let { queryParam } = argHash;
    const { store } = argHash;
    const cbResponse = {
      error: true
    };
    if (!queryParam) {
      queryParam = {};
    }

    const query = {
      'ce-api-uri': queryParam.ceApiUri,
      'x-site-uri': queryParam.xSiteUri,
      'module-name': queryParam.moduleName
    };

    const apiParam = JSON.parse(JSON.stringify(query));
    const querystring = Object.keys(apiParam)
      .map((key) => `${key}=${encodeURIComponent(apiParam[key])}`)
      .join('&');

    const url = `/v1/cascading-risks/caches?${querystring}`;
    const axiosConfig = {
      method: 'DELETE',
      baseURL: this.apiUrl,
      url
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      store.commit(types.COMMON_SET_IS_RESPONSE_WITH_ERROR, false);
      if (
        apiErr &&
        apiErr.response &&
        apiErr.response.data &&
        `${apiErr.response.data.statusCode}` === '400'
      ) {
        cbResponse.error = false;
        cbResponse.data = apiErr.response.data;
      } else {
        cbResponse.error = true;
      }
    }
    return cbResponse;
  }

  async getAttachments(argHash) {
    let { queryParam } = argHash;
    const { sid, store } = argHash;

    if (!queryParam) {
      queryParam = {};
    }
    const apiParam = JSON.parse(JSON.stringify(queryParam));
    store.commit(types.COMMON_SET_API_PARAM, apiParam);
    const querystring = Object.keys(apiParam)
      .map((key) => `${key}=${encodeURIComponent(apiParam[key])}`)
      .join('&');

    const cbResponse = {
      error: true
    };
    const url = `/v1/attachments/${sid}?${querystring}`;
    const axiosConfig = {
      method: 'GET',
      baseURL: this.apiUrl,
      url
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async putExcursionStatuses(argHash) {
    let { queryParam, newStatus } = argHash;
    const { store, id } = argHash;
    if (!newStatus) {
      newStatus = excursionStatus.DRAFT;
    }
    if (!queryParam) {
      queryParam = {};
    }
    const apiParam = JSON.parse(JSON.stringify(queryParam));
    store.commit(types.COMMON_SET_API_PARAM, apiParam);
    const querystring = Object.keys(apiParam)
      .map((key) => `${key}=${encodeURIComponent(apiParam[key])}`)
      .join('&');

    const payload = {
      status: newStatus
    };

    const cbResponse = {
      error: true
    };
    const url = `/v1/excursions/${id}/statuses?${querystring}`;
    const axiosConfig = {
      method: 'PUT',
      baseURL: this.apiUrl,
      url,
      data: payload
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async getVendorList(argHash) {
    let { queryParam } = argHash;
    if (!queryParam) {
      queryParam = {};
    }
    const apiParam = JSON.parse(JSON.stringify(queryParam));
    const querystring = Object.keys(apiParam)
      .map((key) => `${key}=${encodeURIComponent(apiParam[key])}`)
      .join('&');
    const url = `/v1/vendors?${querystring}`;
    const axiosConfig = {
      method: 'GET',
      baseURL: this.apiUrl,
      url
    };
    const axiosResponse = await this.axiosInstance(axiosConfig);
    return axiosResponse.data.data;
  }

  async getStudentManagementVendorList() {
    const argHash = {
      queryParam: {
        type: 'student'
      }
    };

    const vendorList = await this.getVendorList(argHash);
    return vendorList;
  }

  async getExcursionManagementVendorList() {
    const argHash = {
      queryParam: {
        type: 'excursion'
      }
    };

    const vendorList = await this.getVendorList(argHash);
    return vendorList;
  }

  async postPredepartureStrategies(argHash) {
    const { payload } = argHash;
    let { queryParam } = argHash;
    if (!queryParam) {
      queryParam = {};
    }
    const apiParam = JSON.parse(JSON.stringify(queryParam));
    const querystring = Object.keys(apiParam)
      .map((key) => `${key}=${encodeURIComponent(apiParam[key])}`)
      .join('&');
    const url = `/v1/strategies/predeparture?${querystring}`;
    const axiosConfig = {
      method: 'POST',
      baseURL: this.apiUrl,
      url,
      data: payload
    };
    const axiosResponse = await this.axiosInstance(axiosConfig);
    return axiosResponse.data.data;
  }

  async getClientUserList(argHash) {
    const { filterParam } = argHash;
    const cbResponse = {
      error: true
    };

    const apiParam = JSON.parse(JSON.stringify(filterParam));
    const querystring = Object.keys(apiParam)
      .map((key) => `${key}=${encodeURIComponent(apiParam[key])}`)
      .join('&');

    const url = `/v1/clients/users?${querystring}`;
    const axiosConfig = {
      method: 'GET',
      baseURL: this.apiUrl,
      url
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async getClientStaffList(argHash) {
    const { filterParam } = argHash;
    const cbResponse = {
      error: true
    };

    const apiParam = { ...filterParam };
    const querystring = Object.keys(apiParam)
      .map((key) => `${key}=${encodeURIComponent(apiParam[key])}`)
      .join('&');

    const url = `/v1/clients/pcg/staffs?${querystring}`;
    const axiosConfig = {
      method: 'GET',
      baseURL: this.apiUrl,
      url
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async getUserList(argHash) {
    const { filterParam } = argHash;
    const cbResponse = {
      error: true
    };
    const apiParam = JSON.parse(JSON.stringify(filterParam));
    const querystring = Object.keys(apiParam)
      .map((key) => `${key}=${encodeURIComponent(apiParam[key])}`)
      .join('&');

    const url = `/v1/users?${querystring}`;
    const axiosConfig = {
      method: 'GET',
      baseURL: this.apiUrl,
      url
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async postClientUsers(argHash) {
    const { payload, store } = argHash;
    let cbResponse = {
      error: true
    };
    const url = `/v1/clients/users`;
    const axiosConfig = {
      method: 'POST',
      baseURL: this.apiUrl,
      url,
      data: payload.user
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      store.commit(types.COMMON_SET_IS_RESPONSE_WITH_ERROR, false);
      if (
        apiErr &&
        apiErr.response &&
        apiErr.response.data &&
        `${apiErr.response.data.statusCode}` === '409'
      ) {
        cbResponse = apiErr.response.data;
      } else {
        cbResponse.error = true;
      }
    }
    return cbResponse;
  }

  async postUsers(argHash) {
    const { payload, store } = argHash;
    let cbResponse = {
      error: true
    };
    const url = `/v1/users`;
    const axiosConfig = {
      method: 'POST',
      baseURL: this.apiUrl,
      url,
      data: payload.user
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      if (
        apiErr &&
        apiErr.response &&
        apiErr.response.data &&
        `${apiErr.response.data.statusCode}` === '409'
      ) {
        store.commit(types.COMMON_SET_IS_RESPONSE_WITH_ERROR, false);
        cbResponse = apiErr.response.data;
      } else {
        cbResponse.error = true;
      }
    }
    return cbResponse;
  }

  async putClientUsers(argHash) {
    const { id, payload, store } = argHash;
    let cbResponse = {
      error: true
    };
    const url = `/v1/clients/users/${id}`;
    const axiosConfig = {
      method: 'PUT',
      baseURL: this.apiUrl,
      url,
      data: payload.user
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      store.commit(types.COMMON_SET_IS_RESPONSE_WITH_ERROR, false);
      if (
        apiErr &&
        apiErr.response &&
        apiErr.response.data &&
        (`${apiErr.response.data.statusCode}` === '409' ||
          `${apiErr.response.data.statusCode}` === '400')
      ) {
        cbResponse = apiErr.response.data;
      } else {
        cbResponse.error = true;
      }
    }
    return cbResponse;
  }

  async putUsers(argHash) {
    const { id, payload, store } = argHash;
    let cbResponse = {
      error: true
    };
    const url = `/v1/users/${id}`;
    const axiosConfig = {
      method: 'PUT',
      baseURL: this.apiUrl,
      url,
      data: payload.user
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      if (
        apiErr &&
        apiErr.response &&
        apiErr.response.data &&
        `${apiErr.response.data.statusCode}` === '409'
      ) {
        store.commit(types.COMMON_SET_IS_RESPONSE_WITH_ERROR, false);
        cbResponse = apiErr.response.data;
      } else {
        cbResponse.error = true;
      }
    }
    return cbResponse;
  }

  async getClientUsers(argHash) {
    const { id, store } = argHash;
    let cbResponse = {
      error: true
    };
    const url = `/v1/clients/users/${id}`;
    const axiosConfig = {
      method: 'GET',
      baseURL: this.apiUrl,
      url
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      if (
        apiErr &&
        apiErr.response &&
        apiErr.response.data &&
        `${apiErr.response.data.statusCode}` === '409'
      ) {
        store.commit(types.COMMON_SET_IS_RESPONSE_WITH_ERROR, false);
        cbResponse = apiErr.response.data;
      } else {
        cbResponse.error = true;
      }
    }
    return cbResponse;
  }

  async getUsers(argHash) {
    const { id } = argHash;
    const cbResponse = {
      error: true
    };
    const url = `/v1/users/${id}`;
    const axiosConfig = {
      method: 'GET',
      baseURL: this.apiUrl,
      url
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async getClientSetups(argHash) {
    let { queryParam } = argHash;
    if (!queryParam) {
      queryParam = {};
    }
    const apiParam = JSON.parse(JSON.stringify(queryParam));
    const querystring = Object.keys(apiParam)
      .map((key) => `${key}=${encodeURIComponent(apiParam[key])}`)
      .join('&');
    const url = `/v1/clients/setups?${querystring}`;
    const axiosConfig = {
      method: 'GET',
      baseURL: this.apiUrl,
      url
    };
    const cbResponse = {
      error: true
    };

    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async putClientUserEnabled(argHash) {
    const { id, payload } = argHash;
    const cbResponse = {
      error: true
    };
    const url = `/v1/clients/users/${id}/enabled`;
    const axiosConfig = {
      method: 'PUT',
      baseURL: this.apiUrl,
      url,
      data: payload
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async postClientUserImports(argHash) {
    const { payload, store } = argHash;
    let cbResponse = {
      error: true
    };
    const url = `/v1/clients/users/imports`;
    const axiosConfig = {
      method: 'POST',
      baseURL: this.apiUrl,
      url,
      data: payload
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      if (
        apiErr &&
        apiErr.response &&
        apiErr.response.data &&
        `${apiErr.response.data.statusCode}` === '400' &&
        apiErr.response.data.errorCaseSid
      ) {
        store.commit(types.COMMON_SET_IS_RESPONSE_WITH_ERROR, false);
        cbResponse = apiErr.response.data;
      } else {
        cbResponse.error = true;
      }
    }
    return cbResponse;
  }

  async postClientSubjectImports(argHash) {
    const { payload, store } = argHash;
    let cbResponse = {
      error: true
    };
    const url = `/v1/clients/subjects/imports`;
    const axiosConfig = {
      method: 'POST',
      baseURL: this.apiUrl,
      url,
      data: payload
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      store.commit(types.COMMON_SET_IS_RESPONSE_WITH_ERROR, false);
      if (
        apiErr &&
        apiErr.response &&
        apiErr.response.data &&
        `${apiErr.response.data.statusCode}` === '400' &&
        apiErr.response.data.errorSid
      ) {
        cbResponse = apiErr.response.data;
      } else {
        cbResponse.error = true;
      }
    }
    return cbResponse;
  }

  async getClientSubjectExports(argHash) {
    const { store } = argHash;
    let cbResponse = {
      error: true
    };
    const url = `/v1/clients/subjects/exports`;
    const axiosConfig = {
      method: 'GET',
      baseURL: this.apiUrl,
      url
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      store.commit(types.COMMON_SET_IS_RESPONSE_WITH_ERROR, false);
      if (
        apiErr &&
        apiErr.response &&
        apiErr.response.data &&
        `${apiErr.response.data.statusCode}` === '400' &&
        apiErr.response.data.errorCaseSid
      ) {
        cbResponse = apiErr.response.data;
      } else {
        cbResponse.error = true;
      }
    }

    return cbResponse;
  }

  async putUserEnabled(argHash) {
    const { id, payload } = argHash;
    const cbResponse = {
      error: true
    };
    const url = `/v1/users/${id}/enabled`;
    const axiosConfig = {
      method: 'PUT',
      baseURL: this.apiUrl,
      url,
      data: payload
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async getAuditExcursions(argHash) {
    const { id, query } = argHash;
    const querystring = Object.keys(query)
      .map((key) => `${key}=${encodeURIComponent(query[key])}`)
      .join('&');

    const cbResponse = {
      error: true
    };
    const url = `/v1/audit/excursions/${id}?${querystring}`;
    const axiosConfig = {
      method: 'GET',
      baseURL: this.apiUrl,
      url
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async getClientStudentGroupList(argHash) {
    const { studentVendorParams, store } = argHash;
    let cbResponse = {
      error: true
    };
    const querystring = Object.keys(studentVendorParams)
      .map((key) => `${key}=${encodeURIComponent(studentVendorParams[key])}`)
      .join('&');
    const url = `/v1/clients/students/groups?${querystring}`;
    const axiosConfig = {
      method: 'GET',
      baseURL: this.apiUrl,
      url
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      if (
        apiErr &&
        apiErr.response &&
        apiErr.response.data &&
        `${apiErr.response.data.statusCode}` === '503' &&
        apiErr.response.data.errorCaseSid
      ) {
        store.commit(types.COMMON_SET_IS_RESPONSE_WITH_ERROR, false);
        cbResponse = apiErr.response.data;
      } else {
        cbResponse.error = true;
      }
    }
    return cbResponse;
  }

  async putClientEnabled(argHash) {
    const { sid, payload } = argHash;
    const cbResponse = {
      error: true
    };
    const url = `/v1/clients/${sid}/enabled`;
    const axiosConfig = {
      method: 'PUT',
      baseURL: this.apiUrl,
      url,
      data: payload
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async getClientJurisdiction(argHash) {
    const cbResponse = {
      error: true
    };

    const { queryParam } = argHash;
    const querystring = Object.keys(queryParam)
      .map((key) => `${key}=${encodeURIComponent(queryParam[key])}`)
      .join('&');

    const url = `/v1/cascading-risks/pcg/jurisdiction?${querystring}`;
    const axiosConfig = {
      method: 'GET',
      baseURL: this.apiUrl,
      url
    };

    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async putClientStudentGroupManagements(argHash) {
    const { payload } = argHash;
    const cbResponse = {
      error: true
    };
    const url = `/v1/clients/students/groups/managements`;
    const axiosConfig = {
      method: 'PUT',
      baseURL: this.apiUrl,
      url,
      data: payload
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      if (apiErr && apiErr.response && apiErr.response.data) {
        cbResponse.message = apiErr.response.data.message;
      }
    }
    return cbResponse;
  }

  async getClientStudentGroupListLite(readFromCache) {
    const cbResponse = {
      error: true
    };

    const url = `/v1/clients/students/groups/lite?readFromCache=${encodeURIComponent(
      readFromCache
    )}`;
    const axiosConfig = {
      method: 'GET',
      baseURL: this.apiUrl,
      url
    };

    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse.data;
  }

  async getClientStudentGroupManagements() {
    const cbResponse = {
      error: true
    };

    const url = `/v1/clients/students/groups/managements`;
    const axiosConfig = {
      method: 'GET',
      baseURL: this.apiUrl,
      url
    };

    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse.data;
  }

  async getClientFeatures(argHash) {
    let { queryParam } = argHash;
    const { featureSid } = argHash;
    const cbResponse = {
      error: true
    };
    if (!queryParam) {
      queryParam = {};
    }
    const querystring = Object.keys(queryParam)
      .map((key) => `${key}=${encodeURIComponent(queryParam[key])}`)
      .join('&');
    const url = `/v1/clients/features/${featureSid}?${querystring}`;

    const axiosConfig = {
      method: 'GET',
      baseURL: this.apiUrl,
      url
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async getClientSiteSetting(queryParam) {
    const retResponse = {
      error: true,
      message: null,
      data: {
        defaultAdministrators: null,
        defaultApprovers: null,
        defaultFinalApprovers: null,
        defaultAdministratorsEnforcementEnabled: null,
        defaultApproversEnforcementEnabled: null,
        defaultFinalApproversEnforcementEnabled: null
      }
    };

    const querystring = Object.keys(queryParam || {})
      .map((key) => `${key}=${encodeURIComponent(queryParam[key])}`)
      .join('&');
    const url = `/v1/clients/site-setting?${querystring}`;
    const axiosConfig = {
      method: 'GET',
      baseURL: this.apiUrl,
      url
    };

    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse?.data?.data) {
        Object.keys(retResponse.data).forEach((key) => {
          if (Object.prototype.hasOwnProperty.call(axiosResponse.data.data, key)) {
            retResponse.data[key] = axiosResponse.data.data[key];
          } else {
            throw new Error(`Key ${key} not found in response.`);
          }
        });

        retResponse.error = false;
      } else {
        throw new Error('Invalid response data.');
      }
    } catch (apiErr) {
      if (apiErr?.response?.data) {
        retResponse.message = apiErr.response.data.message;
      } else {
        retResponse.message = apiErr.message || 'Error occurred while fetching site setting.';
      }
    }

    return retResponse;
  }

  async patchClientSiteSetting(payload) {
    const retResponse = {
      error: true,
      message: null,
      data: {
        defaultAdministrators: null,
        defaultApprovers: null,
        defaultFinalApprovers: null,
        defaultAdministratorsEnforcementEnabled: null,
        defaultApproversEnforcementEnabled: null,
        defaultFinalApproversEnforcementEnabled: null
      }
    };

    const url = '/v1/clients/site-setting';
    const axiosConfig = {
      method: 'PATCH',
      baseURL: this.apiUrl,
      url,
      data: payload
    };

    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse?.data?.data) {
        Object.keys(retResponse.data).forEach((key) => {
          if (Object.prototype.hasOwnProperty.call(axiosResponse.data.data, key)) {
            retResponse.data[key] = axiosResponse.data.data[key];
          } else {
            throw new Error(`Key ${key} not found in response.`);
          }
        });

        retResponse.error = false;
      } else {
        throw new Error('Invalid response data.');
      }
    } catch (apiErr) {
      if (apiErr?.response?.data) {
        retResponse.message = apiErr.response.data.message;
      } else {
        retResponse.message = apiErr.message || 'Error occurred while fetching site setting.';
      }
    }

    return retResponse;
  }

  async putClientRiskMatrix(argHash) {
    const { payload, store } = argHash;
    const cbResponse = {
      error: true
    };
    const url = `/v1/clients/risk-matrix`;
    const axiosConfig = {
      method: 'PUT',
      baseURL: this.apiUrl,
      url,
      data: payload
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      store.commit(types.COMMON_SET_IS_RESPONSE_WITH_ERROR, false);
      if (apiErr && apiErr.response && apiErr.response.data) {
        cbResponse.message = apiErr.response.data.message;
      }
    }
    return cbResponse;
  }

  async getClientRiskMatrix(argHash) {
    let { queryParam } = argHash;
    const cbResponse = {
      error: true
    };
    if (!queryParam) {
      queryParam = {};
    }
    const querystring = Object.keys(queryParam)
      .map((key) => `${key}=${encodeURIComponent(queryParam[key])}`)
      .join('&');
    const url = `/v1/clients/risk-matrix?${querystring}`;

    const axiosConfig = {
      method: 'GET',
      baseURL: this.apiUrl,
      url
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      if (apiErr && apiErr.response && apiErr.response.data) {
        cbResponse.message = apiErr.response.data.message;
      }
    }
    return cbResponse;
  }

  async postExcursionCopies(argHash) {
    const { id, payload, store } = argHash;
    const cbResponse = {
      error: true
    };
    const url = `/v1/excursions/${id}/copies`;
    const axiosConfig = {
      method: 'POST',
      baseURL: this.apiUrl,
      url,
      data: payload
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      store.commit(types.COMMON_SET_IS_RESPONSE_WITH_ERROR, false);
      if (apiErr && apiErr.response && apiErr.response.data) {
        cbResponse.message = apiErr.response.data.message;
      }
    }
    return cbResponse;
  }

  async getClientRiskCategories(argHash) {
    let { queryParam } = argHash;
    const cbResponse = {
      error: true
    };
    if (!queryParam) {
      queryParam = {};
    }
    const querystring = Object.keys(queryParam)
      .map((key) => `${key}=${encodeURIComponent(queryParam[key])}`)
      .join('&');
    const url = `/v1/clients/risk-categories?${querystring}`;

    const axiosConfig = {
      method: 'GET',
      baseURL: this.apiUrl,
      url
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      if (apiErr && apiErr.response && apiErr.response.data) {
        cbResponse.message = apiErr.response.data.message;
      }
    }
    return cbResponse;
  }

  async putClientRiskCategoryBlacklist(argHash) {
    const { payload, store } = argHash;
    const cbResponse = {
      error: true
    };
    const url = `/v1/clients/risk-categories/blacklist`;
    const axiosConfig = {
      method: 'PUT',
      baseURL: this.apiUrl,
      url,
      data: payload
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      store.commit(types.COMMON_SET_IS_RESPONSE_WITH_ERROR, false);
      if (apiErr && apiErr.response && apiErr.response.data) {
        cbResponse.message = apiErr.response.data.message;
      }
    }
    return cbResponse;
  }

  async postPcgRiskCategorySync(argHash) {
    const { payload } = argHash;
    const cbResponse = {
      error: true
    };
    const url = `/v1/cascading-risks/pcg/risk-categories/sync`;
    const axiosConfig = {
      method: 'POST',
      baseURL: this.apiUrl,
      url,
      data: payload
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.status === 200) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.status;
      }
    } catch (apiErr) {
      if (apiErr && apiErr.response && apiErr.response.data) {
        cbResponse.message = apiErr.response.data.message;
      }
    }
    return cbResponse;
  }

  async getClientIdentifiers(argHash) {
    const { domain, store } = argHash;
    const cbResponse = {
      error: true
    };
    const url = `/v1/clients/identifiers/${domain}`;
    const axiosConfig = {
      method: 'GET',
      baseURL: this.apiUrl,
      url
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      if (
        apiErr &&
        apiErr.response &&
        apiErr.response.data &&
        `${apiErr.response.data.statusCode}` === '404'
      ) {
        store.commit(types.COMMON_SET_IS_RESPONSE_WITH_ERROR, false);
      } else {
        cbResponse.error = true;
      }
    }

    return cbResponse;
  }

  async getAuditStudentMangement() {
    const cbResponse = {
      error: true
    };
    const url = `/v1/audit/clients/students/groups/managements`;
    const axiosConfig = {
      method: 'GET',
      baseURL: this.apiUrl,
      url
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async putStudentGroupManagementReversion(argHash) {
    const { index, store } = argHash;
    const cbResponse = {
      error: true
    };
    const url = `/v1/clients/students/groups/managements/reversion/${index}`;
    const axiosConfig = {
      method: 'PUT',
      baseURL: this.apiUrl,
      url
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      store.commit(types.COMMON_SET_IS_RESPONSE_WITH_ERROR, false);
      if (apiErr && apiErr.response && apiErr.response.data) {
        cbResponse.message = apiErr.response.data.message;
      }
    }
    return cbResponse;
  }

  async getUserAuth(argHash) {
    const { store } = argHash;
    const cbResponse = {
      error: true
    };
    const url = `/v1/users/auth`;
    const axiosConfig = {
      method: 'GET',
      baseURL: this.apiUrl,
      url
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      if (
        apiErr &&
        apiErr.response &&
        apiErr.response.data &&
        `${apiErr.response.data.statusCode}` === '400'
      ) {
        store.commit(types.COMMON_SET_IS_RESPONSE_WITH_ERROR, false);
      } else {
        cbResponse.error = true;
      }
    }
    return cbResponse;
  }

  async getClientStudentGroups(clientSubdomain) {
    const cbResponse = { error: true };
    const url = '/v1/clients/students/groups/managements';

    const axiosConfig = {
      method: 'GET',
      baseURL: this.apiUrl,
      url,
      headers: {
        'x-client-subdomain': clientSubdomain,
        'x-impersonated-client-sid': ''
      }
    };

    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.data = axiosResponse.data.data;
        cbResponse.error = false;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }

    return cbResponse;
  }

  async getClientStudentByGroups(clientSid, groups) {
    const cbResponse = { error: true };
    const url = '/v1/clients/pcg/students';

    try {
      const promises = groups.map((group) => {
        const axiosConfig = {
          method: 'GET',
          baseURL: this.apiUrl,
          url,
          headers: {
            'x-impersonated-client-sid': clientSid
          },
          params: {
            'group-id': group
          }
        };

        return this.axiosInstance(axiosConfig).then((response) => ({ response, group }));
      });

      const axiosResponses = await Promise.all(promises);

      const students = axiosResponses
        .filter(({ response }) => response && response.data.data)
        .flatMap(({ response, group }) =>
          response.data.data.map((item) => ({
            ...item,
            groupId: group
          }))
        );

      cbResponse.data = students;
      cbResponse.error = false;
    } catch (apiErr) {
      cbResponse.error = true;
    }

    return cbResponse;
  }

  async getClientStudentCaches(argHash) {
    const { sid } = argHash;
    const cbResponse = {
      error: true
    };
    const url = `/v1/clients/${sid}/students/caches`;
    const axiosConfig = {
      method: 'GET',
      baseURL: this.apiUrl,
      url
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async postClientBgtStudentCaches(argHash) {
    const { sid } = argHash;
    const cbResponse = {
      error: true
    };
    const url = `/v1/clients/${sid}/bgt/students/caches`;
    const axiosConfig = {
      method: 'POST',
      baseURL: this.apiUrl,
      url
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async getClientUserExports() {
    const cbResponse = {
      error: true
    };

    const url = `/v1/clients/users/exports`;
    const axiosConfig = {
      method: 'GET',
      baseURL: this.apiUrl,
      url
    };
    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async getWondeTestConnection(studentVendor, apiEndpoint, tokenKey, schoolId) {
    const cbResponse = {
      error: true
    };

    const url =
      studentVendor && apiEndpoint && tokenKey && schoolId
        ? `/v1/clients/students/test-connection?studentVendor=${studentVendor}&apiEndpoint=${apiEndpoint}&tokenKey=${tokenKey}&schoolId=${schoolId}`
        : '/v1/clients/students/test-connection';

    const axiosConfig = {
      method: 'GET',
      baseURL: this.apiUrl,
      url
    };

    try {
      const axiosResponse = await this.axiosInstance(axiosConfig);
      if (axiosResponse && axiosResponse.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      if (apiErr?.response?.data?.statusCode) {
        cbResponse.error = false;
        cbResponse.data = apiErr.response.data;
      } else {
        cbResponse.message = apiErr.message;
      }
    }

    return cbResponse;
  }
}

export default {
  Adapter,
  excursionResponseHandler,
  providerResponseHandler,
  riskResponseHandler
};
