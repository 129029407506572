import downloadDocx from './downloadDocx';

const exportRiskAssessmentDocx = async (
  stbApiAdapter,
  store,
  excursionId,
  normalizedRequestParam,
  allChecklistsExported,
  clientLogoEnabled,
  predepartureChecklistExported,
  allAttachmentsExported,
  deviceDetector
) => {
  const argHash = {
    store,
    queryParam: normalizedRequestParam,
    excursionId,
    allChecklistsExported,
    clientLogoEnabled,
    predepartureChecklistExported,
    allAttachmentsExported,
    format: 'docx'
  };

  const response = await stbApiAdapter.postExcursionAssessments(argHash);
  downloadDocx(response.data.computed.source, response.data.computed.filename, deviceDetector);
};

export default exportRiskAssessmentDocx;
