<template>
  <div class="left_menu_container">
    <v-card dark class="settings-content">
      <v-card-text class="px-0 pr-0 mr-0">
        <!-- start navigation links -->
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="client-admin-setting-section-title">
                ADMINISTRATION
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-expansion-panels
            class="app-side-nav-expansion-panels"
            accordion
            multiple
            v-model="panel"
          >
            <v-expansion-panel
              id="app-side-nav-system-admin"
              v-if="isAdminPanel && systemMenuItemList.length !== 0"
            >
              <v-expansion-panel-header>
                <span>
                  <v-icon>person_search</v-icon>
                  <span class="ml-3">System Administration</span>
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-list-item v-for="item in systemMenuItemList" :key="item.title">
                  <v-list-item-content>
                    <router-link :to="item.link">
                      <v-list-item-title
                        class="client-admin-setting-item-title"
                        :id="getLinkItemId(item.title)"
                      >
                        {{ item.title }}
                      </v-list-item-title>
                    </router-link>
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel
              id="app-side-nav-client-management"
              v-if="isAdminPanel && clientMenuItemList.length !== 0"
            >
              <v-expansion-panel-header>
                <span>
                  <v-icon>manage_accounts</v-icon>
                  <span class="ml-3">Client Management</span>
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-list-item v-for="item in clientMenuItemList" :key="item.title">
                  <v-list-item-content>
                    <router-link :to="item.link">
                      <v-list-item-title
                        class="client-admin-setting-item-title"
                        :id="getLinkItemId(item.title)"
                      >
                        {{ item.title }}
                      </v-list-item-title>
                    </router-link>
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel v-if="!isAdminPanel">
              <v-expansion-panel-header>
                <span>
                  <v-icon>travel_explore</v-icon>
                  <span class="ml-3">Excursion Management</span>
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-list-item v-for="item in excursionMenuItemList" :key="item.title">
                  <v-list-item-content>
                    <router-link :to="item.link">
                      <v-list-item-title
                        class="client-admin-setting-item-title"
                        :id="getLinkItemId(item.title)"
                      >
                        {{ item.title }}
                      </v-list-item-title>
                    </router-link>
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-list>

        <!-- end: navigation links -->
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { CSBase } from '@complispace/cs-design-system';
import { mapState } from 'vuex';
import sessionManager from '@/store/modules/session/sessionManager';

export default {
  extends: CSBase,

  props: [],

  data() {
    return {
      active: 0,
      panel: []
    };
  },
  computed: {
    ...mapState({
      publicSchoolFeatureSetEnabled: (state) => state.common.publicSchoolFeatureSetEnabled,
      clientUsersTabDisplayed: (state) => state.common.clientUsersTabDisplayed,
      usersTabDisplayed: (state) => state.common.usersTabDisplayed,
      clientsTabDisplayed: (state) => state.common.clientsTabDisplayed,
      isInitClientConfigInProgress: (state) => state.authorisation.isInitClientConfigInProgress
    }),

    isAdminPanel() {
      const isAdminPanel = String(this.$route.path).includes('/admin');
      if (!isAdminPanel) {
        this.panel = [0];
      } else if (sessionManager.getClientSubdomain() !== '') {
        if (this.$route.name === 'ClientAdminLayout') {
          this.panel = [];
        } else {
          this.panel = [0];
        }
      } else if (this.$route.name === 'ClientAdminLayout') {
        this.panel = [];
      } else if (
        this.$route.name === 'SafeTripBuilderUsersList' ||
        this.$route.name === 'SafeTripBuilderClientsList' ||
        this.$route.name === 'SafeTripBuilderClientNewRecord' ||
        this.$route.name === 'SafeTripBuilderClientExistingRecord'
      ) {
        this.panel = [1];
      } else {
        this.panel = [0];
      }
      return isAdminPanel;
    },
    clientMenuItemList() {
      const { clientSelfGroupManagementDisplayed } = this.$store.state.common;
      const { studentManagementSubjectManagementDisplayed } = this.$store.state.common;
      const riskMatrixManagementDisplayed = !this.publicSchoolFeatureSetEnabled;
      const { riskCategoryManagementMenuItemDisplayed } = this.$store.state.common;
      const { operationManagementMenuItemDisplayed } = this.$store.state.common;

      const localMenuItemList = [
        {
          title: 'Site Users',
          icon: 'person_add',
          link: '/admin/clients/users',
          menuItemDisplayEnabled: this.clientUsersTabDisplayed && this.isAdminPanel
        },
        {
          title: 'Site Settings',
          icon: 'group',
          link: '/admin/site-settings',
          menuItemDisplayEnabled: this.isAdminPanel
        },
        {
          title: 'Groups',
          icon: 'group',
          link: '/admin/group',
          menuItemDisplayEnabled: studentManagementSubjectManagementDisplayed && this.isAdminPanel
        },
        {
          title: 'Student Management',
          icon: 'group',
          link: '/admin/student-management',
          menuItemDisplayEnabled: clientSelfGroupManagementDisplayed && this.isAdminPanel
        },
        {
          title: 'Risk Matrix Management',
          icon: 'group',
          link: '/admin/risk-management',
          menuItemDisplayEnabled: riskMatrixManagementDisplayed && this.isAdminPanel
        },
        {
          title: 'Risk Category Management',
          icon: 'emergency',
          link: '/admin/risk-category-management',
          menuItemDisplayEnabled: riskCategoryManagementMenuItemDisplayed && this.isAdminPanel
        },
        {
          title: 'Operation Management',
          icon: 'emergency',
          link: '/admin/operation-management',
          menuItemDisplayEnabled: operationManagementMenuItemDisplayed && this.isAdminPanel
        }
      ];

      return localMenuItemList.filter((item) => item.menuItemDisplayEnabled);
    },
    excursionMenuItemList() {
      const localMenuItemList = [
        {
          title: 'Excursions',
          icon: 'travel_explore',
          link: '/excursions',
          menuItemDisplayEnabled: !this.isAdminPanel
        },
        {
          title: 'Excursion Templates',
          icon: 'note',
          link: '/excursions/templates',
          menuItemDisplayEnabled: !this.isAdminPanel
        }
      ];

      return localMenuItemList.filter((item) => item.menuItemDisplayEnabled);
    },
    systemMenuItemList() {
      const localMenuItemList = [
        {
          title: 'All Clients',
          icon: 'manage_accounts',
          link: '/admin/clients',
          menuItemDisplayEnabled: this.clientsTabDisplayed && this.isAdminPanel
        },
        {
          title: 'All Users',
          icon: 'person_search',
          link: '/admin/users',
          menuItemDisplayEnabled: this.usersTabDisplayed && this.isAdminPanel
        }
      ];

      return localMenuItemList.filter((item) => item.menuItemDisplayEnabled);
    }
  },
  methods: {
    getLinkItemId(linkItem) {
      return `client-admin-layout--menu-item--link-${linkItem}`;
    },
    pageLoadingAnimationDisplayed() {
      let loadingAnimationDisplayed = false;

      if (this.isInitClientConfigInProgress) {
        loadingAnimationDisplayed = true;
      }

      return loadingAnimationDisplayed;
    }
  }
};
</script>
