<template>
  <div class="btn-padding-0">
    <v-btn
      v-if="categoryName === undefined && !showViewPolicyLabel"
      class="normal-btn white--text primary"
      :disabled="elementDisabled"
      :class="showViewPolicyLabel ? 'normal-btn' : ''"
      @click.stop="getData()"
      id="btnViewPolicy"
    >
      <v-flex v-if="showViewPolicyLabel != true">View Excursion Policy</v-flex>
      <v-flex v-else>View Policy</v-flex>
    </v-btn>

    <v-btn
      v-else
      :disabled="elementDisabled"
      @click.native="getData()"
      :loading="policyDisplayed"
      depressed
      class="normal-btn category-view-policy"
      :id="viewPolicyBtnId"
    >
      View Policy
    </v-btn>
    <v-dialog v-model="policyDisplayed" scrollable persistent>
      <v-card v-if="policyDisplayed">
        <v-card-title class="modalTitle">
          <v-flex>
            <strong v-if="!activitySupervisorScrollDisplayed">View Policy</strong>
            <v-btn
              id="policy-view--supervisor-qualifications-display-button"
              v-if="activitySupervisorScrollDisplayed && content && 0 < content.length"
              @click.stop="turnToSupervisor"
              depressed
              class="normal-btn"
              style="width: auto"
            >
              Supervision Qualifications and Ratios
            </v-btn>
            <v-chip
              v-if="ratioChipDisplayed"
              class="ratio-chip"
              close
              id="view-policy--policy-display-dialog--ratio-chip-display-close--button"
              @click:close="ratioChipDisplayed = false"
              color="#ff7f32"
              label
              outlined
              >Calculated Supervision Ratio - {{ getStaffToStudentRatio() }}</v-chip
            >
          </v-flex>
          <v-btn id="view-policy--policy-display-dialog--close--button" icon @click="policyDisplayed = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="modalDivider"></v-divider>
        <v-card-text id="policy" class="policy view-checklist-policy">
          <div v-if="!content || content.length <= 0" class="complete-loading">
            <v-progress-circular
              :size="70"
              color="rgb(29, 175, 236)"
              class="loading-img"
              indeterminate
            ></v-progress-circular>
          </div>
          <v-list id="policyList" v-for="(option, index) in content" :key="index">
            <vue-markdown :source="option.Policy" :typographer="false"></vue-markdown>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="policyDisplayed = false" class="normal-btn" id="btnClose">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as types from "@/store/mutationTypes";
import riskCategoryLabel from "../lib/const/riskCategoryLabel";

export default {
  props: [
    "categoryName",
    "riskTypeName",
    "riskClassName",
    "riskName",
    "elementDisabled",
    "showViewPolicyLabel",
    "activitySupervisorScrollDisplayed",
    "masterPolicyExported",
    "excursionPolicyExported"
  ],
  data: () => ({
    ratioChipDisplayed: false,
    policyDisplayed: false,
    content: [],
    masterPolicyRiskCategory: ["Master Excursions Policy"]
  }),
  computed: {
    activityTlrcName() {
      // tlrc stands for: top level risk category
      return riskCategoryLabel.ACTIVITIES;
    },
    allSelectedActivityRisk() {
      return this.$store.getters["common/getSelectedActivityClasses"][0];
    },
    viewPolicyBtnId() {
      let idViewPolicy;

      if ("undefined" !== typeof this.categoryName) {
        idViewPolicy = `btnViewPolicy${this.categoryName.replace(/\s/g, "")}`;
      }

      if ("undefined" !== typeof this.riskTypeName && "undefined" !== typeof this.riskClassName) {
        idViewPolicy = `btnViewPolicy${this.riskTypeName.replace(
          /\s/g,
          ""
        )}${this.riskClassName.replace(/\s/g, "")}`;
      }

      if (!idViewPolicy) {
        idViewPolicy = "btnViewPolicyUndefined";
      }

      return idViewPolicy;
    },
  },
  methods: {
    getStaffToStudentRatio() {
      const { excursionDc } = this.$store.state.common;
      return this.$app.excursionCommon.getStaffToStudentRatio(
        excursionDc.staffSupervisorAttendanceCount,
        excursionDc.studentAttendanceCount
      );
    },
    turnToSupervisor() {
      const supervisorSelector = $("h3:contains(Supervisor Qualifications)");
      const supervisionSelector = $("h3:contains(Supervision Ratios)");
      supervisorSelector.css("color", "rgb(29, 175, 236)");
      supervisionSelector.css("color", "rgb(29, 175, 236)");
      const policyListSelector = $("#policyList");
      if (
        supervisorSelector
        && supervisorSelector.position()
        && supervisorSelector
        && supervisorSelector.position()
      ) {
        const supervisorTop = supervisorSelector.position().top;
        const policyListTop = policyListSelector.position().top;
        const policyElement = document.getElementById("policy");
        policyElement.scrollTop = supervisorTop - policyListTop + 30;
      }

      this.ratioChipDisplayed = true;
    },
    async getData() {
      this.ratioChipDisplayed = true;
      this.content = [];
      this.policyDisplayed = true;
      const that = this;

      if (this.categoryName === undefined) {
        const riskCategories = this.$store.state.common.excursionDc.categories.filter(
          tlrc => `${tlrc.name}` !== `${that.activityTlrcName}`
        );
        const paramRiskCategories = this.generateAllParams(riskCategories);
        if (this.masterPolicyExported) {
          paramRiskCategories.push(this.masterPolicyRiskCategory);
        }

        const argHash = {
          queryParam: this.$route.query,
          store: this.$store,
          frcs: paramRiskCategories
        };

        const apiResponse = await this.$app.stbApiAdapter.getViewPolicy(argHash);
        const { mandatory } = apiResponse;
        const { optional } = apiResponse;
        const excursionName = that.$store.state.common.excursionDc.name;
        let headerName = `${excursionName} - Excursion Policy`;
        if (that.masterPolicyExported) {
          that.$store.commit(types.COMMON_SET_CE_COMMIT_OID, apiResponse.ceCommitOid);
          headerName = `Master Excursions Policy`;
        }
        if (mandatory && mandatory.length && mandatory[0].Policy) {
          mandatory[0].Policy = mandatory[0].Policy.replace(
            that.masterPolicyRiskCategory[0],
            `${headerName}`
          );
        }
        that.content = mandatory.concat(optional);
      } else if (
        "undefined" !== typeof this.categoryName
        && undefined === this.riskTypeName
        && undefined === this.riskClassName
      ) {
        const recordedTlrc = this.$store.state.common.excursionDc.categories.find(
          tlrc => tlrc.name === this.categoryName
        );

        let paramRiskCategories = [];

        if (this.categoryName !== that.activityTlrcName) {
          paramRiskCategories = this.generateParams(recordedTlrc);
        } else {
          paramRiskCategories = this.generateParams(this.allSelectedActivityRisk);
        }

        if (0 === paramRiskCategories.length) {
          const paramRiskCategory = [recordedTlrc.name];
          paramRiskCategories.push(paramRiskCategory);
        }

        const argHash = {
          queryParam: this.$route.query,
          store: this.$store,
          frcs: paramRiskCategories
        };

        const apiResponse = await this.$app.stbApiAdapter.getViewPolicy(argHash);
        const { optional } = apiResponse;
        this.content = optional;
        this.$store.commit(types.COMMON_SET_CE_COMMIT_OID, apiResponse.ceCommitOid);
      } else {
        const riskType = this.$app.enumerable
        .from(this.allSelectedActivityRisk.types)
        .firstOrDefault(x => x.name === this.riskTypeName);

        const riskClass = this.$app.enumerable
        .from(riskType.classes)
        .firstOrDefault(x => x.name === this.riskClassName);

        const frcs = [];

        for (let riskKey = 0; riskKey < riskClass.risks.length; riskKey += 1) {
          const riskName = riskClass.risks[riskKey].name;
          const paramRiskCategory = [
            this.activityTlrcName,
            riskType.name,
            riskClass.name,
            riskName
          ];
          frcs.push(paramRiskCategory);
        }

        if (0 < frcs.length) {
          const argHash = {
            queryParam: this.$route.query,
            store: this.$store,
            frcs
          };
          const response = await this.$app.stbApiAdapter.getViewPolicy(argHash);
          const { optional } = response;
          this.content = optional;
          this.$store.commit(types.COMMON_SET_CE_COMMIT_OID, response.ceCommitOid);
        }
      }
    },
    generateParams(riskCategory) {
      const paramRiskCategories = [];
      const { activityTlrcName } = this;
      if (riskCategory.name !== activityTlrcName) {
        riskCategory.types.forEach(function(riskType) {
          riskType.classes.forEach(function(riskClass) {
            const paramRiskCategory = [riskCategory.name, riskType.name, riskClass.name];
            paramRiskCategories.push(paramRiskCategory);
          });
        });
      } else {
        riskCategory.types.forEach(function(riskType) {
          riskType.classes.forEach(function(riskClass) {
            for (let riskKey = 0; riskKey < riskClass.risks.length; riskKey += 1) {
              const riskName = riskClass.risks[riskKey].name;
              const paramRiskCategory = [activityTlrcName, riskType.name, riskClass.name, riskName];
              paramRiskCategories.push(paramRiskCategory);
            }
          });
        });
      }
      return paramRiskCategories;
    },
    generateAllParams(riskCategories) {
      const paramRiskCategories = [];
      const { activityTlrcName } = this;

      riskCategories.forEach(function(category) {
        category.types.forEach(function(riskType) {
          riskType.classes.forEach(function(riskClass) {
            const paramRiskCategory = [category.name, riskType.name, riskClass.name];
            paramRiskCategories.push(paramRiskCategory);
          });
        });
      });
      if ("undefined" !== typeof this.allSelectedActivityRisk) {
        this.allSelectedActivityRisk.types.forEach(function(riskType) {
          riskType.classes.forEach(function(riskClass) {
            for (let riskKey = 0; riskKey < riskClass.risks.length; riskKey += 1) {
              const riskName = riskClass.risks[riskKey].name;
              const paramRiskCategory = [activityTlrcName, riskType.name, riskClass.name, riskName];
              paramRiskCategories.push(paramRiskCategory);
            }
          });
        });
      }
      return paramRiskCategories;
    }
  },
  updated() {
    const h1Elements = $("#policy h1");
    const h2Elements = $("#policy h2");
    const h3Elements = $("#policy h3");
    h1Elements.each((index, h1Element) => {
      $(h1Element).attr("id", `view-policy-modal--h1-${index}`);
    });

    h2Elements.each((index, h2Element) => {
      $(h2Element).attr("id", `view-policy-modal--h2-${index}`);
    });

    h3Elements.each((index, h3Element) => {
      $(h3Element).attr("id", `view-policy-modal--h3-${index}`);
    });
  }
};
</script>
<style scoped>
.ratio-chip {
  height: 30px;
  margin: 0 0 0 8px;
}


.policy {
  padding-left: 30px;
  padding-right: 30px;
  font: 14px Roboto;
  color: #808080;
  line-height: 20px;
}

.policy .header {
  color: #c80063;
}

.policy table {
  border-collapse: collapse;
  vertical-align: middle;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.policy table .header {
  color: #ff7f32 !important;
  font-weight: bold;
  text-align: center;
}

.policy table .odd {
  background-color: #eeeeee;
}

.policy table td,
.policy table th {
  vertical-align: middle;
  border-width: 1px;
  border-style: solid;
  border-color: #bbbbbb;
  padding: 8px 4px 8px 4px;
}

.disabled {
  color: rgba(0, 0, 0, 0.26) !important;
}

h1 {
  font: bold 22px Roboto;
  color: #808080;
  border-bottom: 3px solid #ff7f32;
  margin-bottom: 20px;
}

h2 {
  font: bold 20px Roboto;
  color: #ff7f32;
}

h3 {
  font: bold 16px Roboto;
  color: #808080;
}

.policy h3 {
  margin-top: 25px;
  margin-bottom: 5px;
}

.category-view-policy {
  margin-right: 2px;
  font-weight: unset;
}

.sub-category-view-policy {
  margin-right: 48px;
}
</style>
